import styled, { css } from 'styled-components'
import { BackgroundImage, Column } from '../../../../atoms'
import HeadingStyles from '../../../../atoms/Title/styles'
import { HeroWrapperProps } from './types'

const {
  styles: { H4Styles },
} = HeadingStyles

export const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 1680px;
  max-height: 880px;
  height: 100%;
  background-position: bottom;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-height: 840px;
    background-position: top center;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    overflow: hidden;
    &::after {
      width: 1680px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      content: '';
      background-image: linear-gradient(
          to top,
          black 5%,
          rgba(0, 0, 0, 0.4) 10%,
          rgba(0, 0, 0, 0) 15%
        ),
        linear-gradient(
          to left,
          black 1%,
          rgba(0, 0, 0, 0.5) 3%,
          rgba(0, 0, 0, 0) 7%
        ),
        linear-gradient(
          to right,
          black 1%,
          rgba(0, 0, 0, 0.5) 3%,
          rgba(0, 0, 0, 0) 7%
        );
    }
  }
`

export const Wrapper = styled.div<HeroWrapperProps>`
  background-color: ${(props) => props.theme.colors.background.white};
  min-height: 30rem;
`

export const WrapperWithBackgroundImage = styled(Wrapper as any)`
  position: relative;
  background-color: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};

  ${(props) =>
    props.short
      ? css`
          min-height: 50rem;
          @media ${(props) => props.theme.breakpoints.tablet} {
            min-height: 65rem;
          }
        `
      : css`
          min-height: 65rem;
            @media ${(props) => props.theme.breakpoints.tablet} {
              min-height: 84rem;
            }
          }
        `}
  h1 {
    b {
      font-weight: ${(props) => props.theme.fontWeights.light};
      color: ${(props) => props.theme.colors.text.blue}
    }
    
    p {
      b {
        font-weight: ${(props) => props.theme.fontWeights.bold};
      }
    }
    `

export const TitleColumn = styled(Column)`
  margin-bottom: 2rem;
  max-width: 34rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 2.5rem;
    max-width: 38rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 3.4rem;
    max-width: 50rem;
  }
`

export const ParagraphColumn = styled(Column)`
  margin-bottom: 1rem;
  max-width: 36rem;

  p {
    margin-bottom: 1.5rem;
  }

  h2,
  h3 {
    ${H4Styles}
    margin: 2rem 0;
  }

  ul {
    margin: 0 0 2rem;

    li {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  button {
    margin-top: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin-bottom: 15rem;

    ul li p {
      font-size: 1.5rem;
    }
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: inherit;
  }
`
export const TrustPilotRatingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;
  background: transparent;
  min-height: 0px;
`
