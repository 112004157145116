import React from 'react'
import CallToAction from '../CallToAction'

import {
  LeftContainer,
  LeftText,
  RightContainer,
  RightText,
  CTAWrapper,
  Wrapper,
} from './styles'
import { Props } from './types'

const TwoColumnContentBlock: React.FunctionComponent<Props> = (props) => {
  const {
    data: {
      leftContentBlock,
      leftCallToAction,
      rightContentBlock,
      rightCallToAction,
      componentTheme: { colour },
    },
    className,
  } = props

  return (
    <Wrapper className={className} data-bdd-selector="two-column-block">
      {leftContentBlock && (
        <LeftContainer data-bdd-selector="two-column-left-block">
          <LeftText useTicksForBullets html={leftContentBlock} />
          {leftCallToAction && (
            <CTAWrapper>
              <CallToAction {...leftCallToAction} />
            </CTAWrapper>
          )}
        </LeftContainer>
      )}
      {rightContentBlock && (
        <RightContainer
          colorTheme={colour}
          data-bdd-selector="two-column-right-block"
        >
          <RightText
            useTicksForBullets
            colorTheme={colour}
            html={rightContentBlock}
          />
          {rightCallToAction && (
            <CTAWrapper>
              <CallToAction {...rightCallToAction} />
            </CTAWrapper>
          )}
        </RightContainer>
      )}
    </Wrapper>
  )
}

export default TwoColumnContentBlock
