import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { Image, Link } from '../../atoms'
import ParagraphStyles from '../Paragraph/styles'

import { WrapperProps } from './types'

const {
  styles: { smallStyles, overlineStyles, tagStyles },
} = ParagraphStyles

export const LinkWrapper = styled(Link)`
  display: block;
  height: 100%;
`

export const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.6rem 1.1rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 2rem 2rem 1.4rem;
  }
`

export const Wrapper = styled.div<WrapperProps>`
  display: block;
  height: 100%;
  color: #fff;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  background-size: cover;
  transition: transform ${(props) => props.theme.transitions.normal} ease,
    box-shadow ${(props) => props.theme.transitions.moderate} ease;

  &:focus,
  &:active,
  &:hover {
    color: #fff;
    transform: scale(1.02);
    box-shadow: 0 0 1.2rem 0
      ${(props) => rgba(props.theme.colors.background.black, 0.2)};
  }

  &::before {
    content: '';
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.7;
  }

  ${(props) =>
    !props.hasTextLimit &&
    (props.index === 0 || props.index === 5) &&
    css`
        ${Content} {
          @media ${props.theme.breakpoints.tablet} {
            max-width: 80%;
          }

          @media ${props.theme.breakpoints.desktop} {
            max-width: 70%;
          }
        }
      }
    `}
`

export const StyledImage = styled(Image)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Description = styled.div`
  ${smallStyles}

  margin-top: 0.8rem;
`

export const Published = styled.span``

export const ReadTime = styled.span``

export const Footer = styled.div`
  ${overlineStyles}
  display: flex;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-top: auto;
  padding-top: 2rem;
  ${Published} + ${ReadTime} {
    margin-left: 1.8rem;
    position: relative;

    &::before {
      content: '';
      width: 0.2rem;
      height: 0.2rem;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: -1rem;
      transform: translateY(-50%);
      background: currentColor;

      @media ${(props) => props.theme.breakpoints.desktop} {
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
`

export const Tag = styled.div`
  ${tagStyles}
  font-weight: ${(props) => props.theme.fontWeights.bold};
  border: 1px solid currentColor;
  padding: 0.4rem 0.8rem;
  align-self: flex-start;
  border-radius: 25px;
  line-height: 1;
`

export const Title = styled.div`
  margin-top: 1.3rem;
  font-size: 2rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.close};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 1.5rem;
  }
`
