import React from 'react'

import { buttonKeyDownHandler } from '../../../../utils/helpers'

import {
  CloseButton,
  CloseIcon,
  Container,
  Header,
  ImageWrapper,
  Info,
  Location,
  LocationContainer,
  LocationIcon,
  Quote,
  StyledImage,
  Title,
} from './styles'
import { Props } from './types'

const AdviserCarouselItem: React.FunctionComponent<Props> = (props) => {
  const {
    cardImage,
    name,
    quote,
    region,
    onClick,
    onCloseClick,
    opened,
    connected,
  } = props

  return (
    <Container opened={opened} data-bdd-selector="adviser-carousel-item">
      <ImageWrapper
        tabIndex={0}
        role="button"
        onClick={onClick}
        onKeyDown={(event) => buttonKeyDownHandler(event, onClick)}
      >
        {cardImage && (
          <StyledImage
            data={[cardImage]}
            keepRatio={true}
            size="small"
            data-bdd-selector="adviser-carousel-item-image"
          />
        )}
      </ImageWrapper>
      <Info>
        <Header>
          {name && (
            <Title data-bdd-selector="adviser-carousel-item-title">
              {name}
            </Title>
          )}
          <CloseButton
            role="button"
            tabIndex={0}
            onClick={onCloseClick}
            title="Close description popup"
            onKeyDown={(event) => buttonKeyDownHandler(event, onCloseClick)}
          >
            <CloseIcon
              wrapper="span"
              src={'/static/icons/Close.svg'}
              data-bdd-selector="adviser-carousel-item-close"
            />
          </CloseButton>
        </Header>

        {region && (
          <LocationContainer>
            <LocationIcon
              src={
                connected
                  ? '/static/icons/remote.svg'
                  : '/static/icons/location.svg'
              }
              data-bdd-selector="adviser-carousel-item-location-icon"
            />
            <Location data-bdd-selector="adviser-carousel-item-location">
              {region}
            </Location>
          </LocationContainer>
        )}

        {quote && (
          <Quote data-bdd-selector="adviser-carousel-item-quote">{quote}</Quote>
        )}
      </Info>
    </Container>
  )
}

export default AdviserCarouselItem
