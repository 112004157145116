import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { RichText } from '../../../common/atoms'

import { StyledMetaDataListItem, StyledWrapper } from './types'

export const Wrapper = styled.div<StyledWrapper>`
  display: flex;
  align-items: flex-end;
  min-height: 4rem;
  padding: 3rem 0 2.5rem;
  color: ${(props) =>
    colorTheme(props.colorTheme, 'articleHero.textBaseColor')};

  @media ${(props) => props.theme.breakpoints.tablet} {
    min-height: 54rem;
    padding: 3rem 0 8.1rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-height: 44rem;
    padding: 3rem 0 7.5rem;
  }
`

export const Overline = styled.div`
  margin-bottom: 1.1rem;
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
  line-height: 1.2;
  letter-spacing: 0.25rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
    line-height: 1;
    letter-spacing: 0.3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
    line-height: 1.43;
    letter-spacing: 0.4rem;
  }
`

export const Headline = styled(RichText)``

export const MetaDataList = styled.ul`
  margin-top: 2rem;
  line-height: ${(props) => props.theme.lineHeights.base};

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: flex;
    flex-direction: row;
  }
`

export const MetaDataListItem = styled.li<StyledMetaDataListItem>`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  & + & {
    @media ${(props) => props.theme.breakpoints.mobileMax} {
      margin-top: 0.7rem;
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-left: 2.4rem;
    }
  }

  svg {
    fill: ${(props) =>
      colorTheme(props.colorTheme, 'articleHero.textBaseColor')};
  }
`

export const MetaDataIcon = styled(ReactSVG)`
  svg {
    height: 1.9rem;
    width: 1.9rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      height: 2.4rem;
      width: 2.4rem;
    }
  }
`

export const MetaDataText = styled.span`
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
  margin-left: 0.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
  }
`
