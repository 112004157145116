import styled from 'styled-components'

import { Column, RichText, Tabs } from '../../atoms'
import { TwoColumnContentBlock } from '../../molecules'
import { StyledColumn } from './types'

export const StyledTwoColumnContentBlock = styled(TwoColumnContentBlock)`
  margin-top: 2.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 4rem;
  }
`

export const HeaderColumn = styled(Column)<StyledColumn>`
  color: ${(props) => props.isNested && props.theme.colors.text.white};
`

export const Overline = styled(RichText)`
  text-align: center;
  white-space: pre-line;
  letter-spacing: ${(props) => props.theme.letterSpacings.overline};
`

export const Headline = styled(RichText)`
  text-align: center;
  white-space: pre-line;
  margin-top: 0.8rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 1.6rem;
  }
`

export const Description = styled(RichText)`
  text-align: center;
`

export const StyledTabs = styled(Tabs)`
  margin-top: 4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 6rem;
  }
`
