import * as React from 'react'

import Paragraph from './styles'
import { Props } from './types'

/**
 * A function that creates paragraph element inline with global styles.
 *
 * @param level - Required. The visual styling to apply to the element.
 * @param children - Required. The text content to be rendered as the heading.
 *
 * @returns StyledComponent | Null - The paragraph wrapped with requested
 * element and requested visual style or `null`, if no content provided.
 */
const ParagraphComponent: React.FunctionComponent<Props> = (props) => {
  const { level, children, weight, textAlign } = props

  const levelMapping = {
    large: 1,
    medium: 2,
    small: 3,
  }
  const variants = Object.values(Paragraph.styledComponents)
  const VariantToRender = variants[levelMapping[level] - 1] as any

  return children ? (
    <VariantToRender weight={weight} textAlign={textAlign} {...props}>
      {children}
    </VariantToRender>
  ) : null
}

export default ParagraphComponent
