export interface Props {
  className?: string
  icon: Icons
  useReactSvg?: boolean
}

// TODO: decide on a common naming convention and change everywhere
export type Icons =
  | 'ExclamationMark'
  | 'Tick'
  | 'ChevronDown'
  | 'email'
  | 'phone'
  | 'pin'
  | 'linkedin'
  | 'check'

export function mapIconName(name: string): string {
  if (!name) {
    return 'none'
  }

  if (name.split(' ').length > 1) {
    return name
      .split(' ')
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join('')
  }

  return name
}
