import styled from 'styled-components'

export const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  text-align: left;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3.2rem;
  }
`

export const ArticleCardItem = styled.li`
  height: 100%;
  min-height: 25.8rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    min-height: 27.4rem;

    &:nth-of-type(5n + 1) {
      grid-column: span 2;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-height: 28.4rem;

    &:nth-of-type(5n + 1) {
      grid-column: auto;
    }

    &:nth-of-type(6n + 1) {
      grid-column: span 2;
    }

    &:nth-of-type(6n) {
      grid-column: span 2;
    }
  }
`
