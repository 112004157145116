import * as React from 'react'

import { CaptionText, Credit, Wrapper } from './styles'
import { Props } from './types'

const Caption: React.FunctionComponent<Props> = (props) => {
  const { caption, credit } = props
  return (
    <Wrapper>
      {caption && (
        <CaptionText html={caption} data-bdd-selector="caption-text" />
      )}
      {credit && <Credit html={credit} data-bdd-selector="caption-credit" />}
    </Wrapper>
  )
}

export default Caption
