import { FunctionComponent } from 'react'

import theme from '../../../../styles/theme'
import { Container, Row } from '../../atoms'

import { StyledFeeBlock, Wrapper } from './styles'
import { Breakpoints, FeeBlockColumnWidths, Props } from './types'

const feeBlockMultiColorList = {
  0: theme.colors.background.purple,
  1: theme.colors.background.green,
  2: theme.colors.background.blue,
  3: theme.colors.background.grey4,
}

const colWidths: FeeBlockColumnWidths = {
  3: {
    mobile: 47.25,
    tablet: 30.89,
    desktop: 30.86,
  },
  4: {
    mobile: 47.25,
    tablet: 22.9,
    desktop: 22.9,
  },
}

const feeBlockWhiteColor = theme.colors.background.beigeHighlight

const getBackgroundColor = (color, index) => {
  return color === 'multi'
    ? feeBlockMultiColorList[index]
    : color === 'white'
    ? feeBlockWhiteColor
    : 'transparent'
}

const getTextColor = (color) => {
  return color === 'multi' ? theme.colors.text.white : theme.colors.text.black
}

const isStretch = (index, itemCount) => {
  const oddItemCount = itemCount % 2 !== 0
  return oddItemCount && index === itemCount - 1
}

const getColWidthsPerBreakpoint = (
  itemCount: number,
  stretchItem: boolean
): Breakpoints => {
  if (stretchItem) {
    const stretchLastItemForMobile = {
      ...colWidths[itemCount],
      mobile: 100,
    }
    return stretchLastItemForMobile
  }

  return colWidths[itemCount]
}

const FeeBlockList: FunctionComponent<Props> = (props) => {
  const {
    data,
    className,
    color = 'white',
    desktop,
    tablet,
    mobile,
    verticalAlignment,
  } = props

  return (
    <Container paddingSize="none" data-bdd-selector="fee-block-list">
      <Row>
        <Wrapper
          desktop={desktop}
          tablet={tablet}
          mobile={mobile}
          verticalAlignment={verticalAlignment}
        >
          {data.map((feeBlock, index) => {
            return (
              <StyledFeeBlock
                key={`feebloc-${index}`}
                className={className}
                data={feeBlock}
                backgroundColor={getBackgroundColor(color, index)}
                textColor={getTextColor(color)}
                colWidths={getColWidthsPerBreakpoint(
                  data.length,
                  isStretch(index, data.length)
                )}
              />
            )
          })}
        </Wrapper>
      </Row>
    </Container>
  )
}

export default FeeBlockList
