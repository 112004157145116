import styled from 'styled-components'
import { ContentBlock } from '../../molecules'
import { Row } from '../../atoms'

export const Wrapper = styled.div`
  [class*='__Container'] {
    :first-of-type {
      padding-top: 5rem;
      padding-bottom: 0;
      @media ${(props) => props.theme.breakpoints.desktop} {
        padding-top: 10rem;
      }
    }
    :last-of-type {
      padding-top: 2rem;
      padding-bottom: 6rem;
      @media ${(props) => props.theme.breakpoints.desktop} {
        padding-bottom: 8rem;
      }
    }
  }
  [class*='gm-bundled-control'] {
    display: none;
    @media ${(props) => props.theme.breakpoints.desktop} {
      display: block;
    }
  }
`
export const StyledContentBlock = styled(ContentBlock)`
  h2 {
    font-size: 3rem;
    line-height: 3.4rem;
    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 3.5rem;
      line-height: 3.9rem;
    }
  }

  &&& p + p {
    margin-top: 1.6rem;
  }

  p {
    a,
    a:active,
    a:focus:not(:focus-visible) {
      text-decoration: none;
    }
    a[href^='tel'] {
      font-size: 4rem;
      color: ${(props) => props.theme.colors.text.blue};
      display: block;
      margin-left: 0;
      margin-bottom: 3.4rem;
      font-weight: ${(props) => props.theme.fontWeights.light};
      line-height: 4.5rem;
      @media ${(props) => props.theme.breakpoints.tablet} {
        font-size: 5rem;
        line-height: 5.5rem;
      }
      &:before {
        content: '';
        display: none;
      }
    }
    i {
      font-size: 1.5rem;
      font-style: normal;
      line-height: 2.3rem;
    }
  }

  ul {
    padding-left: 2.7rem;
    li {
      white-space: pre-line;
      *:first-child:before {
        height: 2rem;
        width: 2rem;
        left: -2.9rem;
        top: 0.5rem;
        background-image: url('/static/icons/Check.svg');
        background-position: center;
        background-size: 160%;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }
`

export const StyledRow = styled(Row)`
  justify-content: center;
`
