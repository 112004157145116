import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Column } from '../../atoms'

import { StyledWrapper } from './types'

export const Wrapper = styled.div<StyledWrapper>`
  display: flex;
  align-items: center;
  min-height: 50rem;
  color: ${(props) =>
    props.hasBackgroundImage
      ? props.theme.colors.text.white
      : props.theme.colors.text.black};

  h2 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};
    font-weight: ${(props) => props.theme.fontWeights.light};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h2};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h2};
    }

    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
      color: ${(props) =>
        props.hasBackgroundImage
          ? props.theme.colors.text.white
          : colorTheme(
              props.colorTheme,
              'callbackBanner.textAccentThemeColor'
            )};
    }
  }

  p {
    display: inline;
    font-size: ${(props) => props.theme.fontSizes.tablet.body.primary};

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
    }

    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }
  }

  /* Prevents IE11 bug where min-height elements break flexbox vertical
  *  alignments.
  */
  &:after {
    content: '';
    display: block;
    min-height: inherit;
    font-size: 0;
  }
`

export const ParagraphColumn = styled(Column)`
  padding-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 3.2rem;
  }
`

export const TitleColumn = styled(Column)`
  padding: 0 4.9rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 2rem;
  }
`

export const ButtonColumn = styled(Column)`
  padding-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 3.2rem;
  }
`
