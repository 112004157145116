import classnames from 'classnames'

import { Columns } from '../../../../types/grid'

import { Row, StyledColumn } from './styles'
import { StyledColumnProps } from './types'

export { Row }

/**
 * A function that creates a clean class-name for the required column width.
 * Classnames are provided purely for DOM visibility and no styles are
 * attributed to these classes.
 *
 * @param columnValue - Required. The column, and if required, offset value.
 *
 * @returns String - The requested column width as a class-name.
 */
export const cleanClassName = (columnValue: Columns | [Columns, Columns]) => {
  return columnValue && columnValue.toString().replace(',', '-o-')
}

/**
 * A function that creates an appropriately sizes column using Style-Components.
 * The `column` elements should be used only within the `row` elements, defined
 * above.
 *
 * @param props
 * @param props.mobile - The column size value for `mobile` breakpoint.
 * @param props.tablet - The column size value for `tablet` breakpoint.
 * @param props.desktop - The column size value for `desktop` breakpoint.
 * @param props.desktopLarge - The column size value for `desktopLarge`.
 * breakpoint.
 * @param props.verticalAlignment - The vertical alignment of content within the
 * column.
 * @param props.order - The order of the column relative to its siblings.
 * Controlled via CSS.
 *
 * @returns StyledComponent - The column component.
 */
export const Column = (props: StyledColumnProps) => {
  const { mobile, tablet, desktop, desktopLarge } = props

  const classNames = classnames({
    [`col-m-${cleanClassName(mobile)}`]: mobile,
    [`col-t-${cleanClassName(tablet)}`]: tablet,
    [`col-d-${cleanClassName(desktop)}`]: desktop,
    [`col-dl-${cleanClassName(desktopLarge)}`]: desktopLarge,
  })

  return <StyledColumn className={classNames} {...props} />
}
