import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Container, RichText } from '../../atoms'
import ParagraphStyles from '../../atoms/Paragraph/styles'
import { AdviserLocatorPostcodeSearch } from '../../molecules'

const {
  styles: { mediumStyles },
} = ParagraphStyles

export const StyledHeadline = styled(RichText)`
  b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'adviserLocator.textAccentThemeColor')};
  }
`

export const StyledSubHeadline = styled.p`
  ${mediumStyles}

  margin-top: 2.4rem;
`

export const StyledContainer = styled(Container)`
  text-align: center;
`

export const LocatorCarouselWrapper = styled.div`
  max-width: 116.8rem;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 5.2rem;
`

export const StyledPostcodeSearch = styled(AdviserLocatorPostcodeSearch)`
  margin: 2.6rem auto 0;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 53.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 56.2rem;
  }
`
