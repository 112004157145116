import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledNavLinkAnchor } from './types'

export const NavLinkAnchor = styled.span<StyledNavLinkAnchor>`
  display: block;
  position: relative;
  padding: 1.3rem 0;
  font-size: ${(props) => props.theme.fontSizes.mobile.button.primary};
  ${(props) =>
    props.isActiveRoute &&
    css`
      font-weight: ${props.theme.fontWeights.bold};
    `}
  ${(props) =>
    props.isActiveRoute &&
    css`
      font-weight: ${props.theme.fontWeights.bold};
    `}

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: inherit;

    &:hover {
      color: inherit;

      &::after {
        transform: scaleX(1);
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 0.1rem;
      right: 0rem;
      bottom: 0.7rem;
      left: 0rem;
      background-color: ${(props) => props.theme.colors.text.white};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform ${(props) => props.theme.transitions.moderate} ease,
        background-color ${(props) => props.theme.transitions.normal} ease;

      ${(props) =>
        props.isActiveRoute &&
        css`
          height: 0.2rem;
          transform: scaleX(1);
        `}

      background-color: ${(props) => {
        if (props.invert) {
          return props.useColorTheme
            ? colorTheme(props.colorTheme, 'header.linkBaseColor')
            : props.theme.colors.ui.white
        }
        return props.theme.colors.ui.blue2
      }};
    }
  }
`
