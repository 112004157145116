import styled, { css } from 'styled-components'

import {
  DataTableWrapperProps,
  TableColumnProps,
  TableWrapperProps,
} from './types'

export const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: ${(props) => props.theme.fontSizes.mobile.table};
    position: relative;
    z-index: ${(props) => !props.clickable && -1};

    border: 1.2rem solid
      ${(props) =>
        props.tableStyle === 'Left Anchor'
          ? props.theme.colors.background.beigeHighlight
          : props.theme.colors.background.white};

    background-color: ${(props) =>
      props.tableStyle === 'Left Anchor'
        ? props.theme.colors.background.beigeHighlight
        : props.theme.colors.background.white};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.table};
      border-width: 2.4rem 3rem 2.4rem 3rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.table};
      border-width: 3.4rem 4rem 3.4rem 4rem;
    }

    tr {
      :first-child {
        font-weight: ${(props) => props.theme.fontWeights.bold};
        padding: 1rem 1rem 1rem 0;

        th,
        td {
          padding-top: 0;
        }
      }

      border-bottom: 1px solid ${(props) => props.theme.colors.ui.beigeMedium};
    }

    tbody {
      tr:last-child {
        border-bottom: 0;

        th,
        td {
          padding-bottom: 0;
        }
      }
    }
  }
`

export const DataTableWrapper = styled.div<DataTableWrapperProps>`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: ${(props) => {
    const { showLeftShadow, showRightShadow } = props
    return showLeftShadow || showRightShadow ? '5px 0 0 5px' : '5px'
  }};
  overscroll-behavior-x: none;

  td {
    padding: ${(props) =>
      props.compact ? '1rem 1rem 1rem 1rem' : '1rem 1.5rem 1rem 1.5rem'};

    &:last-of-type {
      padding-right: 0;
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding: ${(props) =>
        props.compact ? '1rem 1rem 1rem 1rem' : '1rem 3rem 1rem 3rem'};
      &:last-of-type {
        padding-right: 0;
      }
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding: ${(props) =>
        props.compact ? '1rem 1rem 1rem 1rem' : '1rem 4rem 1rem 4rem'};
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  th {
    padding: 2rem 3rem 2rem 0;
    ${(props) =>
      props.tableStyle === 'Left Anchor' &&
      css`
        position: sticky;
        background-color: ${props.theme.colors.background.beigeHighlight};
      `};
    z-index: 1;
    left: 0;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding: 1rem 7rem 1rem 0;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding: ${(props) =>
        props.compact ? '1rem 1rem 1rem 0' : '1rem 10rem 1rem 0'};
    }
  }

  th:after {
    box-shadow: ${(props) => {
      const { showLeftShadow } = props
      return showLeftShadow && props.tableStyle === 'Left Anchor'
        ? `inset 2.5rem 0 .5rem -1.5rem ${props.theme.colors.ui.lightBlack}`
        : ''
    }};
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    right: ${(props) => (props.tableStyle === 'Left Anchor' ? '-2.5rem' : '')};
    width: 2.5rem;

    transition: box-shadow ${(props) => props.theme.transitions.normal}
      ease-in-out;
  }

  box-shadow: ${(props) => {
    const { showLeftShadow, showRightShadow } = props
    const styles: string[] = []
    if (showRightShadow) {
      styles.push(
        `inset -2.5rem 0 1.5rem -0rem ${props.theme.colors.ui.brightGray1}`
      )
    }
    if (showLeftShadow && props.tableStyle !== 'Left Anchor') {
      styles.push(`inset 1.2rem 0 0.5rem 0 ${props.theme.colors.ui.lightBlack}`)
    }
    if (styles.length > 0) {
      return styles.join(',')
    }
    return 'none'
  }};

  transition: box-shadow ${(props) => props.theme.transitions.normal}
    ease-in-out;
`

export const PdfAnchor = styled.a`
  background: url('/static/icons/pdf.svg') no-repeat 0 top transparent;
  display: inline-block;
  height: 1.7em;
  width: 1.7em;
`

export const TableColumn = styled.td<TableColumnProps>``
