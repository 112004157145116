import React, {
  forwardRef,
  Fragment,
  RefObject,
  useEffect,
  useState,
} from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { useDraggableItems, useHasDragged } from './hooks'
import {
  DragIcon,
  DragIconBar,
  ItemNumber,
  ItemText,
  List,
  ListItem,
  NumberList,
  NumberListItem,
  SkipButton,
  Wrapper,
} from './styles'
import { Props } from './types'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const onDragStart =
  (setHasDragged: (hasDragged: boolean) => void) => () => {
    setHasDragged(true)
  }

export const onDragEnd =
  (
    draggableItems: object[],
    setDraggableItems: (data: object) => void,
    onChange: (data: object) => void
  ) =>
  (result) => {
    // If item is dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      draggableItems,
      result.source.index,
      result.destination.index
    )

    setDraggableItems(items)
    onChange(items)
  }

const ProfilingQuestionDragDrop = forwardRef((props: Props, ref) => {
  const { data, isActive, onChange, onClickNext, isDisabled } = props

  const { draggableItems, setDraggableItems } = useDraggableItems(data)
  const { hasDragged, setHasDragged } = useHasDragged(false)

  // Wait for window to load: https://github.com/atlassian/react-beautiful-dnd/issues/1756#issuecomment-735369084
  const [isWindowReady, setIsWindowReady] = useState(false)
  useEffect(() => setIsWindowReady(true), [])

  useEffect(() => {
    if (isActive) {
      setDraggableItems(data)
      onChange(data)
      setHasDragged(false)
    }
  }, [isActive])

  const numberOfItems = draggableItems.length

  return (
    <Fragment>
      <Wrapper
        tabIndex={isDisabled ? -1 : 0}
        ref={ref as RefObject<HTMLDivElement>}
        data-bdd-selector="dragDrop-wrapper"
      >
        <NumberList>
          {draggableItems.map((_, index) => (
            <NumberListItem key={`item-number-${index}`}>
              <ItemNumber>{(index + 1).toString()}</ItemNumber>
            </NumberListItem>
          ))}
        </NumberList>
        {isWindowReady && (
          <DragDropContext
            onDragEnd={onDragEnd(draggableItems, setDraggableItems, onChange)}
            onDragStart={onDragStart(setHasDragged)}
          >
            <Droppable droppableId="droppable" isDropDisabled={isDisabled}>
              {(droppableProvided, snapshot) => (
                <List
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {draggableItems &&
                    draggableItems.map((item, index) => {
                      const { _id, answer } = item
                      return (
                        <Draggable
                          key={_id}
                          draggableId={_id}
                          index={index}
                          isDragDisabled={isDisabled}
                        >
                          {(draggableProvided, draggableSnapshot) => {
                            const isBeingDragged =
                              snapshot.draggingFromThisWith === _id

                            return (
                              <ListItem
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                index={index}
                                isActive={isActive}
                                isDragging={draggableSnapshot.isDragging}
                                isDraggingOver={
                                  draggableSnapshot.isDraggingOver
                                }
                                hasDragged={hasDragged}
                                styles={draggableProvided.draggableProps.style}
                                key={_id}
                              >
                                <ItemText
                                  isDragging={snapshot.isDraggingOver}
                                  isBeingDragged={isBeingDragged}
                                >
                                  {answer}
                                  <DragIcon>
                                    <DragIconBar
                                      isDragging={snapshot.isDraggingOver}
                                      isBeingDragged={isBeingDragged}
                                    />
                                    <DragIconBar
                                      isDragging={snapshot.isDraggingOver}
                                      isBeingDragged={isBeingDragged}
                                    />
                                  </DragIcon>
                                </ItemText>
                              </ListItem>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                  {droppableProvided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Wrapper>
      <SkipButton
        animationDelay={numberOfItems}
        onClick={onClickNext}
        tabIndex={0}
      >
        I don't know what my priorities are at the moment, take me to the
        results
      </SkipButton>
    </Fragment>
  )
})

export default ProfilingQuestionDragDrop
