import { FormikErrors, FormikTouched } from 'formik'

import { ColorThemeNames } from '../../../../types/colorThemes'
import {
  Checkbox,
  AppointmentPickerInput,
  DatePicker,
  PhoneNumber,
  Select,
  TimeSelect,
  Text,
} from './variants'

export interface Props {
  id: string
  name: string
  label: string
  inputType: InputTypes
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  helpText: string
  excludedDates?: string[]
  validate?: (value: any) => undefined | string | Promise<any>
  disabled?: boolean
  data?: any
  timeslots?: any[]
  candidates?: any[]
}

export type InputTypes =
  | 'text'
  | 'phoneNumber'
  | 'email'
  | 'time'
  | 'select'
  | 'date'
  | 'appointment'
  | 'checkbox'

export const InputVariantMapping = {
  text: Text,
  phoneNumber: PhoneNumber,
  email: Text,
  time: TimeSelect,
  select: Select,
  date: DatePicker,
  appointment: AppointmentPickerInput,
  checkbox: Checkbox,
}

export interface StyledLabelProps {
  focus: boolean
  dirty: boolean
  isTouched: boolean
  hasValue: boolean
  errorsInField: boolean
}

export interface StyledInputProps {
  errorsInField: boolean
  isTouched: boolean
  hasPrefix?: boolean
  disabledInput?: boolean
  readOnly?: boolean
  colorTheme: ColorThemeNames
}
