const mockFooterData = {
  _id: '71ffbhFNB4Qg4EPKlv7djj',
  _type: 'footer',
  entryTitle: 'Component::SPW Footer',
  companyLogo: {
    _id: '6MluhzrhvkD98GouJ5I1I1',
    _type: 'Asset',
    title: 'Schroders Logo',
    file: {
      url: '//images.ctfassets.net/dtgpf5d164ms/6MluhzrhvkD98GouJ5I1I1/f0f3b0c3eedf8b55c3f546be43c1a722/schroders-logo.svg',
      details: {
        size: 10716,
        image: {
          width: 136,
          height: 50,
        },
      },
      fileName: 'schroders-logo.svg',
      contentType: 'image/svg+xml',
    },
  },
  primaryLinks: [
    {
      _id: '5hx7y40wiWqewfOGYaCx98',
      _type: 'primaryFooter',
      entryTitle: 'Component::SPW Primary Footer',
      column1: [
        {
          _id: '6mP8MgTc0nhnBEaTjScdNU',
          _type: 'footerLevelOne',
          entryTitle: 'Component:: MenuLink > What we do',
          pageTitle: 'What we do',
          pageUrl: '/what-we-do',
        },
        {
          _id: '1IQAChSI1RuIM2gg2M8uDR',
          _type: 'footerLevelOne',
          entryTitle: 'Component:: MenuLink >How it works',
          pageTitle: 'How it works',
          pageUrl: '/how-it-works',
        },
        {
          _id: '6w0Nu82tx0dp1iQuoEo5gC',
          _type: 'footerLevelOne',
          entryTitle: 'Component:: MenuLink > Our Advisers',
          pageTitle: 'Our Advisers',
          pageUrl: '/advisers',
        },
        {
          _id: 'KY5pRxSlVY6C9uhmYzkUm',
          _type: 'footerLevelOne',
          entryTitle: 'Component:: MenuLink > Wealth Coach',
          pageTitle: 'Wealth Coach',
          pageUrl: '/wealth-coach',
        },
        {
          _id: '6oteeNvsVVaEGHxBPWyNWW',
          _type: 'footerLevelOne',
          entryTitle: 'Component::MenuLink> Testing',
          pageTitle: 'Testing',
          pageUrl: '/testing',
        },
        {
          _id: '6yBf4aYFrIOqoYiCMvF7Cv',
          _type: 'footerLevelOne',
          entryTitle: 'Component::MenuLink> Test1',
          pageTitle: 'Testing 1',
          pageUrl: '/what',
        },
      ],
      column2: [
        {
          _id: '5FEswJRFSCx0gXfVHtAGoh',
          _type: 'footerLevelOne',
          entryTitle: 'Component:: MenuLink > About Us',
          pageTitle: 'About Us',
          pageUrl: '/about',
        },
        {
          _id: 'S1dmnNaLMEpmRDwtDOdXl',
          _type: 'footerLevelOne',
          entryTitle: 'Component::MenuLink>Accessibility',
          pageTitle: 'Accessibility',
          pageUrl: '/accessibility',
        },
        {
          _id: '5FD2dydAbQLw91ywCyF7eR',
          _type: 'footerLevelOne',
          entryTitle: 'Component::MenuLink> Wealth Platform',
          pageTitle: 'Wealth Platform',
          pageUrl: '/wealth-platform',
        },
        {
          _id: '1h9PJ2jVAqkKtM7muHn7pK',
          _type: 'footerLevelOne',
          entryTitle: 'Component::MenuLink > Work With Us',
          pageTitle: 'Work With Us',
          pageUrl: '/work-with-us',
        },
      ],
      socialLinkTitle: 'Follow us here:',

      socialLinks: [
        {
          _id: '5HsY7RGXUty7hLokLbAA97',
          _type: 'socialIcons',
          entryTitle: 'Component:: SocialCTA > LinkedIn',
          socialIcon: {
            _id: '6oYqXeEKzhlPrBOBPHP8S7',
            _type: 'Asset',
            title: 'Icon Desktop:: LinkedIn',
            file: {
              url: '//images.ctfassets.net/l7z7tzydh3um/6oYqXeEKzhlPrBOBPHP8S7/3885c373a4d45ad30e71e159af98e50e/linkedin.svg',
              details: {
                size: 985,
                image: {
                  width: 36,
                  height: 36,
                },
              },
              fileName: 'linkedin.svg',
              contentType: 'image/svg+xml',
            },
          },
          socialUrl: 'https://www.linkedin.com/company/spw-wealth/ ',
          target: 'new window',
        },
        {
          _id: '2aPxuyGFbckO3CZizCONoA',
          _type: 'socialIcons',
          entryTitle: 'Component:: SocialCTA > Twitter',
          socialIcon: {
            _id: '2oR08CSUORsr9nQOUQzmyI',
            _type: 'Asset',
            title: 'Icon Desktop:: Twitter',
            file: {
              url: '//images.ctfassets.net/l7z7tzydh3um/2oR08CSUORsr9nQOUQzmyI/17f26b09a389ff17a2aa3f46570e79d4/twitter.svg',
              details: {
                size: 836,
                image: {
                  width: 36,
                  height: 36,
                },
              },
              fileName: 'twitter.svg',
              contentType: 'image/svg+xml',
            },
          },
          socialUrl: 'https://twitter.com/schroders_pw',
          target: 'new window',
        },
        {
          _id: '51pMi4y5HcYLqpR8eufFZ8',
          _type: 'socialIcons',
          entryTitle: 'Component:: Social CTA > Facebook',
          socialIcon: {
            _id: '1XAVjxXBQQ14tuEaeV7OHT',
            _type: 'Asset',
            title: 'Icon Desktop:: Facebook',
            file: {
              url: '//images.ctfassets.net/l7z7tzydh3um/1XAVjxXBQQ14tuEaeV7OHT/8a7c651e4716b80416c85ab618ce5abe/facebook.svg',
              details: {
                size: 455,
                image: {
                  width: 36,
                  height: 36,
                },
              },
              fileName: 'facebook.svg',
              contentType: 'image/svg+xml',
            },
          },
          socialUrl: 'https://www.facebook.com/schroderspw/',
          target: 'new window',
        },
      ],
    },
  ],
  secondaryLinks: [
    {
      _id: '49hU49bkafydX0Xj6xhXSQ',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Legal Information',
      pageTitle: 'Legal Information',
      pageUrl: '/legal-information',
    },
    {
      _id: '7j6QTFp7rI3dCkaz0sOYsp',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Modern Slavery Act',
      pageTitle: 'Moder Slavery Act',
      pageUrl: '/modern-slavery-act',
    },
    {
      _id: '5mdIkJNXZak1d6vzuBW11N',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Cookie Policy',
      pageTitle: 'Cookie Policy',
      pageUrl: '/cookie-policy',
    },
    {
      _id: '1UqZxFciBgUZQmHrN3Emq6',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Privacy',
      pageTitle: 'Privacy',
      pageUrl: '/privacy',
    },
    {
      _id: '05s0Ir88VokVz2oh0NkYEx',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Security',
      pageTitle: 'Security',
      pageUrl: '/security',
    },
    {
      _id: '197yymsEpCJwXxnTrPAWSp',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Terms & Conditions',
      pageTitle: 'Terms & Conditions',
      pageUrl: '/terms-and-conditions',
    },
    {
      _id: '3MBA21Mrcdx56HoFFK4DM5',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Common Reporting Standard',
      pageTitle: 'Common Reporting Standard',
      pageUrl: '/common-reporting-standard',
    },
    {
      _id: '2baVf81LPS9m3N24624a26',
      _type: 'footerLevelOne',
      entryTitle: 'Component:: MenuLink > Shared Dealing',
      pageTitle: 'Shared dealing',
      pageUrl: '/shared-dealing',
    },
  ],
  disclaimer:
    '<p><b>Important information: </b>Tax treatment depends on individual circumstances and may be subject to change in future Forecasts of future performance are not a reliable guide to actual results in the future; neither is past performance a reliable guide to future performance. The value of investments, and the income from them, may fall as well as rise and cannot be guaranteed. Any views expressed are our in - house views as at June 2019. Investment markets and conditions can change rapidly and the views expressed should not be taken as statements of fact nor relied upon when making investment decisions. This document may not be used, copied, quoted, circulated or otherwise disclosed (in whole or in part) without our prior written consent. Schroders Personal Wealth is a trading name of Scottish Widows Schroder Personal Wealth Limited. Scottish Widows Schroder Personal Wealth Limited. Registered Office: 25 Gresham Street, London EC2V 7HN. Registered in England and Wales No. 11722983. Authorised and regulated by the Financial Conduct Authority under number 830170. Eligible investments with us are protected by the Financial Services Compensation Scheme. We are covered by the Financial Ombudsman Service.</p>',
  copyright: 'Scottish Widows Schroder Personal Wealth Limited',
}

export default mockFooterData
