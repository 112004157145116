import { useRouter } from 'next/router'
import React from 'react'

import { Props } from './types'

const LinkWrapper: React.FunctionComponent<Props> = (props) => {
  const {
    route,
    target,
    rel,
    callback,
    children,
    className,
    externalTarget = false,
  } = props

  const router = useRouter()
  const isActiveRoute = router && router.asPath === route

  const handleClick = (e) => {
    if (externalTarget) {
      return
    }

    e.preventDefault()

    if (!isActiveRoute) {
      window.location.href = route
      // router.push(route);
      // On the client, the line above does not consider the entirety of
      // the routing setup, and therefore will behave unexpectedly.
      // So, we have replaced this with a simple browser redirect.
      if (callback) {
        callback()
      }
    } else {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      return false
    }
  }

  const childrenWithProps =
    typeof children === 'object'
      ? React.Children.map(
          children,
          (element: React.DetailedReactHTMLElement<any, HTMLElement>) =>
            React.cloneElement(element, { isActiveRoute })
        )
      : children

  return (
    <a
      href={route}
      target={target}
      rel={rel}
      onClick={handleClick}
      className={className}
    >
      {childrenWithProps}
    </a>
  )
}

export default LinkWrapper
