import { FunctionComponent } from 'react'

import CallToAction from '../CallToAction'

import { Description, Headline, TextWrapper } from './styles'
import { Props } from './types'

const ContentBlock: FunctionComponent<Props> = (props) => {
  const { data, colorTheme, isNested, className, useTicksForBullets } = props
  const { headline, description, callToAction } = data

  return (
    <TextWrapper
      className={className}
      colorTheme={colorTheme}
      isNested={isNested}
      data-bdd-selector="content-block"
    >
      {headline && (
        <Headline
          html={headline}
          colorTheme={colorTheme}
          data-bdd-selector="callout-block-headline"
        />
      )}
      {description && (
        <Description
          html={description}
          colorTheme={colorTheme}
          useTicksForBullets={useTicksForBullets}
          data-bdd-selector="callout-block-description"
        />
      )}
      {callToAction && callToAction.length > 0 && (
        <CallToAction
          id={
            callToAction[0].target === 'showCallback'
              ? 'gtm-content-block-callback-modal-open-button'
              : undefined
          }
          {...callToAction[0]}
          iconBeforeText={false}
          componentTheme={colorTheme}
          data-bdd-selector="callout-block-button"
        />
      )}
    </TextWrapper>
  )
}

export default ContentBlock
