import styled from 'styled-components'

export const Fieldset = styled.fieldset`
  & + & {
    margin-top: 3rem;
  }
`

export const SubTitle = styled.p`
  margin-top: 1rem;
`
