import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { Container } from '../../../../atoms'
import { Icon, Link } from '../../../../atoms/Button/styles'

import { StyledHeaderWrapper } from './types'

export const HeaderWrapper = styled.header<StyledHeaderWrapper>`
  height: 7rem;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.headerIsDark
      ? props.theme.colors.text.white
      : props.theme.colors.text.black};
  fill: ${(props) =>
    props.headerIsDark
      ? props.theme.colors.text.white
      : props.theme.colors.text.black};
  position: absolute;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid ${(props) => rgba(props.theme.colors.ui.white, 0.1)};
  font-size: ${(props) => props.theme.fontSizes.mobile.navigation.secondary};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 10rem;
    font-size: ${(props) => props.theme.fontSizes.tablet.navigation.secondary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 10rem;
    font-size: ${(props) => props.theme.fontSizes.desktop.navigation.secondary};
  }

  & + main > *:first-child {
    padding-top: 10rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: 14rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: 16.5rem;
    }
  }
`

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
`

export const HeaderLogo = styled.div`
  height: 3.6rem;
  width: 13rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 4.9rem;
    width: 13.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 3.6rem;
    width: 19rem;
  }
`

const HeaderColumn = styled.div`
  display: flex;
  flex: 1 1 33.333%;
`

const LinkStyle = css`
  height: auto;
  color: ${(props) => props.theme.colors.text.white};
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color ${(props) => props.theme.transitions.normal} ease,
    background-color ${(props) => props.theme.transitions.normal} ease;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    height: 3.6rem;
    width: 3.6rem;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors.ui.white};
    border-radius: 100%;
  }

  &:focus,
  &:active,
  &:hover {
    @media ${(props) => props.theme.breakpoints.mobileMax} {
      color: ${(props) => props.theme.colors.text.purple};
      background-color: ${(props) => props.theme.colors.background.white};
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      color: ${(props) => props.theme.colors.text.blue};
    }
  }

  &::after {
    content: none;
  }
`

const IconStyle = css`
  line-height: 0;

  @media ${(props) => props.theme.breakpoints.tablet} {
    line-height: ${(props) => props.theme.lineHeights.base};
  }

  svg {
    @media ${(props) => props.theme.breakpoints.mobileMax} {
      height: 1.2rem;
      width: 1.2rem;
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-right: 1.2rem;
    }
  }
`

export const HeaderColumnLeft = styled(HeaderColumn)`
  ${Link} {
    ${LinkStyle}
  }

  ${Icon} {
    ${IconStyle}
  }
`

export const HeaderColumnCenter = styled(HeaderColumn)`
  justify-content: center;
`

export const HeaderColumnRight = styled(HeaderColumn)`
  justify-content: flex-end;

  ${Link} {
    ${LinkStyle}
  }

  ${Icon} {
    ${IconStyle}

    @media ${(props) => props.theme.breakpoints.tablet} {
      display: none;
    }
  }
`
