import { rgba } from 'polished'
import ReactSlider from 'react-slider'

import { styled } from '../../../../styles/theme'
import { colorTheme } from '../../../../styles/utils'
import ParagraphStyles from '../Paragraph/styles'

const {
  styles: { overlineStyles },
} = ParagraphStyles

export const Wrapper = styled.div``

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 3.2rem;

  .slider-track {
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
  }

  .slider-track-0 {
    background: ${(props) =>
      colorTheme(props.colorTheme, 'slider.trackThemeColor')};
    border-radius: 4px 0 0 4px;
  }

  .slider-track-1 {
    background: ${(props) => props.theme.colors.background.grey1};
    border-radius: 0 4px 4px 0;
  }
`

export const ThumbWrapper = styled.div`
  width: 3.2rem;
  border-radius: 50%;
  background: ${(props) =>
    colorTheme(props.colorTheme, 'slider.thumbBackgroundThemeColor')};
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
`

export const ThumbStripe = styled.div`
  background: ${(props) => rgba(props.theme.colors.background.white, 0.4)};
  width: 2px;
  height: 100%;
  flex-shrink: 0;
  border-radius: 3px;
`

export const RangeLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RangeLabel = styled.label`
  ${overlineStyles}
  color: ${(props) => props.theme.colors.text.grey4};
`
