import { FunctionComponent, useState, useRef } from 'react'
import {
  useLoadScript,
  InfoWindow,
  Marker,
  GoogleMap,
} from '@react-google-maps/api'
import { Paragraph } from '../../atoms'
import { MapStyles, StyledPlaceholder, InfoWindowContent } from './styles'
import { Props } from './types'
import { EnvConfig } from '../../../../constants/environmentConfig'

const LocationsMap: FunctionComponent<Props> = (props) => {
  const {
    data: {
      locationsItem,
      zoom,
      centerPointLatitude,
      centerPointLongitude,
      errorMessage,
    },
  } = props
  const mapRef = useRef(null)
  const [selectedPin, setSelectedPin] = useState(null)
  const [center, setCenter] = useState({
    lat: centerPointLatitude,
    lng: centerPointLongitude,
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: EnvConfig.SPW_UI_GOOGLE_MAPS_API_KEY,
  })

  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  }

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds()
    locationsItem.forEach((location) => {
      bounds.extend({ lat: location.latitude, lng: location.longitude })
      return location._id
    })
    map.fitBounds(bounds)
  }

  const loadHandler = (map) => {
    mapRef.current = map
    fitBounds(map)
  }

  const updateCenter = () => {
    if (mapRef !== null) return
    const newCenter = mapRef.current.getCenter().toJSON()
    setCenter(newCenter)
  }

  const renderMap = () => {
    return (
      <GoogleMap
        center={center}
        zoom={zoom}
        mapContainerStyle={MapStyles}
        data-bdd-selector="locations-map-block"
        onLoad={loadHandler}
        onCenterChanged={updateCenter}
        onZoomChanged={() => {
          setSelectedPin(null)
        }}
        options={mapOptions}
      >
        <>
          {locationsItem.map((pin) => (
            <Marker
              key={pin._id}
              position={{ lat: pin.latitude, lng: pin.longitude }}
              onClick={() => {
                setSelectedPin(pin)
              }}
              icon={{
                path: 'M15.27,10.129 C15.27,12.925 12.9922,15.1934 10.1824,15.1934 C7.3722,15.1934 5.0948,12.925 5.0948,10.129 C5.0948,7.3322 7.3726,5.0646 10.1824,5.0646 C12.9928,5.0644 15.27,7.332 15.27,10.129 M20.5486,10.129 C20.5486,4.5348 15.9932,0 10.3732,0 C4.7536,0 0.1978,4.5348 0.1978,10.129 C0.1978,12.5886 1.0794,14.8438 2.5444,16.598 L10.382,27.8546 L18.3342,16.4338 C18.6198,16.078 18.8814,15.703 19.1174,15.3088 L19.1978,15.194 L19.1846,15.194 C20.0506,13.7032 20.5486,11.9748 20.5486,10.129',
                fillColor: '#31235D',
                fillOpacity: 1.0,
                strokeWeight: 0,
                scale: 1,
                anchor: new window.google.maps.Point(10, 26),
              }}
            />
          ))}
          {selectedPin && (
            <InfoWindow
              onCloseClick={() => {
                setSelectedPin(null)
              }}
              position={{
                lat: selectedPin.latitude,
                lng: selectedPin.longitude,
              }}
            >
              <InfoWindowContent
                dangerouslySetInnerHTML={{
                  __html: selectedPin.address,
                }}
              ></InfoWindowContent>
            </InfoWindow>
          )}
        </>
      </GoogleMap>
    )
  }

  if (loadError) {
    return <Paragraph level="medium">{errorMessage}</Paragraph>
  }

  return isLoaded ? renderMap() : <StyledPlaceholder />
}

export default LocationsMap
