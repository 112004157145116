import { extractor } from '../../../../utils'
import { Caption, Image } from '../../../common/atoms'

import { DivContainer } from './styles'
import { Props } from './types'

const ArticleImage = (props: Props) => {
  const { data, overlay, keepRatio, opacity = 0, colorTheme } = props
  const caption = extractor(data.items).caption
  const articleImgCaptionData = caption ? caption[0] : caption

  return (
    <DivContainer data-bdd-selector="article-image">
      <Image
        data={data.items}
        colorTheme={colorTheme}
        overlay={overlay}
        size="large"
        keepRatio={keepRatio}
        opacity={opacity}
      />
      <Caption {...articleImgCaptionData} />
    </DivContainer>
  )
}

export default ArticleImage
