import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  padding: 6rem 2rem;

  @media ${(props) => props.theme.breakpoints.tabletMax} {
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    background: ${(props) => props.theme.colors.background.black};
  }

  & > div {
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    justify-content: space-around;
    margin: auto;
    max-width: ${(props) => props.theme.dimensions.containerMaxWidth};
    text-align: center;

    @media ${(props) => props.theme.breakpoints.mobileMax} {
      flex-direction: column;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }

  p:first-child {
    font-size: 1.5rem;
  }

  p b {
    display: block;
    font-size: 3rem;
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.white};
  }
`

export const Item = styled.div<StyledWrapper>`
  align-items: flex-start;
  background: url('/static/images/constellation-lens.svg') no-repeat center;
  background-size: contain;
  display: flex;
  height: 21.6rem;
  justify-content: center;
  margin-right: 4.5rem;
  padding: 5.4rem 2.2rem 2.2rem;
  width: 21rem;

  & > div {
    display: flex;
    flex-direction: column;
    height: 23rem;
    justify-content: space-between;

    @media ${(props) => props.theme.breakpoints.tabletMax} {
      height: 21rem;
    }

    @media ${(props) => props.theme.breakpoints.mobileMax} {
      height: auto;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    margin-right: 1.4rem;
    padding: 5.4rem 1.4rem 2.2rem;
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    height: 12.6rem;
    margin-bottom: 3.9rem;
    margin-left: auto;
    margin-right: auto;
    min-width: 25rem;
    padding: 0.5rem 2.2rem 2.2rem;

    &:last-child {
      margin-right: auto;
    }
  }
`
