import * as React from 'react'

import { Paragraph, RichText } from '../../atoms'

import { Figure, Section } from './styles'
import { Props } from './types'

const ColumnItem: React.FunctionComponent<Props> = (props) => {
  const {
    data: { statistic, headline, description },
  } = props

  return (
    <Section>
      {statistic && (
        <Figure data-bdd-selector="three-columns-item-statistic">
          {statistic}
        </Figure>
      )}
      {headline && (
        <Paragraph
          level="medium"
          weight="bold"
          data-bdd-selector="three-columns-item-headline"
        >
          {headline}
        </Paragraph>
      )}
      {description && (
        <RichText
          html={description}
          data-bdd-selector="three-columns-item-description"
        />
      )}
    </Section>
  )
}

export default ColumnItem
