import { GetThemeContext, ThemeContext } from '../../../../context'
import { palette } from '../../../../styles/theme/colors'
import { BackgroundImage, Container, RichText, Row } from '../../atoms'
import { CallToAction } from '../../molecules'

import { ButtonColumn, ParagraphColumn, TitleColumn, Wrapper } from './styles'
import { Props } from './types'

const CallbackBanner: React.FunctionComponent<Props> = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: { headline, description, callToAction, backgroundImage },
    useColorThemeForOverlay,
  } = props

  const hasBackgroundImage = Boolean(backgroundImage)
  const textColor = hasBackgroundImage ? palette.white : palette.black

  return (
    <BackgroundImage
      data={backgroundImage}
      colorTheme={colorTheme}
      useColorThemeForOverlay={useColorThemeForOverlay}
    >
      <Wrapper
        textColor={textColor}
        colorTheme={colorTheme}
        hasBackgroundImage={hasBackgroundImage}
        data-bdd-selector="callback-banner"
      >
        <Container paddingSize="triple" center={true}>
          <Row>
            {headline && (
              <TitleColumn tablet={[6, 1]} desktop={[6, 3]}>
                <RichText
                  data-bdd-selector="callback-banner-headline"
                  html={headline}
                />
              </TitleColumn>
            )}
            {description && (
              <ParagraphColumn tablet={[6, 1]} desktop={[6, 3]}>
                <RichText
                  data-bdd-selector="callback-banner-description"
                  html={description}
                />
              </ParagraphColumn>
            )}
            {callToAction && (
              <ButtonColumn tablet={[6, 1]} desktop={[6, 3]}>
                <CallToAction
                  id="gtm-callback-banner-callback-modal-open-button"
                  data-bdd-selector="callback-banner-button"
                  {...callToAction[0]}
                  colorTheme={colorTheme}
                />
              </ButtonColumn>
            )}
          </Row>
        </Container>
      </Wrapper>
    </BackgroundImage>
  )
}

export default CallbackBanner
