import React from 'react'

import { AdviserCarousel, CallToAction, ContentBlock } from '../../molecules'

import {
  Wrapper,
  ContentWrapper,
  QuotesCarouselWrapper,
  ButtonWrapper,
} from './styles'
import { Props } from './types'

export const AdviserQuoteCards: React.FunctionComponent<Props> = (props) => {
  const {
    data: { contentBlock, cards, callToAction },
    isNested,
  } = props

  return (
    <>
      <Wrapper data-bdd-selector="adviser-quote-cards">
        <ContentWrapper>
          {contentBlock && (
            <ContentBlock data={contentBlock} isNested={isNested} />
          )}
        </ContentWrapper>
        {cards && (
          <QuotesCarouselWrapper>
            <AdviserCarousel
              cards={cards}
              slidesToShow={1}
              hideArrows
              isNested={isNested}
            />
          </QuotesCarouselWrapper>
        )}
      </Wrapper>
      {callToAction && (
        <ButtonWrapper>
          <CallToAction {...callToAction} />
        </ButtonWrapper>
      )}
    </>
  )
}

export default AdviserQuoteCards
