import React, { useState } from 'react'
import { GetThemeContext, ThemeContext } from '../../../../../context'
import { StyledTopLevelNavItem, StyledNavLink } from '../styles'
import { NavItemData, SPWDropdownProps } from '../types'
import NavDropdownMenu from './NavDropdownMenu'

const NavDropdown: React.FunctionComponent<SPWDropdownProps> = (props) => {
  const [open, setOpen] = useState(false)

  const hasChildren = (item: NavItemData) =>
    item.footerLevelTwo && item.footerLevelTwo.length > 0

  const openDropdownMenu = () => {
    setOpen(true)
  }

  const closeDropdownMenu = () => {
    setOpen(false)
  }

  return (
    <StyledTopLevelNavItem
      className={open && 'active'}
      onMouseOver={openDropdownMenu}
      onMouseLeave={closeDropdownMenu}
      onFocus={openDropdownMenu}
      onBlur={closeDropdownMenu}
    >
      <StyledNavLink
        colorTheme={GetThemeContext(ThemeContext)}
        invert={props.invert}
        useColorTheme={props.useColorTheme}
        href={props.data['pageUrl']}
        data-bdd-selector="nav-link-anchor"
        hasCustomHeaderBG={props.hasCustomHeaderBG}
      >
        {props.data['pageTitle']}
      </StyledNavLink>

      {hasChildren(props.data) && (
        <NavDropdownMenu
          isOpen={open}
          data={props.data}
          invert={props.invert}
          useColorTheme={props.useColorTheme}
        />
      )}
    </StyledTopLevelNavItem>
  )
}

export default NavDropdown
