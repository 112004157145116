import styled, { css } from 'styled-components'

import { StyledWrapper } from './types'

export const Wrapper = styled.div<StyledWrapper>`
  position: relative;
  z-index: 1;
  fill: ${(props) =>
    props.isDark
      ? props.theme.colors.background.black
      : props.theme.colors.background.grey1};
  background-color: ${(props) =>
    props.hasBackgroundColor && props.theme.colors.background.grey9};
  ${(props) =>
    props.overlapContent &&
    css`
      margin-top: -9%;

      @media ${props.theme.breakpoints.desktopExtraLarge} {
        margin-top: -13rem;
      }
    `}
`

export const Curve = styled.svg`
  display: block;
  width: 100%;
  max-height: 3rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-height: 7rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-height: 13rem;
  }
`
