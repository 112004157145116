import React from 'react'

import { Props } from './types'
import { HeaderDefault, HeaderSlim } from './variants'

import LogoPlaceHolder from '../../atoms/Logo/constants'

let imageUrl = LogoPlaceHolder
const Header = (props: Props) => {
  const {
    data,
    headerType,
    headerIsDark,
    isOnOverlayHero,
    isUsingTransparentHero,
    enableCustomHeaderBackground,
    fixedHeader,
    viewportInfo,
  } = props

  const variantMapping = {
    default: HeaderDefault,
    slim: HeaderSlim,
  }

  const VariantToRender = headerType
    ? variantMapping[headerType]
    : variantMapping.default

  const { companyLogo } = data
  if (companyLogo && companyLogo.length) {
    const { isSmallerThan } = viewportInfo
    const { desktopImage, mobileImage, tabletImage } =
      companyLogo && companyLogo[0]

    imageUrl = desktopImage[0].file.url
    if (isSmallerThan.tablet) {
      imageUrl = mobileImage[0].file.url
    } else if (isSmallerThan.desktop) {
      imageUrl = tabletImage[0].file.url
    }
  }

  if (VariantToRender) {
    return (
      <VariantToRender
        data={data}
        imageUrl={imageUrl}
        headerIsDark={headerIsDark}
        isOnOverlayHero={isOnOverlayHero}
        isUsingTransparentHero={isUsingTransparentHero}
        enableCustomHeaderBackground={enableCustomHeaderBackground}
        fixedHeader={fixedHeader}
        viewportInfo={viewportInfo}
        invert={headerType === 'slim' ? true : undefined}
      />
    )
  }

  return null
}

export default Header
