import * as React from 'react'
import { lazyload } from 'react-lazyload'
import api from '../../../../services/api'

import theme from '../../../../styles/theme'
import { fetchUTM } from '../../../../utils/helpers'

import { quality, sizes } from './constants'
import { Overlay, Picture, StyledImage } from './styles'
import { Branding, Device, DeviceImage, ImageSize, Props } from './types'

const {
  breakpoints: { mobileMax, tabletMax },
} = theme

export const getUrl = (
  image: DeviceImage,
  device: Device,
  size: ImageSize,
  scale?: number
) => {
  return image.file
    ? `${image.file.url}?q=${quality}&w=${
        scale ? sizes[size][device] * scale : sizes[size][device]
      }${scale ? ` ${scale}x` : ''}`
    : ''
}

export const Image = (props: Props) => {
  const [branding, setBranding] = React.useState<Branding>()
  const {
    data,
    overlay = false,
    'data-bdd-selector': dataBddSelector,
    keepRatio,
    className,
    colorTheme,
    size = 'large',
  } = props
  const desktopImage = branding
    ? branding.Logo.desktopImage
    : data[0].desktopImage
  const { entryTitle: dataEntryTitle } = data[0]
  const tabletImage = branding ? branding.Logo.tabletImage : data[0].tabletImage
  const mobileImage = branding ? branding.Logo.mobileImage : data[0].mobileImage
  const altText = branding ? branding.Logo.altText : data[0].altText

  const utm = fetchUTM()
  React.useEffect(() => {
    const getBranding = async () => {
      const { data } = await api.getUtmBrandingData(utm.utm_source)
      if (data.total > 0) setBranding(data.items[0])
    }
    if (!dataEntryTitle) return
    if (dataEntryTitle.search(/utm_reactive/i) === -1) return
    if (!utm.utm_source) return
    if (branding) return
    // Fetch image
    getBranding()
  }, [utm, dataEntryTitle])

  return (
    <Picture className={className} data-bdd-selector={dataBddSelector}>
      <source
        media={mobileMax}
        srcSet={`${getUrl(mobileImage[0], 'mobile', size, 2)}, ${getUrl(
          mobileImage[0],
          'mobile',
          size,
          1
        )}`}
      />
      <source
        media={tabletMax}
        srcSet={`${getUrl(tabletImage[0], 'tablet', size, 2)}, ${getUrl(
          tabletImage[0],
          'tablet',
          size,
          1
        )}`}
      />

      {overlay && <Overlay opacity={props.opacity} colorTheme={colorTheme} />}
      <StyledImage
        keepRatio={keepRatio}
        src={getUrl(desktopImage[0], 'desktop', size)}
        srcSet={`${getUrl(desktopImage[0], 'desktop', size, 2)}, ${getUrl(
          desktopImage[0],
          'desktop',
          size,
          1
        )}`}
        alt={altText}
        title={altText}
      />
    </Picture>
  )
}

const lazyloadConfig = {
  height: 200,
  once: true,
  offset: 100,
  style: {
    height: '100%',
  },
}

export default lazyload(lazyloadConfig)(Image)
