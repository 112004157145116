import styled from 'styled-components'

import { StyledList } from './types'

export const List = styled.ul<StyledList>`
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 1.6rem;
  grid-column-gap: 1.6rem;
  margin-top: 4rem;

  ${(props) =>
    props.isLongList &&
    `
    grid-template-columns: 1fr;

    @media ${props.theme.breakpoints.tablet} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${props.theme.breakpoints.desktop} {
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }
  `}
`
