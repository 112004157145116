import React, { useState } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { LoadingSpinner, Paragraph, RichText } from '../../atoms'
import { ButtonType } from '../../atoms/Button/types'
import { ArticleCards } from '../../molecules'

import {
  CardsContainer,
  Headline,
  StyledContainer,
  StyledTabs,
  ViewMoreButton,
} from './styles'
import { Props } from './types'

const ArticlesList: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    cards,
    onLoadMore,
    headline,
    viewMoreVisible,
    tabs,
    onFilterChange,
    loading,
    noResults,
  } = props

  const [selectedTab, setSelectedTab] = useState(0)

  const handleOnClick = () => {
    onLoadMore()
  }

  const handleTabClick = (index: number) => {
    onFilterChange(tabs[index])

    setSelectedTab(index)
  }

  return (
    <StyledContainer data-bdd-selector="article-list" id="articles">
      {headline && (
        <Headline colorTheme={colorTheme}>
          <RichText
            document={headline}
            data-bdd-selector="article-list-headline"
          />
        </Headline>
      )}
      <StyledTabs
        aria-label="Article filter"
        tabs={tabs}
        onTabClick={handleTabClick}
        selectedTab={selectedTab}
      />
      <CardsContainer data-bdd-selector="article-list-cards">
        {loading ? (
          <LoadingSpinner isDark={true} />
        ) : cards && cards.length ? (
          <ArticleCards cards={cards} />
        ) : (
          <Paragraph level="small">{noResults}</Paragraph>
        )}
      </CardsContainer>
      {viewMoreVisible && !loading && (
        <ViewMoreButton
          onClick={handleOnClick}
          label="View more"
          buttonType={ButtonType.Secondary}
          isButton={true}
          data-bdd-selector="article-list-view-more-button"
        />
      )}
    </StyledContainer>
  )
}

export default ArticlesList
