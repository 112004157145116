import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledBackgroundImage } from './types'

export const BackgroundImage = styled.div<StyledBackgroundImage>`
  position: relative;
  width: 100%;
  background-image: url(${(props) =>
    props.images.mobileImage[0].file.url + '?q=90'});
  background-size: cover;
  background-position: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-image: url(${(props) =>
      props.images.tabletImage[0].file.url + '?q=90'});
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    background-image: url(${(props) =>
      props.images.desktopImage[0].file.url + '?q=90'});
  }

  ${(props) =>
    props.images &&
    props.overlayOpacity !== 0 &&
    css`
      &::before {
        content: '';
        background-color: ${colorTheme(
          props.colorTheme,
          props.useColorThemeForOverlay
            ? 'backgroundImage.overlayThemeColor'
            : 'backgroundImage.overlayBaseColor'
        )};
        color: ${colorTheme(props.colorTheme, 'imageOverlayColor')};
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: ${props.overlayOpacity};
      }
    `}
`
