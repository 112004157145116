import { ColumnWidthAsString } from './types'

export const columnWidthAsString: ColumnWidthAsString = (
  columns,
  breakpoint
) => {
  const breakpointMaxColumns = {
    desktop: 12,
    tablet: 8,
    mobile: 2,
  }

  if (!columns) {
    return
  }

  const width =
    ((columns[0] || columns) / breakpointMaxColumns[breakpoint]) * 100

  let result = `flex-basis: ${width}%; max-width: ${width}%;`

  if (typeof columns === 'object') {
    const offset = (columns[1] / breakpointMaxColumns[breakpoint]) * 100

    result += ` margin-left: ${offset}%;`
  }

  return result
}
