import * as React from 'react'

import {
  Column,
  Container,
  Image,
  TrustPilotTrustBoxMini,
} from '../../../../atoms'

import {
  Content,
  Description,
  HeroHeadline,
  ImageColumn,
  Row,
  Wrapper,
  TrustPilotRatingWrapper,
} from './styles'
import { Props } from './types'

const WithImage = (props: Props) => {
  const {
    data: { headline, heroImage, description, textAlignment },
    colorTheme,
    className,
    isTrustPilotRatingBarVisible = false,
  } = props

  const leftTextAlignment = textAlignment === 'left'

  let contentColumnsSize
  let mediaColumnSize

  if (leftTextAlignment) {
    contentColumnsSize = 5
    mediaColumnSize = [6, 1]
  } else {
    contentColumnsSize = 6
    mediaColumnSize = 5
  }

  return (
    <Wrapper data-bdd-selector="hero-with-image" className={className}>
      <Container paddingSize="none">
        <Row leftTextAlignment={leftTextAlignment}>
          {heroImage && (
            <ImageColumn
              tablet={4}
              desktop={mediaColumnSize}
              verticalAlignment="center"
            >
              <Image
                data={heroImage}
                data-bdd-selector="hero-with-image-image"
                keepRatio={true}
              />
            </ImageColumn>
          )}
          <Column
            tablet={4}
            desktop={contentColumnsSize}
            verticalAlignment="center"
          >
            <Content id="main">
              {headline && (
                <HeroHeadline
                  html={headline}
                  colorTheme={colorTheme}
                  data-bdd-selector="hero-with-image-headline"
                />
              )}
              {description && (
                <Description
                  html={description}
                  colorTheme={colorTheme}
                  data-bdd-selector="hero-with-image-description"
                />
              )}
            </Content>
          </Column>
        </Row>
      </Container>
      {isTrustPilotRatingBarVisible && (
        <TrustPilotRatingWrapper>
          <TrustPilotTrustBoxMini />
        </TrustPilotRatingWrapper>
      )}
    </Wrapper>
  )
}

export default WithImage
