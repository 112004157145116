import styled from 'styled-components'
export const ModalIconLink = styled.a`
  display: inline-block;
  cursor: pointer;
  width: 90px;
  &:hover {
    text-decoration: underline;
  }
`

export const ModalLinkIcon = styled.div`
  width: 90px;
  padding: 10px;
`

export const ModalLinkFooterText = styled.div`
  font-size: 19px;
  color: ${(props) => props.theme.colors.text.black};
`
