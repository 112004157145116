import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Container, RichText, Title } from '../../atoms'
import { PrimaryButton } from '../../atoms/Button/styles'

import {
  StyledKeyFactsContainer,
  StyledMainHeadline,
  StyledSvgIconContainer,
} from './types'

export const KeyFactsContainer = styled(Container)<StyledKeyFactsContainer>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background.beigeHighlight};

  ${PrimaryButton},
  button {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'keyFacts.textThemeColor')};
    background-color: ${(props) =>
      colorTheme(props.colorTheme, 'keyFacts.backgroundHoverThemeColor')};
    white-space: normal;
    word-wrap: break-word;

    ::before {
      background-color: ${(props) =>
        colorTheme(props.colorTheme, 'keyFacts.buttonBackgroundThemeColor')};
    }

    margin-top: 4rem;
    width: 20.7rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      width: unset;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`

export const MainHeadline = styled(RichText)<StyledMainHeadline>`
  h2 {
    font-weight: ${(props) => props.theme.fontWeights.light};

    b {
      color: ${(props) =>
        colorTheme(props.colorTheme, 'keyFacts.textAccentThemeColor')};
    }
  }
`

export const Facts = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space - evenly;
  }
`

export const FactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    flex: 0 0 50%;
    align-items: flex - start;
    text-align: left;
  }
`

export const SvgIconContainer = styled.div<StyledSvgIconContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 8.8rem;
  min-width: 8.8rem;
  border-radius: 50%;

  > div {
    padding-top: 0.8rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    min-height: 4.8rem;
    min-width: 4.8rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-height: 6.7rem;
    min-width: 6.7rem;
  }

  background-color: ${(props) => props.theme.colors.background.beigeLight};

  svg {
    fill: ${(props) => colorTheme(props.colorTheme, 'keyFacts.iconThemeColor')};
    width: 2.86rem;
    height: 4rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      height: 2rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      height: 3.2rem;
    }
  }
`

export const FactTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.2rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 2rem;
  }
`

export const FactHeadline = styled(Title)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 0;
    margin-bottom: 0.8rem;
  }
`

export const FactDescription = styled(RichText)`
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`
