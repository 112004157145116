import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const BORDER_RADIUS = '0.9rem'

const BaseStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`

export const OuterWrapper = styled(BaseStyles)`
  background-color: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  padding: 1rem;
  text-align: center;
`

export const ContentWrapper = styled(BaseStyles)`
  max-width: 58.7rem;
`

export const BorderWrapper = styled(BaseStyles)`
  flex-direction: row;
  margin-top: 1.6rem 0;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 2.4rem 0;
  }
`

export const AppointmentWrapper = styled.section`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin: 2rem 0;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    margin: 3rem 0;
  }
`

export const AppointmentDetails = styled(BaseStyles)`
  align-items: space-between;
  font-size: 3.5rem;
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: 4rem;
  padding: 3rem;
  white-space: nowrap;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 5rem;
    line-height: 5.5rem;
    width: 50%;
  }
`

export const ColumnHeading = styled.div`
  color: ${(props) => props.theme.colors.text.white};
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: 2.7rem;
  margin-bottom: 0.5rem;
`

export const AppointmentDetailsDate = styled(AppointmentDetails)`
  background-color: ${(props) => props.theme.colors.background.white};
  color: ${(props) => props.theme.colors.text.black};

  @media ${(props) => props.theme.breakpoints.mobile} {
    border-top-left-radius: ${BORDER_RADIUS};
    border-top-right-radius: ${BORDER_RADIUS};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-top-left-radius: ${BORDER_RADIUS};
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-top-right-radius: 0;
  }

  ${ColumnHeading} {
    color: ${(props) => props.theme.colors.text.blue};
  }
`

export const AppointmentDetailsTime = styled(AppointmentDetails)`
  background: ${(props) => props.theme.colors.background.rocketboyGradient};

  @media ${(props) => props.theme.breakpoints.mobile} {
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }
`

export const Headline = styled.h1`
  display: flex;
  flex-wrap: wrap;
  font-size: 3.5rem;
  justify-content: center;
  line-height: 4rem;
  font-weight: ${(props) => props.theme.fontWeights.light};
  margin-top: 3.2rem;

  > .even {
    color: ${(props) => props.theme.colors.text.blue};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    flex-direction: column;

    > span {
      margin-right: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    font-size: 5rem;
    line-height: 5.5rem;

    > span {
      margin-right: 1rem;
    }

    > span:last-child {
      margin-right: 0;
    }
  }
`

export const SubHeadline = styled.p`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 1.8rem;
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
`

export const Message = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin-bottom: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 3rem;
  }
`

export const StyledReactSVG = styled(ReactSVG)`
  background-color: ${(props) => props.theme.colors.background.blue};
  border-radius: 50%;
  margin-top: 6rem;
  padding: 2rem;

  > div {
    height: 8rem;
    width: 8rem;
  }

  path {
    fill: ${(props) => props.theme.colors.background.white};
  }
`

export const ButtonWrapper = styled.div`
  margin-bottom: 3.2rem;
  font-size: 1.8rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 8.4rem;
  }
`
