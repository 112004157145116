import styled from 'styled-components'

import { Container } from '../../atoms'

export const LoadingWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  margin-bottom: 9.2rem;
  animation: ${(props) => props.theme.animations.fadeIn}
    ${(props) => props.theme.transitions.slow}
    ${(props) => props.theme.transitions.verySlow} ease forwards;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 11.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 10.6rem;
  }
`

export const LoadingContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  align-items: center;
`

export const LoadingText = styled.div`
  margin-bottom: 3rem;
`
