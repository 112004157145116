import React from 'react'

import { Column, RichText, Row } from '../../atoms'
import { Form, OrderedStepsList, CallbackBlock } from '../../molecules'

import {
  CallbackFormContainer,
  CallbackBlockColumn,
  FormWrapper,
  Headline,
  Paragraph,
  SubHeadline,
} from './styles'
import { Props } from './types'

export const CallbackForm = (props: Props) => {
  const {
    data,
    formInput,
    saveCallback,
    showCaptchaV2,
    loading,
    className,
    timeslots,
    candidates,
  } = props

  if (!data || !saveCallback) {
    return null
  }

  const {
    headline,
    subhead,
    stepsToComplete,
    description,
    html,
    formFields,
    callToActions,
    description2,
    captchaAction,
    captchaLegalText,
    formType,
  } = data

  return (
    <CallbackFormContainer
      className={className}
      data-bdd-selector="callback-form-container"
    >
      <Row>
        <Column tablet={8} desktop={6}>
          {headline && (
            <Headline
              data-bdd-selector="callback-form-headline"
              html={headline}
            />
          )}

          {stepsToComplete && (
            <OrderedStepsList
              list={stepsToComplete}
              data-bdd-selector="callback-form-orderedlist"
            ></OrderedStepsList>
          )}

          {subhead && (
            <SubHeadline
              data-bdd-selector="callback-form-subhead"
              html={subhead}
            />
          )}

          {description && (
            <Paragraph
              data-bdd-selector="callback-form-description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></Paragraph>
          )}

          {html && <RichText html={html} />}
          <FormWrapper>
            <Form
              formType={formType}
              formFields={formFields}
              captchaAction={captchaAction}
              captchaLegalText={captchaLegalText}
              formInput={formInput}
              callToActions={callToActions}
              saveCallback={saveCallback}
              showCaptchaV2={showCaptchaV2}
              loading={loading}
              gtmButtonID="gtm-callback-modal-submit-button"
              timeslots={timeslots}
              candidates={candidates}
            />
          </FormWrapper>
        </Column>
        {description2 && (
          <CallbackBlockColumn tablet={8} desktop={[5, 1]}>
            <CallbackBlock
              data={description2}
              data-bdd-selector="callback-form-description2"
            />
          </CallbackBlockColumn>
        )}
      </Row>
    </CallbackFormContainer>
  )
}

export default CallbackForm
