import React, { forwardRef, RefObject } from 'react'

import { Row, Image, RichText } from '../../atoms'
import { CallToActionGroup, YoutubeVideo } from '../../molecules'

import {
  ButtonListWrapper,
  Content,
  Description,
  Headline,
  ImageWrapper,
  Overline,
  ComponentWrapper,
  ContentWrapper,
  Container,
  MediaColumn,
  TextColumn,
  Column,
} from './styles'
import { Props } from './types'

const buttonList = (hasVideo, colorTheme, callToAction = []) => {
  if (!callToAction || callToAction.length === 0) {
    return null
  }

  return (
    <ButtonListWrapper
      data-bdd-selector="feature-block-call-to-actions"
      hasVideo={hasVideo}
      colorTheme={colorTheme}
    >
      <CallToActionGroup callToActions={callToAction} />
    </ButtonListWrapper>
  )
}

const VideoContent = (props) => {
  const { embedUrl, mediaColumnSize, videoTitle, mediaAlignment } = props

  return (
    <MediaColumn
      tablet={mediaColumnSize.tablet}
      desktop={mediaColumnSize.desktop}
      verticalAlignment="center"
      mediaAlignment={mediaAlignment}
    >
      <YoutubeVideo embedUrl={embedUrl} videoTitle={videoTitle} />
    </MediaColumn>
  )
}

const ImageContent = (props) => {
  const { data, mediaColumnSize, mediaAlignment } = props

  return (
    <MediaColumn
      tablet={[6, 1]}
      desktop={mediaColumnSize.desktop}
      verticalAlignment="center"
      mediaAlignment={mediaAlignment}
    >
      <ImageWrapper mediaAlignment={mediaAlignment}>
        <Image data={data} data-bdd-selector="feature-block-image" />
      </ImageWrapper>
    </MediaColumn>
  )
}

const FeatureBlock = forwardRef((props: Props, ref: RefObject<HTMLElement>) => {
  const {
    data: {
      images = [],
      mediaAlignment = 'right',
      contentBlock: {
        callToAction = [],
        description = '',
        headline = '',
        overline = '',
        useTicksForBullets = false,
      } = {},
      addVideo: { embedUrl = '', videoTitle = '' } = {},
      boxed = false,
    },
    colorTheme,
  } = props

  let contentColumnsSize
  let mediaColumnSize

  if (mediaAlignment === 'right') {
    if (boxed) {
      contentColumnsSize = {
        desktop: [6, 0],
        tablet: [6, 0],
      }
      mediaColumnSize = {
        desktop: [6, 0],
        tablet: [6, 1],
      }
    } else {
      contentColumnsSize = {
        desktop: [5, 0],
        tablet: [6, 1],
      }
      mediaColumnSize = {
        desktop: [6, 1],
        tablet: [6, 1],
      }
    }
  } else {
    if (boxed) {
      contentColumnsSize = {
        desktop: [6, 0],
        tablet: [6, 0],
      }
      mediaColumnSize = {
        desktop: [6, 0],
        tablet: [6, 1],
      }
    } else {
      contentColumnsSize = {
        desktop: [5, 1],
        tablet: [6, 1],
      }
      mediaColumnSize = {
        desktop: [6, 0],
        tablet: [6, 0],
      }
    }
  }

  const hasVideo = Boolean(embedUrl)
  const hasImage = Array.isArray(images) && images.length > 0

  return (
    <ComponentWrapper
      {...{ colorTheme, hasVideo, boxed, ref }}
      data-bdd-selector="feature-block"
    >
      <Container paddingSize="none">
        <Row>
          <Column>
            <ContentWrapper>
              {hasVideo && (
                <VideoContent
                  embedUrl={embedUrl}
                  videoTitle={videoTitle}
                  mediaColumnSize={mediaColumnSize}
                  mediaAlignment={mediaAlignment}
                  data-bdd-selector="feature-block-video"
                />
              )}
              {hasImage && (
                <ImageContent
                  data={images}
                  mediaAlignment={mediaAlignment}
                  mediaColumnSize={mediaColumnSize}
                  data-bdd-selector="feature-block-image"
                />
              )}
              <TextColumn
                tablet={contentColumnsSize.tablet}
                desktop={contentColumnsSize.desktop}
                verticalAlignment="center"
                mediaAlignment={mediaAlignment}
              >
                <Content data-bdd-selector="feature-block-content">
                  {overline && (
                    <Overline>
                      <RichText
                        html={overline}
                        data-bdd-selector="feature-block-overline"
                      />
                    </Overline>
                  )}
                  {headline && (
                    <Headline>
                      <RichText
                        html={headline}
                        data-bdd-selector="feature-block-headline"
                      />
                    </Headline>
                  )}
                  {description && (
                    <Description>
                      <RichText
                        html={description}
                        useTicksForBullets={useTicksForBullets}
                        data-bdd-selector="feature-block-description"
                      />
                    </Description>
                  )}
                  {callToAction &&
                    buttonList(hasVideo, colorTheme, callToAction)}
                </Content>
              </TextColumn>
            </ContentWrapper>
          </Column>
        </Row>
      </Container>
    </ComponentWrapper>
  )
})

export default FeatureBlock
