import { useRouter } from 'next/router'
import { useState, Fragment, useEffect } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import api from '../../../../services/api'
import { palette } from '../../../../styles/theme/colors'
import { logEvent } from '../../../../utils'
import { Container, RichText, Row, DividerLine } from '../../atoms'
import { CallToAction } from '../../molecules'
import Form from '../../molecules/Form'
import { NEWSLETTER_FIELDS } from '../../molecules/Form/baseData'
import FormWrapper from '../../molecules/FormWrapper'
import { CallbackResultData } from '../CallbackFormResult/types'
import {
  ButtonWrapper,
  FormWrapper as FormWrapperStyled,
  Headline,
  Icon,
  ParagraphColumn,
  ResultWrapper,
  StyledWrapper,
  SubHeadline,
  TitleColumn,
} from './styles'
import { initialState, Props, State } from './types'

const NewsLetterSignUpBanner = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const [state, setState] = useState<State>(props.initialState || initialState)
  const [showCaptchaV2, setShowCaptchaV2] = useState(false)
  const router = useRouter()

  const { success, error, formInput, loading } = state

  const { data, campaignNameFromPage } = props

  /* istanbul ignore next */
  useEffect(() => {
    if (
      error &&
      error.response &&
      error.response.status === 406 &&
      !showCaptchaV2
    ) {
      setShowCaptchaV2(true)
    }
  }, [error, showCaptchaV2])

  if (!data) {
    return null
  }
  const {
    sys,
    headline,
    description,
    formFieldsCollection,
    captchaAction,
    captchaLegalText,
    callToActionsCollection,
    resultsCollection,
    campaignName,
  } = data || {}
  const cdnFormFields = data.formFields ? data.formFields : []
  const gqlFormFields = formFieldsCollection ? formFieldsCollection.items : []
  const formFields = sys
    ? [...NEWSLETTER_FIELDS, ...gqlFormFields]
    : [...NEWSLETTER_FIELDS, ...cdnFormFields]

  // to use modal we use formwrapper but this populates the newsletter fields for us
  const formFieldsFormWrapper = sys ? gqlFormFields : cdnFormFields

  const callToActions = sys ? callToActionsCollection.items : data.callToActions
  const results = sys ? resultsCollection.items : data.results

  const backgroundColor = props.backgroundColor || palette.black
  const textColor = palette.white

  /* istanbul ignore next */
  const resultsCTAHandler = (e) => {
    e.preventDefault()
    const newState: State = {
      ...state,
      error: false,
      loading: false,
      success: false,
    }
    if (success) {
      newState.formInput = {}
    }
    // clears the form input
    setState(newState)
  }

  /* istanbul ignore next */
  const save = async (formValues: any) => {
    formValues._formId = sys ? sys.id : data._id

    if (formValues && formValues.Profiling_Results_Opt_In_c) {
      const query = router && router.query
      formValues.profilingAnswers = query
    } else {
      formValues.profilingAnswers = undefined
    }

    setState({
      ...state,
      loading: true,
      formInput: formValues,
    })

    try {
      await api.postNewsLetterSignup(formValues)
      setState({
        ...state,
        loading: false,
        success: true,
        error: false,
      })
      logEvent({
        category: 'User interactions',
        action: 'Newsletter',
        label: 'Signup-success',
        value: 1,
      })
    } catch (e) {
      setState({
        ...state,
        formInput: formValues,
        error: e,
        loading: false,
        success: false,
      })
      logEvent({
        category: 'User interactions',
        action: 'Newsletter',
        label: 'Signup-error',
        value: 0,
      })
    }
  }

  // TODO - Rodrigo -  Instead of using images, use the ReactSVG component and use the fill property to
  // TODO - Rodrigo change the filling colour instead of having different icons to handle different themes.
  let result: CallbackResultData | undefined
  let icon: string | undefined
  const isNewContentType = results && results.every((r) => r.resultType)
  if (success || error) {
    icon = success
      ? '/static/images/success-tick-white.svg'
      : '/static/images/error-tick.svg'
    // backwards compat and improvement
    // if new content type (user has updated contenful form since migration 89) we have a new property called 'resultType' that states Failure or Success
    // the previous way you had to rely on the order of the 'results' array.
    /* istanbul ignore next */
    if (isNewContentType) {
      result = success
        ? results.find((r) => r.resultType === 'Success')
        : results.find((r) => r.resultType === 'Failure')
    } else result = success ? results[0] : results[1]
  }
  if (result && !isNewContentType) {
    return (
      <StyledWrapper
        data-bdd-selector="newsletter-signup-banner"
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <Container center={true}>
          <Row>
            <ResultWrapper tablet={[6, 1]} desktop={[6, 3]}>
              <Icon
                data-bdd-selector="newsletter-signup-banner-result-icon"
                src={icon}
                alt=""
              />
              <Headline
                element={3}
                level={5}
                weight="bold"
                data-bdd-selector="newsletter-signup-banner-result-headline"
              >
                {result.headline}
              </Headline>
              <SubHeadline data-bdd-selector="newsletter-signup-banner-result-sub-headline">
                {result.subHeadline}
              </SubHeadline>
              <ButtonWrapper>
                {sys ? (
                  <CallToAction
                    {...result.callToActionsCollection.items[0]}
                    clickHandler={resultsCTAHandler}
                    componentTheme={'blue'}
                    data-bdd-selector="newsletter-signup-banner-result-button"
                  />
                ) : (
                  <CallToAction
                    {...result.callToActions[0]}
                    clickHandler={resultsCTAHandler}
                    componentTheme={'blue'}
                    data-bdd-selector="newsletter-signup-banner-result-button"
                  />
                )}
              </ButtonWrapper>
            </ResultWrapper>
          </Row>
        </Container>
      </StyledWrapper>
    )
  }

  return (
    <Fragment>
      <StyledWrapper
        data-bdd-selector="newsletter-signup-banner"
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <Container center={true}>
          <Row>
            {headline && (
              <TitleColumn colorTheme={colorTheme}>
                <RichText
                  data-bdd-selector="newsletter-signup-banner-headline"
                  html={typeof headline === 'string' ? headline : undefined}
                  document={
                    typeof headline === 'object' ? headline.json : undefined
                  }
                />
              </TitleColumn>
            )}
            {description && (
              <ParagraphColumn tablet={[6, 1]} desktop={[6, 3]}>
                <RichText
                  data-bdd-selector="newsletter-signup-banner-description"
                  html={description}
                />
              </ParagraphColumn>
            )}

            {isNewContentType ? (
              <FormWrapperStyled tablet={[6, 1]} desktop={[6, 3]}>
                <FormWrapper
                  dark={true}
                  formType={'Newsletter'}
                  formFields={formFieldsFormWrapper}
                  callToActions={callToActions}
                  results={results}
                  sys={sys?.id ? { id: sys.id } : { id: data._id }}
                  campaignName={
                    campaignNameFromPage
                      ? campaignNameFromPage.name
                      : campaignName?.name
                  }
                />
              </FormWrapperStyled>
            ) : (
              <FormWrapperStyled tablet={[6, 1]} desktop={[6, 3]}>
                <Form
                  data-bdd-selector="newsletter-signup-banner-form-wrapper"
                  formFields={formFields}
                  captchaAction={captchaAction}
                  captchaLegalText={captchaLegalText}
                  formInput={formInput}
                  callToActions={callToActions}
                  saveCallback={save}
                  showCaptchaV2={showCaptchaV2}
                  loading={loading}
                  isNewletterSignUp={true}
                  gtmButtonID="gtm-newsletter-submit-button"
                />
              </FormWrapperStyled>
            )}
          </Row>
        </Container>
      </StyledWrapper>
      <DividerLine />
    </Fragment>
  )
}

export default NewsLetterSignUpBanner
