import React, { FunctionComponent, useState } from 'react'

import Slider from '../../atoms/Slider'

import {
  ControlsWrapper,
  SliderTitle,
  StyledPriceInput,
  StyledPricingDetailBlock,
  Wrapper,
} from './styles'
import { Props } from './types'

const PriceCalculator: FunctionComponent<Props> = (props: Props) => {
  const {
    className,
    data: {
      sliderContent,
      oneOffFeeDetails,
      ongoingFeeDetails,
      averageFeeDetails,
    },
  } = props

  const [value, setValue] = useState(
    sliderContent.sliderDefaultValue.toString()
  )
  const min = sliderContent.sliderStartValue
  const max = sliderContent.sliderEndValue
  const step = sliderContent.sliderIntervals

  const getError = (_value) => {
    if (parseFloat(_value) < min) {
      return `Please enter a value higher than ${min.toLocaleString()}`
    } else if (parseFloat(_value) > max) {
      return `Please enter a value less than ${max.toLocaleString()}`
    }
  }

  return (
    <Wrapper className={className} data-bdd-selector="price-calculator">
      <ControlsWrapper>
        <SliderTitle data-bdd-selector="price-calculator-title">
          {sliderContent.sliderTitle}
        </SliderTitle>
        <StyledPriceInput
          onChange={(newValue) => {
            setValue(newValue)
          }}
          value={value}
          label={sliderContent.sliderInputLabel}
          errorMessage={getError(value)}
        />
        <Slider
          data-bdd-selector="price-calculator-slider"
          min={min}
          max={max}
          minLabel={`£${min.toLocaleString()}`}
          maxLabel={`£${max.toLocaleString()}`}
          step={step}
          value={parseFloat(value) || min}
          onChange={(newValue) => {
            newValue > max
              ? setValue(max.toString())
              : setValue(newValue.toString())
          }}
        />
      </ControlsWrapper>

      <StyledPricingDetailBlock
        data={oneOffFeeDetails}
        investmentValue={value}
      />
      <StyledPricingDetailBlock
        data={ongoingFeeDetails}
        investmentValue={value}
      />
      <StyledPricingDetailBlock
        data={averageFeeDetails}
        investmentValue={value}
      />
    </Wrapper>
  )
}

export default PriceCalculator
