import { Fragment, FunctionComponent } from 'react'

import { RichText } from '../../atoms'

import {
  Wrapper,
  Description,
  Heading,
  Headline,
  StyledFeeBlock,
  ContentColumn,
  FeeBars,
  FeeBar,
} from './styles'
import { FeeTableProps, Props } from './types'

const FeeTable: FunctionComponent<FeeTableProps> = (props) => {
  const { data } = props
  const gridProperties = {
    dataRowOffset: 1,
  }

  return (
    <Fragment>
      <Heading>
        <RichText html={data.headline} />
      </Heading>
      <FeeBars>
        {data.feeBlocks.map((feeBlock, index) => {
          return (
            <FeeBar key={index}>
              <Headline row={index + gridProperties.dataRowOffset}>
                {feeBlock.headline}
              </Headline>
              <Description
                row={index + gridProperties.dataRowOffset}
                backgroundColor={index > 4 ? 4 : index}
              >
                <StyledFeeBlock key={`feeblock-${index}`} data={feeBlock} />
              </Description>
            </FeeBar>
          )
        })}
      </FeeBars>
    </Fragment>
  )
}

const FeeGrid: FunctionComponent<Props> = (props) => {
  const { data, className } = props

  return (
    <Wrapper data-bdd-selector="fee-block-grid">
      <ContentColumn>
        {data && data.feeTableLeft && (
          <FeeTable data={data.feeTableLeft} className={className} />
        )}
      </ContentColumn>
      <ContentColumn>
        {data && data.feeTableRight && (
          <FeeTable data={data.feeTableRight} className={className} />
        )}
      </ContentColumn>
    </Wrapper>
  )
}

export default FeeGrid
