import styled from 'styled-components'

import { Container, Title } from '..'
import { palette } from '../../../../styles/theme/colors'

const { red } = palette

export const StyledContainer = styled(Container)`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 3rem 0 !important;

  background: ${(props) =>
    props.isDark
      ? props.theme.colors.background.black
      : props.theme.colors.background.grey1};

  h3 {
    color: ${(props) =>
      props.isDark
        ? props.theme.colors.background.white
        : props.theme.colors.background.black};
  }
`

export const Overline = styled(Title)`
  color: ${red};
  text-transform: uppercase;
`
