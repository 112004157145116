import { FunctionComponent, useEffect, useState } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { HiddenText } from '../../atoms'

import { DownSVG, ScrollToTopWrapper, UpSVG, Wrapper } from './styles'
import { Props, ScrollType } from './types'

const downAnchorSvg = '/static/icons/ChevronDown.svg'
const upAnchorSvg = '/static/icons/ChevronUp.svg'

/**
 * A function that creates a Scroll to Link
 * @param scrollType - Enum to specify Scroll To top or Scroll Down to element.
 *  Scroll to top doesnt need to specify scrollElementRef
 * @param scrollDuration - Enum to specify Scroll duration
 * @param scrollElementRef - Ref of the html Element to scroll to.
 * The Element tag should wrap the component that needs to be scrolled to.
 */
const Scroll: FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { scrollElementRef, invertTheme } = props

  const [scrollType, setScrollType] = useState<ScrollType>(ScrollType.ToElement)

  const [scrollToTopVisibility, setScrollToTopVisibility] = useState(false)

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    return false
  }

  const scrollToElement = () => {
    if (scrollElementRef && scrollElementRef.current) {
      scrollElementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      setScrollToTopVisibility(true)
    } else {
      setScrollToTopVisibility(false)
    }
  }

  useEffect(() => {
    setScrollType(props.scrollType)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollType])

  if (scrollType === ScrollType.ToElement) {
    return (
      <Wrapper
        colorTheme={colorTheme}
        invertTheme={invertTheme}
        onClick={scrollToElement}
        data-bdd-selector="scroll-to-element"
      >
        <HiddenText all={true}>Scroll to next section</HiddenText>
        <DownSVG src={downAnchorSvg} wrapper="span" />
      </Wrapper>
    )
  }

  return (
    <ScrollToTopWrapper
      visible={scrollToTopVisibility}
      data-bdd-selector="scroll-to-top"
    >
      <Wrapper
        onClick={scrollToTop}
        colorTheme={colorTheme}
        isScrollToTop={true}
      >
        <HiddenText all={true}>Scroll to top</HiddenText>
        <UpSVG src={upAnchorSvg} wrapper="span" />
      </Wrapper>
    </ScrollToTopWrapper>
  )
}

export default Scroll
