import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React, { forwardRef, FunctionComponent } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'

import { RichTextContainer } from './styles'
import { Props } from './types'

/**
 * A function that renders HTML content and applies the necessary styles.
 *
 * @param html - Required. The incoming HTML data.
 *
 * @returns HTML - The HTML markup.
 */
const RichText: FunctionComponent<Props> = forwardRef((props, ref) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { html, document, element } = props

  if (html) {
    const filteredHtml = html.replace(/<[^\/>][^>]{1,20}><\/[^>]+>/, '')
    return (
      <RichTextContainer
        ref={ref}
        {...props}
        as={element}
        colorTheme={colorTheme}
        dangerouslySetInnerHTML={{ __html: filteredHtml }}
      />
    )
  }

  if (document) {
    return (
      <RichTextContainer
        ref={ref}
        {...props}
        as={element}
        colorTheme={colorTheme}
      >
        {documentToReactComponents(document)}
      </RichTextContainer>
    )
  }

  return null
})

export default RichText
