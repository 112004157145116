import styled from 'styled-components'

import { FeeBlock } from '..'
import theme from '../../../../styles/theme'

import { GridProps } from './types'

const feeBlockMultiColorList = {
  0: theme.colors.background.blue,
  1: theme.colors.background.red,
  2: theme.colors.background.blue2,
  3: theme.colors.background.black,
}

const borderRadius = '1.6rem'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-column-gap: 3.1rem;
  }
`

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius};
  overflow: hidden;
  margin-top: 3rem;
  padding: 3.2rem 3.2rem 4rem;
  background: ${(props) => props.theme.colors.background.white};
`

export const Heading = styled.div<GridProps>`
  flex-grow: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.text.black};
  margin-bottom: 3rem;

  h2 {
    margin-top: 0;
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h4};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h5};
    }
  }
`

export const StyledFeeBlock = styled(FeeBlock)`
  margin-bottom: 0;
  padding-top: 0;
`

export const FeeBars = styled.div`
  width: 100%;
  align-self: flex-end;
`

export const FeeBar = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-template-columns: 26% 74%;
  }
`

export const Headline = styled.div<GridProps>`
  padding-right: 2rem;
  text-align: right;
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};
  color: ${(props) => props.theme.colors.text.grey4};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-row: ${(props) => props.row};
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`

export const Description = styled.div<GridProps>`
  background-color: ${(props) => feeBlockMultiColorList[props.backgroundColor]};

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-row: ${(props) => props.row};
  }
`
