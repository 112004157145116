import styled from 'styled-components'

import { Column, Container, RichText } from '../../atoms/'

export const CallbackFormContainer = styled(Container)`
  padding-top: 10rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 11rem;
  }
`

export const CallbackBlockColumn = styled(Column)`
  display: none;
  @media ${(props) => props.theme.breakpoints.tablet} {
    display: block;
    margin-top: 6rem;
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 8.5rem;
  }
`

export const Headline = styled(RichText)`
  color: ${(props) => props.theme.colors.text.white};
  h3,
  h4 {
    font-size: 3.5rem;
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: 3.9rem;
    padding: 0;
    margin-bottom: 2rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 5rem;
      line-height: 5.5rem;
      margin-bottom: 3rem;
    }
  }
  h3,
  h4 b {
    color: ${(props) => props.theme.colors.text.blue};
    font-weight: ${(props) => props.theme.fontWeights.light};
  }
`

export const SubHeadline = styled(RichText)`
  h4,
  h5 {
    color: ${(props) => props.theme.colors.text.white};
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: 4rem;
    margin-top: 1.6rem;
    padding: 0;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 3.5rem;
      line-height: 3.9rem;
    }

    b {
      color: ${(props) => props.theme.colors.text.blue};
      font-weight: ${(props) => props.theme.fontWeights.light};
    }
  }
`

export const Paragraph = styled(Column)`
  color: ${(props) => props.theme.colors.text.white};
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 1rem;
  padding: 0;
  white-space: pre-wrap;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.5rem;
  }
`

export const FormWrapper = styled.div`
  p {
    color: ${(props) => props.theme.colors.text.white};
  }

  [class*='__CheckboxInputLabel'] {
    color: ${(props) => props.theme.colors.text.white};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.body.secondary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin-top: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 2.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 3rem;
  }

  [class*='__CaptchaPolicy'] {
    text-align: left;
  }

  [class*='__CTAWrapper'] {
    @media ${(props) => props.theme.breakpoints.mobileMax} {
      text-align: center;
    }
  }
`
