import { rgba } from 'polished'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { WrapperProps } from './types'

export const DownSVG = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: ${(props) => props.theme.lineHeights.none};
`

export const UpSVG = styled(ReactSVG)`
  display: block;
  width: 100%;
  text-align: center;
`

export const Wrapper = styled.button<WrapperProps>`
  height: 3.6rem;
  width: 3.6rem;
  appearance: initial;
  display: flex;
  align-items: center;
  border: 0;
  box-shadow: ${(props) =>
    `${rgba(props.theme.colors.background.black, 0.15)} 0 2px 4px 0`};
  background-color: ${(props) =>
    colorTheme(
      props.colorTheme,
      props.invertTheme
        ? 'scroll.iconThemeColor'
        : 'scroll.backgroundThemeColor'
    )};
  border-radius: 2.5rem;
  margin: 0 auto;
  padding: 0;
  color: inherit;
  z-index: 1;
  cursor: pointer;
  transition: background-color ${(props) => props.theme.transitions.normal} ease,
    transform ${(props) => props.theme.transitions.normal} ease;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 4.4rem;
    width: 4.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 5rem;
    width: 5rem;
  }

  &:hover {
    transform: ${(props) =>
      props.isScrollToTop ? 'translateY(-1rem)' : 'translateY(1rem)'};
    background-color: ${(props) =>
      colorTheme(
        props.colorTheme,
        props.invertTheme
          ? 'scroll.iconThemeColor'
          : 'scroll.backgroundThemeColor'
      )};
  }

  svg {
    height: 2rem;
    width: 2rem;
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        props.invertTheme
          ? 'scroll.backgroundThemeColor'
          : 'scroll.iconThemeColor'
      )};

    @media ${(props) => props.theme.breakpoints.desktop} {
      height: 2.4rem;
      width: 2.4rem;
    }
  }
`

export const ScrollContainer = styled.div`
  position: absolute;
  left: 20%;
  top: 20%;
  z-index: 2;
`

export const ScrollToTopWrapper = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: fixed;
  bottom: -5rem;
  right: 2rem;
  z-index: 2;
  transform: ${(props) => props.visible && `translateY(-7rem)`};
  transition: transform ${(props) => props.theme.transitions.normal} ease,
    visibility ${(props) => props.theme.transitions.normal};

  @media ${(props) => props.theme.breakpoints.tablet} {
    right: 1.8rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    right: 4rem;
  }
`
