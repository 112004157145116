import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledWrapper } from './types'

export const Wrapper = styled.span<StyledWrapper>`
  height: 100%;
  width: 100%;
  fill: ${(props) => {
    if (props.invert) {
      return props.useColorTheme
        ? colorTheme(props.colorTheme, 'logo.iconThemeColor')
        : props.theme.colors.ui.white
    }
    if (props.hasCustomHeaderBG) {
      return props.theme.colors.ui.white
    }
    return props.theme.colors.ui.blue2
  }};

  transition: fill ${(props) => props.theme.transitions.normal} ease;
`

export const LogoSVG = styled(ReactSVG)`
  height: 100%;
  width: 100%;

  & > span {
    height: 100%;
    width: 100%;

    & > svg {
      display: block;

      & > g .st0,
      .st1 {
        fill: unset;
      }
    }
  }
`
