import { Field } from 'formik'
import * as React from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'

import { Props, InputVariantMapping } from './types'

const RenderInput: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    name,
    label,
    inputType,
    validate,
    id,
    helpText,
    disabled,
    excludedDates,
    data,
    timeslots,
    candidates,
  } = props

  if (InputVariantMapping[inputType]) {
    const configProps: {
      validate?: (value: any) => undefined | string | Promise<any>
    } = {}

    if (validate !== undefined) {
      configProps.validate = validate
    }

    return (
      <Field
        name={name}
        id={id}
        label={label}
        inputType={inputType}
        component={InputVariantMapping[inputType]}
        helpText={helpText}
        excludedDates={excludedDates}
        disabled={disabled}
        colorTheme={colorTheme}
        data={data}
        timeslots={timeslots}
        candidates={candidates}
        {...configProps}
      />
    )
  }

  return null
}

export default RenderInput
