import styled from 'styled-components'

import { Paragraph, RichText } from '..'

export const Section = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  padding: 5rem 2rem 2.4rem;
  flex-direction: column;
  text-align: left;
  border-radius: 1.6rem;
  background: ${(props) => props.theme.colors.background.white};
`

export const Icon = styled.div`
  position: absolute;
  width: 6.4rem;
  height: 6.4rem;
  top: -3.2rem;
  border-radius: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.colors.background.blue};

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    svg {
      position: absolute;
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const IconImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const Headline = styled(Paragraph)`
  margin-bottom: 1.6rem;

  &,
  p {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
  }
`

export const Description = styled(RichText)`
  &,
  p {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`
