import { rgba } from 'polished'
import styled from 'styled-components'

export const Item = styled.div`
  display: block;
  height: auto;
  background-color: ${(props) => props.theme.colors.background.white};
  border-radius: 8px;
  box-shadow: 0 2px 14px 0
    ${(props) => rgba(props.theme.colors.background.black, 0.08)};
  margin-bottom: 2.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 100%;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-column-gap: 3.1rem;
  }

  ul {
    margin-bottom: 0;
  }

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`
