import React from 'react'
import { Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.css'
import Mobile from './Mobile'
import Desktop from './Desktop'
import { useDispatch } from 'react-redux'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { HiddenText, Link, Logo } from '../../atoms'
import { CallOrBeCalled, CallToAction } from '../../molecules'
import { actions as callbackActions } from '../CallbackFormModal/actions'
import NAV_MENU_BUTTON_LABEL from './constants'
import {
  ListItem,
  Nav,
  NavControls,
  NavLogo,
  NavLogoWrapper,
  NavActionsWrapper,
  NavMenuButton,
  NavMenuButtonWrapper,
  NavMenuCross,
  NavPhoneNumber,
  SecondaryList,
} from './styles'
import { Props } from './types'

const Navigation: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    secondaryMenuData,
    callToAction,
    phoneNumber,
    isOpen,
    onOpenSideMenuClick,
    invert,
    imageUrl,
    useColorTheme,
    hasCustomHeaderBG,
    viewportInfo: { isSmallerThan },
  } = props

  const dispatch = useDispatch()

  const handleClick = () => {
    onOpenSideMenuClick()
  }

  const handleCallbackClick = () => {
    onOpenSideMenuClick()
    dispatch(callbackActions.showCallbackFormModal())
  }

  const filteredHtml =
    phoneNumber && phoneNumber.replace(/<[^\/>][^>]*><\/[^>]+>/, '')

  return (
    <Nav
      invert={invert}
      useColorTheme={useColorTheme}
      isOpen={isOpen}
      colorTheme={colorTheme}
      data-bdd-selector="navigation"
      hasCustomHeaderBG={hasCustomHeaderBG}
    >
      {isSmallerThan.desktopMedium && (
        <div>
          <NavControls>
            <NavLogoWrapper>
              <NavLogo data-bdd-selector="navigation-logo">
                <Link route="/" callback={handleClick}>
                  <Logo useColorTheme={useColorTheme} imageData={imageUrl} />
                  <HiddenText all={true}>Go to home page</HiddenText>
                </Link>
              </NavLogo>
            </NavLogoWrapper>

            <NavActionsWrapper>
              <CallOrBeCalled
                callToAction={callToAction}
                phoneNumber={phoneNumber}
              />
            </NavActionsWrapper>

            <NavMenuButtonWrapper>
              <NavMenuButton
                onClick={onOpenSideMenuClick}
                data-bdd-selector="navigation-menu-button"
              >
                <HiddenText mobile tablet desktop>
                  {NAV_MENU_BUTTON_LABEL}
                </HiddenText>
                <NavMenuCross />
              </NavMenuButton>
            </NavMenuButtonWrapper>
          </NavControls>
          <Mobile {...props} />
        </div>
      )}
      {!isSmallerThan.desktopMedium && (
        <Menu
          vertical={false}
          style={{ width: 'inherit !important' }}
          secondary
        >
          <Desktop {...props} />
        </Menu>
      )}
      {secondaryMenuData && isSmallerThan.desktopMedium && (
        <SecondaryList
          tabIndex={isOpen ? 0 : -1}
          data-bdd-selector="navigation-secondary-links"
        >
          {secondaryMenuData.map((item, index) => {
            const { _id, callToAction } = item
            const clickHandler =
              callToAction[0].target === 'modal' && handleCallbackClick

            return (
              <ListItem key={_id} hasHighlight={index % 2 === 0}>
                <CallToAction
                  {...callToAction[0]}
                  clickHandler={clickHandler}
                  data-bdd-selector="navigation-secondary-link"
                />
              </ListItem>
            )
          })}
          {phoneNumber && (
            <ListItem>
              <NavPhoneNumber
                dangerouslySetInnerHTML={{ __html: filteredHtml }}
              />
            </ListItem>
          )}
        </SecondaryList>
      )}
    </Nav>
  )
}

export default Navigation
