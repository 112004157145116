import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledColumn, StyledSection, StyledWrapper } from './types'

export const Wrapper = styled.div<StyledWrapper>`
  overflow: auto;
  background-color: ${(props) => props.theme.colors.background.beigeHighlight};
`

export const Section = styled.section<StyledSection>`
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 5%;
  }

  h2 b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) =>
      colorTheme(props.colorTheme, 'threeColumnsIcons.textAccentThemeColor')};
  }

  h5 {
    font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
    line-height: ${(props) => props.theme.lineHeights.compact};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin: 2.3rem 0 1.7rem;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${(props) => props.theme.breakpoints.tablet} {
      line-height: ${(props) => props.theme.lineHeights.compact};
      font-weight: ${(props) => props.theme.fontWeights.bold};
      margin: 3.3rem 0 1.6rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
      line-height: ${(props) => props.theme.lineHeights.default};
      font-weight: ${(props) => props.theme.fontWeights.bold};
      margin: 0.5rem 0 1.6rem;
    }
  }

  p {
    color: ${(props) => props.theme.colors.text.grey4};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`

export const Column = styled.div<StyledColumn>`
  padding: 0 10%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex: 1 1 33.333%;
    padding: 0 2.5%;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 3%;
  }
`

export const ColumnItem = styled.div`
  margin-top: 6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 7rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 4rem;
  }
`

export const IconContainer = styled.div`
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10.8rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 10rem;
  }
`

export const CallToActionWrapper = styled.div`
  margin-top: 4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 5rem;
  }
`
