import { ColorThemeNames } from '../../../../types/colorThemes'

export type Props = ButtonData

export interface BaseProps {
  iconOnly: boolean
  hasIcon: boolean
  iconBeforeText: boolean
  hideTextOnMobile: boolean
  isLoading: boolean
}

export enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
  Link,
  Close,
}

export interface ButtonData {
  id?: string
  buttonType: ButtonType
  type?: string
  label?: string
  iconType?: string
  iconBeforeText?: boolean
  disabled?: boolean
  loading?: boolean
  onClick?: (e?: any) => void
  analyticsCategory?: string
  analyticsAction?: string
  rel?: string
  colorTheme?: ColorThemeNames
  componentTheme?: ColorThemeNames
  className?: string
  'data-bdd-selector'?: string
  'aria-label'?: string
  hideTextOnMobile?: boolean
  isButton?: boolean
  isLoading?: boolean
  invertColorTheme?: boolean
}

export interface StyledWrapper {
  colorTheme?: ColorThemeNames
  invertColorTheme?: boolean
}

export interface StyledText {
  hideTextOnMobile?: boolean
}

export interface StyledIcon {
  iconBeforeText: boolean
  hideTextOnMobile: boolean
}
