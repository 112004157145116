import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledQuoteWrapper } from './types'

export const QuoteWrapper = styled.div<StyledQuoteWrapper>`
  border-left: 0.3rem solid
    ${(props) => colorTheme(props.colorTheme, 'pullQuote.borderThemeColor')};
`

const BaseAlignment = css`
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

export const Quote = styled.div`
  ${BaseAlignment}
  padding-top: 0.6rem;
  p {
    font-family: inherit;
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.light};
    font-size: ${(props) => props.theme.fontSizes.mobile.body.secondary};
    line-height: ${(props) => props.theme.lineHeights.default};
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    p {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.secondary};
    }
  }
`

export const Credit = styled.footer`
  ${BaseAlignment}
  padding-top: 2.4rem;
  padding-bottom: 0.6rem;
  p {
    font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: ${(props) => props.theme.lineHeights.compact};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    p {
      font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    p {
      font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
    }
  }
`
