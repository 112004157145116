const sizes = {
  large: {
    desktop: 1200,
    tablet: 700,
    mobile: 400,
  },
  medium: {
    desktop: 600,
    tablet: 700,
    mobile: 400,
  },
  small: {
    desktop: 400,
    tablet: 400,
    mobile: 400,
  },
}

const quality = 80

export { sizes, quality }
