import React, { FunctionComponent } from 'react'

import { Container, IconCard } from '../../atoms'
import { CallToAction } from '../../molecules'

import {
  CallToActionWrapper,
  IconCardCallToActionWrapper,
  Section,
  Wrapper,
  StyledRow,
  StyledColumn,
  StyledRichText,
} from './styles'
import { Props } from './types'

const IconCardBlock: FunctionComponent<Props> = (props) => {
  const {
    data: { headline, columnIcon, callToAction },
  } = props

  return (
    <Wrapper data-bdd-selector="icon-card-block">
      <Container paddingSize="default" center={true}>
        <Section>
          <StyledRichText
            html={headline}
            data-bdd-selector="icon-card-block-headline"
          />
          {
            <StyledRow>
              {columnIcon.map((columnIconData) => (
                <StyledColumn
                  desktop={4}
                  tablet={4}
                  key={columnIconData._id}
                  data-bdd-selector="icon-card-block-item"
                >
                  <IconCard data={columnIconData} key={columnIconData._id}>
                    {columnIconData.callToAction && (
                      <IconCardCallToActionWrapper>
                        <CallToAction
                          aria-label={`${columnIconData.headline}: ${columnIconData.callToAction.buttonLabel}`}
                          {...columnIconData.callToAction}
                          data-bdd-selector="icon-card-block-item-cta"
                        />
                      </IconCardCallToActionWrapper>
                    )}
                  </IconCard>
                </StyledColumn>
              ))}
            </StyledRow>
          }
          {callToAction && callToAction[0] && (
            <CallToActionWrapper>
              <CallToAction
                {...callToAction[0]}
                data-bdd-selector="icon-card-block-cta"
              />
            </CallToActionWrapper>
          )}
        </Section>
      </Container>
    </Wrapper>
  )
}

export default IconCardBlock
