import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { RichText } from '../../atoms'

import { StyledElement } from './types'

export const Wrapper = styled.div`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-direction: row;
  }
`

const Container = css`
  flex: 1 1 50%;
  padding: 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 4rem;
    padding-right: 16.8rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 4.4rem;
    padding-right: 11rem;
  }
`

export const LeftContainer = styled.div`
  ${Container}
  background: ${(props) => props.theme.colors.background.white};
`

export const LeftText = styled(RichText)`
  ul {
    padding-left: 1.8rem;

    li::before {
      background-image: none;
      background: ${(props) => props.theme.colors.background.grey2};
      width: 0.6rem;
      height: 0.6rem;
      left: -1.8rem;
      top: 1rem;
    }
  }
`

export const RightContainer = styled.div<StyledElement>`
  ${Container}
  background: ${(props) =>
    colorTheme(props.colorTheme, 'twoColumnContentBlock.backgroundThemeColor')};
`

export const RightText = styled(RichText)<StyledElement>`
  color: ${(props) =>
    colorTheme(props.colorTheme, 'twoColumnContentBlock.textThemeColor')};

  i {
    margin-top: 3rem;
    display: block;
  }
`

export const CTAWrapper = styled.div`
  margin-top: 1.6rem;
`
