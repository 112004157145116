import React from 'react'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { RichText } from '../../atoms'
import { OrderedStepsListItem, Wrapper } from './styles'
import { Props } from './types'

const OrderedStepsList: React.FunctionComponent<Props> = (props) => {
  const { list } = props
  const colorTheme = GetThemeContext(ThemeContext)

  return (
    <Wrapper light={props.light}>
      {list.orderedStepsListItem.map((item, index) => (
        <OrderedStepsListItem key={index} light={props.light}>
          <RichText
            html={item.description}
            data-bdd-selector="ordered-list-item"
            colorTheme={colorTheme}
          />
        </OrderedStepsListItem>
      ))}
    </Wrapper>
  )
}

export default OrderedStepsList
