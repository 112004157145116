import React, { useState } from 'react'

import { Column, Container, RichText, Row } from '../../atoms'

import {
  HeaderColumn,
  Headline,
  Overline,
  Description,
  StyledTabs,
  StyledTwoColumnContentBlock,
} from './styles'
import { Props } from './types'

const getComponentToRender = (content) => {
  const components = {
    twoColumnContentBlock: () => <StyledTwoColumnContentBlock data={content} />,
    richText: () => (
      <Row>
        <Column tablet={8} desktop={[8, 2]}>
          <RichText html={content.richText} />
        </Column>
      </Row>
    ),
  }

  return components[content._type] ? components[content._type]() : null
}

const TabbedContentBlock: React.FunctionComponent<Props> = (props) => {
  const {
    data: { tabLinks, twoColumnContent, contentBlock },
    isNested,
  } = props

  const [selectedContent, setSelectedContent] = useState(twoColumnContent[0])
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabClick = (index: number) => {
    setSelectedContent(twoColumnContent[index] || twoColumnContent[0])

    setSelectedTab(index)
  }

  return (
    <Container>
      <Row>
        <HeaderColumn tablet={8} desktop={[10, 1]} isNested={isNested}>
          {contentBlock.overline && (
            <Overline
              html={contentBlock.overline}
              data-bdd-selector="tabbed-content-overline"
            />
          )}
          {contentBlock.headline && (
            <Headline
              html={contentBlock.headline}
              data-bdd-selector="tabbed-content-headline"
            />
          )}
          {contentBlock.description && (
            <Description
              html={contentBlock.description}
              data-bdd-selector="tabbed-content-description"
            />
          )}
        </HeaderColumn>
      </Row>
      <Row>
        <Column>
          <StyledTabs
            aria-label="Content filter"
            tabs={tabLinks}
            onTabClick={handleTabClick}
            selectedTab={selectedTab}
            isNested={isNested}
          />
          {getComponentToRender(selectedContent)}
        </Column>
      </Row>
    </Container>
  )
}

export default TabbedContentBlock
