import React from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'

import { Props } from './types'
import { TextOnly, WithImage } from './variants'

const Hero = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)

  const {
    data,
    trustBar,
    className,
    setColorThemeForOverlay,
    short,
    isTrustPilotRatingBarVisible = false,
  } = props
  const { heroType } = data

  const variantMapping = {
    textOnly: TextOnly,
    heroWithImage: WithImage,
  }

  const VariantToRender = variantMapping[heroType]

  if (VariantToRender) {
    return (
      <VariantToRender
        data={data}
        className={className}
        trustBar={trustBar}
        colorTheme={colorTheme}
        setColorThemeForOverlay={setColorThemeForOverlay}
        short={short}
        isTrustPilotRatingBarVisible={isTrustPilotRatingBarVisible}
      />
    )
  }

  return null
}

export default Hero
