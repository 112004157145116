import React, { createRef, useEffect } from 'react'

import { Props } from './types'

const TrackVisibility: React.FunctionComponent<Props> = (props) => {
  const { onVisible, children, className, threshold = 0.8 } = props
  const ref = createRef<HTMLDivElement>()
  let prevRatio = 0

  useEffect(() => {
    if (!window.IntersectionObserver) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.intersectionRatio >= threshold &&
          prevRatio < entry.intersectionRatio
        ) {
          onVisible()
        }

        prevRatio = entry.intersectionRatio
      },
      {
        root: null,
        rootMargin: '-100px 0px 0px 0px',
        threshold: [0, 0.25, 0.5, 0.75, 0.9, 1.0],
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}

export default TrackVisibility
