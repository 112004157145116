import * as React from 'react'
import { ReactSVG } from 'react-svg'

import { Section, Icon, Headline, IconImage, Description } from './styles'
import { Props } from './types'

const IconCard: React.FunctionComponent<Props> = (props) => {
  const {
    data: { svgIcon, headline, description },
  } = props

  return (
    <Section data-bdd-selector="icon-card">
      {/* jpg or png assumes background set in image, fallback to blue */}
      {svgIcon.file && svgIcon.file.url.indexOf('.svg') === -1 && (
        <Icon>
          <IconImage
            src={svgIcon.file.url}
            aria-hidden="true"
            data-bdd-selector="icon-card-icon"
          />
        </Icon>
      )}
      {svgIcon.file && svgIcon.file.url.indexOf('.svg') > 0 && (
        <Icon>
          <ReactSVG
            src={svgIcon.file.url}
            aria-hidden="true"
            data-bdd-selector="icon-card-icon"
          />
        </Icon>
      )}
      {headline && (
        <Headline
          level="medium"
          weight="bold"
          data-bdd-selector="icon-card-headline"
        >
          {headline}
        </Headline>
      )}
      {description && (
        <Description
          html={description}
          data-bdd-selector="icon-card-description"
        />
      )}
      {props.children}
    </Section>
  )
}

export default IconCard
