import * as React from 'react'

import { BackgroundImage, ColumnItem, Container, RichText } from '../../atoms'

import { Column, ItemsRow, Section } from './styles'
import { Props } from './types'

const ThreeColumns: React.FunctionComponent<Props> = (props) => {
  const {
    data: { headline, columnItem, backgroundImage },
    colorTheme,
  } = props

  return (
    <BackgroundImage
      colorTheme={colorTheme}
      data={backgroundImage}
      data-bdd-selector="three-columns"
    >
      <Container paddingSize="double" center={true}>
        <Section>
          <RichText
            html={headline}
            data-bdd-selector="three-columns-headline"
          />
          {columnItem && (
            <ItemsRow>
              {columnItem.map((item) => (
                <Column key={item._id} data-bdd-selector="three-columns-item">
                  <ColumnItem data={item} />
                </Column>
              ))}
            </ItemsRow>
          )}
        </Section>
      </Container>
    </BackgroundImage>
  )
}

export default ThreeColumns
