import * as React from 'react'

import { EnvConfig } from '../../../../constants/environmentConfig'
import { Caption } from '../../atoms'

import { Props } from './types'

const HIGH_CHART_URL = EnvConfig.SPW_UI_HIGH_CHART_URL

// TODO: Add nonce to the script tag and CSP headers for extra safety
// TODO: Add placeholder/loading state for the chart as its a deferred script.
// TODO: Storybook does not eval the script - so doesn't render highchart
const Chart: React.FunctionComponent<Props> = (props) => {
  const { chartId, caption } = props
  return (
    <section>
      {chartId && (
        <section id={`highcharts-${chartId}`} data-bdd-selector="chart">
          <script src={`${HIGH_CHART_URL}/${chartId}/`} defer={true} />
        </section>
      )}

      {caption && <Caption caption={caption.caption} credit={caption.credit} />}
    </section>
  )
}

export default Chart
