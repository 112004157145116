import { FormType } from '../../../../utils/form'
import { CallbackResultData } from '../../organisms/CallbackFormResult/types'
import { CallbackBlockData } from '../CallbackBlock/types'
import { CallToActionData } from '../CallToAction/types'
import { FormElement } from '../Form/types'
import { OrderedListItems } from '../OrderedStepsList/types'

export interface LeadSource {
  _id: string
  _type: 'secondaryLeadSource'
  entryTitle: string
  primaryLeadSourceKey?: string
  key?: string // secondary lead source
}
export interface FormData {
  _id?: string
  sys?: {
    id: string
  }
  _type?: string
  formType: FormType
  entryTitle?: string
  headline?: string | { json: object }
  subhead?: string | { json: object }
  description?: string | { json: object }
  stepsToComplete?: OrderedListItems
  html?: string
  formFields: FormElement[]
  results: CallbackResultData[]
  callToActions: CallToActionData[]
  description2?: CallbackBlockData
  captchaAction?: string
  captchaLegalText?: string
  salesforceCampaignName?: string
  referPageType?: ReferPageType
  additionalFormValues?: any
  dark?: boolean
  leadSource?: LeadSource
  campaignName: string
}

export type FormStatus = 'idle' | 'loading' | 'success' | 'failure'

export type Error =
  | {
      response: {
        status: number
      }
    }
  | undefined

export enum ReferPageType {
  Referral,
  Refer,
}
