import { addDays, format, isAfter, parseISO } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { ReactSVG } from 'react-svg'

import { Icons } from '../../../Icon/types'
import { InputContainer, Message, HelpText } from '../../styles'
import { TextInputLabel } from '../Text/styles'

import DatePickerReadOnlyInput from './datePickerReadOnlyInput'
import { DatePickerWrapper } from './styles'

const isWeekday = (date) => {
  const day = date.getDay()
  return day !== 0 && day !== 6
}

const currentDate = new Date()
const minDate = isAfter(currentDate, new Date().setHours(17, 0, 0, 0))
  ? addDays(currentDate, 1)
  : currentDate
const maxDate = addDays(currentDate, 14)

const DatePickerInput = ({
  field,
  form: { touched, errors, dirty, setFieldValue, setFieldTouched },
  colorTheme,
  ...props
}) => {
  const { label, id, helpText, excludedDates } = props
  const [isFocused, setIsFocused] = useState(false)

  const handleOnFocus = () => {
    setIsFocused(true)
    setFieldTouched(field.name, true, false)
  }

  const handleOnBlur = (e) => {
    field.onBlur(e)
    setIsFocused(false)
  }

  const handleOnChange = (e) => {
    e = e || ''
    field.onBlur()
    setIsFocused(false)

    const resultValue = format(e, 'yyyy-MM-dd')
    setFieldTouched(field.name, true, false)
    setFieldValue(field.name, resultValue)
  }

  const getFieldDate = () => {
    const value = field.value
    if (value) {
      const result = parseISO(value)
      return result
    }
    return ''
  }

  const excludeDates =
    excludedDates &&
    excludedDates.map((date) => {
      return new Date(date)
    })
  const isTouched = Boolean(touched[field.name])
  const hasErrors = errors[field.name]
  const hasValue = Boolean(field.value)
  const errorsInField = isTouched && hasErrors && errors[field.name]

  const icon =
    (isTouched && hasErrors && 'ExclamationMark') ||
    (isTouched && !hasErrors && 'Tick')

  return (
    <>
      <InputContainer>
        <TextInputLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={isTouched}
          errorsInField={errorsInField}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </TextInputLabel>
        <DatePickerWrapper
          colorTheme={colorTheme}
          data-bdd-selector="input_date_picker"
        >
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={getFieldDate()}
            onChange={handleOnChange}
            minDate={minDate}
            maxDate={maxDate}
            filterDate={isWeekday}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            excludeDates={excludeDates}
            id={id}
            popperPlacement="bottom-end"
            className={isFocused && 'focused'}
            disabled={props.disabled}
            customInput={
              <DatePickerReadOnlyInput
                {...field}
                {...props}
                isTouched={isTouched}
                errorsInField={errorsInField}
                data-bdd-selector={`input_${field.name}`}
                colorTheme={colorTheme}
              />
            }
          />
        </DatePickerWrapper>
        {icon && (
          <ReactSVG
            className="a-icon"
            src={`/static/icons/${icon as Icons}.svg`}
          />
        )}
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {errorsInField}
      </Message>
      {helpText && (
        <HelpText data-bdd-selector={`input_${field.name}_helpText`}>
          {helpText}
        </HelpText>
      )}
    </>
  )
}

export default DatePickerInput
