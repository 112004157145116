import styled from 'styled-components'

import { Row, Column, RichText } from '../../../common/atoms'

export const StyledRow = styled(Row)`
  margin-bottom: -6rem;
  justify-content: center;
`

export const StyledColumn = styled(Column)`
  display: flex;
  margin-bottom: 6rem;
`

export const StyledRichText = styled(RichText)`
  p,
  h2 {
    margin-bottom: 6rem;
  }

  h2 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};

    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-bottom: 7.2rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
      margin-bottom: 8.6rem;
    }
  }
`

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.grey1};
`

export const Section = styled.section`
    h2,
    h2 b,
    p {
      color: ${(props) => props.theme.colors.background.black};
    }

    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }
  }
`

export const CallToActionWrapper = styled.div`
  margin-top: 3rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 5rem;
  }
`

export const IconCardCallToActionWrapper = styled.div`
  margin-top: 1rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 2rem;
  }
`
