import { BaseAction } from '../../../../types/actions'
import CampaignName from '../../../../types/campaignName'
import { ColorThemeNames } from '../../../../types/colorThemes'
import { BaseItem } from '../../../../types/response'
import { CallToActionData } from '../../molecules/CallToAction/types'
import { FormElement } from '../../molecules/Form/types'
import { CallbackResultData } from '../CallbackFormResult/types'

export interface Props {
  colorTheme?: string
  data: NewsLetterSignUpBannerData
  backgroundColor?: string
  initialState?: State
  campaignNameFromPage: CampaignName
}

export interface NewsLetterSignUpBannerData extends BaseItem {
  sys?: {
    id: string
  }
  formType?: 'Newsletter' | 'Gated Content' | 'Book a Call'
  headline:
    | string
    | {
        json: object
      }
  description: string
  formFields: FormElement[]
  formFieldsCollection: { items: FormElement[] }
  results: CallbackResultData[]
  resultsCollection?: { items: CallbackResultData[] }
  callToActions: CallToActionData[]
  callToActionsCollection?: { items: CallToActionData[] }
  captchaAction: string
  captchaLegalText: string
  campaignName?: CampaignName
}

export interface FormInputValues {
  Email: string
  FirstName: string
  HasOptedOutOfEmail: boolean
  LastName: string
  Phone: string
}

export interface State {
  loading: boolean
  error:
    | {
        response: {
          status: number
        }
      }
    | false
  success: boolean
  formInput: { [key: string]: any }
}

export const initialState: State = {
  loading: false,
  error: false,
  success: false,
  formInput: undefined,
}

export interface NewsLetterSignUpAction extends BaseAction {
  payload: State
}

export interface StyledTitleColumn {
  colorTheme: ColorThemeNames
}
