import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/modal.min.css'
import { Modal, ModalContent } from 'semantic-ui-react'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

export const StyledModal = styled(Modal)`
  touch-action: none;
  && {
    background-color: ${() => colorTheme('blue', 'backgroundColor')};
    border-radius: 12px;
    padding: 0.1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    overflow: hidden;
  }
`

export const StyledModalContent = styled(ModalContent)`
  &&& {
    background-color: ${() => colorTheme('blue', 'backgroundColor')};
    border-radius: 2.5rem;
    overflow: auto;
  }
`
