import styled from 'styled-components'

export const StyledAnchor = styled.span`
  font-size: inherit;
  display: inline-block;
`

export const StyledDiv = styled.div`
  margin-right: 1rem;
  display: inline-flex;
`
