import shuffle from 'lodash.shuffle'
import React, { createRef, RefObject, useEffect, useState } from 'react'
import Slider from 'react-slick'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { Column, Row } from '../../atoms'
import { AdviserCarousel } from '../../molecules'

import {
  LocatorCarouselWrapper,
  StyledContainer,
  StyledHeadline,
  StyledPostcodeSearch,
  StyledSubHeadline,
} from './styles'
import { Props } from './types'

export const AdviserLocator: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { headline, subHeadline, cards, quotes, hasErrors, onSubmit } = props
  const [shuffledQuotes, setShuffledQuotes] = useState([])
  const [mappedAdviserCards, setMappedAdviserCards] = useState(cards)

  const carouselRef: RefObject<Slider> = createRef()

  useEffect(() => {
    setShuffledQuotes(shuffle(quotes))

    carouselRef.current.slickGoTo(0)
  }, [cards])

  useEffect(() => {
    if (!shuffledQuotes.length) {
      return
    }

    const newCards = cards.map((item, index) => {
      const card = {
        ...item,
        quote: shuffledQuotes[index] ? shuffledQuotes[index].quote : '',
        adviserName: item.name,
        region: item.region,
      }

      return card
    })

    setMappedAdviserCards(newCards)
  }, [shuffledQuotes])

  const handleSubmit = (value: string) => {
    onSubmit(value)
  }

  return (
    <StyledContainer paddingSize="double" data-bdd-selector="adviser-locator">
      <Row>
        <Column desktop={12} tablet={8}>
          {headline && (
            <StyledHeadline
              html={headline}
              colorTheme={colorTheme}
              data-bdd-selector="adviser-locator-headline"
            />
          )}
          {subHeadline && (
            <StyledSubHeadline data-bdd-selector="adviser-locator-sub-headline">
              {subHeadline}
            </StyledSubHeadline>
          )}
          <StyledPostcodeSearch
            onSubmit={handleSubmit}
            hasErrors={hasErrors}
            data-bdd-selector="adviser-locator-search-input"
          />
          {cards && (
            <LocatorCarouselWrapper data-bdd-selector="adviser-locator-carousel">
              <AdviserCarousel cards={mappedAdviserCards} ref={carouselRef} />
            </LocatorCarouselWrapper>
          )}
        </Column>
      </Row>
    </StyledContainer>
  )
}

export default AdviserLocator
