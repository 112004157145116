import styled from 'styled-components'
import HeadingStyles from '../../atoms/Title/styles'

const {
  styles: { H3Styles },
} = HeadingStyles

export const Wrapper = styled.div`
  margin-bottom: auto;

  background-color: ${(props) => props.theme.colors.background.black};
`

export const ContentWrapper = styled.div`
  text-align: center;
  padding: 0 0 3rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 0 4rem;
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 4rem 5rem;
  }

  h2 {
    ${H3Styles};
  }
`

export const AccordionWrapper = styled.div`
  margin: 0 auto 4rem;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`
