import * as React from 'react'

import Headings from './styles'
import { Props } from './types'

/**
 * A function that creates heading element inline with global styles.
 *
 * @param element - Required. The element to be rendered in the DOM, e.g. `h1`
 * @param level - Required. The visual styling to apply to the element.
 * @param weight The optional value to request the title in a specific
 * font-weight.
 * @param children - Required. The text content to be rendered as the heading.
 *
 * @returns StyledComponent | Null - The heading wrapped with requested element
 * and requested visual style or `null`, if no content provided.
 */
const Title: React.FunctionComponent<Props> = (props) => {
  const { element, level, weight, children, ...rest } = props

  const variants = Object.values(Headings.styledComponents)
  const VariantToRender = variants[level - 1] as any

  const elementValue = `h${element}` as keyof JSX.IntrinsicElements

  return children ? (
    <VariantToRender weight={weight} as={elementValue} {...rest}>
      {children}
    </VariantToRender>
  ) : null
}

export default Title
