import styled from 'styled-components'
import { colorTheme } from '../../../../styles/utils'
import { Container } from '../../atoms'
import { StyledContainer } from './types'
import { ContentBlock } from '..'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 25rem;
  overflow: hidden;
`

export const ContentContainer = styled(Container)<StyledContainer>`
  background: ${(props) =>
    colorTheme(props.colorTheme, 'callOutBanner.backgroundThemeColor')};
  border-radius: 1.6rem;
  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 4.5rem;
  }
`

export const StyledContentBlock = styled(ContentBlock)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    h2 {
      font-size: 3.5rem;
      line-height: 3.9rem;
    }
  }

  p a[href^='tel'] {
    display: block;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h3};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 3.5rem;
    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h2};
      line-height: 3.9rem;
    }
    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
    }
  }

  p a[href^='tel']:before {
    content: '';
    display: none;
  }

  a,
  a:active,
  a:focus:not(:focus-visible) {
    text-decoration: none;
  }
  [class*='__Description']:last-of-type {
    margin-bottom: 0;
  }
  p {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
  p i {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-style: normal;
    line-height: 2.3rem;
  }
`
