import { linearGradient } from 'polished'
import styled, { css } from 'styled-components'

import { Container, Row } from '../../../../atoms'

export const FooterContainer = styled(Container)`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    padding: 0;
  }
`

export const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  font-size: ${(props) => props.theme.fontSizes.mobile.footer.primary};
  position: relative;
  padding: 2rem 1.6rem 0;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.footer.primary};
    padding: 2rem 3rem 0.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.footer.primary};
    padding: 2rem 3rem;
  }

  &::before {
    content: '';
    height: 1rem;
    ${(props) =>
      linearGradient({
        colorStops: [
          `${props.theme.colors.background.black} 0%`,
          `${props.theme.colors.background.black} 100%`,
        ],
        toDirection: 'to bottom',
        fallback: props.theme.colors.background.black,
      })};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.5;
  }
`

export const Copyright = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

export const SecondaryLinksRow = styled(Row)`
  margin-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.1rem;
  }
`

export const SecondaryLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const LinkStyles = css`
  a {
    transition: color ${(props) => props.theme.transitions.moderate} ease;

    &:hover,
    &:active,
    &:focus {
      color: ${(props) => props.theme.colors.text.blue};
    }
  }
`

export const SecondaryLinksItem = styled.li`
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    margin-bottom: 1.2rem;
  }

  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      height: 0.2rem;
      width: 0.2rem;
      background-color: ${(props) => props.theme.colors.ui.beige};
      border-radius: 100%;
      margin: 0 1rem;
    }
  }

  ${LinkStyles}
`
