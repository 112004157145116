import { FunctionComponent } from 'react'

import { Row, Column } from '../../atoms'
import { ContentBlock } from '../'

import {
  Wrapper,
  Background,
  LeftBackgroundBox,
  RightBackgroundBox,
  ContentContainer,
  BackgroundImage,
} from './styles'
import { Props } from './types'

const CalloutBanner: FunctionComponent<Props> = (props) => {
  const {
    data: {
      contentBlock,
      image,
      componentTheme: { colour },
      mediaAlignment,
    },
    componentTheme,
    isMirrored,
  } = props

  return (
    <Wrapper data-bdd-selector="callout-banner">
      <ContentContainer colorTheme={componentTheme || colour}>
        <Row>
          <Column desktop={mediaAlignment === 'right' ? [5, 0] : [5, 7]}>
            {contentBlock && (
              <ContentBlock
                data={contentBlock}
                colorTheme={componentTheme || colour}
                isNested={true}
              />
            )}
          </Column>
        </Row>
      </ContentContainer>
      <Background
        flexDirection={mediaAlignment === 'right' ? 'row' : 'row-reverse'}
      >
        <LeftBackgroundBox
          colorTheme={componentTheme || colour}
          style={isMirrored ? { order: 2 } : {}}
        ></LeftBackgroundBox>
        <RightBackgroundBox
          colorTheme={componentTheme || colour}
          style={isMirrored ? { order: 1 } : {}}
        >
          {image && (
            <BackgroundImage
              size="medium"
              data={[image]}
              keepRatio={true}
              data-bdd-selector="callout-banner-image"
            />
          )}
        </RightBackgroundBox>
      </Background>
    </Wrapper>
  )
}

export default CalloutBanner
