import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import ParagraphStyles from '../../atoms/Paragraph/styles'
import HeadingStyles from '../../atoms/Title/styles'

import { RichTextContainerProps, StyledContactLinks } from './types'

const {
  styles: { H1Styles, H2Styles, H3Styles, H4Styles, H5Styles, H6Styles },
} = HeadingStyles
const {
  styles: { mediumStyles },
} = ParagraphStyles

export const contactLinks = css<StyledContactLinks>`
  a[href^='tel'] {
    margin-left: 3rem;
  }

  a:focus,
  a:active,
  a:hover {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'richText.textAccentThemeColor')};
  }

  a[href^='tel']:before {
    content: '';
    position: absolute;
    height: 2.4rem;
    width: 2.4rem;
    margin-left: -3rem;
    align-items: center;
    justify-content: center;
    background-image: url('/static/icons/phone-beige.svg');
    background-position: center;
  }

  a[href^='mailto'] {
    margin-left: 3rem;
  }

  a[href^='mailto']:before {
    content: '';
    position: absolute;
    height: 2.4rem;
    width: 2.4rem;
    margin-left: -3rem;
    align-items: center;
    justify-content: center;
    background-image: url('/static/icons/email.svg');
    background-position: center;
  }
`

export const RichTextContainer = styled.div<RichTextContainerProps>`
  & + & > {
    h5:first-of-type {
      margin-top: 6rem;
    }
  }

  h1 {
    ${H1Styles}
  }

  h2 {
    ${H2Styles}
  }

  h3 {
    ${H3Styles}
  }

  h4 {
    ${H4Styles}
  }

  h5 {
    ${H5Styles}
  }

  h6 {
    ${H6Styles}
  }

  p {
    ${mediumStyles}

    ${contactLinks}

    & + ul {
      margin: 1.3rem 0 3.5rem;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:focus,
    :active,
    :visited {
      text-decoration: underline;
    }
  }

  ul {
    margin: 3.5rem 0;
    padding-left: 1.6rem;
    text-align: left;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-left: 2.4rem;
    }

    ul {
      margin: 1rem 0 1.9rem;
    }

    & + h4,
    & + h5,
    & + h6 {
      margin-top: 5rem;
    }

    li {
      position: relative;
      padding: 0.3rem 0;
      white-space: pre-wrap;

      > *:first-child::before {
        position: absolute;
      }

      ${(props) =>
        props.useTicksForBullets
          ? css`
              > *:first-child::before {
                content: '✓';
                left: -1.5rem;
              }

              padding-left: 1.3rem;

              @media ${(props) => props.theme.breakpoints.tablet} {
                > *:first-child::before {
                  left: -2.4rem;
                }

                padding-left: 0.5rem;
              }
            `
          : css`
              > *:first-child::before {
                content: '';
                height: 0.6rem;
                width: 0.6rem;
                top: 1.2rem;
                left: -1.4rem;
                border-radius: 100%;
                background-color: ${(props) =>
                  props.theme.colors.background.grey2};

                @media ${(props) => props.theme.breakpoints.tablet} {
                  height: 0.8rem;
                  width: 0.8rem;
                  left: -2.3rem;
                }
              }
            `}

      h4:first-child::before {
        top: 1.7rem;

        @media ${(props) => props.theme.breakpoints.tablet} {
          top: 1.9rem;
        }

        @media ${(props) => props.theme.breakpoints.desktop} {
          top: 2.2rem;
        }
      }

      & + li {
        margin-top: 2rem;
      }
    }
  }
`
