import React from 'react'

import { ArticleCard } from '../../atoms'

import { ArticleCardItem, Wrapper } from './styles'
import { Props } from './types'

const ArticleCards: React.FunctionComponent<Props> = (props) => {
  const { cards } = props

  return (
    <Wrapper>
      {cards.map((card, index) => (
        <ArticleCardItem key={index}>
          <ArticleCard {...card} index={index} />
        </ArticleCardItem>
      ))}
    </Wrapper>
  )
}

export default ArticleCards
