import React, { FunctionComponent, useEffect, useState } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { HiddenText } from '../../atoms'

import { InputWrapper, StyledCleave, StyledMessage, Wrapper } from './styles'
import { Props } from './types'

const PriceInput: FunctionComponent<Props> = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { value, onChange, errorMessage, className, placeholder, label } = props

  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const [isTouched, setIsTouched] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value.replace(/,/g, ''))
    setIsTouched(true)
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      onChange('0')
    }
  }

  return (
    <Wrapper
      className={className}
      data-bdd-selector="price-input-container"
      isTouched={isTouched}
      errorsInField={!!errorMessage}
      colorTheme={colorTheme}
    >
      <label>
        <HiddenText all={true}>{label}</HiddenText>
        <InputWrapper>
          <StyledCleave
            data-bdd-selector="price-input"
            placeholder={placeholder}
            options={{ numeral: true, numeralDecimalScale: 0 }}
            onBlur={handleOnBlur}
            onChange={handleChange}
            value={internalValue}
          />
        </InputWrapper>
      </label>

      <StyledMessage
        data-bdd-selector="price-input-error-message"
        hidden={!errorMessage}
      >
        {errorMessage}
      </StyledMessage>
    </Wrapper>
  )
}

export default PriceInput
