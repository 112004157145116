import React from 'react'
import { Column, Image } from '../'
import { ResultLinks } from './types'
import { ModalIconLink, ModalLinkFooterText, ModalLinkIcon } from './styles'

const IconLinkHolder = (props: ResultLinks) => {
  const { linkTitle, linkIcon, linkUrl, target } = props
  return (
    <Column mobile={[2, 0]} tablet={[4, 0]} desktop={[6, 0]}>
      <ModalIconLink
        title={linkTitle}
        href={linkUrl}
        {...(target === 'New Window' && { target: '_blank' })}
      >
        <ModalLinkIcon>{linkIcon && <Image data={[linkIcon]} />}</ModalLinkIcon>
        <ModalLinkFooterText>{linkTitle}</ModalLinkFooterText>
      </ModalIconLink>
    </Column>
  )
}

export default IconLinkHolder
