import { FormElement } from './types'

const BASE_FIRST_NAME: FormElement = {
  sys: {
    id: 'FirstName',
  },
  name: 'FirstName',
  label: 'First Name',
  type: 'text',
  validationErrorsCollection: {
    items: [
      {
        errorCode: 'required',
        errorDescription: 'Please enter your first name',
      },
    ],
  },
  required: true,
}
const BASE_LAST_NAME: FormElement = {
  sys: {
    id: 'LastName',
  },
  name: 'LastName',
  label: 'Last Name',
  type: 'text',
  validationErrorsCollection: {
    items: [
      {
        errorCode: 'required',
        errorDescription: 'Please enter your last name',
      },
      {
        errorCode: '<40',
        errorDescription: 'Last name must have less than 40 characters',
      },
    ],
  },
  required: true,
}
const BASE_EMAIL: FormElement = {
  sys: {
    id: 'Email',
  },
  name: 'Email',
  label: 'Email',
  type: 'email',
  validationErrorsCollection: {
    items: [
      {
        errorCode: 'required',
        errorDescription: 'Please enter an email address',
      },
      {
        errorCode: '<80',
        errorDescription: 'Email must have less than 80 characters.',
      },
      {
        errorCode: 'format',
        errorDescription: 'Please enter a valid email address',
      },
    ],
  },
  required: true,
}

export const NEWSLETTER_FIELDS: FormElement[] = [
  BASE_FIRST_NAME,
  BASE_LAST_NAME,
  BASE_EMAIL,
]

export const GATED_FIELDS: FormElement[] = [
  BASE_FIRST_NAME,
  BASE_LAST_NAME,
  BASE_EMAIL,
  {
    sys: {
      id: 'Marketing_Events_Opt_In__c',
    },
    name: 'Marketing_Events_Opt_In__c',
    label: 'I give my consent to recieve marketing materials',
    type: 'checkbox',
    required: true,
    columnSize: 8,
    validationErrorsCollection: {
      items: [
        {
          errorCode: 'required',
          errorDescription: 'Please consent to marketing',
        },
      ],
    },
  },
]

export const BOOK_A_CALL_FIELDS: FormElement[] = [
  {
    sys: {
      id: 'SPW_Hub__c',
    },
    name: 'SPW_Hub__c',
    label: 'Your Region',
    type: 'select',
    validationErrorsCollection: {
      items: [
        {
          errorCode: 'required',
          errorDescription: 'Your title',
        },
      ],
    },
    columnSize: 8,
    required: true,
    data: {
      options: [
        {
          key: 'Bristol Hub',
          value: 'Bristol & Severn Side',
        },
        {
          key: 'Cambridge Hub',
          value: 'Cambridge & East Anglia',
        },
        {
          key: 'London Hub',
          value: 'London',
        },
        {
          key: 'Birmingham Hub',
          value: 'Midlands',
        },
        {
          key: 'Manchester Hub',
          value: 'North West & North Wales',
        },
        {
          key: 'Oxford Hub',
          value: 'Oxford',
        },
        {
          key: 'Edinburgh Hub',
          value: 'Scotland & Northern Ireland',
        },
        {
          key: 'Guildford Hub',
          value: 'South East',
        },
        {
          key: 'Cardiff Hub',
          value: 'South Wales & Borders',
        },
        {
          key: 'Exeter Hub',
          value: 'South West',
        },
        {
          key: 'Leeds Hub',
          value: 'Yorkshire &  North East',
        },
      ],
    },
  },
  { ...BASE_FIRST_NAME, columnSize: 4 },
  {
    ...BASE_LAST_NAME,
  },
  {
    sys: {
      id: 'PostalCode',
    },
    name: 'PostalCode',
    label: 'Postcode',
    type: 'text',
    validationErrorsCollection: {
      items: [
        {
          errorCode: 'required',
          errorDescription: 'Please enter a postcode',
        },
        {
          errorCode: '>5',
          errorDescription: 'Minimum postcode length is 5 characters',
        },
        {
          errorCode: '<8',
          errorDescription: 'Maximum postcode length is 8 characters',
        },
        {
          errorCode:
            'regex[^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$]',
          errorDescription: 'Please enter a valid postcode',
        },
      ],
    },
    required: true,
    helpText: 'To find your nearest adviser',
  },
  {
    sys: {
      id: 'Phone',
    },
    name: 'Phone',
    label: 'Contact number',
    type: 'phoneNumber',
    validationErrorsCollection: {
      items: [
        {
          errorCode: 'required',
          errorDescription: 'Please enter a phone number',
        },
        {
          errorCode: '>8',
          errorDescription: 'Phone number must have at least 8 characters',
        },
      ],
    },
    required: true,
    helpText: 'A UK number to call you on',
  },
  {
    ...BASE_EMAIL,
    helpText: "We'll email you a confirmation of your appointment",
    columnSize: 8,
  },
  {
    sys: {
      id: 'SPW_Marketing_Channel_Combined__c',
    },
    name: 'SPW_Marketing_Channel_Combined__c',
    label: "I'm happy for Schroders Personal Wealth to keep in touch",
    type: 'checkbox',
    validationErrorsCollection: {
      items: [],
    },
    columnSize: 8,
  },
]
