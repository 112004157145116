import { rgba } from 'polished'
import { ReactSVG } from 'react-svg'
import styled, { css } from 'styled-components'

import Image from '../../atoms/Image'

import { AdviserCarouselItemContainerProps } from './types'

export const ImageWrapper = styled.div`
  height: 100%;
`

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Info = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1.6rem;
  border-radius: 0 0 10px 10px;

  transition: padding-bottom 0.1s ease-in-out;

  background-image: linear-gradient(
    to bottom,
    transparent,
    ${(props) => props.theme.colors.ui.black}
  );
  color: ${(props) => props.theme.colors.text.white};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 2.4rem;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h5`
  line-height: ${(props) => props.theme.lineHeights.close};
  font-size: ${(props) => props.theme.fontSizes.tablet.headings.h5};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

export const CloseButton = styled.div`
  display: none;
`

export const CloseIcon = styled(ReactSVG)`
  line-height: 0;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.colors.ui.white};
    width: 2rem;
    height: 2rem;
  }
`

export const LocationContainer = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
  }
`

export const Location = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-left: 0.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-left: 0.7rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 0.8rem;
  }
`

export const LocationIcon = styled(ReactSVG)`
  fill: currentColor;
  line-height: ${(props) => props.theme.lineHeights.base};

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  svg[data-src$='remote.svg'] {
    width: 1.6rem;
    height: 1.6rem;
  }
`

export const Quote = styled.p`
  line-height: ${(props) => props.theme.lineHeights.normal};
  font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
  display: none;
  margin-top: 1.5rem;
`

export const Container = styled.div<AdviserCarouselItemContainerProps>`
  position: relative;
  width: 26.5rem;
  height: 34rem;
  cursor: pointer;
  border-radius: 20px;
  border-radius: 2rem;
  box-shadow: 0 1.6rem 2.5rem 0 ${(props) => props.theme.colors.ui.lightBlack};
  overflow: hidden;
  margin: 0 auto 3rem;
  text-align: left;

  ${ImageWrapper}:active, ${ImageWrapper}:hover, ${ImageWrapper}:focus {
    & + ${Info} {
      padding-bottom: 4.5rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
  }

  ${(props) =>
    props.opened &&
    css`
      cursor: default;

      ${Info} {
        top: 0;
        background-image: unset;
        background-color: ${rgba(props.theme.colors.background.black, 0.8)};
        border-radius: 10px;
      }

      ${Quote} {
        display: block;
      }

      ${CloseButton} {
        display: block;
      }
    `}
`
