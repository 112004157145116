import { rgba } from 'polished'
import styled from 'styled-components'

import { Container, Row } from '../../atoms'
import {
  SecondaryButton,
  SecondaryButtonDisabled,
} from '../../atoms/Button/styles'

export const ControlsContainer = styled(Container)`
  position: sticky;
  bottom: 0;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const Controls = styled.div`
  background-color: ${(props) => props.theme.colors.background.black};
  padding: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 2.4rem 3.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 1.6rem 0;
  }
`

export const ControlsRow = styled(Row)`
  display: flex;
  align-items: center;

  ${SecondaryButton} {
    background-color: ${(props) => rgba(props.theme.colors.ui.white, 0.8)};
    border-color: ${(props) => props.theme.colors.ui.white};
    color: ${(props) => props.theme.colors.text.black};
  }

  ${SecondaryButtonDisabled} {
    background-color: transparent;
  }

  ${SecondaryButton},
  ${SecondaryButtonDisabled} {
    margin-left: 2.4rem;
  }
`
