import styled, { css } from 'styled-components'
import { colorTheme } from '../../../../styles/utils'

import { Image, Container } from '../../atoms'

import { StyledContainer, StyledBackground } from './types'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 40rem;
  overflow: hidden;
`

export const ContentContainer = styled(Container)<StyledContainer>`
  @media ${(props) => props.theme.breakpoints.tabletMax} {
    background: ${(props) =>
      colorTheme(props.colorTheme, 'callOutBanner.backgroundThemeColor')};
  }
`

export const Background = styled.div<StyledBackground>`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: ${({ flexDirection }) => flexDirection};

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    position: relative;
  }
`

export const BackgroundBoxStyle = css`
  position: relative;
  flex: 0 0 auto;
  width: 50%;

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    width: 100%;
  }
`

export const LeftBackgroundBox = styled.div<StyledContainer>`
  ${BackgroundBoxStyle};
  background: ${(props) =>
    colorTheme(props.colorTheme, 'callOutBanner.backgroundThemeColor')};

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    display: auto;
  }
`

export const RightBackgroundBox = styled.div`
  ${BackgroundBoxStyle};
`

export const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
  }

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    width: auto;
    height: auto;

    img {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
`
