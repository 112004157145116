import React, { useState } from 'react'
import { format, parseISO } from 'date-fns'
import { Column, Container, RichText, Row } from '../../atoms/'
import {
  CallbackBlock,
  CallToAction,
  Form,
  OrderedStepsList,
} from '../../molecules'
import { Headline, Paragraph, SubHeadline } from '../CallbackForm/styles'
import { ContactFormProps } from './types'
import api from '../../../../services/api'
import { FormInputWithFirstName } from '../../../containers/Referral/types'
import {
  AppointmentDetailsDate,
  AppointmentDetailsTime,
  AppointmentWrapper,
  BorderWrapper,
  ColumnHeading,
  Message,
} from '../CallbackFormResult/styles'
import { CallToActionData } from '../../molecules/CallToAction/types'
import { CallToActionContainer } from '../../../containers/Pricing/styles'
import { StyledModalContent } from './styles'
import { CSSProperties } from 'styled-components'

export const formatDateTime = (date, formatType) => {
  if (date) {
    const value = parseISO(date)
    return format(value, formatType)
  }
  return ''
}

const ContactForm: React.FunctionComponent<ContactFormProps> = (props) => {
  const [formInput, setFormInput] = useState({})
  const [loading, setLoading] = useState(false)
  const [showCaptchaV2] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successGreetName, setSuccessGreetName] = useState('')
  const [error, setError] = useState(null)
  const { data } = props

  const showResultColumn = (): boolean => !!error || !!success

  if (!data) {
    return null
  }

  const textStyle: CSSProperties = {
    textAlign: 'center',
    color: 'white',
  }

  const action: CallToActionData = {
    type: 'primary',
    buttonLabel: 'Try again',
    analyticsCategory: 'Callback Form - Result - Failure',
    analyticsAction: 'Try Again',
    clickHandler: (e) => {
      e.preventDefault()
      setError(null)
      setSuccess(false)
    },
  }

  const formTime =
    formInput['Appointment_Date__c'] && formInput['Appointment_Time__c']
      ? `${formInput['Appointment_Date__c']} ${formInput['Appointment_Time__c']}`
      : null

  const {
    headline,
    subhead,
    description,
    description2,
    html,
    formFields,
    callToActions,
    captchaAction,
    stepsToComplete,
    salesforceCampaignName,
  } = data

  const saveCallback = async (formValues: any) => {
    const { FirstName } = formValues as FormInputWithFirstName
    const body: any = {
      ...formValues,
      SPW_Campaign_Name__c: salesforceCampaignName,
    }
    setFormInput(body)

    try {
      await api.postCallbackForm(formValues)
      setError(null)
      setLoading(false)
      setSuccess(true)
      setSuccessGreetName(FirstName)
    } catch (e) {
      setError(e)
      setLoading(false)
      setSuccess(false)
      setSuccessGreetName('')
    }
  }

  return (
    <StyledModalContent>
      <Container data-bdd-selector="contact-form-container">
        <Row>
          {showResultColumn() ? (
            <Column>
              {success && (
                <>
                  <h3 style={textStyle}>That’s great!</h3>
                  <p style={textStyle}>
                    Thanks, {successGreetName}. We’ll be in touch shortly.
                  </p>
                  <BorderWrapper>
                    <AppointmentWrapper>
                      <AppointmentDetailsDate>
                        <ColumnHeading>Date</ColumnHeading>
                        <div data-bdd-selector="callback-form-result-date">
                          {formatDateTime(
                            formInput['Appointment_Date__c'],
                            'dd MMM'
                          )}
                        </div>
                      </AppointmentDetailsDate>
                      <AppointmentDetailsTime>
                        <ColumnHeading>Time</ColumnHeading>
                        <div data-bdd-selector="callback-form-result-time">
                          {formatDateTime(formTime, "hh:mm aaaaa'm'")}
                        </div>
                      </AppointmentDetailsTime>
                    </AppointmentWrapper>
                  </BorderWrapper>
                </>
              )}
              {error && (
                <>
                  <h3 style={textStyle}>Oops!</h3>
                  <Message
                    dangerouslySetInnerHTML={{
                      __html:
                        'Something went wrong. Your details haven’t been processed, try again.',
                    }}
                    style={{ color: 'white' }}
                  />
                  <CallToActionContainer center={true} paddingSize="none">
                    <CallToAction
                      {...action}
                      data-bdd-selector="contact-form-result-button"
                    />
                  </CallToActionContainer>
                </>
              )}
            </Column>
          ) : (
            <>
              <Column tablet={[6, 1]} desktop={[5, 1]}>
                {headline && (
                  <Headline
                    data-bdd-selector="callback-form-headline"
                    html={headline}
                  />
                )}

                {stepsToComplete && (
                  <OrderedStepsList
                    list={stepsToComplete}
                    data-bdd-selector="callback-form-orderedlist"
                  ></OrderedStepsList>
                )}

                {subhead && (
                  <SubHeadline
                    data-bdd-selector="callback-form-subhead"
                    html={subhead}
                  />
                )}

                {description && (
                  <Paragraph
                    data-bdd-selector="callback-form-description"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  ></Paragraph>
                )}

                {html && <RichText html={html} />}

                <Form
                  formFields={formFields}
                  captchaAction={captchaAction}
                  captchaLegalText={null}
                  formInput={formInput}
                  callToActions={callToActions}
                  saveCallback={saveCallback}
                  showCaptchaV2={showCaptchaV2}
                  loading={loading}
                  gtmButtonID="gtm-contact-modal-submit-button"
                />
              </Column>
              {description2 && (
                <Column tablet={8} desktop={[5, 1]}>
                  <CallbackBlock
                    data={description2}
                    data-bdd-selector="contact-form-description2"
                  />
                </Column>
              )}
            </>
          )}
        </Row>
      </Container>
    </StyledModalContent>
  )
}

export default ContactForm
