import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/modal.min.css'
import { Modal, ModalContent } from 'semantic-ui-react'
import styled from 'styled-components'

import CallbackForm from '../CallbackForm'

import { StyledWrapper } from './types'

export const StyledModal = styled(Modal)`
  touch-action: none;

  #checkbox-label {
    color: white;
  }

  &&&&&& {
    background-color: ${(props) => props.theme.colors.background.black};
    border-radius: 1.6rem;
    border: solid 0.2rem transparent;
    background-clip: padding-box;
    width: 98%;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow: visible;

    p {
      color: ${(props) => props.theme.colors.text.white};
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -0.2rem;
      border-radius: inherit;
      background: ${(props) => props.theme.colors.background.rocketboyGradient};
    }

    & > :first-child:not(.icon) {
      border-radius: inherit;
    }

    @media ${(props) => props.theme.breakpoints.tablet} {
      max-width: 76.8rem;
      width: 90%;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      max-width: 117rem;
    }
  }
`

export const StyledModalHeader = styled.div<StyledWrapper>`
  top: 2rem;
  right: 2rem;
  position: absolute;
  z-index: 1;

  .ui.modal > .icon:first-child**,
  .ui.modal > :first-child:not(.icon) {
    border-radius: 2.5rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: 3rem;
    right: 3rem;
  }
`

export const StyledCallbackform = styled(CallbackForm)`
  margin-top: 7rem;
  padding-top: 0;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 0;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 2rem;
  }
`

export const StyledModalContent = styled(ModalContent)`
  &&&&&&&& {
    background-color: inherit;
    overflow: auto;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 0;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    form > div > div:nth-child(1) {
      flex-basis: 100%;
      max-width: 100%;
    }

    form > div > div:nth-child(2) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`
