import { useState } from 'react'
import { GetThemeContext, ThemeContext } from '../../../../../context'
import { generateUUID } from '../../../../../utils/helpers'
import { StyledNavLink } from '../styles'
import { NavItemData } from '../types'

const NavDropdownItem: React.FunctionComponent<NavItemData> = (props) => {
  const { pageUrl, pageTitle, invert, useColorTheme } = props

  const [key] = useState(generateUUID())

  return (
    <li key={key}>
      <StyledNavLink
        colorTheme={GetThemeContext(ThemeContext)}
        invert={invert}
        useColorTheme={useColorTheme}
        href={pageUrl}
        data-bdd-selector="nav-link-anchor"
        className="dropdown-link"
      >
        {pageTitle}
      </StyledNavLink>
    </li>
  )
}

export default NavDropdownItem
