import styled, { css } from 'styled-components'

import { paddingSize } from '../../../../styles/utils'
import theme from '../../../../styles/theme'

const { colors } = theme

export const PageStyles = css`
  .custom_class {
    background-color: ${colors.background.black};
  }

  #__next {
    height: 100%;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
`

export const Main = styled.main`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  color: ${(props) => props.theme.colors.text.white};

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    min-height: 100%;
  }
`

export const FooterWrapper = styled.div`
  padding-top: ${paddingSize(5, 'default')};
`
