import { GetThemeContext, ThemeContext } from '../../../../context'

import { LogoSVG, Wrapper } from './styles'
import { Props } from './types'

const Logo: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { invert, useColorTheme, imageData, hasCustomHeaderBG } = props

  return (
    <Wrapper
      invert={invert}
      useColorTheme={useColorTheme}
      colorTheme={colorTheme}
      hasCustomHeaderBG={hasCustomHeaderBG}
    >
      <LogoSVG src={imageData} wrapper="span" />
    </Wrapper>
  )
}

export default Logo
