import { Fragment, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { Icons } from '../../../Icon/types'
import { Input, InputContainer, Message, HelpText } from '../../styles'

import { TextInputLabel } from './styles'

const TextInput = ({
  field,
  form: { touched, errors, dirty, setFieldTouched },
  colorTheme,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleOnBlur = (e) => {
    setFieldTouched(field.name, true, false)
    field.onBlur(e)
    setIsFocused(false)
  }

  const handleOnChange = (e) => {
    if (field.name !== 'Email') {
      setFieldTouched(field.name, true, false)
    }

    field.onChange(e)
  }

  const { label, inputType, id, helpText } = props

  const isTouched = touched[field.name] !== undefined
  const hasValue = Boolean(field.value)
  const hasErrors = isTouched && errors[field.name] !== undefined
  const fieldErrors = errors[field.name]

  const icon =
    (isTouched && hasErrors && 'ExclamationMark') ||
    (isTouched && !hasErrors && 'Tick')

  return (
    <Fragment>
      <InputContainer>
        <TextInputLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={isTouched}
          errorsInField={hasErrors}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </TextInputLabel>
        <Input
          {...field}
          {...props}
          id={id}
          type={inputType}
          isTouched={isTouched}
          errorsInField={hasErrors}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          data-bdd-selector={`input_${field.name}`}
          className={isFocused && 'focused'}
          colorTheme={colorTheme}
        />
        {icon && (
          <ReactSVG
            className="a-icon"
            src={`/static/icons/${icon as Icons}.svg`}
          />
        )}
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {hasErrors && fieldErrors}
      </Message>
      {helpText && (
        <HelpText data-bdd-selector="callback-form-result-sub-helpText">
          {helpText}
        </HelpText>
      )}
    </Fragment>
  )
}

export default TextInput
