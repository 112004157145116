import { Fragment, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { InputContainer, Message } from '../../styles'

import { Select, SelectLabel } from './styles'

const SelectInput = ({
  field,
  form: { touched, errors, dirty, setFieldValue },
  colorTheme,
  data,
  ...props
}) => {
  const { label, id } = props
  const { options } = data
  const [isFocused, setIsFocused] = useState(false)
  const selectRef = useRef(null)

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleOnBlur = (e) => {
    field.onBlur(e)
    setIsFocused(false)
  }

  useEffect(() => {
    // Keeps value up-to-date for when date is changed from a future date to
    // current date, but a timeslot that is unavailable (has already passed) was
    // already selected.
    const current = selectRef.current

    if (!field.value) {
      current.selectedIndex = 0
    } else if (current && current.value && current.value !== field.value) {
      setFieldValue(field.name, current.value)
    }
  })

  const isTouched = touched[field.name]
  const hasErrors = isTouched ? errors[field.name] : ''
  const hasValue = Boolean(field.value)
  const errorsInField = typeof hasErrors !== 'undefined'

  return (
    <Fragment>
      <InputContainer>
        <SelectLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={true}
          errorsInField={errorsInField}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </SelectLabel>
        <Select
          {...field}
          {...props}
          id={id}
          isTouched={isTouched}
          errorsInField={errorsInField}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          hasValue={hasValue}
          ref={selectRef}
          data-bdd-selector={`input_${field.name}`}
          className={isFocused && 'focused'}
          colorTheme={colorTheme}
        >
          <option
            value=""
            label={label}
            disabled={true}
            key={`${label}_empty_option`}
          >
            {label}
          </option>
          {options.map((option) => {
            const optionValue = option.value || option.key
            const optionElement = (
              <option
                value={option.key}
                label={optionValue}
                key={`${label}_${option.key}`}
              >
                {optionValue}
              </option>
            )
            return optionElement
          })}
        </Select>
        <ReactSVG src={`/static/icons/ChevronDown.svg`} className="a-icon" />
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {hasErrors}
      </Message>
    </Fragment>
  )
}

export default SelectInput
