import { rgba } from 'polished'

import { styled } from '../../../../styles/theme'
import PriceInput from '../../molecules/PriceInput'
import PricingDetailBlock from '../../molecules/PricingDetailBlock'

const borderRadius = '1.6rem'

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.black};
  overflow: hidden;
  box-shadow: 0 0.2rem 2.4rem 0
    ${(props) => rgba(props.theme.colors.background.black, 0.14)};
  color: ${(props) => props.theme.colors.text.white};
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`

export const SliderTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h4};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
  margin-bottom: 2.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
  }
`

export const ControlsWrapper = styled.div`
  position: relative;
  padding: 3.2rem 2rem;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  z-index: 0;
  background: ${(props) => props.theme.colors.background.white};
  color: ${(props) => props.theme.colors.text.black};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 3.4rem 4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 3.2rem 4rem;
  }
`

export const StyledPricingDetailBlock = styled(PricingDetailBlock)`
  background: ${(props) => props.theme.colors.background.grey6};

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.ui.grey8};
  }
`

export const StyledPriceInput = styled(PriceInput)`
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: 36.8rem;
  }
`
