import styled from 'styled-components'

import { paddingSize } from '../../../../styles/utils'

import { Props } from './types'

/**
 * A Styled-Component which acts as the container to constrain (horizontally)
 * content within it. Provides also global default horizontal and vertical
 * padding amounts.
 *
 * @param props
 * @param props.fullWidth - The component is full width with no padding either
 * side, but possibly vertical padding.
 * @param props.paddingSize - The requested padding size if not default. Example
 * would be "double", which provides the default padding size x2.
 *
 * @returns StyledComponent - The column component.
 */
export const Container = styled.div<Props>`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: ${(props) => paddingSize(4, props.paddingSize)};
  padding-bottom: ${(props) => paddingSize(4, props.paddingSize)};
  padding-right: ${(props) => !props.fullWidth && '1.6rem'};
  padding-left: ${(props) => !props.fullWidth && '1.6rem'};
  text-align: ${(props) => props.center && 'center'};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: ${(props) => paddingSize(5, props.paddingSize)};
    padding-bottom: ${(props) => paddingSize(5, props.paddingSize)};
    padding-right: ${(props) => !props.fullWidth && '4rem'};
    padding-left: ${(props) => !props.fullWidth && '4rem'};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: ${(props) =>
      !props.fullWidth && props.theme.dimensions.containerMaxWidth};
    padding-top: ${(props) => paddingSize(6, props.paddingSize)};
    padding-bottom: ${(props) => paddingSize(6, props.paddingSize)};
    padding-right: ${(props) => !props.fullWidth && '1.6rem'};
    padding-left: ${(props) => !props.fullWidth && '1.6rem'};
  }
`
