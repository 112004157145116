import React from 'react'

import { CallToAction } from '../../molecules'

import { Button, ButtonWrapper } from './styles'
import { Props } from './types'

const CallToActionGroup: React.SFC<Props> = (props) => {
  const { callToActions } = props
  if (
    callToActions &&
    Array.isArray(callToActions) &&
    callToActions.length > 0
  ) {
    return (
      <ButtonWrapper data-bdd-selector="cta-group">
        {callToActions.map((callToAction) => (
          <Button key={callToAction._id}>
            <CallToAction {...callToAction} />
          </Button>
        ))}
      </ButtonWrapper>
    )
  }
  return null
}

export default CallToActionGroup
