import { FunctionComponent } from 'react'

import { AccordionBanner, ContentBlock } from '..'
import AdviserQuoteCards from '../../organisms/AdviserQuoteCards'

import {
  Wrapper,
  LeftColumn,
  RightColumn,
  LeftColumnWrapper,
  RightColumnWrapper,
  StyledBackgroundImage,
} from './styles'
import { Props } from './types'

const AdviserSplitBlock: FunctionComponent<Props> = (props) => {
  const {
    data: { leftContentBlock, rightContentBlock, backgroundImage },
    colorTheme,
  } = props

  const molecules = {
    accordianBannerhome: AccordionBanner,
    featureBlockAnimation: ContentBlock,
  }

  const LeftBlock = molecules[leftContentBlock._type]

  return (
    <Wrapper>
      {leftContentBlock && (
        <LeftColumnWrapper>
          <LeftColumn>
            <LeftBlock
              data={leftContentBlock}
              colorTheme={colorTheme}
              isNested={true}
              useTicksForBullets
            />
          </LeftColumn>
        </LeftColumnWrapper>
      )}
      {rightContentBlock && (
        <RightColumnWrapper>
          <RightColumn>
            <AdviserQuoteCards data={rightContentBlock} isNested />
          </RightColumn>
          {backgroundImage && (
            <StyledBackgroundImage
              data={backgroundImage}
              colorTheme={colorTheme}
            />
          )}
        </RightColumnWrapper>
      )}
    </Wrapper>
  )
}

export default AdviserSplitBlock
