import React, { forwardRef, RefObject } from 'react'

import { ProfilingQuestionListItem } from '../../atoms'

import { List } from './styles'
import { Props } from './types'

const ProfilingQuestionList = forwardRef(
  (
    { data, questionId, currentlySelectedAnswerId, isActive, onChange }: Props,
    ref: RefObject<HTMLInputElement>
  ) => {
    const isLongList = data && data.length > 3

    if (data) {
      return (
        <List isLongList={isLongList}>
          {data.map((answerItem, index) => {
            const { _id } = answerItem

            return (
              <ProfilingQuestionListItem
                data={answerItem}
                ref={ref}
                currentlySelectedAnswerId={currentlySelectedAnswerId}
                questionId={questionId}
                isLongListItem={isLongList}
                isActive={isActive}
                onChange={onChange}
                index={index}
                key={_id}
              />
            )
          })}
        </List>
      )
    }

    return null
  }
)

export default ProfilingQuestionList
