import React from 'react'
import { useDispatch } from 'react-redux'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { logEvent } from '../../../../utils'
import { isUrlExternalTarget } from '../../../../utils/helpers'
import Button from '../../atoms/Button'
import { ButtonType } from '../../atoms/Button/types'
import Link from '../../atoms/Link'
import { actions as callbackFormModalActions } from '../../organisms/CallbackFormModal/actions'

import { StyledAnchor, StyledDiv } from './styles'
import { Props } from './types'

const CallToAction: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const dispatch = useDispatch()
  const {
    id,
    type,
    buttonLabel,
    iconType,
    iconBeforeText,
    hideTextOnMobile,
    url,
    target,
    analyticsAction,
    analyticsCategory,
    disabled = false,
    clickHandler = () => undefined,
    'data-bdd-selector': dataBddSelector,
    'aria-label': ariaLabel,
    loading,
    invertColorTheme,
    componentTheme,
  } = props

  let buttonType

  switch (type) {
    case 'secondary':
      buttonType = ButtonType.Secondary
      break
    case 'tertiary':
      buttonType = ButtonType.Tertiary
      break
    case 'link text':
      buttonType = ButtonType.Link
      break
    default:
      buttonType = ButtonType.Primary
      break
  }

  let cta
  const buttonProps = {
    buttonType,
    label: buttonLabel,
    colorTheme,
    componentTheme,
    invertColorTheme,
    disabled,
    iconType,
    iconBeforeText,
    hideTextOnMobile,
    analyticsCategory,
    analyticsAction,
    onClick: clickHandler,
    id,
    'data-bdd-selector': dataBddSelector,
    'aria-label': ariaLabel,
  }

  const externalTarget: boolean = isUrlExternalTarget(url)

  switch (target) {
    case 'same window':
      cta = (
        <Link route={url} rel="noreferrer" externalTarget={externalTarget}>
          <StyledAnchor>
            <Button {...buttonProps} />
          </StyledAnchor>
        </Link>
      )
      break

    case 'new window':
      cta = (
        <Link
          route={url}
          rel="noreferrer"
          target="_blank"
          externalTarget={externalTarget}
        >
          <Button {...buttonProps} />
        </Link>
      )
      break

    case 'showCallback':
      {
        const callbackClickHandler = (e) => {
          e.preventDefault()
          dispatch(callbackFormModalActions.showCallbackFormModal())
          logEvent({
            category: 'User interactions',
            action: 'Forms',
            label: 'Callback Form - open',
            value: 1,
          })
        }
        const adjustedButtonProps = {
          ...buttonProps,
          onClick: callbackClickHandler,
        }
        cta = (
          <Button
            {...adjustedButtonProps}
            type={target}
            isButton={true}
            loading={loading}
          />
        )
      }
      break

    default:
      cta = (
        <StyledDiv>
          <Button
            {...buttonProps}
            componentTheme={componentTheme ? componentTheme : 'red'}
            type={target}
            isButton={true}
            loading={loading}
          />
        </StyledDiv>
      )
  }

  return cta
}

export default CallToAction
