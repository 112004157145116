import styled from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'
import { Label } from '../../styles'

import { StyledTextInputLabel } from './types'

export const TextInputLabel = styled(Label)<StyledTextInputLabel>`
  color: ${(props) =>
    (props.focus &&
      !props.isTouched &&
      colorTheme(props.colorTheme, 'input.labelThemeColor')) ||
    ((props.focus || props.hasValue) &&
      props.errorsInField &&
      props.theme.colors.text.red2) ||
    (props.focus &&
      !props.errorsInField &&
      colorTheme(props.colorTheme, 'input.labelThemeColor')) ||
    props.theme.colors.text.black};
  font-weight: ${(props) =>
    ((props.focus || props.hasValue) && props.theme.fontWeights.bold) ||
    props.theme.fontWeights.normal};
  font-size: ${(props) =>
    props.focus || props.hasValue
      ? props.theme.fontSizes.desktop.input.labelOnFocus
      : props.theme.fontSizes.desktop.input.label};
  transform: ${(props) =>
    props.focus || props.hasValue ? 'translateY(-1rem)' : 'translateY(0)'};
`
