import styled, { css } from 'styled-components'

export const PageStyles = css`
  #__next {
    display: flex;
    flex-flow: column;
  }
`

export const Main = styled.main`
  display: flex;
  flex-flow: column;
  color: ${(props) => props.theme.colors.text.white};
`
