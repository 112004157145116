import styled, { css } from 'styled-components'

import { StyledWrapper } from './types'

const HiddenTextStyles = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const Wrapper = styled.span<StyledWrapper>`
  ${(props) =>
    props.mobile &&
    css`
      @media ${props.theme.breakpoints.mobileMax} {
        ${HiddenTextStyles}
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media ${props.theme.breakpoints.tabletOnly} {
        ${HiddenTextStyles}
      }
    `}

  ${(props) =>
    props.desktop &&
    css`
      @media ${props.theme.breakpoints.desktop} {
        ${HiddenTextStyles}
      }
    `}
`
