import React from 'react'

import { Props } from './types'
import { FooterDefault, FooterSlim } from './variants'

import LogoPlaceHolder from '../../atoms/Logo/constants'

let imageUrl = LogoPlaceHolder
const Footer = (props: Props) => {
  const { data, footerType } = props

  const variantMapping = {
    default: FooterDefault,
    slim: FooterSlim,
  }

  const VariantToRender = footerType
    ? variantMapping[footerType]
    : variantMapping.default

  if (data && data.companyLogo && data.companyLogo.file) {
    imageUrl = data.companyLogo.file.url
  }

  if (VariantToRender) {
    return <VariantToRender data={data} imageUrl={imageUrl} />
  }

  return null
}

export default Footer
