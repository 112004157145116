import styled, { css } from 'styled-components'

import theme from '../../../../styles/theme'

import { BaseParagraphProps, Weights } from './types'

const fontWeightMapping = (fontWeight: Weights) => {
  const {
    fontWeights: { light, normal, bold },
  } = theme

  const weights = {
    light,
    normal,
    bold,
  }

  return weights[fontWeight]
}

export const BaseParagraphStyles = css<BaseParagraphProps>`
  line-height: ${(props) => props.theme.lineHeights.normal};
  font-weight: ${(props) =>
    props.weight
      ? fontWeightMapping(props.weight)
      : props.theme.fontWeights.normal};
  text-align: ${(props) => props.textAlign};

  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  & + p {
    margin-top: 1.6rem;
  }

  & + h4,
  & + h5,
  & + h6 {
    margin-top: 5rem;
  }
`

export const BaseParagraph = styled.p`
  ${BaseParagraphStyles};
`

const smallStyles = css`
  ${BaseParagraphStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`

const small = styled.p`
  ${smallStyles}
`

const mediumStyles = css`
  ${BaseParagraphStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
  }

  & + ${small} {
    margin-top: 1.5rem;
  }
`

const medium = styled.p`
  ${mediumStyles}
`

const largeStyles = css`
  ${BaseParagraphStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.body.secondary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.secondary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.secondary};
  }

  & + ${medium} {
    margin-top: 2rem;
  }
`

const large = styled.p`
  ${largeStyles}
`

// TODO: move font-size styles to separate components to be able to reuse them, not include BaseParagraphStyles
const overlineStyles = css`
  ${BaseParagraphStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
  }
`

const overline = styled.p`
  ${overlineStyles}
`

const tagStyles = css`
  ${BaseParagraphStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.tag.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.tag.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.tag.primary};
  }
`

const tag = styled.p`
  ${tagStyles}
`

export default {
  styles: {
    smallStyles,
    mediumStyles,
    largeStyles,
    overlineStyles,
    tagStyles,
  },
  styledComponents: {
    large,
    medium,
    small,
    overline,
    tag,
  },
}
