import { addMinutes, isBefore, isToday, parseISO } from 'date-fns'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { InputContainer, Message } from '../../styles'

import { Select, SelectLabel } from './styles'

const TimeSelectInput = ({
  field,
  form: { touched, errors, dirty, values, setFieldValue },
  colorTheme,
  ...props
}) => {
  const { label, id } = props
  const [isFocused, setIsFocused] = useState(false)
  const selectRef = useRef(null)

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleOnBlur = (e) => {
    field.onBlur(e)
    setIsFocused(false)
  }

  const startTime = 9
  const endTime = 18
  const totalHours = endTime - startTime
  const interval = 0.5
  const totalTimesSlots = totalHours / interval

  // eslint-disable-next-line prefer-spread
  const timeSlots = Array.apply(null, { length: totalTimesSlots }).map(
    (_, index) => {
      const time = index * interval + startTime

      const hours = Math.floor(time).toString().padStart(2, '0')
      const minutes = ((time % 1) * 60).toString().padEnd(2, '0')

      return `${hours}:${minutes}`
    }
  )

  useEffect(() => {
    // Keeps value up-to-date for when date is changed from a future date to
    // current date, but a timeslot that is unavailable (has already passed) was
    // already selected.
    const current = selectRef.current

    if (!field.value) {
      current.selectedIndex = 0
    } else if (current && current.value && current.value !== field.value) {
      setFieldValue(field.name, current.value)
    }
  })

  const isTouched = touched[field.name]
  const hasErrors = isTouched ? errors[field.name] : ''
  const hasValue = Boolean(field.value)
  const errorsInField = typeof hasErrors !== 'undefined'
  const cutoffTime = addMinutes(new Date(), 30)
  const isTodaysDate = isToday(parseISO(values.Appointment_Date__c))

  return (
    <Fragment>
      <InputContainer>
        <SelectLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={true}
          errorsInField={errorsInField}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </SelectLabel>
        <Select
          {...field}
          {...props}
          id={id}
          isTouched={isTouched}
          errorsInField={errorsInField}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          hasValue={hasValue}
          ref={selectRef}
          data-bdd-selector={`input_${field.name}`}
          className={isFocused && 'focused'}
          colorTheme={colorTheme}
        >
          <option value="" label={label} disabled={true}>
            {label}
          </option>
          {timeSlots.map((option) => {
            const optionTime = option.split(':')
            const optionDate = new Date().setHours(
              optionTime[0],
              optionTime[1],
              0,
              0
            )
            const validTime = isBefore(cutoffTime, optionDate)
            const optionValue = `${option}:00`

            const optionElement = (
              <option value={optionValue} label={option} key={option}>
                {option}
              </option>
            )

            if (!isTodaysDate) {
              return optionElement
            }

            if (isTodaysDate && validTime) {
              return optionElement
            }
          })}
        </Select>
        <ReactSVG src={`/static/icons/ChevronDown.svg`} className="a-icon" />
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {hasErrors}
      </Message>
    </Fragment>
  )
}

export default TimeSelectInput
