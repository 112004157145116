import React, {
  createRef,
  Fragment,
  RefObject,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useRouter } from 'next/router'

import routes from '../../../../../server/routes'
import { logEvent } from '../../../../utils'
import { Column, Container, Row } from '../../atoms'
import {
  ProfilingControls,
  ProfilingLoading,
  ProfilingQuestion,
} from '../../molecules'

import { REDIRECT_TIMER, SCROLL_TOP_TIMER } from './constants'
import { QuestionsForm, QuestionWrapper, Wrapper } from './styles'
import { Props, QuestionResponse } from './types'
import { ProfilingQuestionData } from '../../molecules/ProfilingQuestion/types'

const { Router } = routes

const wrapperRef: { current: any } = createRef()

export const onBeforeUnload = (event) => {
  event.preventDefault()
  event.returnValue = ''
}

export const addEventListener = () => {
  window.addEventListener('beforeunload', onBeforeUnload, false)
}

export const removeEventListeners = () => {
  window.removeEventListener('beforeunload', onBeforeUnload, false)
}

export const scrollTop = () => {
  setTimeout(() => {
    wrapperRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }, SCROLL_TOP_TIMER)
}

const getInitialQuestionStack = (
  start: number,
  defaultQuestionId: number,
  allProfilingQuestionsById: Record<number, ProfilingQuestionData>
): QuestionResponse[] => [
  {
    questionId:
      allProfilingQuestionsById[start] === undefined ||
      allProfilingQuestionsById[start].questionId === undefined
        ? defaultQuestionId
        : allProfilingQuestionsById[start].questionId,
  },
]

const getAllQuestionsMapById = (
  allProfilingQuestions: ProfilingQuestionData[]
): Record<number, ProfilingQuestionData> => {
  return allProfilingQuestions.reduce((acc, curr) => {
    acc[curr.questionId] = curr
    return acc
  }, {})
}

const ProfilingQuestions: React.FunctionComponent<Props> = ({
  allProfilingQuestions,
  loadingScreenText,
}) => {
  const {
    query: { start: startQuestionId },
  } = useRouter()

  const allQuestionsMapById = useMemo<Record<number, ProfilingQuestionData>>(
    () => getAllQuestionsMapById(allProfilingQuestions),
    [allProfilingQuestions]
  )
  const [questionAndAnswerStack, setQuestionAndAnswerStack] = useState<
    QuestionResponse[]
  >(
    getInitialQuestionStack(
      parseInt(startQuestionId as string, 10),
      // This sets the default question to be the first element in the questionSteps list in Contentful
      allProfilingQuestions[0].questionId,
      allQuestionsMapById
    )
  )
  const [isQuestionnaireComplete, setIsQuestionnaireComplete] = useState(false)

  const currentQuestionAndAnswers = useMemo(
    () => questionAndAnswerStack[questionAndAnswerStack.length - 1],
    [questionAndAnswerStack]
  )

  const currentQuestionId = currentQuestionAndAnswers.questionId

  const profilingQuestionsRefs: Record<string, RefObject<HTMLInputElement>> = {}

  useEffect(() => addEventListener(), [])

  const handleOnChange = (questionId: number, answerIds: number[]) => {
    setQuestionAndAnswerStack((stack) => [
      ...stack.slice(0, stack.length - 1),
      { questionId, answerIds: [answerIds[0]] },
    ])
  }

  const handleOnClickPrevious = () => {
    scrollTop()

    if (profilingQuestionsRefs[currentQuestionId].current) {
      profilingQuestionsRefs[currentQuestionId].current.checked = false
    }

    setQuestionAndAnswerStack((currStack) => [
      ...currStack.slice(0, currStack.length - 1),
    ])
  }

  const handleOnClickNext = () => {
    scrollTop()

    const currentQuestion = allQuestionsMapById[currentQuestionId]
    const selectedAnswer = currentQuestion.answers.find(
      ({ answerId }) => answerId === currentQuestionAndAnswers.answerIds[0]
    )

    if (selectedAnswer.questionToShowNext.questionId) {
      const nextQuestionId = selectedAnswer.questionToShowNext.questionId

      setQuestionAndAnswerStack((currStack) => [
        ...currStack,
        { questionId: nextQuestionId },
      ])
    } else {
      setIsQuestionnaireComplete(true)
      removeEventListeners()
      navigateToResults()
    }

    logEvent({
      category: 'User interactions',
      action: 'Affinity questionnaire',
      label:
        'Question-' +
        currentQuestionId +
        '| Answer-' +
        currentQuestionAndAnswers.answerIds[0].toString(),
      value: 1,
    })
  }

  const navigateToResults = () => {
    const queryParams = {
      answers: btoa(JSON.stringify(questionAndAnswerStack)),
    }

    // Add 4s timer to simulate data processing
    setTimeout(() => {
      Router.pushRoute('profiling-tool-results', queryParams)
    }, REDIRECT_TIMER)
  }

  const isPreviousButtonDisabled =
    questionAndAnswerStack.length === 1 || isQuestionnaireComplete
  const isPreviousButtonVisible = questionAndAnswerStack.length !== 1

  const isNextButtonDisabled =
    currentQuestionAndAnswers.answerIds === undefined || isQuestionnaireComplete

  return (
    <Fragment>
      <Wrapper ref={wrapperRef} data-bdd-selector="profiling-questions">
        <QuestionsForm
          currentQuestion={currentQuestionId}
          allQuestionsAnswered={isQuestionnaireComplete}
        >
          {allProfilingQuestions.map((question) => {
            const { questionId } = question

            const isQuestionActive = currentQuestionId === questionId

            if (profilingQuestionsRefs[questionId] === undefined) {
              const profilingQuestionsRef = createRef<HTMLInputElement>()
              profilingQuestionsRefs[questionId] = profilingQuestionsRef
            }

            const currentlySelectedAnswerId =
              currentQuestionAndAnswers.answerIds
                ? currentQuestionAndAnswers.answerIds[0]
                : null

            return (
              !isQuestionnaireComplete && (
                <QuestionWrapper
                  isQuestionActive={isQuestionActive}
                  tabIndex={isQuestionActive ? 0 : -1}
                  key={questionId}
                >
                  <Container>
                    <Row>
                      <Column tablet={[6, 1]} desktop={[10, 1]}>
                        <ProfilingQuestion
                          data={question}
                          currentlySelectedAnswerId={currentlySelectedAnswerId}
                          ref={profilingQuestionsRefs[questionId]}
                          isActive={isQuestionActive}
                          isDisabled={!isQuestionActive}
                          handleOnChange={handleOnChange}
                          handleOnClickNext={handleOnClickNext}
                          data-bdd-selector="profiling-questions-question"
                        />
                      </Column>
                    </Row>
                  </Container>
                </QuestionWrapper>
              )
            )
          })}
        </QuestionsForm>
        {isQuestionnaireComplete && (
          <ProfilingLoading
            data={loadingScreenText}
            data-bdd-selector="profiling-questions-loading"
          />
        )}
      </Wrapper>
      <ProfilingControls
        onClickPrevious={handleOnClickPrevious}
        isPreviousDisabled={isPreviousButtonDisabled}
        isPreviousVisible={isPreviousButtonVisible}
        onClickNext={handleOnClickNext}
        isNextDisabled={isNextButtonDisabled}
        data-bdd-selector="profiling-questions-controls"
      />
    </Fragment>
  )
}

export default ProfilingQuestions
