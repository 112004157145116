import styled, { css } from 'styled-components'

import { StyledForm, StyledQuestionWrapper } from './types'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
`

export const QuestionsForm = styled.form<StyledForm>`
  flex: 1 0 auto;
  position: relative;
  opacity: 0;
  animation: ${(props) => props.theme.animations.fadeIn}
    ${(props) => props.theme.transitions.slow}
    ${(props) => props.theme.transitions.verySlow} ease forwards;
`

export const QuestionWrapper = styled.div<StyledQuestionWrapper>`
  overflow: hidden;
  transition: opacity ${(props) => props.theme.transitions.slow} ease,
    height 0s ${(props) => props.theme.transitions.verySlow};
  ${(props) =>
    props.isQuestionActive
      ? css`
          height: 100%;
          opacity: 1;
          z-index: 1;
          transition-delay: 1s;
        `
      : css`
          height: 0;
          opacity: 0;
          z-index: 0;
          transition-delay: 0;
        `}

  &:focus {
    outline: none;
  }
`
