import Slider from 'react-slick'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Button } from '../../atoms'

import { SliderProps } from './types'

const StyledButton = styled(Button)`
  display: none;

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: inline-flex;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: inline-flex;
  }

  &.slick-disabled {
    cursor: default;
    pointer-events: none;
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        'callToAction.primary.disabled.textThemeColor'
      )};
    background-color: ${(props) =>
      colorTheme(
        props.colorTheme,
        'callToAction.primary.disabled.backgroundThemeColor'
      )};
    opacity: 0.3;
  }

  &.slick-disabled::before {
    content: none;
  }
`

export const NextButton = styled(StyledButton)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-left: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: 5rem;
  }
`

export const PrevButton = styled(StyledButton)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-right: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 5rem;
  }
`

export const StyledSlider = styled(Slider)<SliderProps>`
  &.slick-slider {
    display: flex;
    align-items: center;
  }

  & .slick-list {
    margin: 0 -${(props) => props.gap}rem;
    flex: 1;
  }

  & .slick-slide {
    padding: 0 ${(props) => props.gap}rem;
    box-sizing: border-box;
  }

  & .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: -1.8rem;
    left: 50%;
    transform: translateX(-50%);

    & li button {
      width: 1.6rem;
      height: 1.6rem;
      font-size: 0;
      border-radius: 50%;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid;
      border-color: ${(props) =>
        props.isNested
          ? props.theme.colors.ui.white
          : colorTheme(
              props.colorTheme,
              'callToAction.primary.active.borderThemeColor'
            )};
    }

    & li.slick-active button {
      background-color: ${(props) =>
        props.isNested
          ? props.theme.colors.background.white
          : colorTheme(
              props.colorTheme,
              'callToAction.primary.active.backgroundThemeColor'
            )};
    }

    & li + li {
      margin-left: 2.5rem;
    }
  }
`
