import styled from 'styled-components'
import { colorTheme } from '../../../../styles/utils'

import { StyledListItem } from './types'

interface Props {
  light: boolean
}

export const Wrapper = styled.ol<Props>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: ${(props) =>
    props.light
      ? props.theme.colors.background.transparent
      : props.theme.colors.background.black};
  counter-reset: section;
`

export const OrderedStepsListItem = styled.li<StyledListItem>`
  counter-increment: step-counter;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: ${(props) =>
    props.light
      ? props.theme.colors.background.black
      : colorTheme(props.colorTheme, 'orderedList.textThemeColor')};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  display: flex;

  &:before {
    content: counter(section);
    counter-increment: section;
    margin-right: 1.8rem;
    font-size: 2rem;
    color: ${(props) =>
      props.light
        ? props.theme.colors.background.black
        : colorTheme(props.colorTheme, 'orderedList.textThemeColor')};
    font-weight: ${(props) => props.theme.fontWeights.light};
    border: 0.15rem solid
      ${(props) =>
        props.light
          ? props.theme.colors.background.black
          : colorTheme(props.colorTheme, 'orderedList.borderThemeColor')};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4rem;
    height: 4rem;

    text-align: center;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 3rem;
      min-width: 5rem;
      height: 5rem;
    }
  }
`
