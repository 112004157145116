import { RichText } from '../../../atoms'
import { CallToAction } from '../../../molecules'
import { StyledDropdownContentBlock } from '../styles'
import { DropdownContentBlockData } from '../types'

const NavDropdownContentBlock: React.FunctionComponent<
  DropdownContentBlockData
> = (props) => {
  const { heading, text, callToAction } = props

  return (
    <StyledDropdownContentBlock>
      {heading && <h3>{heading}</h3>}
      {text && <RichText html={text} />}
      {callToAction && <CallToAction {...callToAction} />}
    </StyledDropdownContentBlock>
  )
}

export default NavDropdownContentBlock
