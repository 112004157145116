import { Fragment, FunctionComponent } from 'react'

import {
  Caption,
  CaptionWrapper,
  Description,
  StyledContentBlock,
} from './styles'
import { ContentBlockWithCaptionData, Props } from './types'

const mapToContentBlockData = (
  contentBlockWithCaptionData: ContentBlockWithCaptionData
) => {
  return {
    _id: '',
    _type: '',
    entryTitle: '',
    headline:
      contentBlockWithCaptionData && contentBlockWithCaptionData.headline,
    description:
      contentBlockWithCaptionData && contentBlockWithCaptionData.subheadline,
    callToAction: null,
  }
}

const ContentBlockWithCaption: FunctionComponent<Props> = (props) => {
  const { data } = props
  const { description, bottomCaption } = data

  return (
    <Fragment>
      {(mapToContentBlockData(data).headline ||
        mapToContentBlockData(data).description) && (
        <StyledContentBlock data={mapToContentBlockData(data)} />
      )}
      {description && (
        <Description
          html={description}
          data-bdd-selector="content-block-with-caption-description"
          useTicksForBullets
        />
      )}
      {bottomCaption && (
        <CaptionWrapper>
          <Caption
            html={bottomCaption}
            data-bdd-selector="content-block-with-caption-bottom"
          />
        </CaptionWrapper>
      )}
    </Fragment>
  )
}

export default ContentBlockWithCaption
