import styled from 'styled-components'

import { columnWidthAsString } from '../../../../styles/utils'

import { RowProps, StyledColumnProps } from './types'

export const Row = styled.div<RowProps>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.8rem;
  margin-left: -0.8rem;

  ${(props) =>
    props.horizontalAlignment &&
    `
    justify-content: ${props.horizontalAlignment};
  `};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: -1.6rem;
    margin-left: -1.6rem;
  }
`

export const StyledColumn = styled.div<StyledColumnProps>`
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  ${(props) =>
    props.mobile
      ? `
      ${columnWidthAsString(props.mobile, 'mobile')};
      `
      : `
      ${columnWidthAsString(2, 'mobile')}
  `}
  ${(props) =>
    props.verticalAlignment &&
    `
    display: flex;
    flex-flow: column;
    justify-content: ${props.verticalAlignment}
  `}
  ${(props) =>
    props.horizontalAlignment &&
    `
    display: flex;
    justify-content: ${props.horizontalAlignment};
  `};

  @media ${(props) => props.theme.breakpoints.tablet} {
    ${(props) => columnWidthAsString(props.tablet, 'tablet')}
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    ${(props) => columnWidthAsString(props.desktop, 'desktop')}
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }
`
