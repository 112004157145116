import { FC } from 'react'
import { palette } from '../../../../styles/theme/colors'
import { FormType } from '../../../../utils/form'
import { Container, Row } from '../../atoms'
import { CallbackResultData } from '../../organisms/CallbackFormResult/types'
import {
  ButtonWrapper,
  Headline,
  Icon,
  ResultWrapper,
  StyledWrapper,
  SubHeadline,
} from '../../organisms/NewsLetterSignUpBanner/styles'
import CallToAction from '../CallToAction'
import { FormStatus } from '../FormWrapper/types'
import ResultModal from '../ResultModal/ResultModal'

interface ResultProps {
  content: CallbackResultData
  formType: FormType
  status: FormStatus
  dark?: boolean
  onCtaClick?: () => void
  closeModal?: () => void
}

const Result: FC<ResultProps> = ({
  content,
  status,
  dark = true,
  onCtaClick,
  closeModal,
}) => {
  const icon =
    status === 'success'
      ? '/static/images/success-tick-white.svg'
      : '/static/images/error-tick.svg'
  const textColor = dark ? palette.white : palette.black
  const backgroundColor = dark ? palette.black : palette.transparent

  const callToActions = content.sys
    ? content.callToActionsCollection.items
    : content.callToActions

  if (
    content.displayType === 'Modal' &&
    (status === 'failure' || status === 'success')
  ) {
    return (
      <ResultModal
        showModal={true}
        modalContent={content}
        status={status}
        closeModal={closeModal}
      />
    )
  }
  // inline
  return (
    <StyledWrapper
      data-bdd-selector="newsletter-signup-banner"
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <Container center={true}>
        <Row>
          <ResultWrapper tablet={[6, 1]} desktop={[6, 3]}>
            <Icon
              data-bdd-selector="newsletter-signup-banner-result-icon"
              src={icon}
              alt={`${status} icon`}
            />
            <Headline
              element={3}
              level={5}
              weight="bold"
              data-bdd-selector="newsletter-signup-banner-result-headline"
            >
              {content.headline}
            </Headline>
            <SubHeadline data-bdd-selector="newsletter-signup-banner-result-sub-headline">
              {content.subHeadline}
            </SubHeadline>
            <ButtonWrapper>
              {callToActions
                ? callToActions.map((cta) => (
                    <CallToAction
                      {...cta}
                      clickHandler={onCtaClick}
                      componentTheme={'blue'}
                      data-bdd-selector="newsletter-signup-banner-result-button"
                    />
                  ))
                : null}
            </ButtonWrapper>
          </ResultWrapper>
        </Row>
      </Container>
    </StyledWrapper>
  )
}

export default Result
