import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`

export const FormElement = styled.div`
  margin-top: 3.2rem;
`
