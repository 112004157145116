import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { StyledInputProps, StyledLabelProps } from './types'

export const InputContainer = styled.div`
  position: relative;

  .a-icon {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 1.6rem;
    pointer-events: none;
  }
`

export const Label = styled.label<StyledLabelProps>`
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
  z-index: 1;
  transition: color ${(props) => props.theme.transitions.fast} ease,
    transform ${(props) => props.theme.transitions.fast} ease,
    font-size ${(props) => props.theme.transitions.fast} ease;
`

export const BaseInputStyles = css`
  height: 5.2rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  background-color: ${(props) => props.theme.colors.background.white};
  border-radius: 5rem;
  font-size: ${(props) => props.theme.fontSizes.desktop.input.label};
  font-family: inherit;
  appearance: none;
  transition: border-color ${(props) => props.theme.transitions.fast} ease,
    box-shadow ${(props) => props.theme.transitions.fast} ease,
    opacity ${(props) => props.theme.transitions.fast} ease;
`

export const BaseInput = styled.input`
  ${BaseInputStyles}

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

export const InputStyles = css<StyledInputProps>`
  padding: 2.4rem 4rem 0.6rem
    ${(props) => (props.hasPrefix ? '5.5rem' : '2.5rem')};
  color: ${(props) => props.theme.colors.text.black};
  border: 0.1rem solid
    ${(props) =>
      (props.isTouched && props.errorsInField && props.theme.colors.ui.red2) ||
      props.theme.colors.ui.grey2};
  box-shadow: 0 0 0 1px
    ${(props) =>
      (props.isTouched && props.errorsInField && props.theme.colors.ui.red2) ||
      'transparent'};

  &::placeholder {
    opacity: 0;
    transition: opacity ${(props) => props.theme.transitions.fast} ease;
  }

  &:focus::placeholder {
    opacity: 1;
  }

  &:focus,
  &:active {
    border: 1px solid
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        colorTheme(props.colorTheme, 'input.borderThemeColor')};
    box-shadow: 0 0 0 1px
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        colorTheme(props.colorTheme, 'input.boxShadowThemeColor')};
  }
`

export const Input = styled(BaseInput)`
  ${InputStyles}
`

export const Message = styled.div`
  font-size: ${(props) => props.theme.fontSizes.mobile.input.error};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  min-height: 2rem;
  padding: 0.3rem 2rem 0;
  color: ${(props) => props.theme.colors.text.red2};

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.input.error};
  }
`

export const HelpText = styled.p`
  font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  line-height: ${(props) => props.theme.lineHeights.tight};
  margin-top: -0.1rem;
  padding: 0rem 2rem 2.4rem;
  opacity: 0.8;
`
