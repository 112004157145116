import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { OverlayProps, StyledImageProps } from './types'

export const StyledImage = styled.img<StyledImageProps>`
  width: 100%;
  ${(props) =>
    props.keepRatio &&
    `
   height: 100%;
   object-fit: cover;
  `}
`

export const Picture = styled.picture`
  display: block;
  position: relative;
  line-height: 0;
  height: 100%;
  width: auto;
`

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  opacity: ${(props) => props.opacity};
  background-color: ${(props) =>
    colorTheme(props.colorTheme, 'imageOverlayColor')};
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
`
