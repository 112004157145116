import React, { FunctionComponent } from 'react'
import { ReactSVG } from 'react-svg'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { Container, RichText } from '../../atoms'
import { CallToAction } from '../../molecules'

import {
  CallToActionWrapper,
  Column,
  ColumnItem,
  IconContainer,
  Row,
  Section,
  Wrapper,
} from './styles'
import { Props } from './types'

const ThreeColumnsIcons: FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: { headline, columnIcon, callToAction },
  } = props

  return (
    <Wrapper colorTheme={colorTheme} data-bdd-selector="three-columns-icons">
      <Container paddingSize="double" center={true}>
        <Section colorTheme={colorTheme}>
          <RichText
            html={headline}
            data-bdd-selector="three-columns-icons-headline"
          />
          <Row>
            {columnIcon.map(
              ({
                headline: columnTitle,
                _id,
                description,
                svgIcon: {
                  file: { url },
                },
              }) => (
                <Column
                  key={_id}
                  data-bdd-selector="three-columns-icons-item"
                  colorTheme={colorTheme}
                >
                  <ColumnItem>
                    <IconContainer data-bdd-selector="three-columns-icons-item-icon">
                      <ReactSVG src={url} aria-hidden="true" />
                    </IconContainer>
                    <h5 data-bdd-selector="three-columns-icons-item-title">
                      {columnTitle}
                    </h5>
                    {description && (
                      <RichText
                        html={description}
                        data-bdd-selector="three-columns-icons-item-description"
                      />
                    )}
                  </ColumnItem>
                </Column>
              )
            )}
          </Row>
          {callToAction[0] && (
            <CallToActionWrapper>
              <CallToAction
                {...callToAction[0]}
                data-bdd-selector="three-columns-icons-button"
              />
            </CallToActionWrapper>
          )}
        </Section>
      </Container>
    </Wrapper>
  )
}

export default ThreeColumnsIcons
