import styled, { css } from 'styled-components'

import { colorTheme, paddingSize } from '../../../styles/utils'
import { Column, Container, RichText } from '../../common/atoms'

import { StyledTableTitle, StyledTitleColumn } from './types'

export const PricingWrapper = styled.div`
  background: ${(props) => props.theme.colors.background.white};
`

export const PricingContainer = styled(Container)`
  padding-top: ${paddingSize(6, 'default')};
  padding-bottom: ${paddingSize(4, 'default')};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: ${paddingSize(6, 'default')};
    padding-bottom: ${paddingSize(5, 'default')};
  }
`

export const CallToActionContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: ${paddingSize(3, 'double')};

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: ${paddingSize(5, 'double')};
  }
`

export const TitleColumn = styled(Column)<StyledTitleColumn>`
  margin: 0 auto 5rem;
  text-align: center;
  color: ${(props) =>
    colorTheme(props.colorTheme, 'pricing.textAccentThemeColor')};

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    max-width: 75vw;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: 4rem auto 5rem;
  }

  b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'pricing.textAccentThemeColor')};
  }
`

export const TableTitleDescription = styled(RichText)<StyledTableTitle>`
  text-align: ${(props) => props.textAlign};
  padding-bottom: 2.2rem;

  ${(props) =>
    props.topPaddingRequired &&
    css`
      padding-top: 2.2rem;

      @media ${props.theme.breakpoints.tablet} {
        padding-top: 3rem;
      }

      @media ${props.theme.breakpoints.desktop} {
        padding-top: 0;
      }
    `}

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-bottom: 3rem;
  }

  p:nth-child(2) {
    margin-top: 3.26rem;
  }
`
