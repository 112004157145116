import React from 'react'

import { Button, Modal } from '../../atoms'
import { ButtonType } from '../../atoms/Button/types'

import { Body, StyledModalContent, Title } from './styles'
import { Props } from './types'

const BrowserNotSupported: React.FunctionComponent<Props> = (props) => {
  const { data, clickHandler, visible } = props
  if (!data) {
    return undefined
  }

  return (
    <Modal show={visible} size="tiny">
      <StyledModalContent paddingSize="half">
        {data.headline && <Title html={data.headline} />}
        {data.description && <Body html={data.description} />}
        {data.callToAction && data.callToAction[0] && (
          <Button
            buttonType={ButtonType.Primary}
            label={data.callToAction[0].buttonLabel}
            onClick={clickHandler}
          />
        )}
      </StyledModalContent>
    </Modal>
  )
}

export default BrowserNotSupported
