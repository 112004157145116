import { rgba } from 'polished'

import { styled } from '../../../../styles/theme'
import Image from '../Image'
import ParagraphStyles from '../Paragraph/styles'
import TitleStyles from '../Title/styles'

const {
  styles: { smallStyles, overlineStyles, tagStyles },
} = ParagraphStyles
const {
  styles: { H4Styles },
} = TitleStyles

export const StyledImage = styled(Image)`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(70%) contrast(80%);
  }
`

export const Description = styled.div`
  ${smallStyles}

  margin-top: 0.8rem;

  padding-right: 10%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-right: 25%;
  }
`

export const Footer = styled.div`
  ${overlineStyles}
  display: flex;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding-top: 2rem;
  align-items: center;
`

export const Published = styled.div`
  margin-left: 1.2rem;
`

export const ReadTime = styled.div`
  margin-left: 1.8rem;
  position: relative;

  &::before {
    content: '';
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    background: currentColor;
  }
`

export const Tag = styled.div`
  ${tagStyles}

  font-weight: ${(props) => props.theme.fontWeights.bold};
  border: 1px solid currentColor;
  padding: 0.4rem 0.8rem;
  align-self: flex-start;
  border-radius: 25px;
  line-height: ${(props) => props.theme.lineHeights.base};
`

export const Title = styled.div`
  ${H4Styles}
  padding-right: 10%;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  line-height: ${(props) => props.theme.lineHeights.compact};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-right: 25%;
  }
`

export const Content = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  justify-content: flex-end;

  background-image: linear-gradient(
    to bottom,
    ${(props) => rgba(props.theme.colors.background.black, 0)},
    ${(props) => rgba(props.theme.colors.background.black, 1)}
  );
`

export const Wrapper = styled.div`
  height: 100%;
  display: block;
  color: ${(props) => props.theme.colors.text.white};
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  transition: transform ${(props) => props.theme.transitions.normal} ease,
    box-shadow ${(props) => props.theme.transitions.moderate} ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 1.2rem 0
      ${(props) => rgba(props.theme.colors.background.black, 0.2)};
    color: ${(props) => props.theme.colors.text.white};
  }
`
