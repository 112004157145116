import styled, { css } from 'styled-components'
import { colorTheme } from '../../../../styles/utils'
import { Tabs } from '../../atoms'
import { TabsList } from '../../atoms/Tabs/styles'
import { StyledTab } from '../../atoms/Tabs/types'
import { Themed } from './types'

export const StyledTabs = styled(Tabs)`
  ${TabsList} {
    justify-content: space-between;

    &::after {
      content: none;
    }
  }
`

export const Title = styled.span``

export const Copy = styled.span``

export const Wrapper = styled.div<Themed>`
  padding: 1.1rem 0;
  color: ${(props) => colorTheme(props.colorTheme, 'anchorNav.textThemeColor')};

  max-width: ${(props) => props.theme.dimensions.containerMaxWidth};

  @media ${(props) => props.theme.breakpoints.mobile} {
    margin: 0 1.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: auto;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-color: ${(props) =>
      colorTheme(props.colorTheme, 'anchorNav.backgroundThemeColor')};
    padding: 1.1rem 1.3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
  }

  ${Title} {
    ${(props) => css`
      color: ${() =>
        colorTheme(props.colorTheme, 'anchorNav.textTitleThemeColor')};
    `}
  }

  ${Copy} {
    ${(props) => css`
      color: ${() => colorTheme(props.colorTheme, 'anchorNav.textThemeColor')};
    `}
  }
`

export const Tab = styled.button<StyledTab>`
  padding: 0.8rem 1.8rem;
  border-radius: 5rem;
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: inherit;
  appearance: initial;
  border: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.anchorNavLink.primary};
    min-height: 6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) =>
      props.theme.fontSizes.desktop.anchorNavLink.primary};
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${colorTheme(
        props.colorTheme,
        'anchorNav.selectedBackgroundThemeColor'
      )};
      ${Copy} {
        color: ${colorTheme(
          props.colorTheme,
          'anchorNav.textAccentThemeColor'
        )};
      }
    `}
`
