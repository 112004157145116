import styled from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'
import { Column, RichText, Row as GridRow } from '../../../../atoms'

import { StyledRow } from './types'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.ui.beigeMedium};

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`

export const Row = styled(GridRow)<StyledRow>`
  flex-flow: ${(props) => props.leftTextAlignment && 'row-reverse'};
`

export const HeroHeadline = styled(RichText)`
  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) =>
      colorTheme(props.colorTheme, 'hero.withImage.textAccentThemeColor')};
  }
`

export const Description = styled(RichText)`
  margin-top: 2rem;
`

export const ImageColumn = styled(Column)`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    padding: 0;
    margin: 0 -0.8rem;
    width: calc(100% + 1.6rem);
    max-width: none;
    flex-basis: auto;

    &::after {
      content: '';
      height: 1px;
      width: calc(100% + 3.2rem);
      margin: 0 -1.6rem;
      background: ${(props) => props.theme.colors.ui.beigeMedium};
    }
  }

  @media ${(props) => props.theme.breakpoints.tabletOnly} {
    margin-left: -4rem;
    width: calc(50% + 4rem);
    flex-basis: auto;
    max-width: none;
  }
`

export const Content = styled.div`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    padding: 3.2rem 0 3rem;
    text-align: center;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 6rem 0;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 8rem 0;
  }
`
export const TrustPilotRatingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: flex-end;
  background: transparent;
  min-height: 0px;
`
