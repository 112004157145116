import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import base from './base'
import fonts from './fonts'
import nprogress from './nprogress'

export default createGlobalStyle`
  ${reset}
  ${fonts}
  ${base}
  ${nprogress}

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none !important;
  }
`
