import React, { RefObject } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { deviceSizes } from '../../../../styles/theme/breakpoints'
import { ButtonType } from '../../atoms/Button/types'

import { NextButton, PrevButton, StyledSlider } from './styles'
import { Props, SliderProps } from './types'

const Carousel: React.FunctionComponent<Props> = React.forwardRef(
  (props, ref: RefObject<Slider>) => {
    const colorTheme = GetThemeContext(ThemeContext)
    const {
      children,
      onDragChange = () => undefined,
      gap = 2,
      isSelectedArticlesCarousel,
      slidesToShow,
      hideArrows,
      isNested,
    } = props

    const beforeChangeHandler = () => {
      onDragChange(true)
    }

    const afterChangeHandler = () => {
      onDragChange(false)
    }

    const color = isSelectedArticlesCarousel ? 'blue' : colorTheme

    const defaultParameters: Settings = {
      lazyLoad: 'progressive',
      dots: true,
      infinite: false,
      speed: 250,
      slidesToShow: slidesToShow ? slidesToShow : 3,
      slidesToScroll: slidesToShow ? slidesToShow : 3,
      arrows: !hideArrows,
      prevArrow: (
        <PrevButton
          isButton={true}
          aria-label="Previous page"
          data-bdd-selector="carousel-button-prev"
          componentTheme={color}
          buttonType={ButtonType.Primary}
          iconType="ChevronLeft"
        />
      ),
      nextArrow: (
        <NextButton
          isButton={true}
          aria-label="Next page"
          componentTheme={color}
          data-bdd-selector="carousel-button-next"
          buttonType={ButtonType.Primary}
          iconType="ChevronRight"
        />
      ),
      beforeChange: beforeChangeHandler,
      afterChange: afterChangeHandler,
    }

    const responsiveParameters = !slidesToShow && {
      responsive: [
        {
          breakpoint: deviceSizes.desktop - 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: deviceSizes.tablet - 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    const settings: SliderProps = {
      ...defaultParameters,
      ...responsiveParameters,
      ...props,
    }

    return (
      <StyledSlider
        {...settings}
        gap={gap}
        colorTheme={color}
        isNested={isNested}
        ref={ref}
      >
        {children}
      </StyledSlider>
    )
  }
)

export default Carousel
