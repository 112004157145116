import { css } from 'styled-components'

import theme from '../theme'

const {
  baseFontSizePercentage,
  breakpoints,
  colors,
  fontFamily,
  fontWeights,
  lineHeights,
} = theme

export default css`
  html {
    /* Usually default font-size of the browser is 16px. Setting font-size: 100%
      will make 1rem = 16px. But it will make calculations a little difficult. A
      better way is to set font-size: 62.5% 62.5% of 16px is 10px, which makes 1
      rem = 10px */
    font-size: ${baseFontSizePercentage}%;
  }

  body {
    font-family: ${fontFamily.primary};
    font-weight: ${fontWeights.normal};
    line-height: ${lineHeights.normal};
    color: ${colors.text.black};
    background-color: ${colors.background.grey1};
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;

    @media ${breakpoints.desktop} {
      font-size: 1.8rem;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  select::-ms-expand {
    display: none;
  }

  input::-ms-clear {
    display: none;
  }

  h1,
  .xx-large {
    font-size: 3.5rem;
    line-height: 1.25;
  }

  h2,
  .x-large {
    font-size: 3rem;
    line-height: 1.25;
  }

  h3,
  .large {
    font-size: 2.5rem;
    line-height: 1.25;
  }

  h4,
  .medium {
    font-size: 2rem;
    line-height: 1.5;
  }

  h5,
  .small {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  h6,
  .x-small {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.5;
    &.large {
      font-size: 1.8rem;
    }
  }

  i,
  em {
    font-style: italic;
  }

  button {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  .overline {
    font-size: 1.1rem;
    line-height: 1.1rem;
    letter-spacing: 0.2rem;
  }

  @media ${breakpoints.tablet} {
    h1,
    .xx-large {
      font-size: 4rem;
      line-height: 1.25;
    }

    h2,
    .x-large {
      font-size: 3.5rem;
      line-height: 1.25;
    }

    h3,
    .large {
      font-size: 3rem;
      line-height: 1.25;
    }

    h4,
    .medium {
      font-size: 2.5rem;
      line-height: 1.5;
    }

    h5,
    .small {
      font-size: 2rem;
      line-height: 1.5;
    }

    h6,
    .x-small {
      font-size: 1.2rem;
      line-height: 1.5;
    }

    .overline {
      font-size: 1.2rem;
      line-height: 1;
      letter-spacing: 0.3rem;
    }
  }

  @media ${breakpoints.desktop} {
    h1,
    .xx-large {
      font-size: 5rem;
      line-height: 1.25;
    }

    h2,
    .x-large {
      font-size: 3.5rem;
      line-height: 1.25;
    }

    h3,
    .large {
      font-size: 3rem;
      line-height: 1.25;
    }

    h4,
    .medium {
      font-size: 2rem;
      line-height: 1.5;
    }

    h5,
    .small {
      font-size: 1.8rem;
      line-height: 1.5;
    }

    h6,
    .x-small {
      font-size: 1.4rem;
      line-height: 1.5;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.5;
      &.large {
        font-size: 2.3rem;
      }
    }
    .overline {
      font-size: 1.4rem;
      line-height: 1.4rem;
      letter-spacing: 0.4rem;
    }
  }

  @keyframes grow {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    50% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }

    100% {
      transform: translateX(0px);
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  // Hides an element WITHOUT preventing a
  // screenreader from accessing it
  .accessibly-hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }
`
