export const transformImageObject = (card) => {
  const {
    altText,
    desktopImageCollection,
    tabletImageCollection,
    mobileImageCollection,
  } = card
  card.backgroundImage = [
    {
      altText,
      desktopImage: [
        {
          title: desktopImageCollection.items[0].fileName,
          file: {
            url: desktopImageCollection.items[0].url,
            fileName: desktopImageCollection.items[0].fileName,
            contentType: desktopImageCollection.items[0].contentType,
            details: {
              size: desktopImageCollection.items[0].size,
              image: {
                width: desktopImageCollection.items[0].width,
                height: desktopImageCollection.items[0].height,
              },
            },
          },
        },
      ],
      tabletImage: [
        {
          title: tabletImageCollection.items[0].fileName,
          file: {
            url: tabletImageCollection.items[0].url,
            fileName: tabletImageCollection.items[0].fileName,
            contentType: tabletImageCollection.items[0].contentType,
            details: {
              size: tabletImageCollection.items[0].size,
              image: {
                width: tabletImageCollection.items[0].width,
                height: tabletImageCollection.items[0].height,
              },
            },
          },
        },
      ],
      mobileImage: [
        {
          title: mobileImageCollection.items[0].fileName,
          file: {
            url: mobileImageCollection.items[0].url,
            fileName: mobileImageCollection.items[0].fileName,
            contentType: mobileImageCollection.items[0].contentType,
            details: {
              size: mobileImageCollection.items[0].size,
              image: {
                width: mobileImageCollection.items[0].width,
                height: mobileImageCollection.items[0].height,
              },
            },
          },
        },
      ],
    },
  ]
  return card
}
