import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import 'semantic-ui-css/components/icon.min.css'
import { Icon } from 'semantic-ui-react'

import { Paragraph, Title } from '../../../../atoms'

import { CarouselButtonGroup, StyledIcon, StyledSlideContent } from './styles'

export default class BaseTestimonial extends React.Component {
  render() {
    const { items } = this.props
    return (
      <div>
        <Title element={4} level={4} weight={'bold'}>
          {items[0].title}
        </Title>
        <CarouselProvider
          naturalSlideWidth={4}
          naturalSlideHeight={2}
          totalSlides={items.length}
          interval={10000}
          isPlaying={true}
        >
          <Slider>
            {items.map(({ _id, quote, credit }, index) => (
              <Slide key={_id} index={index}>
                <StyledSlideContent>
                  <div>
                    <Paragraph
                      level="small"
                      textAlign={isMobileOnly ? 'center' : 'left'}
                    >
                      <StyledIcon name="quote left" size="big" />
                      {quote}
                      <StyledIcon name="quote right" size="big" />
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph
                      level="small"
                      weight="bold"
                      textAlign={isMobileOnly ? 'center' : 'left'}
                    >
                      <Icon name="minus" size="small" />
                      {credit}
                    </Paragraph>
                  </div>
                </StyledSlideContent>
              </Slide>
            ))}
          </Slider>
          <CarouselButtonGroup />
        </CarouselProvider>
      </div>
    )
  }
}
