import { createContext, useContext } from 'react'

import { ColorThemeNames } from '../../types/colorThemes'

const ThemeContext = createContext('')
const ThemeContextProvider = ThemeContext.Provider

const GetThemeContext = (themeContext): ColorThemeNames => {
  return useContext(themeContext)
}

export { ThemeContext, ThemeContextProvider, GetThemeContext }
