import styled from 'styled-components'

import { paddingSize } from '../../../../styles/utils'

import { ContentBlock } from '..'
import { Container as BaseContainer, Column } from '../../atoms'

import { Headline } from '../ContentBlock/styles'

export const Wrapper = styled.div``

export const StyledColumn = styled(Column)`
  padding-bottom: 1.5rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-bottom: 2rem;
  }
`

export const Container = styled(BaseContainer)`
  padding-top: ${paddingSize(5, 'default')};
  padding-bottom: ${paddingSize(6, 'default')};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-bottom: ${paddingSize(6, 'default')};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: ${paddingSize(5, 'double')};
    padding-bottom: ${paddingSize(5, 'double')};
  }
`

export const PricingContentBlock = styled(ContentBlock)`
  text-align: center;

  div:nth-child(2) {
    margin-bottom: 0;
  }

  &&& p + p {
    margin-top: 1.6rem;
  }

  ${Headline} {
    h2 {
      font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};

      @media ${(props) => props.theme.breakpoints.tablet} {
        font-size: ${(props) => props.theme.fontSizes.tablet.headings.h3};
      }

      @media ${(props) => props.theme.breakpoints.desktop} {
        font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 4rem;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 5rem;
  }
`
