import React from 'react'
import { ReactSVG } from 'react-svg'
import { GetThemeContext, ThemeContext } from '../../../../context'
import {
  StyledDropdown,
  StyledTrigger,
  Tab,
  TabIcon,
  TabsList,
  TabText,
  Wrapper,
} from './styles'
import { Props } from './types'

const dropDownText = (tab) => {
  const separator = tab.linkTitle && tab.linkCopy ? ': ' : ''
  const linkTitle = tab.linkTitle ? tab.linkTitle : ''
  const linkCopy = tab.linkCopy ? tab.linkCopy : ''

  return `${linkTitle}${separator}${linkCopy}`
}

const Tabs: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    tabs = [],
    onTabClick,
    className,
    customTab,
    'aria-label': ariaLabel,
    selectedTab,
    mobileIcon = true,
    isNested,
  } = props

  const [dropdownOpened, setDropdownOpened] = React.useState(false)

  const getIndex = (data) => {
    return tabs.indexOf(tabs.find((tab) => tab.linkTitle === data.value))
  }

  const handleTabClick = (index) => {
    onTabClick(index)
  }

  return (
    <Wrapper
      className={className}
      mobileIcon={mobileIcon}
      data-bdd-selector="tabs"
    >
      <StyledDropdown
        colorTheme={colorTheme}
        aria-label={ariaLabel}
        mobileIcon={mobileIcon}
        icon={
          <ReactSVG className="icon" src={`/static/icons/ChevronDown.svg`} />
        }
        options={tabs.map((tab) => ({
          key: tab._id,
          value: tab.linkTitle,
          text: dropDownText(tab),
        }))}
        onChange={(ev, data) => handleTabClick(getIndex(data))}
        trigger={
          <StyledTrigger dropdownOpened={dropdownOpened}>
            {mobileIcon && <ReactSVG src={'/static/icons/filter.svg'} />}
            <span>{dropDownText(tabs[selectedTab])}</span>
          </StyledTrigger>
        }
        value={dropDownText(tabs[selectedTab])}
        onOpen={() => setDropdownOpened(true)}
        onClose={() => setDropdownOpened(false)}
      />
      <TabsList data-bdd-selector="tabs-list">
        {tabs.map((tab, index) => {
          if (customTab) {
            return customTab(tab, index)
          }

          return (
            <li key={tab._id}>
              <Tab
                isNested={isNested}
                selected={selectedTab === index}
                onClick={() => handleTabClick(index)}
                colorTheme={colorTheme}
                data-bdd-selector="tabs-tab"
              >
                {tab.svgIcon && tab.svgIcon.file && (
                  <TabIcon
                    isNested={isNested}
                    selected={selectedTab === index}
                    colorTheme={colorTheme}
                  >
                    <ReactSVG
                      src={tab.svgIcon.file.url}
                      wrapper="span"
                      data-bdd-selector="tabs-tab-icon"
                    />
                  </TabIcon>
                )}
                <TabText data-bdd-selector="tabs-tab-text">
                  {tab.linkTitle}
                </TabText>
              </Tab>
            </li>
          )
        })}
      </TabsList>
    </Wrapper>
  )
}

export default Tabs
