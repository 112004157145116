import { FC } from 'react'
import { ReactSVG } from 'react-svg'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { CallToAction } from '../../molecules'
import { Wrapper, PhoneNumber, Icon } from './styles'
import { Props } from './types'

const CallOrBeCalled: FC<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    callToAction,
    phoneNumber,
    invert,
    useColorTheme,
    hasCustomHeaderBG,
  } = props

  if ((!callToAction || !callToAction.length) && !phoneNumber) return null

  const filteredHtml =
    phoneNumber && phoneNumber.replace(/<[^\/>][^>]{1,20}><\/[^>]+>/, '')

  return (
    <Wrapper
      invert={invert}
      useColorTheme={useColorTheme}
      colorTheme={colorTheme}
    >
      {callToAction && (
        <CallToAction
          {...callToAction[0]}
          iconBeforeText
          invertColorTheme={useColorTheme && invert}
          data-bdd-selector="header-primary-menu-callback-button"
          id="gtm-header-callback-modal-open-button"
        />
      )}

      {phoneNumber && (
        <PhoneNumber
          invert={invert}
          useColorTheme={useColorTheme}
          colorTheme={colorTheme}
          hasCustomHeaderBG={hasCustomHeaderBG}
        >
          <Icon>
            <ReactSVG src={`/static/icons/phone.svg`} wrapper="span" />
          </Icon>
          <div dangerouslySetInnerHTML={{ __html: filteredHtml }} />
        </PhoneNumber>
      )}
    </Wrapper>
  )
}

export default CallOrBeCalled
