import React, { useEffect, useRef, useState } from 'react'

import api from '../../../services/api'
import { Caption, HiddenText } from '../../common/atoms'

import { DataTableWrapper, PdfAnchor, TableWrapper } from './styles'
import { CellProps, Props } from './types'

// Iterates over all the CSV cells and identify the most accurate format for presentation.
// The format for each cell can be test=T, number=N, and mixed=M
// Also create a row by row typing, rows can all numeric, textual or mixed.
// The idea is to align numbers to the right, text to the left and mixed will be handled as text.
const buildTableInfo = (
  data: [string[]],
  skipFirstRow = true,
  autoSpanColumns: number[] = []
) => {
  if (!data) {
    return
  }

  const columnsTypes = [] // Each cell can be 'N' - number,'T' - text, 'M' - mixed, 'L' - PDF download link
  const cellProps = []
  const values = []
  data.map((rowData, rowIndex) => {
    let emptyColumnsCount = 0
    const rowTypes = rowData.map((columnData, columnIndex) => {
      // skips empty cells.
      if (columnData.trim().length === 0) {
        emptyColumnsCount++
        return false
      } else if (!skipFirstRow || rowIndex > 0) {
        // verify if the cell's value is a number including spaces and currency symbols.
        const isNumber = /^[0-9,.£$€\s]+$/gi.test(columnData.trim())
        // verify if the cell's value is a URL within the website combined with a description; the
        // URL needs to start with http(s)://, ./, ../, or /; the description is separated with a
        // tilde (~)
        const isPdfLink =
          /^(http(s?):\/\/([a-z0-9\-]+\.)+[a-z]+\/|\.\/|\.\.\/|\/)([a-zA-Z0-9-_\/]+\/){0,10}[a-zA-Z0-9%-_]+\.(pdf|msdoc\/)~.{0,255}$/gi.test(
            columnData.trim()
          )
        const thisCellProps: CellProps = {
          type: isNumber ? 'N' : isPdfLink ? 'L' : 'T',
          skip: false,
        }
        // checks the previous column type and if the type diverges set it to mixed=M
        if (!columnsTypes[columnIndex]) {
          columnsTypes[columnIndex] = thisCellProps
        } else if (columnsTypes[columnIndex].type !== thisCellProps.type) {
          columnsTypes[columnIndex].type = 'M'
        }
        // checks for a matching value in the previous row, where that column is identified
        // as automatically spanning, and sets it to skip if the previous value matches
        if (
          autoSpanColumns &&
          autoSpanColumns.includes(columnIndex) &&
          rowIndex > (skipFirstRow ? 1 : 0)
        ) {
          if (columnData === data[rowIndex - 1][columnIndex]) {
            thisCellProps.skip = true
          }
        }
        return thisCellProps
      }
    })
    // removed undefined value from the result
    // these values come from dirty CVS files.
    if (emptyColumnsCount !== rowData.length) {
      cellProps.push(rowTypes)
      values.push(rowData)
    }
  })

  // works backwards through the data, resolving skipped values to row spans that are applied
  // to the first of the set of rows.
  if (autoSpanColumns) {
    let i: number
    let j: number
    let current: CellProps
    let below: CellProps
    for (i = cellProps.length - 1; i >= (skipFirstRow ? 1 : 0); i--) {
      for (j = 0; j < values[0].length; j++) {
        current = cellProps[i][j]
        if (typeof current !== 'boolean') {
          below = i < cellProps.length - 1 ? cellProps[i + 1][j] : undefined
          current.rowSpan = ((below && below.skip && below.rowSpan) || 0) + 1
        }
      }
    }
  }

  return { values, tableInfo: { cellTypes: cellProps, columnsTypes } }
}

const Table: React.FunctionComponent<Props> = (props: Props) => {
  const [data, setData] = useState(null)
  const [appearanceState, setAppearanceState] = useState({
    showLeftShadow: false,
    showRightShadow: false,
    clickable: props.clickable || true,
  })
  const dataTableWrapper = useRef(null)
  const {
    data: { csv, style, caption, autoSpanColumns, compact },
  } = props

  useEffect(() => {
    if (csv) {
      // First try to use the embedded data if it's present.
      if (csv.data) {
        const tableData = buildTableInfo(csv.data, true, autoSpanColumns)
        setData(tableData)
        updateAppearance()
      }
      // if the data is not present load it from the resource,
      // and convert it to json
      else if (csv.file && csv.file.url) {
        api.loadAndConvertCsvFile(csv.file.url).then((result) => {
          const tableData = buildTableInfo(result, true, autoSpanColumns)
          setData(tableData)
          updateAppearance()
        })
      }
      const handleResize = () => updateAppearance()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const { showLeftShadow, showRightShadow, clickable } = appearanceState

  const updateAppearance = () => {
    const el = dataTableWrapper.current
    if (el) {
      const showStartShadow = el.scrollLeft > 0
      const showEndShadow = el.scrollWidth > el.clientWidth + el.scrollLeft
      const clickableProp = props.clickable || true
      if (
        showStartShadow !== showLeftShadow ||
        showEndShadow !== showRightShadow ||
        clickableProp !== clickable
      ) {
        setAppearanceState({
          showLeftShadow: showStartShadow,
          showRightShadow: showEndShadow,
          clickable: clickableProp,
        })
      }
    }
  }

  return (
    data && (
      <div data-bdd-selector="table-wrapper">
        <TableWrapper
          tableStyle={style}
          data-bdd-selector="table-wrapper-container"
          clickable={clickable}
        >
          <DataTableWrapper
            tableStyle={style}
            ref={dataTableWrapper}
            onScroll={updateAppearance}
            showLeftShadow={showLeftShadow}
            showRightShadow={showRightShadow}
            compact={compact}
            data-bdd-selector="table-data-container"
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              data-bdd-selector="table-element"
            >
              <tbody>
                {data.values.map((row, rowIndex) => (
                  <tr key={`data_${rowIndex}`}>
                    {row.map((cellData, columnIndex) => {
                      const cellProps =
                        data.tableInfo.cellTypes[rowIndex][columnIndex]
                      if (cellProps && cellProps.skip) {
                        return <></>
                      }
                      const alignment =
                        data.tableInfo.columnsTypes[columnIndex].type === 'N'
                          ? 'right'
                          : 'left'
                      let renderedContent = cellData
                      if (
                        data.tableInfo.columnsTypes[columnIndex].type === 'L' &&
                        rowIndex > 0 &&
                        renderedContent.indexOf('~') > 0
                      ) {
                        const linkParts = renderedContent.split('~')
                        renderedContent = (
                          <PdfAnchor
                            title={linkParts[1]}
                            href={linkParts[0]}
                            target="_blank"
                            rel="noopener"
                          >
                            <HiddenText all={true}>Download PDF</HiddenText>
                          </PdfAnchor>
                        )
                      }
                      const rowSpan =
                        cellProps && cellProps.rowSpan && cellProps.rowSpan > 1
                          ? cellProps.rowSpan
                          : undefined
                      return (
                        (columnIndex > 0 && (
                          <td
                            align={alignment}
                            key={`cell-${rowIndex}-${columnIndex}`}
                            data-bdd-selector={`cell-${rowIndex}-${columnIndex}`}
                            rowSpan={rowSpan}
                          >
                            {renderedContent}
                          </td>
                        )) || (
                          <th
                            align={alignment}
                            key={`cell-${rowIndex}-${columnIndex}`}
                            data-bdd-selector={`cell-${rowIndex}-${columnIndex}`}
                            rowSpan={rowSpan}
                          >
                            {renderedContent}
                          </th>
                        )
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </DataTableWrapper>
        </TableWrapper>
        {caption && caption.length > 0 && (
          <Caption caption={caption[0].caption} credit={caption[0].credit} />
        )}
      </div>
    )
  )
}

export default Table
