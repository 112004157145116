import Cleave from 'cleave.js/react'

import { styled } from '../../../../styles/theme'
import { colorTheme } from '../../../../styles/utils'
import { BaseInputStyles, InputStyles, Message } from '../../atoms/Input/styles'
import { StyledInputProps } from '../../atoms/Input/types'
import ParagraphStyles from '../../atoms/Paragraph/styles'

const borderRadius = '3.2rem'

const {
  styles: { smallStyles },
} = ParagraphStyles

export const InputWrapper = styled.div`
  position: relative;
  font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  &::before {
    content: '£';
    position: absolute;
    left: 3.2rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${(props) => props.theme.colors.text.white};
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 7.2rem;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
    background: ${(props) => props.theme.colors.background.blue};
  }
`

export const StyledCleave = styled(Cleave)`
  ${BaseInputStyles}
  ${InputStyles}

  padding: 0 2rem;
  height: 6.4rem;
  border-radius: ${borderRadius};
  font-size: inherit;
  font-weight: inherit;
  text-align: right;
  background: ${(props) => props.theme.colors.background.grey1};

  &::placeholder {
    opacity: 1;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 2.4rem;
  }
`

export const Wrapper = styled.div<StyledInputProps>`
  ${InputWrapper} {
    &::after {
      border: 0.1rem solid
        ${(props) =>
          (props.isTouched &&
            props.errorsInField &&
            props.theme.colors.ui.red2) ||
          'transparent'};
      border-right-color: transparent;
    }
  }

  ${StyledCleave} {
    border: 0.1rem solid
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        'transparent'};
    box-shadow: 0 0 0 1px
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        'transparent'};

    &:focus,
    &:active {
      border: 1px solid
        ${(props) =>
          (props.isTouched &&
            props.errorsInField &&
            props.theme.colors.ui.red2) ||
          colorTheme(props.colorTheme, 'priceInput.borderThemeColor')};
      box-shadow: 0 0 0 1px
        ${(props) =>
          (props.isTouched &&
            props.errorsInField &&
            props.theme.colors.ui.red2) ||
          colorTheme(props.colorTheme, 'priceInput.boxShadowThemeColor')};
    }
  }
`

export const StyledMessage = styled(Message)`
  margin-top: 0.8rem;
  text-align: center;

  ${smallStyles}
  font-weight: ${(props) => props.theme.fontWeights.bold};

  &[hidden] {
    display: block;
    visibility: hidden;
  }
`
