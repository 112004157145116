import { Fragment } from 'react'

import { BackgroundImage } from './styles'
import { Props } from './types'

/**
 * A component which provides a background image wrapper for a component(s).
 *
 * @param props
 * @param props.data - Required. The image(s) to be shown as a background-image.
 * @param props.colorTheme - The color theme.
 * @param props.children - Required. The child elements.
 *
 * @returns JSX.Element - The child elements.
 */
const Background: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    colorTheme,
    overlayOpacity = 0.8,
    children,
    className,
    useColorThemeForOverlay,
  } = props

  const imagesData = !Array.isArray(data) ? [data] : data

  if (imagesData && imagesData[0]) {
    return (
      <BackgroundImage
        images={imagesData[0]}
        className={className}
        colorTheme={colorTheme}
        overlayOpacity={overlayOpacity}
        useColorThemeForOverlay={useColorThemeForOverlay}
      >
        {children}
      </BackgroundImage>
    )
  }

  return <Fragment>{children}</Fragment>
}

export default Background
