import { GetThemeContext, ThemeContext } from '../../../../context'
import { Button, Column } from '../../atoms'
import { ButtonType } from '../../atoms/Button/types'

import { Controls, ControlsContainer, ControlsRow } from './styles'
import { Props } from './types'

const ProfilingControls: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    onClickPrevious,
    isPreviousDisabled,
    isPreviousVisible,
    onClickNext,
    isNextDisabled,
    isLastQuestion = false,
  } = props

  return (
    <ControlsContainer
      paddingSize="none"
      data-bdd-selector="profilingControls-wrapper"
    >
      <Controls>
        <ControlsRow>
          <Column
            mobile={1}
            tablet={5}
            desktop={11}
            horizontalAlignment="flex-end"
          >
            {isPreviousVisible && (
              <Button
                buttonType={ButtonType.Secondary}
                label="Previous"
                onClick={onClickPrevious}
                disabled={isPreviousDisabled}
                hideTextOnMobile={true}
                iconType="ChevronLeft"
                iconBeforeText={true}
                isButton={true}
                data-bdd-selector="profilingControls-previous-button"
                colorTheme={colorTheme}
              />
            )}
            <Button
              buttonType={ButtonType.Secondary}
              label={isLastQuestion ? 'See Results' : 'Next'}
              onClick={onClickNext}
              disabled={isNextDisabled}
              hideTextOnMobile={true}
              iconType="ChevronRight"
              isButton={true}
              data-bdd-selector="profilingControls-next-button"
              colorTheme={colorTheme}
            />
          </Column>
        </ControlsRow>
      </Controls>
    </ControlsContainer>
  )
}

export default ProfilingControls
