import React from 'react'

import { getTargetWindow } from '../../../../../../utils'
import { Column, Link, Row } from '../../../../atoms'
import { Props } from '../../types'

import {
  Copyright,
  FooterContainer,
  SecondaryLinks,
  SecondaryLinksItem,
  SecondaryLinksRow,
  StyledFooter,
} from './styles'

const FooterSlim: React.FunctionComponent<Props> = (props) => {
  try {
    const {
      data: { secondaryLinks, copyright },
    } = props

    const copyrightText = () => {
      const year = new Date().getFullYear()

      return `\u00A9 ${year} ${copyright}`
    }

    return (
      <FooterContainer paddingSize="none" data-bdd-selector="footer-slim">
        <StyledFooter id="footer">
          <Row>
            <Column>
              <Copyright data-bdd-selector="footer-slim-copyright">
                {copyrightText()}
              </Copyright>
            </Column>
          </Row>
          {secondaryLinks && (
            <SecondaryLinksRow>
              <Column>
                <SecondaryLinks data-bdd-selector="footer-slim-secondary-links">
                  {secondaryLinks.map(({ _id, pageTitle, pageUrl, target }) => (
                    <SecondaryLinksItem key={_id}>
                      <Link route={pageUrl}>
                        <a
                          target={getTargetWindow(target)}
                          data-bdd-selector="footer-slim-secondary-link"
                        >
                          {pageTitle}
                        </a>
                      </Link>
                    </SecondaryLinksItem>
                  ))}
                </SecondaryLinks>
              </Column>
            </SecondaryLinksRow>
          )}
        </StyledFooter>
      </FooterContainer>
    )
  } catch (e) {
    return null
  }
}

export default FooterSlim
