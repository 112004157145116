import { parseISO, startOfDay, isEqual, isSameDay, addMinutes } from 'date-fns'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { ReactSVG } from 'react-svg'

import { Icons } from '../../../Icon/types'
import { InputContainer, Message, HelpText } from '../../styles'
import { TextInputLabel } from '../Text/styles'

import AppointmentPickerReadOnlyInput from './appointmentPickerReadOnlyInput'
import { DatePickerWrapper } from './styles'

export const filterTimeSlotsForDate = (timeSlots: Date[] = [], date: Date) => {
  const ts = timeSlots.filter((timeSlot) => isSameDay(timeSlot, date))
  return ts
}

export const getUniqueTimeSlotsForDay = (timeSlots: Date[] = []) => {
  const uniqueDates = timeSlots
    .map((s) => s.getTime())
    .filter((s, i, a) => a.indexOf(s) == i)
    .map((s) => new Date(s))
  return uniqueDates
}

export const isValidTimeSlot = (timeSlots: Date[] = [], date: Date) => {
  const ts = timeSlots.findIndex((timeSlot) => isEqual(timeSlot, date)) >= 0
  return ts
}

const AppointmentPickerInput = ({
  field,
  form: { touched, errors, dirty, setFieldValue, setFieldTouched },
  colorTheme,
  ...props
}) => {
  const { label, id, helpText, excludedDates, candidates } = props
  const [isFocused, setIsFocused] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  const [timeSlotsForDay, setTimeSlotsForDay] = useState<Date[]>([])
  const timeSlots = candidates
    ? candidates.map((date) => {
        return parseISO(date.startTime)
      })
    : []

  useEffect(() => {
    setTimeSlotsForDay(filterTimeSlotsForDate(timeSlots, selectedDate))
  }, [selectedDate])

  const handleOnFocus = () => {
    setIsFocused(true)
    setFieldTouched(field.name, true, true)
  }

  const handleOnBlur = (e) => {
    setIsFocused(false)
    field.onBlur(e)
  }

  const handleOnChange = (date) => {
    if (isValidTimeSlot(timeSlots, date)) {
      setSelectedDate(date)
      setFieldValue(field.name, date, true)
    } else {
      // if the selection is not valid, reset time i.e. start of day
      // set field value to empty to trigger validation
      setSelectedDate(startOfDay(date))
      setFieldValue(field.name, '', true)
    }
  }

  const excludeDates =
    excludedDates &&
    excludedDates.map((date) => {
      return new Date(date)
    })

  const isTouched = Boolean(touched[field.name])
  const hasErrors = errors[field.name]
  const hasValue = Boolean(field.value)
  const errorsInField = isTouched && hasErrors && errors[field.name]

  const icon =
    (isTouched && hasErrors && 'ExclamationMark') ||
    (isTouched && !hasErrors && 'Tick')

  return (
    <>
      <InputContainer>
        <TextInputLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={isTouched}
          errorsInField={errorsInField}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </TextInputLabel>
        <DatePickerWrapper
          colorTheme={colorTheme}
          data-bdd-selector="input_appointment_picker"
        >
          <DatePicker
            dateFormat={
              isEqual(selectedDate, startOfDay(selectedDate))
                ? 'dd/MM/yyyy'
                : 'dd/MM/yyyy, h:mm a'
            }
            selected={selectedDate}
            onChange={handleOnChange}
            showTimeSelect
            includeTimes={timeSlotsForDay}
            injectTimes={getUniqueTimeSlotsForDay(timeSlotsForDay)}
            includeDates={timeSlots}
            minDate={addMinutes(new Date(), 30)}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            excludeDates={excludeDates}
            id={id}
            popperPlacement="bottom-end"
            className={isFocused && 'focused'}
            disabled={props.disabled}
            timeCaption={null}
            customInput={
              <AppointmentPickerReadOnlyInput
                {...field}
                {...props}
                isTouched={isTouched}
                errorsInField={errorsInField}
                data-bdd-selector={`input_${field.name}`}
                colorTheme={colorTheme}
              />
            }
          />
        </DatePickerWrapper>
        {icon && (
          <ReactSVG
            className="a-icon"
            src={`/static/icons/${icon as Icons}.svg`}
          />
        )}
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {errorsInField}
      </Message>
      {helpText && (
        <HelpText data-bdd-selector={`input_${field.name}_helpText`}>
          {helpText}
        </HelpText>
      )}
    </>
  )
}

export default AppointmentPickerInput
