import * as yup from 'yup'

/*
 * Author: Rodrigo S. Cavalcanti - 17/09/2019
 *
 * Creates an YUP schema based on the form field definitions
 */

type Constructor = yup.BooleanSchema | yup.DateSchema | yup.StringSchema

const resolve = (
  fieldName: string,
  fieldType: string,
  errorCode: string,
  errorDescription: string,
  constructor: Constructor
): Constructor => {
  if (errorCode === 'required') {
    if (fieldType === 'checkbox')
      return (constructor as yup.BooleanSchema).oneOf([true], errorDescription)
    return constructor.required(errorDescription)
  }
  if (
    errorCode.startsWith('<') &&
    (fieldType === 'text' ||
      fieldType === 'phoneNumber' ||
      fieldType === 'email')
  ) {
    const value = parseInt(errorCode.substring(1), 10)
    return (constructor as yup.StringSchema).max(value, errorDescription)
  }
  if (
    errorCode.startsWith('>') &&
    (fieldType === 'text' ||
      fieldType === 'phoneNumber' ||
      fieldType === 'email')
  ) {
    const value = parseInt(errorCode.substring(1), 10)
    return (constructor as yup.StringSchema).min(value, errorDescription)
  }
  if (
    errorCode.startsWith('regex[') &&
    errorCode.endsWith(']') &&
    (fieldType === 'text' ||
      fieldType === 'phoneNumber' ||
      fieldType === 'email')
  ) {
    const value = errorCode.substring(6, errorCode.length - 1)
    return (constructor as yup.StringSchema).matches(
      new RegExp(value),
      errorDescription
    )
  }
  if (errorCode === 'format' && fieldType === 'email') {
    return (constructor as yup.StringSchema).email(errorDescription)
  }
  throw new Error(
    `Unknown errorCode found: ${errorCode} for field: ${fieldName}`
  )
}

export const generateYupSchema = (formFields: any[]) => {
  const yupDef: any = {}

  if (formFields && formFields.length && formFields.length > 0) {
    for (const field of formFields) {
      const validationErrors =
        field.sys && field.type !== 'richTextBlock'
          ? field.validationErrorsCollection.items
          : field.validationErrors
      if (
        validationErrors &&
        validationErrors.length &&
        validationErrors.length > 0
      ) {
        let constructor: Constructor
        switch (field.type) {
          case 'text':
          case 'select':
          case 'email':
            {
              constructor = yup.string().trim()
            }
            break
          case 'time':
            {
              constructor = yup
                .string()
                .trim()
                .matches(
                  /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])(:[0-5]?[0-9])?$/,
                  'Invalid time format'
                )
            }
            break
          case 'date':
            {
              constructor = yup
                .string()
                .trim()
                .matches(
                  /^\d{4}(\-)(((0)[0-9])|((1)[0-2]))(\-)([0-2][0-9]|(3)[0-1])$/,
                  'Invalid date format'
                )
            }
            break
          case 'appointment':
            {
              constructor = yup
                .date()
                .min(new Date(), 'Please choose a date in the future')
            }
            break
          case 'phoneNumber':
            {
              constructor = yup
                .string()
                .trim()
                .matches(
                  /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/,
                  'Please enter a valid UK phone number.'
                )
            }
            break
          case 'checkbox':
            {
              constructor = yup.boolean()
            }
            break
          default:
            throw new Error(`Field type: ${field.type} was not recognized.`)
        }

        for (const validationError of validationErrors) {
          constructor = resolve(
            field.name,
            field.type,
            validationError.errorCode,
            validationError.errorDescription,
            constructor
          )
        }

        yupDef[field.name] = constructor
      }
    }
  }

  return yup.object(yupDef)
}
