import { Fragment, FunctionComponent } from 'react'

import { SkipToContent } from '../../atoms'
import { Footer, Header } from '../../organisms'

import { Main } from './styles'
import { Props } from './types'

/**
 * A template option which provides the header and footer on a page.
 *
 * Currently used in:
 *
 * 1. About
 * 2. AdviserProfile
 * 3. Advisers
 * 4. Home
 *
 * @param children - Required. The child components to be rendered.
 *
 * @returns JSX.Element - TemplateC wrapped around provided child components.
 */
const TemplateA: FunctionComponent<Props> = (props) => {
  const {
    headerData,
    headerSlimData,
    headerType,
    headerIsDark,
    isOnOverlayHero,
    isUsingTransparentHero,
    enableCustomHeaderBackground,
    fixedHeader,
    footerData,
    viewportInfo,
    children,
  } = props

  return (
    <Fragment>
      <SkipToContent />
      <Header
        data={headerType === 'slim' ? headerSlimData : headerData}
        headerType={headerType}
        headerIsDark={headerIsDark}
        isOnOverlayHero={isOnOverlayHero}
        isUsingTransparentHero={isUsingTransparentHero}
        enableCustomHeaderBackground={enableCustomHeaderBackground}
        fixedHeader={fixedHeader}
        viewportInfo={viewportInfo}
      />
      <Main>{children}</Main>
      <Footer data={footerData} />
    </Fragment>
  )
}

export default TemplateA
