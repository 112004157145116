import React from 'react'
import { RichText } from '../../atoms'

import { Item, Wrapper } from './styles'
import { Props } from './types'

/**
 * A function that creates a TrustBar element inline with global styles.
 *
 * @returns StyledComponent - The TrustBar
 */
const TrustBar = ({ columns }: Props) => (
  <Wrapper>
    <div>
      {columns.map(({ richText }, index) => (
        <Item key={index}>
          <RichText html={richText} />
        </Item>
      ))}
    </div>
  </Wrapper>
)

export default TrustBar
