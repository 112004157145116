import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { StyledAnswer, StyledListItem } from './types'

export const ListItem = styled.li<StyledListItem>`
  text-align: ${(props) => props.isLongListItem && 'center'};
  ${(props) =>
    props.isActive
      ? css`
          opacity: 0;
          animation: ${props.theme.animations.fadeIn}
            ${props.theme.transitions.verySlow} ${props.index * 0.2 + 1}s ease
            forwards;
        `
      : css`
          opacity: 1;
        `}
`

export const Answer = styled.span<StyledAnswer>`
  height: 100%;
  display: grid;
  align-items: center;
  background-color: ${(props) =>
    rgba(props.theme.colors.background.white, 0.2)};
  border-radius: 8px;
  padding: 1.6rem;
  transition: background-color 0.3s ease, color 0.3s ease;

  ${(props) =>
    !props.isLongListItem &&
    css`
      @media ${props.theme.breakpoints.tablet} {
        padding: 1.6rem 2.4rem;
      }

      @media ${props.theme.breakpoints.desktop} {
        padding: 1.6rem 4rem;
      }
    `}
`

const AnswerSelectHoverStyle = css`
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) =>
    rgba(props.theme.colors.background.white, 0.35)};
`

const AnswerSelectCheckedStyle = css`
  color: ${(props) => props.theme.colors.text.black};
  background-color: ${(props) => props.theme.colors.background.white};
`

export const Label = styled.label`
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    ${Answer} {
      ${AnswerSelectHoverStyle}
    }
  }
`

export const Input = styled.input.attrs({
  type: 'radio',
})`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:focus,
  &:active,
  &:hover {
    & + ${Answer} {
      ${AnswerSelectHoverStyle}
    }
  }

  &:checked {
    & + ${Answer} {
      ${AnswerSelectCheckedStyle}
    }
  }
`
