import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { RichText } from '../../atoms'

import { StyledDescription, StyledHeadline, StyledTextWrapper } from './types'

export const TextWrapper = styled.div<StyledTextWrapper>`
  color: ${(props) =>
    props.isNested
      ? colorTheme(props.colorTheme, 'contentBlock.textNestedBaseColor')
      : colorTheme(props.colorTheme, 'contentBlock.textBaseColor')};
`

export const Headline = styled(RichText)<StyledHeadline>`
  h2 b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'contentBlock.textAccentThemeColor')};
  }
`

export const Description = styled(RichText)<StyledDescription>`
  margin: 2rem 0 2.8rem;

  b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'contentBlock.textAccentThemeColor')};
  }

  &&& p {
    margin-top: 0;
  }

  &&& i {
    margin-top: 3rem;
    display: block;
  }
`
