import { Wrapper } from './styles'
import { Props } from './types'

/**
 * A component which visually (using CSS styles) hides text, whilst keep it
 * still accessible for screen readers etc.
 *
 * @param props
 * @param props.mobile - Hide text on `mobile`.
 * @param props.tablet - Hide text on `tablet`.
 * @param props.desktop - Hide text on `desktop`.
 * @param props.children - Required. The text string.
 *
 * @returns JSX.Element - The text.
 */
const HiddenText: React.FunctionComponent<Props> = (props) => {
  const { mobile, tablet, desktop, children, all } = props

  return (
    <Wrapper
      mobile={mobile || all}
      tablet={tablet || all}
      desktop={desktop || all}
      data-bdd-selector="hidden-text"
    >
      {children}
    </Wrapper>
  )
}

export default HiddenText
