import styled from 'styled-components'

import { RichText } from '../../atoms'
import ContentBlock from '../ContentBlock'
import {
  Description as ContentBlockDescription,
  Headline as ContentBlockHeadline,
} from '../ContentBlock/styles'

const borderRadius = '1.6rem'

export const StyledContentBlock = styled(ContentBlock)`
  display: grid;
  grid-row: auto;
  row-gap: 2rem;
  padding: 2.6rem 1.4rem 2.4rem 1.4rem;
  border-radius: ${borderRadius} ${borderRadius} 0 0;
  text-align: center;
  color: ${(props) => props.theme.colors.text.black};
  background: ${(props) => props.theme.colors.background.grey1};
  border-bottom: 1px solid ${(props) => props.theme.colors.background.grey4};

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-row: 1;
    padding: 3.4rem 3rem 2.4rem 3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 3.4rem 3.2rem 2.4rem 3.2rem;
  }

  h6 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h4};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h4};
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.body.primary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
    }

    &::before {
      top: 0.8rem;
    }
  }

  ${ContentBlockHeadline} {
    align-self: end;
  }

  ${ContentBlockDescription} {
    margin: 0;
  }
`

export const Description = styled(RichText)`
  margin-bottom: auto;
  padding: 2.8rem 1.4rem 2.8rem;
  height: 100%;
  border-radius: 0 0 ${borderRadius} ${borderRadius};
  grid-row: auto;
  color: ${(props) => props.theme.colors.text.black};
  background: ${(props) => props.theme.colors.background.grey1};

  & + ${StyledContentBlock} {
    margin-top: 3.5rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 4.2rem 3rem 4.2rem;

    & + ${StyledContentBlock} {
      margin-top: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 4.2rem 3.2rem 3.6rem;
  }

  ul {
    margin-top: 0;
  }

  p {
    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
    }
  }
`

export const CaptionWrapper = styled.div`
  margin-top: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 2.4rem;
  }
`

export const Caption = styled(RichText)`
  p {
    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.desktop.footer.primary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.footer.primary};
    }
  }
`
