import * as React from 'react'

import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { EnvConfig } from '../../../../constants/environmentConfig'
import { formQueryParam } from '../../../../utils'
import { Caption } from '../../atoms'

import { Video } from './types'

export const getVideoIdFromEmbedUrl = (embedUrl: string): string | null =>
  embedUrl && embedUrl.includes('embed/')
    ? embedUrl.substr(embedUrl.indexOf('embed/') + 6)
    : null

const YoutubeVideo: React.FunctionComponent<Video> = (props: Video) => {
  const { embedUrl, caption, videoTitle } = props
  const videoId: string | null = getVideoIdFromEmbedUrl(embedUrl)

  const params: Record<any, any> = {
    enablejsapi: 1,
    modestbranding: 1,
    rel: 0,
    autoplay: 0,
    origin: EnvConfig.SPW_UI_SITE_DOMAIN,
  }

  return videoId ? (
    <>
      <LiteYouTubeEmbed
        id={videoId}
        title={videoTitle}
        params={formQueryParam(params)}
        poster="sddefault"
      />
      {caption && caption[0] && <Caption {...caption[0]} />}
    </>
  ) : null
}

export default YoutubeVideo
