import { connect } from 'react-redux'

import { AppState } from '../../../../types'
import { SelectedArticles as SelectedArticlesData } from '../../../../types/selectedArticles'
import { selectedArticlesSelector } from '../../templates/Layout/selectors'
import { SelectedArticles } from './index'

function mapStateToProps(state: AppState, props: SelectedArticlesData) {
  const allArticles = selectedArticlesSelector(state)
  const selectedArticles = allArticles.articleCollection.items
    .filter(
      (article) =>
        !!article.contentThemeTag &&
        article.contentThemeTag.includes(props.contentTheme)
    )
    .slice(0, props.numberOfCards)

  return {
    data: {
      headline: props.headline,
      selectedArticles: {
        items: selectedArticles,
      },
    },
  }
}

export default connect(mapStateToProps)(SelectedArticles)
