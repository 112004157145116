import React, { useEffect, useState } from 'react'
import { Accordion, Icon, Menu } from 'semantic-ui-react'

import { generateUUID } from '../../../../../utils/helpers'
import Link from '../../../atoms/Link'
import { Props } from '../types'

const Mobile: React.FunctionComponent<Props> = (props) => {
  const [panels, setPanels] = useState([])
  const { primaryMenuData } = props
  const hasChildren = (item) =>
    item.footerLevelTwo && item.footerLevelTwo.length > 0

  const handleTopLevelClick = (e, item) => {
    if (item && item.pageUrl) {
      e.stopPropagation()
      window.location.href = item.pageUrl
    }
  }

  const buildLevel1Panels = (menuData) => {
    const result = []
    for (let index = 0; index < menuData.length; index++) {
      const item = menuData[index]
      result.push(buildLevel1Panel(item))
    }
    return result
  }

  const buildLevel1Panel = (item) => {
    const getTitle = () => {
      if (!hasChildren(item)) {
        return {
          content: <Link route={item['pageUrl']}>{item['pageTitle']}</Link>,
          icon: 'none',
        }
      } else {
        return {
          content: (
            <span>
              <a
                onClick={(e) => handleTopLevelClick(e, item)}
                style={{ textDecoration: 'none' }}
              >
                {item['pageTitle']}
              </a>
              <Icon
                name="angle down"
                style={{ float: 'right', marginRight: '0.6em' }}
              />
            </span>
          ),
          icon: 'none',
        }
      }
    }
    const getContent = () => {
      if (hasChildren(item)) {
        return {
          content: buildLevel2Panels(item),
          key: generateUUID(),
        }
      }
    }

    return {
      key: generateUUID(),
      title: getTitle(),
      content: getContent(),
    }
  }
  const buildLevel2Panels = (item) => {
    const panels = []

    for (const child of item.footerLevelTwo) {
      const panel = (
        <Link route={child['pageUrl']}>
          <Menu.Item>{child['pageTitle']}</Menu.Item>
        </Link>
      )
      panels.push(panel)
    }

    return (
      <Accordion.Accordion
        panels={panels}
        style={{ backgroundColor: '#EDEDED', margin: '0' }}
      />
    )
  }

  useEffect(() => {
    setPanels(buildLevel1Panels(primaryMenuData))
  }, [])

  return (
    <Accordion
      as={Menu}
      vertical
      panels={panels}
      style={{
        padding: '0',
        margin: '0',
        fontFamily: 'SchrodersCircular',
        fontSize: '14px',
        width: '100%',
        border: 'none',
        backgroundColor: '#FAFAFA',
      }}
    />
  )
}

export default Mobile
