import styled from 'styled-components'

import { Paragraph } from '../../atoms'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin-bottom: 1.6rem;
  }
`

export const StatisticWrapper = styled.div`
  height: 100%;
  padding: 1.4rem 2.2rem;
  color: ${(props) => props.theme.colors.text.white};
`

export const Statistic = styled(Paragraph)`
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h3};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h3};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h4};
  }
`

export const Description = styled(Paragraph)`
  margin-top: 0 !important;
`
