import styled from 'styled-components'
import { colorTheme } from '../../../../styles/utils'

import { RichText } from '../../atoms'

import { TextHighlightStyles } from './types'

export const HighlightRichText = styled(RichText)<TextHighlightStyles>`
  background-color: ${(props) =>
    colorTheme(props.colorTheme, 'textHighlight.backgroundThemeColor')};
  border-radius: 0.5rem;
  padding: 3rem 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 4rem;
  }

  h2,
  h2 b,
  h3,
  h3 b,
  p,
  p b,
  ul li {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'textHighlight.textThemeColor')};
  }

  a:focus,
  a:active,
  a:hover {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'textHighlight.textAccentThemeColor')};
  }

  ul {
    margin: 0;
    padding-left: 2.1rem;

    li::before {
      height: 0.6rem;
      width: 0.6rem;
      top: 1rem;
      left: -2.1rem;
      background-image: none;
      background-color: ${(props) => props.theme.colors.background.grey2};
    }
  }

  hr {
    display: block;
    padding: 1.2rem 0;
    margin: 0;
    border: transparent;
  }
`
