import styled from 'styled-components'

import { Carousel } from '../'
import { Button } from '../../atoms'
import { ArticleCard } from '../../atoms'

export const StyledCarousel = styled(Carousel)`
  & .slick-list {
    padding: 30px 0;
  }

  & .slick-list {
    margin: 0 -0.8rem;

    @media ${(props) => props.theme.breakpoints.desktop} {
      margin: 0 -1.2rem;
    }
  }

  & .slick-slide {
    box-sizing: border-box;
    padding: 0 0.8rem;

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding: 0 1.2rem;
    }
  }
`

export const StyledArticleCard = styled(ArticleCard)`
  margin: auto;
  height: 30.6rem;
  text-align: left;
`

export const StyledButton = styled(Button)`
  margin-top: 4rem;
`
