import React from 'react'

const TrustPilotTrustBoxMini = () => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5e5670de7116b20001234cce"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="dark"
      ref={ref}
    >
      <a
        href="https://www.trustpilot.com/review/spw.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}
export default TrustPilotTrustBoxMini
