import React from 'react'

import { FeaturedArticleCard } from '../../atoms'

import { FeaturedCardItem, Wrapper } from './styles'
import { Props } from './types'

const FeaturedCards: React.FunctionComponent<Props> = (props) => {
  const { cards } = props

  return (
    <Wrapper data-bdd-selector="featured-cards">
      {cards.map((card, index) => (
        <FeaturedCardItem key={index}>
          <FeaturedArticleCard
            {...card}
            imageSize={index === 0 ? 'medium' : 'small'}
          />
        </FeaturedCardItem>
      ))}
    </Wrapper>
  )
}

export default FeaturedCards
