import { DotGroup } from 'pure-react-carousel'
import 'semantic-ui-css/components/icon.min.css'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { palette } from '../../../../../../styles/theme/colors'

const { white, blue, blue2 } = palette

export const StyledSlideContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`

export const StyledIcon = styled(Icon)`
  color: ${blue2};
`

export const CarouselButtonGroup = styled(DotGroup)`
  display: flex;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    justify-content: left;
  }

  button {
    height: 12px;
    width: 12px;
    padding-bottom: 12px;
    border-radius: 50%;
    border-width: 2px;
    border-color: ${white};
    background-color: ${white};
    margin-right: 25px;
  }

  button:disabled {
    background-color: ${blue};
  }
`
