import styled from 'styled-components'

export const Button = styled.div`
  display: inline-block;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
  margin-left: -1rem;
  align-items: center;

  & > ${Button} {
    margin-top: 1rem;
    margin-left: 1rem;
  }
`
