import { Component } from 'react'

import { Input } from '../../styles'
import { StyledInputProps } from '../../types'

export default class DatePickerReadOnlyInput extends Component<
  StyledInputProps,
  {}
> {
  render() {
    return <Input {...this.props} readOnly={true} />
  }
}
