import { format, parseISO } from 'date-fns'
import React from 'react'
import { useDispatch } from 'react-redux'
import { logEvent } from '../../../../utils'
import { Button } from '../../atoms'
import { ButtonType } from '../../atoms/Button/types'
import { CallToAction } from '../../molecules'
import { CallToActionData } from '../../molecules/CallToAction/types'
import { StyledModalHeader } from '../CallbackFormModal/styles'
import { actions } from './actions'

import {
  AppointmentDetailsDate,
  AppointmentDetailsTime,
  AppointmentWrapper,
  BorderWrapper,
  ButtonWrapper,
  ColumnHeading,
  ContentWrapper,
  OuterWrapper,
  Headline,
  Message,
  StyledReactSVG,
  SubHeadline,
} from './styles'
import { CallbackResultData, Props } from './types'

const splitTextOnPunctuation = (text = '') => text.split(/([^.?!:;]+[.?!:;]*)/g)

const formatHeadline = (headline: string) => {
  const segments = splitTextOnPunctuation(headline).filter(
    (segment) => !!segment
  )
  return segments.map((segment, i) => {
    const isEven = (i + 1) % 2 === 0
    return (
      <span key={segment} className={isEven ? 'even' : 'odd'}>
        {segment}
      </span>
    )
  })
}

export const CallbackFormResult = (props: Props) => {
  const dispatch = useDispatch()

  const formatDateTime = (date, formatType) => {
    if (date) {
      const value = parseISO(date)
      return format(value, formatType)
    }
    return ''
  }

  const { successPageData, errorPageData, formInput, success } = props

  let formDate, formTime

  if (formInput.hasOwnProperty('Appointment_Date_Time__c')) {
    formDate = formatDateTime(formInput.Appointment_Date_Time__c, 'dd MMM')
    formTime = formatDateTime(
      formInput.Appointment_Date_Time__c,
      "hh:mm aaaaa'm'"
    )
  } else {
    formDate = formatDateTime(formInput.Appointment_Date__c, 'dd MMM')
    formTime =
      formInput.Appointment_Date__c && formInput.Appointment_Time__c
        ? formatDateTime(
            `${formInput.Appointment_Date__c} ${formInput.Appointment_Time__c}`,
            "hh:mm aaaaa'm'"
          )
        : null
  }

  let data: CallbackResultData
  let icon: string

  /* istanbul ignore next */
  const closeHandler = () => {
    dispatch(actions.close())
  }

  if (success) {
    icon = '/static/images/success-tick-line.svg'
    data = successPageData
    logEvent({
      category: 'User interactions',
      action: 'Forms',
      label: 'Call back form - success',
      value: 1,
    })
  } else {
    icon = '/static/images/error-compass.svg'
    data = errorPageData
    logEvent({
      category: 'User interactions',
      action: 'Forms',
      label: 'Call back form - error',
      value: 0,
    })
  }

  const action: CallToActionData = {
    ...data.callToActions[0],
    clickHandler: (e) => {
      e.preventDefault()
      props.clickHandler(e)
    },
  }
  return (
    <OuterWrapper as="main">
      <StyledModalHeader colorTheme="rocketboy">
        <Button
          buttonType={ButtonType.Close}
          onClick={closeHandler}
          data-bdd-selector="callback-form-result-modal-close-button"
          label="Close"
          iconType="Close"
          analyticsAction="close form"
          analyticsCategory="callback form"
        />
      </StyledModalHeader>
      <ContentWrapper>
        <StyledReactSVG
          src={icon}
          data-bdd-selector="callback-form-result-icon"
        />
        <Headline data-bdd-selector="callback-form-result-headline">
          {formatHeadline(data.headline)}
        </Headline>
        <SubHeadline data-bdd-selector="callback-form-result-sub-headline">
          {data.subHeadline}
        </SubHeadline>
        {success && (
          <BorderWrapper>
            <AppointmentWrapper>
              <AppointmentDetailsDate>
                <ColumnHeading>Date</ColumnHeading>
                <div data-bdd-selector="callback-form-result-date">
                  {formDate}
                </div>
              </AppointmentDetailsDate>
              <AppointmentDetailsTime>
                <ColumnHeading>Time</ColumnHeading>
                <div data-bdd-selector="callback-form-result-time">
                  {formTime}
                </div>
              </AppointmentDetailsTime>
            </AppointmentWrapper>
          </BorderWrapper>
        )}
        <Message data-bdd-selector="callback-form-result-message">
          {data.description}
        </Message>
        <Message
          dangerouslySetInnerHTML={{ __html: data.instructions }}
        ></Message>
        <ButtonWrapper>
          <CallToAction
            {...action}
            data-bdd-selector="callback-form-result-button"
          />
        </ButtonWrapper>
      </ContentWrapper>
    </OuterWrapper>
  )
}

export default CallbackFormResult
