import { FunctionComponent } from 'react'

import { Accordion, CallToAction, ContentBlock } from '../../molecules'

import {
  Wrapper,
  ContentWrapper,
  AccordionWrapper,
  ButtonWrapper,
} from './styles'
import { Props } from './types'

const AccordionBanner: FunctionComponent<Props> = (props) => {
  const {
    data: { contentBlock, accordionItems, callToAction },
    colorTheme,
  } = props

  return (
    <>
      <Wrapper data-bdd-selector="accordion-banner">
        <ContentWrapper>
          {contentBlock && contentBlock.length > 0 && (
            <ContentBlock
              colorTheme={colorTheme}
              data={contentBlock[0]}
              isNested={true}
            />
          )}
        </ContentWrapper>
        {accordionItems && (
          <AccordionWrapper>
            <Accordion data={{ accordionItems }} colorTheme={colorTheme} />
          </AccordionWrapper>
        )}
      </Wrapper>
      {callToAction && (
        <ButtonWrapper>
          <CallToAction {...callToAction} />
        </ButtonWrapper>
      )}
    </>
  )
}

export default AccordionBanner
