import { FunctionComponent } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'

import {
  RangeLabel,
  RangeLabelsWrapper,
  StyledSlider,
  ThumbStripe,
  ThumbWrapper,
  Wrapper,
} from './styles'
import { Props } from './types'

const Slider: FunctionComponent<Props> = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    className,
    min = 0,
    max = 100,
    value,
    onChange,
    step,
    'data-bdd-selector': dataBddSelector,
    minLabel = min,
    maxLabel = max,
  } = props

  return (
    <Wrapper className={className} data-bdd-selector={dataBddSelector}>
      <StyledSlider
        value={value}
        onChange={onChange}
        step={step}
        min={min}
        max={max}
        trackClassName="slider-track"
        colorTheme={colorTheme}
        renderThumb={(_props) => (
          <ThumbWrapper {..._props} colorTheme={colorTheme}>
            <ThumbStripe />
            <ThumbStripe />
            <ThumbStripe />
          </ThumbWrapper>
        )}
      />
      <RangeLabelsWrapper>
        <RangeLabel data-bdd-selector={`${dataBddSelector}-range-label-min`}>
          {minLabel}
        </RangeLabel>
        <RangeLabel data-bdd-selector={`${dataBddSelector}-range-label-min`}>
          {maxLabel}
        </RangeLabel>
      </RangeLabelsWrapper>
    </Wrapper>
  )
}

export default Slider
