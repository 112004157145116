import * as React from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { HighlightRichText } from './styles'
import { Props } from './types'

const TextHighlight: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: { description },
    className,
    useTicksForBullets,
  } = props

  return (
    <HighlightRichText
      colorTheme={colorTheme}
      html={description}
      className={className}
      useTicksForBullets={useTicksForBullets}
    />
  )
}

export default TextHighlight
