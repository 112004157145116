import React from 'react'

import { StyledModal, StyledModalContent } from './styles'
import { Props } from './types'

const Modal: React.FunctionComponent<Props> = (props) => {
  const { show, children, size } = props
  return (
    <StyledModal open={show} size={size}>
      <StyledModalContent>{children}</StyledModalContent>
    </StyledModal>
  )
}

export default Modal
