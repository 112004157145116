import { useDispatch } from 'react-redux'

import { HiddenText, Link, Logo } from '../../../../atoms'
import { ButtonType } from '../../../../atoms/Button/types'
import { CallToAction } from '../../../../molecules'
import { actions as callbackFormActions } from '../../../../organisms/CallbackFormModal/actions'

import {
  HeaderColumnCenter,
  HeaderColumnLeft,
  HeaderColumnRight,
  HeaderContainer,
  HeaderLogo,
  HeaderWrapper,
} from './styles'
import { Props } from './types'

const BackToHomeButton = (props) => {
  const { data } = props

  return (
    <CallToAction
      {...data[0]}
      iconBeforeText={true}
      hideTextOnMobile={true}
      data-bdd-selector="header-slim-back-to-home-link"
    />
  )
}

const ContactUsButton = (props) => {
  const { data, iconVisible, openCallbackFormModal } = props

  return (
    <CallToAction
      {...data[0]}
      iconVisible={iconVisible}
      hideTextOnMobile={true}
      hideIconOnDesktop={true}
      clickHandler={openCallbackFormModal}
      buttonType={ButtonType.Primary}
      data-bdd-selector="header-slim-callback-button"
    />
  )
}

const HeaderSlim: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()
  const {
    data: { backToHomeAction, callbackModalAction },
    imageUrl,
    headerIsDark,
    viewportInfo: { equals },
    invert,
  } = props

  const showCallbackForm = () => {
    dispatch(callbackFormActions.showCallbackFormModal())
  }

  return (
    <HeaderWrapper headerIsDark={headerIsDark} data-bdd-selector="header-slim">
      <HeaderContainer paddingSize="none">
        <HeaderColumnLeft>
          {backToHomeAction && <BackToHomeButton data={backToHomeAction} />}
        </HeaderColumnLeft>
        <HeaderColumnCenter>
          <HeaderLogo>
            <Link route="/" data-bdd-selector="header-slim-logo">
              <Logo
                invert={invert || false}
                useColorTheme={false}
                imageData={imageUrl}
              />
              <HiddenText all={true}>Home page url</HiddenText>
            </Link>
          </HeaderLogo>
        </HeaderColumnCenter>
        <HeaderColumnRight>
          {callbackModalAction && (
            <ContactUsButton
              data={callbackModalAction}
              openCallbackFormModal={showCallbackForm}
              iconVisible={equals === 'mobile'}
            />
          )}
        </HeaderColumnRight>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default HeaderSlim
