import styled, { css } from 'styled-components'

import {
  StyledLoadingSpinnerAnimationSegment,
  StyledLoadingSpinnerContainer,
} from './types'

export const LoadingSpinnerAnimationSegment = styled.span<StyledLoadingSpinnerAnimationSegment>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid ${(props) => props.theme.colors.background.white};
  border-radius: 50%;
  animation: ${(props) => props.theme.animations.rotate360}
    ${(props) => props.theme.transitions.verySlow} cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: ${(props) =>
      props.isDark
        ? props.theme.colors.text.grey2
        : props.theme.colors.text.white}
    transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: 500ms;
  }

  &:nth-child(2) {
    animation-delay: 450ms;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }
`

export const LoadingSpinnerContainer = styled.div<StyledLoadingSpinnerContainer>`
  width: ${(props) => (props.isSmall ? '2rem' : '3rem')};
  height: ${(props) => (props.isSmall ? '2rem' : '3rem')};
  position: relative;

  ${(props) =>
    props.isSmall &&
    css`
      ${LoadingSpinnerAnimationSegment} {
        border-width: 2px;
      }
    `}
`
