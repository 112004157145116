import React, { FunctionComponent, useEffect, useState } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { getFee } from '../../../../utils/helpers'

import {
  AverageFeeDetailsWrapper,
  AverageFeeExpandButton,
  AverageFeeExpandButtonIcon,
  ExpandableArea,
  FeeInfo,
  FeeInfoDescription,
  FeeInfoPrice,
  FeeInfoPriceDescription,
  FeeInfoTitle,
  RootWrapper,
  Title,
  Wrapper,
} from './styles'
import { FeeRate, Props } from './types'

const PricingDetailBlock: FunctionComponent<Props> = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    title,
    className,
    light,
    data: {
      heading,
      description,
      feeRates,
      averageFeeDetails,
      averageFeeDetailsText,
      monthlyFee,
      deductedFromFund,
      showFeePercentage = false,
    },
    investmentValue,
  } = props

  const [currentRate, setCurrentRate] = useState<FeeRate>()
  const [averageFeeExpanded, setAverageFeeExpanded] = useState(false)

  useEffect(() => {
    if (!feeRates || !investmentValue) {
      return
    }

    const newRate = feeRates.find((rate) => {
      const max = rate.maximumAmount || parseFloat(investmentValue)
      const min = rate.minimumAmount || 0

      return (
        parseFloat(investmentValue) >= min && parseFloat(investmentValue) <= max
      )
    })

    setCurrentRate(newRate)
  }, [investmentValue, feeRates])

  const fee = (
    getFee(
      parseFloat(investmentValue),
      0,
      parseFloat(investmentValue),
      feeRates,
      currentRate
    ) / (monthlyFee ? 12 : 1)
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const feePercentageStr = `: ${
    (currentRate && currentRate.feePercentage) || 0
  }%`

  const getDescription = (): string => {
    if (deductedFromFund) return 'Deducted from within the fund'
    return monthlyFee ? 'per month' : 'one-off fee'
  }

  return (
    <RootWrapper
      className={className}
      averageAreaExpanded={averageFeeExpanded}
      light={light}
      data-bdd-selector="pricing-detail-block"
    >
      <Wrapper>
        {title && <Title>{title}</Title>}
        <FeeInfo>
          <FeeInfoTitle data-bdd-selector="pricing-detail-block-title">
            {`${heading}${showFeePercentage ? feePercentageStr : ''}`}
          </FeeInfoTitle>
          <FeeInfoPrice data-bdd-selector="pricing-detail-block-price">
            {`£${fee}`}
          </FeeInfoPrice>
          <FeeInfoDescription data-bdd-selector="pricing-detail-block-description">
            {description}
          </FeeInfoDescription>
          <FeeInfoPriceDescription data-bdd-selector="pricing-detail-block-price-description">
            {getDescription()}
          </FeeInfoPriceDescription>
        </FeeInfo>

        {averageFeeDetailsText && (
          <AverageFeeExpandButton
            onClick={() => setAverageFeeExpanded(!averageFeeExpanded)}
            colorTheme={colorTheme}
            data-bdd-selector="pricing-detail-block-expand-button"
          >
            <span>{averageFeeDetailsText}</span>
            <AverageFeeExpandButtonIcon
              src="static/icons/ChevronRightNoViewBox.svg"
              wrapper="span"
            />
          </AverageFeeExpandButton>
        )}
      </Wrapper>

      {averageFeeDetails && (
        <ExpandableArea
          averageAreaExpanded={averageFeeExpanded}
          data-bdd-selector="pricing-detail-block-expandable-area"
        >
          <AverageFeeDetailsWrapper>
            {averageFeeDetails.map((detail, index) => (
              <PricingDetailBlock
                className="average-fee-detail"
                key={index}
                light={true}
                data={detail}
                investmentValue={investmentValue}
              />
            ))}
          </AverageFeeDetailsWrapper>
        </ExpandableArea>
      )}
    </RootWrapper>
  )
}

export default PricingDetailBlock
