import React from 'react'

import { Column, Container, Row } from '../../../../atoms'
import { Wrapper } from '../../styles'
import BaseTestimonial from '../BaseTestimonial'

export default class Testimonial extends React.Component {
  render() {
    const { items } = this.props
    return (
      <Wrapper>
        <Container>
          <Row>
            <Column tablet={[5, 3]} desktop={[7, 5]}>
              <BaseTestimonial items={items} />
            </Column>
          </Row>
        </Container>
      </Wrapper>
    )
  }
}
