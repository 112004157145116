import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Link } from '../../atoms/Button/styles'

import { StyledNav, StyledNavLink as StyledLink, StyledListItem } from './types'

export const Nav = styled.nav<StyledNav>`
  font-size: ${(props) => props.theme.fontSizes.mobile.navigation.primary};
  box-sizing: border-box;

  .ui.menu .ui.dropdown .menu > .item {
    color: white !important;
    background-color: #141414 !important;
  }

  .ui.secondary.menu .item {
    border-radius: 0px !important;
  }

  .ui.secondary.menu .dropdown.item > .menu {
    border-radius: 0px !important;
    margin-top: -1px !important;
  }

  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    visibility: ${(props) => !props.isOpen && 'hidden'};
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    padding: 2rem 0;
    background-color: ${(props) => props.theme.colors.background.white};
    color: ${(props) => props.theme.colors.text.black};
    z-index: 1;
    transform: ${(props) =>
      props.isOpen ? 'translateX(-100%)' : 'translateX(0)'};
    transition: transform ${(props) => props.theme.transitions.slow} ease,
      visibility 0s
        ${(props) => (props.isOpen ? '0s' : props.theme.transitions.slow)};
  }

  @media ${(props) => props.theme.breakpoints.desktopMedium} {
    font-size: ${(props) => props.theme.fontSizes.tablet.navigation.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktopMedium} {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: ${(props) => props.theme.fontSizes.desktop.navigation.primary};
    color: ${(props) => {
      if (props.invert) {
        return props.useColorTheme
          ? colorTheme(props.colorTheme, 'header.linkBaseColor')
          : props.theme.colors.ui.white
      }
      if (props.hasCustomHeaderBG) {
        return props.theme.colors.ui.white
      }
      return props.theme.colors.ui.blue2
    }};

    transition: color ${(props) => props.theme.transitions.normal} ease;
  }
`

export const NavControls = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2.4rem;
  margin-bottom: 1.5rem;

  @media ${(props) => props.theme.breakpoints.desktopMedium} {
    display: none;
  }
`

export const NavLogoWrapper = styled.div`
  display: flex;
`

export const NavActionsWrapper = styled.div`
  margin: 0 1.2rem;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const NavMenuButtonWrapper = styled.div`
  display: flex;
`

const NavMenuCrossLine = css`
  content: '';
  height: 2rem;
  width: 0.25rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: ${(props) => props.theme.colors.background.blue2};
`

export const NavMenuCross = styled.span`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  transition: transform ${(props) => props.theme.transitions.moderate} ease;

  &::before,
  &::after {
    ${NavMenuCrossLine}
    transition: transform ${(props) => props.theme.transitions.moderate} ease;
  }

  &::before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
`

export const NavMenuButton = styled.button`
  border: none;
  border-radius: 100%;
  height: 3.4rem;
  width: 3.4rem;
  cursor: pointer;
  background-color: ${(props) =>
    rgba(props.theme.colors.background.white, 0.1)};
  transition: background-color ${(props) => props.theme.transitions.normal} ease;

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) =>
      rgba(props.theme.colors.background.blue2, 0.15)};

    ${NavMenuCross} {
      transform: rotate(90deg);

      &::before {
        transform: translate3d(-50%, -50%, 0) rotate(45deg) scaleY(1.2);
      }

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg) scaleY(1.2);
      }
    }
  }
`

export const NavPhoneNumber = styled.div`
  a {
    display: block;
    padding: 1.3rem 0;
    font-size: ${(props) => props.theme.fontSizes.mobile.button.primary};
  }
`

export const PrimaryList = styled.ul``

export const ListItem = styled.li<StyledListItem>`
  a:hover {
    text-decoration: underline;
  }

  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    padding: 0 2.4rem;
    ${(props) =>
      props.hasHighlight &&
      css`
        background-color: ${props.theme.colors.background.grey3};
      `}
  }

  @media ${(props) => props.theme.breakpoints.desktopMedium} {
    display: inline-block;

    a:hover {
      text-decoration: none;
    }

    & + & {
      margin-left: 2.1rem;
    }
  }

  ${Link} {
    display: block;
    padding: 1.3rem 0;
    font-size: ${(props) => props.theme.fontSizes.mobile.button.primary};
    color: inherit;
    font-weight: normal;

    &::after {
      content: none;
    }

    svg {
      width: 1.6rem;
      margin-right: 1.2rem;
    }
  }
`

export const SecondaryList = styled.ul`
  a[href^='tel:'] {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`

export const NavLogo = styled.div`
  cursor: pointer;
  width: 13rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 16rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 19rem;
  }
`

export const StyledTopLevelNavItem = styled.div`
  position: relative;
  padding: 1rem 1.3rem;
  margin: 0 0.5rem;
  background: inherit;
  cursor: pointer;
  color: inherit;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-size: ${(props) => props.theme.fontSizes.desktop.anchorNavLink.primary};
  text-align: center;
  background: inherit;
  transition: none;
  border-bottom: 1px solid;
  border-color: transparent;

  &.active {
    transition: border-bottom ${(props) => props.theme.transitions.slow} ease;
    border-color: inherit;
  }
`

export const StyledNavLink = styled.a<StyledLink>`
  border-bottom: none;
  text-decoration: none;

  &:visited,
  &:hover,
  &:active {
    color: ${(props) => {
      if (props.invert) {
        return props.useColorTheme
          ? colorTheme(props.colorTheme, 'header.linkBaseColor')
          : props.theme.colors.ui.white
      }
      if (props.hasCustomHeaderBG) {
        return props.theme.colors.ui.white
      }
      return props.theme.colors.ui.blue2
    }};
  }

  &.dropdown-link {
    padding: 0.8rem 1.6rem;
    display: block;
    text-align: left;
    width: 100%;

    &:visited {
      color: inherit;
    }

    &:active,
    &:hover {
      color: ${(props) => props.theme.colors.text.blue};
    }
  }
`

export const StyledDropdownWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);

  &.no-content-block {
    position: absolute;
  }
`

export const StyledNavDropdown = styled.div`
  background-color: ${(props) => props.theme.colors.background.grey10};
  color: ${(props) => props.theme.colors.text.white};
  width: max-content;
  display: flex;
  flex-direction: row;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 3rem;
`

export const StyledDropdownClickbox = styled.span`
  height: 3.5rem;
  width: 25rem;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

export const StyledDropdownChevron = styled.span`
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 18px solid ${(props) => props.theme.colors.background.grey10};
`

export const StyledDropdownContentBlock = styled.div`
  width: 65rem;
  background-color: ${(props) => props.theme.colors.background.black};
  border: solid white;
  border-width: 0 0 0 0.1rem;
  padding: 4rem;
  text-align: left;

  h3 {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.8;
    padding-bottom: 1.2rem;
  }
`

export const StyledDropdownLinksList = styled.ul`
  width: 30rem;
  padding: 2rem;
`
