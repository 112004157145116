import Head from 'next/head'
import { Fragment, FunctionComponent } from 'react'
import { Container, DividerLine } from '../../atoms'
import { Footer, Header } from '../../organisms'

import { Main, PageStyles, Wrapper, FooterWrapper } from './styles'
import { Props } from './types'

/**
 * A template option which provides a variant header and no footer on a page,
 * with a purple background.
 *
 * Currently used in:
 *
 * 1. Profiling Tool
 *
 * @param children - Required. The child components to be rendered.
 *
 * @returns JSX.Element - TemplateC wrapped around provided child components.
 */
const TemplateC: FunctionComponent<Props> = (props) => {
  const {
    headerSlimData,
    headerType,
    headerIsDark,
    footerData,
    viewportInfo,
    children,
  } = props

  return (
    <Fragment>
      <Head>
        <style>{PageStyles}</style>
      </Head>
      <Wrapper>
        <Header
          data={headerSlimData}
          headerType={headerType}
          headerIsDark={headerIsDark}
          viewportInfo={viewportInfo}
        />
        <Main>{children}</Main>
        <FooterWrapper>
          <DividerLine />
          <Container>
            <Footer data={footerData} footerType="slim" />
          </Container>
        </FooterWrapper>
      </Wrapper>
    </Fragment>
  )
}

export default TemplateC
