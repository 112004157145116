import styled from 'styled-components'
import { colorTheme } from '../../../../styles/utils'
import { PrimaryButton } from '../../atoms/Button/styles'
import { StyledWrapper, StyledPhoneNumber } from './types'

export const Wrapper = styled.div<StyledWrapper>`
  display: flex;

  ${PrimaryButton} {
    min-height: 3.4rem;
    min-width: 3.4rem;
    padding: 0 1.6rem;
    margin-left: 1rem;
    font-size: ${(props) => props.theme.fontSizes.mobile.button.primary};

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.button.primary};
    }
  }
`

export const PhoneNumber = styled.div<StyledPhoneNumber>`
  position: relative;
  margin-left: 0.8rem;
  color: ${(props) => {
    if (props.invert) {
      return props.useColorTheme
        ? colorTheme(props.colorTheme, 'header.linkBaseColor')
        : props.theme.colors.ui.white
    }
    if (props.hasCustomHeaderBG) {
      return props.theme.colors.ui.white
    }
    return props.theme.colors.ui.blue2
  }};

  p {
    font-size: ${(props) => props.theme.fontSizes.mobile.navigation.primary};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 3.4rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.desktop.navigation.primary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.navigation.primary};
    }
  }

  a {
    display: inline-block;
    padding-left: 2.6rem;
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    height: 3.4rem;
    width: 3.4rem;

    color: ${(props) =>
      props.useColorTheme && props.invert
        ? colorTheme(
            props.colorTheme,
            'callToAction.primary.active.backgroundThemeColor'
          )
        : colorTheme(
            props.colorTheme,
            'callToAction.primary.active.textThemeColor'
          )};

    background-color: ${(props) =>
      props.useColorTheme && props.invert
        ? colorTheme(
            props.colorTheme,
            'callToAction.primary.active.textThemeColor'
          )
        : colorTheme(
            props.colorTheme,
            'callToAction.primary.active.backgroundThemeColor'
          )};

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export const Icon = styled.span`
  position: absolute;
  top: 0.5rem;
  left: 0.3rem;
  z-index: -1;
  fill: currentColor;
  transition: fill ${(props) => props.theme.transitions.normal} ease;

  svg {
    width: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    top: 0.4rem;
    left: 0.9rem;
    z-index: 0;
  }
`
