import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Column as BaseColumn, Container as BaseContainer } from '../../atoms'
import { Button, ButtonWrapper } from '../../molecules/CallToActionGroup/styles'

import {
  StyledButtonListWrapper,
  StyledImageWrapper,
  StyledWrapper,
  StyledColumn,
} from './types'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

// Overline base styling
export const Overline = styled.div`
  margin-bottom: 2.4rem;

  h6 {
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    letter-spacing: 0.2rem;
    font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
    }
  }
`

// Headline base styling
export const Headline = styled.div`
  h2 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h3};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
    }
  }
`

// Description base styling
export const Description = styled.div`
  margin-top: 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.5rem;

    p {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
    }
  }
`

export const Column = styled(BaseColumn)``

export const TextColumn = styled(BaseColumn)<StyledColumn>`
  order: 1;
`

export const MediaColumn = styled(BaseColumn)<StyledColumn>`
  order: 2;

  ${(props) =>
    props.mediaAlignment === 'left' &&
    css`
      @media ${props.theme.breakpoints.desktop} {
        order: 1;
      }
    `}
`

// Container base styling
export const Container = styled(BaseContainer)``

export const ImageWrapper = styled.div<StyledImageWrapper>`
  order: 2;
  border-radius: 8px;
  overflow: hidden;
`

export const Content = styled.div`
  position: relative;

  ${(props) => css`
    @media ${props.theme.breakpoints.tabletMax} {
      margin-bottom: 3.3rem;
    }

    @media ${props.theme.breakpoints.desktop} {
      padding: 2rem 0;
    }
  `}
`

export const ButtonListWrapper = styled.div<StyledButtonListWrapper>`
  margin-top: 2rem;

  ${ButtonWrapper} {
    margin: 0;

    & > ${Button} {
      margin: 0.8rem;
      box-sizing: border-box;

      &:first-child {
        margin-left: 0;
      }
    }

    ul {
      li {
        &:before {
          width: 2.4rem;
          height: 2.4rem;
          background: no-repeat url('static/icons/tick-white.svg');
          background-color: ${(props) =>
            colorTheme(
              props.colorTheme,
              'featureBlock.listItemAccentThemeColor'
            )};
          background-size: 2rem;
          background-position: 0.2rem;
          top: -0.2rem;
        }
        p {
          margin-left: 1.6rem;
        }
      }
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    h2 {
      margin: 1.7rem 0 3rem;
    }

    .description {
      margin: 3rem auto 0;
    }

    ul {
      li {
        &:before {
          width: 3rem;
          height: 3rem;
          background-size: 2.4rem;
          background-position: 0.3rem;
        }

        & + li {
          margin-top: 1.5rem;
        }

        p {
          margin-left: 2rem;
        }
      }
    }
  }
`

// ComponentWrapper - theme children using props
export const ComponentWrapper = styled.section<StyledWrapper>`
  position: relative;
  padding: ${(props) => (props.boxed ? '2rem 0' : '6rem 0')};

  ${(props) =>
    props.boxed &&
    css`
      &:first-of-type:not(:last-of-type) {
        padding-top: 2rem;
      }

      &:last-of-type:not(:first-of-type) {
        padding-bottom: 4rem;
      }
    `}

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: ${(props) => (props.boxed ? '5rem 0' : '6rem 0')};

    ${(props) =>
      props.boxed &&
      css`
        &:first-of-type:not(:last-of-type) {
          padding-top: 4rem;
        }

        &:last-of-type:not(:first-of-type) {
          padding-bottom: 10rem;
        }
      `}
  }

  background: ${(props) =>
    colorTheme(
      props.colorTheme,
      props.boxed
        ? 'featureBlock.boxed.backgroundThemeColor'
        : 'featureBlock.backgroundThemeColor'
    )};

  ${Column} {
    ${(props) =>
      !props.boxed &&
      css`
        padding: 0;
      `}
  }

  ${MediaColumn} {
    ${(props) =>
      props.boxed &&
      css`
        ${(props) =>
          !props.hasVideo
            ? css`
                padding: 0;
                @media ${(props) => props.theme.breakpoints.tabletMax} {
                  width: 100%;
                  max-width: 100%;
                  flex-basis: 100%;
                  margin: 0;
                }
              `
            : css`
                @media ${(props) => props.theme.breakpoints.mobileMax} {
                  padding: 0 2rem;
                }

                @media ${(props) => props.theme.breakpoints.tabletMax} {
                  padding-bottom: 3rem;
                }
              `}
      `}
  }

  ${ImageWrapper} {
    ${(props) =>
      props.boxed &&
      css`
        overflow: hidden;
        border-radius: 0;

        img {
          position: absolute;
          height: 100%;
          top: 0;
          right: 0;
          object-fit: cover;
        }

        @media ${(props) => props.theme.breakpoints.mobileMax} {
          position: relative;
          left: -0.5rem;
          width: calc(100vw - 2rem);
          height: calc(100vw - 4.8rem);
        }

        @media ${(props) => props.theme.breakpoints.tablet} {
          height: 61.62vw;
        }

        @media ${(props) => props.theme.breakpoints.desktop} {
          width: 100%;
          height: 100%;
        }
      `}
  }

  ${ContentWrapper} {
    ${(props) =>
      props.boxed &&
      css`
        border-radius: 1.6rem;
        overflow: hidden;

        ${(props) =>
          props.hasVideo &&
          css`
            background: ${(props) =>
              colorTheme(
                props.colorTheme,
                'featureBlock.boxed.contentBackgroundThemeColor'
              )};
          `}
      `}
  }

  ${TextColumn} {
    ${(props) =>
      props.boxed &&
      css`
        @media ${(props) => props.theme.breakpoints.tabletMax} {
          flex-basis: 100%;
          max-width: 100%;
        }

        background: ${(props) =>
          colorTheme(
            props.colorTheme,
            'featureBlock.boxed.contentBackgroundThemeColor'
          )};
      `}
  }

  ${Content} {
    ${(props) =>
      props.boxed &&
      css`
        margin-bottom: 0;
        padding: 3rem 1rem;

        @media ${(props) => props.theme.breakpoints.tablet} {
          padding: 4rem 2rem;
        }

        @media ${(props) => props.theme.breakpoints.desktop} {
          padding: 6.5rem 4rem;
        }
      `}

    @media ${(props) => props.theme.breakpoints.tabletMax} {
      text-align: left;
    }
  }

  ${Overline} {
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        props.boxed
          ? 'featureBlock.boxed.textAccentThemeColor'
          : 'featureBlock.textThemeColor'
      )};
  }

  ${Headline} {
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        props.boxed
          ? 'featureBlock.boxed.textThemeColor'
          : 'featureBlock.textThemeColor'
      )};

    b {
      color: ${(props) =>
        colorTheme(
          props.colorTheme,
          props.boxed
            ? 'featureBlock.boxed.textThemeColor'
            : 'featureBlock.textThemeColor'
        )};
    }
  }

  ${Description} {
    &,
    .description p {
      color: ${(props) =>
        colorTheme(
          props.colorTheme,
          props.boxed
            ? 'featureBlock.boxed.textThemeColor'
            : 'featureBlock.textThemeColor'
        )};
    }

    p + ul {
      margin-top: 1.8rem;
    }

    ${(props) =>
      props.boxed &&
      css`
        ul {
          &:last-child {
            margin-bottom: 0;
          }
        }
      `}
  }
`
