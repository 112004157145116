import React, { useState, useEffect } from 'react'

import { StyledContentBlock, StyledRow, Wrapper } from './styles'
import { LocationsMap } from '../../molecules'
import { Container, Column } from '../../atoms'
import { Props } from './types'

export const LocationsBlock: React.FunctionComponent<Props> = (props) => {
  const {
    data: { topContentBlock, bottomContentBlock, locationsMap, centerAlign },
    isNested,
  } = props

  const [desktopWidth, setdesktopWidth] = useState(null)

  useEffect(() => {
    if (centerAlign) {
      setdesktopWidth(6)
    } else {
      setdesktopWidth(8)
    }
  }, [centerAlign])

  return (
    <Wrapper>
      <Container data-bdd-selector="location-map" center={true}>
        {topContentBlock && (
          <StyledRow>
            <Column desktop={8}>
              <StyledContentBlock data={topContentBlock} isNested={isNested} />
            </Column>
          </StyledRow>
        )}
        {locationsMap && (
          <StyledRow>
            <Column>
              <LocationsMap data={locationsMap}></LocationsMap>
            </Column>
          </StyledRow>
        )}
      </Container>
      {bottomContentBlock && (
        <Container center={centerAlign}>
          <StyledRow>
            <Column tablet={6} desktop={desktopWidth}>
              <StyledContentBlock
                data={bottomContentBlock}
                isNested={isNested}
              />
            </Column>
          </StyledRow>
        </Container>
      )}
    </Wrapper>
  )
}

export default LocationsBlock
