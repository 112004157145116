import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Column } from '../../atoms'

export const FormColumn = styled(Column)`
  margin-bottom: 0.6rem;
  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`

/* istanbul ignore next */
export const Button = styled.button`
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
  font-family: inherit;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding: 10px 0;
  border: 0;
  background-color: ${(props) =>
    colorTheme(props.colorTheme, 'primaryCtaBackgroundColor')};
  border-radius: 2.5rem;
  color: white;
  line-height: 1.67rem;
  width: 230px;
  transition: color 0.1s ease, background-color 0.1s ease;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 25rem;
  }

  &:disabled,
  &[disabled] {
    color: ${(props) => props.theme.colors.text.black};
    background-color: ${(props) => props.theme.colors.background.grey2};
  }
`

export const CTAWrapper = styled.div`
  margin-top: 3.2rem;
  font-size: 1.8rem;
  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: -1rem;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CaptchaWrapper = styled.div`
  margin-top: 3.2rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  div {
    margin: 0 auto;
  }
`

export const StyledRichTextBlock = styled.div`
  p {
    font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};
  }

  a {
    text-decoration: none;
    color: ${(props) =>
      colorTheme(
        props.colorTheme,
        'newsletterSignUpBanner.textAccentThemeColor'
      )};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    position: relative;
    overflow: hidden;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
`
