import React, { RefObject, useEffect, useState } from 'react'
import Slider from 'react-slick'

import { AdviserCarouselItem, Carousel } from '..'

import { Props } from './types'

const AdviserCarousel: React.FunctionComponent<Props> = React.forwardRef(
  (props, carouselRef: RefObject<Slider>) => {
    const { cards, className, slidesToShow, hideArrows, isNested } = props

    const [opened, setOpened] = useState(-1)
    const [dragging, setDragging] = useState(false)
    const dragChangeHandler = (_dragging: boolean) => {
      setDragging(_dragging)
    }

    useEffect(() => {
      setOpened(-1)
    }, [cards])

    const carouselItems = cards.map((card, index) => {
      const clickHandler = () => {
        return !dragging && opened !== index && setOpened(index)
      }

      const closeClickHandler = () => {
        return !dragging && setOpened(-1)
      }

      return (
        <AdviserCarouselItem
          key={index}
          onClick={clickHandler}
          onCloseClick={closeClickHandler}
          opened={index === opened}
          {...card}
          data-bdd-selector="adviser-adviser-carousel-item"
        />
      )
    })

    return (
      <Carousel
        onDragChange={dragChangeHandler}
        className={className}
        ref={carouselRef}
        slidesToShow={slidesToShow}
        hideArrows={hideArrows}
        isNested={isNested}
      >
        {carouselItems}
      </Carousel>
    )
  }
)

export default AdviserCarousel
