import styled, { css } from 'styled-components'

import theme from '../../../../styles/theme'
import { BaseParagraph } from '../Paragraph/styles'

import { BaseHeadingProps, Weights } from './types'

const fontWeightMapping = (fontWeight: Weights) => {
  const {
    fontWeights: { light, normal, bold },
  } = theme

  const weights = {
    light,
    normal,
    bold,
  }

  return weights[fontWeight]
}

const BaseHeadingStyles = css<BaseHeadingProps>`
  line-height: ${(props) => props.theme.lineHeights.normal};
  font-weight: ${(props) =>
    props.weight
      ? fontWeightMapping(props.weight)
      : props.theme.fontWeights.light};

  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  & + *:not[p] {
    margin-top: 3rem;
  }
`

const H1Styles = css`
  ${BaseHeadingStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h1};
  font-weight: ${(props) => props.theme.fontWeights.light};
  line-height: ${(props) => props.theme.lineHeights.close};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h1};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h1};
  }
`

const H1 = styled.h1`
  ${H1Styles}
`

const H2Styles = css`
  ${BaseHeadingStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};
  line-height: ${(props) => props.theme.lineHeights.close};

  & + ${BaseParagraph}, & + p {
    margin-top: 2.5rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h2};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h2};
  }
`

const H2 = styled.h2`
  ${H2Styles}
`

const H3Styles = css`
  ${BaseHeadingStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h3};
  line-height: ${(props) => props.theme.lineHeights.close};

  & + ${BaseParagraph}, & + p {
    margin-top: 1.5rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h3};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
  }
`

const H3 = styled.h3`
  ${H3Styles}
`

const H4Styles = css`
  ${BaseHeadingStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h4};
  line-height: ${(props) => props.theme.lineHeights.close};

  & + ${BaseParagraph}, & + p {
    margin-top: 2.5rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h4};
  }
`

const H4 = styled.h4`
  ${H4Styles}
`

const H5Styles = css`
  ${BaseHeadingStyles};
  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h5};
  line-height: ${(props) => props.theme.lineHeights.close};

  & + ${BaseParagraph}, & + p {
    margin-top: 2.7rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h5};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h5};
  }
`

const H5 = styled.h5`
  ${H5Styles}
`

const H6Styles = css`
  ${BaseHeadingStyles};

  & + ${BaseParagraph}, & + p {
    margin-top: 2rem;
  }

  font-size: ${(props) => props.theme.fontSizes.mobile.headings.h6};
  line-height: ${(props) => props.theme.lineHeights.close};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.headings.h6};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.headings.h6};
  }
`

const H6 = styled.h6`
  ${H6Styles}
`

export default {
  styles: {
    H1Styles,
    H2Styles,
    H3Styles,
    H4Styles,
    H5Styles,
    H6Styles,
  },
  styledComponents: {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
  },
}
