import * as React from 'react'

import { Column, Container, Image, RichText, Row } from '../../atoms'
import { AdviserContactCard } from '../../molecules'

import {
  AdviserHeroWrapper,
  AdviserAboutProfile,
  AdviserContainer,
  ContactCardContainer,
  CopyColumn,
  ImageColumn,
  StyledDescription,
  StyledHeadline,
  StyledSubHeadline,
} from './styles'
import { Props } from './types'

const AdviserHero = (props: Props) => {
  const {
    data: { headline, subHeadline, heroImage, description },
    adviserContactCardData,
    aboutAdviser,
  } = props
  return (
    <AdviserHeroWrapper data-bdd-selector="adviser-profile-hero-headline">
      <AdviserContainer paddingSize="none">
        <Row>
          <CopyColumn tablet={4} desktop={5} verticalAlignment="center">
            {headline && (
              <StyledHeadline data-bdd-selector="adviser-profile-hero-headline">
                {headline}
              </StyledHeadline>
            )}
            {subHeadline && (
              <StyledSubHeadline data-bdd-selector="adviser-profile-hero-subheadline">
                {subHeadline}
              </StyledSubHeadline>
            )}
            {description && (
              <StyledDescription data-bdd-selector="adviser-profile-hero-description">
                {description}
              </StyledDescription>
            )}
          </CopyColumn>
          <ImageColumn tablet={4} desktop={[6, 1]}>
            <Image
              data={heroImage}
              data-bdd-selector="adviser-profile-hero-image"
            />
          </ImageColumn>
        </Row>
      </AdviserContainer>
      <AdviserAboutProfile>
        <Container>
          <Row>
            {adviserContactCardData &&
              adviserContactCardData.name &&
              adviserContactCardData.email && (
                <Column tablet={3} desktop={4}>
                  <ContactCardContainer>
                    <AdviserContactCard data={adviserContactCardData} />
                  </ContactCardContainer>
                </Column>
              )}

            <Column tablet={5} desktop={[7, 1]}>
              <RichText
                html={aboutAdviser}
                data-bdd-selector="adviser-profile-about-adviser"
              />
            </Column>
          </Row>
        </Container>
      </AdviserAboutProfile>
    </AdviserHeroWrapper>
  )
}

export default AdviserHero
