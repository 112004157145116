import styled from 'styled-components'

import { StyledProgressBar } from './types'

export const Progress = styled.div`
  height: 0.4rem;
  overflow: hidden;
  margin-top: 0.5rem;
  background-color: ${(props) => props.theme.colors.background.grey4};
  border-radius: 4px;
`

export const ProgressBar = styled.span<StyledProgressBar>`
  height: 100%;
  display: block;
  position: relative;

  &::before {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    border-radius: 0 4px 4px 0;
    background-color: ${(props) => props.theme.colors.background.white};
    transform-origin: left;
    transform: ${(props) =>
      `translateX(${
        ((props.currentQuestion - 1) / props.totalNumberOfQuestions) * 100
      }%)`};
    transition: transform ${(props) => props.theme.transitions.slow} ease;
  }
`
