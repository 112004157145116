import styled from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'

import { StyledCheckboxInputLabel } from './types'

export const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
`

export const CheckboxInputLabel = styled.label<StyledCheckboxInputLabel>`
  font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
  cursor: pointer;
  margin-left: 2.5rem;

  & {
    text-align: left;
  }

  &::before {
    content: '';
    height: 24px;
    width: 24px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.background.white};
    border: 0.1rem solid
      ${(props) => colorTheme(props.colorTheme, 'input.borderThemeColor')};
    transition: background-color ${(props) => props.theme.transitions.fast} ease;
  }

  &::after {
    content: '✓';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0.4rem 0.4rem;
    color: ${(props) => props.theme.colors.background.white};
    transition: opacity ${(props) => props.theme.transitions.fast} ease;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: relative;

  &:checked {
    & + ${CheckboxInputLabel} {
      &:before {
        background-color: ${() =>
          colorTheme(undefined, 'input.boxShadowThemeColor')};
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    & + ${CheckboxInputLabel} {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`
