import { InputContainer, Message } from '../../styles'

import { Checkbox, CheckboxContainer, CheckboxInputLabel } from './styles'

const CheckboxInput = ({
  field,
  form: { errors, setFieldValue },
  ...props
}) => {
  const { label, inputType, id, colorTheme } = props

  const handleOnChange = () => {
    setFieldValue(field.name, !field.value)
  }

  const hasErrors = errors[field.name] !== undefined
  const fieldErrors = errors[field.name]

  return (
    <>
      <InputContainer>
        <CheckboxContainer>
          <Checkbox
            {...field}
            {...props}
            id={id}
            type={inputType}
            onChange={handleOnChange}
            checked={field.value}
            colorTheme={colorTheme}
            data-bdd-selector={`input_${field.name}`}
          />
          <CheckboxInputLabel
            htmlFor={id}
            colorTheme={colorTheme}
            id="checkbox-label"
          >
            {label}
          </CheckboxInputLabel>
        </CheckboxContainer>
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {hasErrors && fieldErrors}
      </Message>
    </>
  )
}

export default CheckboxInput
