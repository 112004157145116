import {
  StyledDropdownChevron,
  StyledDropdownClickbox,
  StyledDropdownLinksList,
  StyledDropdownWrapper,
  StyledNavDropdown,
} from '../styles'
import { DropdownMenuProps, NavItemData } from '../types'
import NavDropdownContentBlock from './NavDropdownContentBlock'
import NavDropdownItem from './NavDropdownItem'

const NavDropdownMenu: React.FunctionComponent<DropdownMenuProps> = (props) => {
  const {
    data,
    data: { footerLevelTwo: dropdownItems, contentBlock },
    isOpen,
    invert,
    useColorTheme,
  } = props

  const hasChildren = (item: NavItemData) => {
    return item && item.footerLevelTwo && item.footerLevelTwo.length > 0
  }

  const buildDropdownItem = (linkData: NavItemData, key) => {
    return (
      <NavDropdownItem
        key={key}
        {...linkData}
        invert={invert}
        useColorTheme={useColorTheme}
      />
    )
  }

  const buildDropdownList = () => {
    if (hasChildren(data)) {
      return dropdownItems.map((item, index) => buildDropdownItem(item, index))
    }
    return null
  }

  return (
    <>
      <StyledDropdownClickbox className={isOpen ? '' : 'accessibly-hide'}>
        <StyledDropdownChevron />
      </StyledDropdownClickbox>

      <StyledDropdownWrapper
        className={`
          ${isOpen ? '' : 'accessibly-hide'} 
          ${contentBlock ? '' : 'no-content-block'}
        `}
      >
        <StyledNavDropdown>
          <StyledDropdownLinksList>
            {buildDropdownList()}
          </StyledDropdownLinksList>
          {contentBlock && <NavDropdownContentBlock {...contentBlock} />}
        </StyledNavDropdown>
      </StyledDropdownWrapper>
    </>
  )
}

export default NavDropdownMenu
