import { Fragment, FunctionComponent } from 'react'

import { Footer, Header } from '../../organisms'
import mockFooterData from '../../organisms/Footer/__mockData__'

import { Main } from './styles'
import { Props } from './types'

/**
 * A template option which provides a variant header and no footer on a page,
 * with a purple background.
 *
 * Currently used in:
 *
 * 1. Profiling Tool
 *
 * @param children - Required. The child components to be rendered.
 *
 * @returns JSX.Element - TemplateC wrapped around provided child components.
 */
const TemplateB: FunctionComponent<Props> = (props) => {
  const { headerData, headerType, headerIsDark, viewportInfo, children } = props

  return (
    <Fragment>
      <Header
        data={headerData}
        headerType={headerType}
        headerIsDark={headerIsDark}
        viewportInfo={viewportInfo}
      />
      <Main>{children}</Main>
      <Footer {...mockFooterData.items[0]} />
    </Fragment>
  )
}

export default TemplateB

/* import Head from 'next/head';
import { Fragment, FunctionComponent } from 'react';

import { Main, PageStyles } from '../TemplateC/styles';
import { Header, Footer } from '../../organisms';

import { Props } from './types';

const TemplateB: FunctionComponent<Props> = props => {
  const {
    headerData,
    headerType,
    headerIsDark,
    viewportInfo,
    children
  } = props;

  return (
    <Fragment>
      <Head>
        <style>{PageStyles}</style>
      </Head>
      <Header
        data={headerData}
        headerType={headerType}
        headerIsDark={headerIsDark}
        viewportInfo={viewportInfo}
      />
      <Main>{children}</Main>
      <Footer {...mockFooterData.items[0]} />
    </Fragment>
  );
};

export default TemplateB;
 */
