import { APP_DEFAULT_THEME_COLOUR } from '../../../constants/ui'
import theme from '../../theme'
import { ColorTheme } from './types'

const { colorThemes } = theme

/**
 * A function that returns the requested colour theme value for a specific
 * property.
 *
 * @param themeName - Required. The theme that the component should be styled
 * as.
 * @param breakpoint - Required. The CSS property that the theme is being
 * requested for.
 *
 * @returns string - The colour HEX value for the request theme and colour
 * property.
 */
export const colorTheme: ColorTheme = (themeName, property) => {
  if (!themeName) themeName = APP_DEFAULT_THEME_COLOUR
  return property.split('.').reduce((o, i) => o[i], colorThemes[themeName])
}
