import { rgba } from 'polished'
import styled from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'

import { StyledDatePickerWrapper } from './types'

export const DatePickerWrapper = styled.div<StyledDatePickerWrapper>`
  .react-datepicker {
    color: ${(props) => props.theme.colors.text.black};
  }

  .react-datepicker-popper {
    z-index: 2;
    background-color: ${(props) => props.theme.colors.background.white};
    width: 100%;
    box-shadow: 0 0 12px 0
      ${(props) => rgba(props.theme.colors.background.black, 0.2)};
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .react-datepicker__navigation {
    cursor: pointer;
    position: absolute;
    visibility: hidden;
    width: 3rem;
    height: 3rem;
    &--previous:before,
    &--next:after {
      visibility: visible;
      content: '';
      height: 3rem;
      width: 3rem;
      position: absolute;
      top: 1.2rem;
      align-items: center;
      justify-content: center;
      display: flex;
      background-position: center;
      background-color: transparent;
    }
    &--previous {
      left: 0;
      &:before {
        left: 2.1rem;
        background-image: url('/static/icons/ChevronLeft.svg');
      }
    }

    &--next {
      right: 0;

      &:after {
        right: 2.1rem;
        background-image: url('/static/icons/ChevronRight.svg');
      }
    }
  }

  .react-datepicker__month {
    margin-top: 1rem;
  }

  .react-datepicker__month-container {
    margin-top: 0.2rem;
    padding: 0 2rem 2rem;
  }

  .react-datepicker__month-container {
    text-align: center;
    font-size: 1.2rem;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  .react-datepicker__day-name {
    color: ${(props) => colorTheme(props.colorTheme, 'input.labelThemeColor')};
  }

  .react-datepicker__current-month {
    padding: 2rem 0 2.4rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  .react-datepicker__day {
    cursor: pointer;
    position: relative;
    font-size: 1.2rem;
    margin: 1rem 0;

    &::after {
      transition: color ${(props) => props.theme.transitions.fast} ease,
        background-color ${(props) => props.theme.transitions.fast} ease;
      display: block;
      content: '';
      width: 29px;
      height: 29px;
      border-radius: 100%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: -6px;
      z-index: -1;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${(props) => props.theme.colors.text.white};
      font-weight: ${(props) => props.theme.fontWeights.bold};

      &::after {
        background-color: ${(props) =>
          colorTheme(props.colorTheme, 'input.labelThemeColor')};
      }
    }
  }

  .react-datepicker__day--disabled {
    color: grey;
    cursor: not-allowed;
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeights.normal};

    &:hover,
    &:active,
    &:focus {
      &::after {
        background-color: ${(props) => props.theme.colors.background.grey2};
      }
    }
  }

  .react-datepicker__day--selected {
    color: ${(props) => props.theme.colors.text.white};
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};

    &::after {
      background-color: ${(props) =>
        colorTheme(props.colorTheme, 'input.labelThemeColor')};
    }
  }

  .react-datepicker__header--time {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .react-datepicker__time-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5%;
    height: auto !important;
  }

  .react-datepicker__time-list-item {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    text-align: center;
    padding: 5px 10px;
    border: 1px ${(props) => props.theme.colors.background.grey2} solid;
    border-radius: 100px;
    margin: 3px;
    transition: all ${(props) => props.theme.transitions.normal} ease;
  }

  .react-datepicker__time-list-item:hover {
    background-color: ${(props) => props.theme.colors.background.blue};
    color: ${(props) => props.theme.colors.text.white};
    border: 1px ${(props) => props.theme.colors.background.blue} solid;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${(props) => props.theme.colors.background.blue};
    color: ${(props) => props.theme.colors.text.white};
    border: 1px ${(props) => props.theme.colors.background.blue} solid;
  }

  .react-datepicker__time-list-item--disabled {
    display: none;
    color: grey;
    text-decoration: line-through;
    cursor: not-allowed;
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeights.normal};

    &:hover,
    &:active,
    &:focus {
      &::after {
        background-color: ${(props) => props.theme.colors.background.grey2};
      }
    }
  }
`
