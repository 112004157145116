import styled from 'styled-components'
import HeadingStyles from '../../atoms/Title/styles'

const {
  styles: { H3Styles },
} = HeadingStyles

export const Wrapper = styled.div`
  margin-bottom: auto;
  text-align: center;
`

export const ContentWrapper = styled.div`
  max-width: 50rem;
  margin: 0 auto;

  div h2 {
    max-width: 40rem;
    margin: 0 auto;
    ${H3Styles};
  }
`

export const QuotesCarouselWrapper = styled.div`
  margin: 0 auto 4.5rem;
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`
