import React from 'react'
import TextTruncate from 'react-text-truncate'

import { Link } from '..'
import { ARTICLE_URL } from '../../../../constants/routes'
import { transformImageObject } from '../../../../utils/articles/imageTransform'
import { articlePublishedDate } from '../../../../utils/dates'

import {
  Content,
  Description,
  Footer,
  Published,
  ReadTime,
  StyledImage,
  Tag,
  Title,
  Wrapper,
} from './styles'
import { Props } from './types'

const getBackgroundImage = (heroBlock) => {
  const card = transformImageObject(
    heroBlock.items[0].backgroundImageCollection.items[0]
  )
  try {
    return card.backgroundImage
  } catch {
    return []
  }
}

const getReadTime = (metaData) => {
  try {
    return metaData.timeOnPage
  } catch {
    return undefined
  }
}

const getPublishedDate = (metaData) => {
  try {
    return metaData.publishedDate
  } catch {
    return undefined
  }
}

const FeaturedArticleCard: React.FunctionComponent<Props> = (props) => {
  const {
    cardTitle,
    contentFormatTag,
    cardDescription,
    className,
    imageSize = 'medium',
    metaData,
    articleHeroBlockCollection,
    slug,
    titleLimit = 0,
    descriptionLimit = 0,
  } = props

  return (
    <Link route={`${ARTICLE_URL}/${slug}`}>
      <Wrapper className={className} data-bdd-selector="featured-article-card">
        {getBackgroundImage(articleHeroBlockCollection) &&
          getBackgroundImage(articleHeroBlockCollection).length && (
            <StyledImage
              size={imageSize}
              data={getBackgroundImage(articleHeroBlockCollection)}
              keepRatio={true}
            />
          )}
        <Content>
          {cardTitle && (
            <Title>
              {titleLimit ? (
                <TextTruncate
                  line={titleLimit}
                  element="span"
                  truncateText="…"
                  text={cardTitle}
                />
              ) : (
                cardTitle
              )}
            </Title>
          )}
          {cardDescription && (
            <Description>
              {descriptionLimit ? (
                <TextTruncate
                  line={descriptionLimit}
                  element="span"
                  truncateText="…"
                  text={cardDescription}
                />
              ) : (
                cardDescription
              )}
            </Description>
          )}
          {(getPublishedDate(metaData) ||
            getReadTime(metaData) ||
            contentFormatTag) && (
            <Footer>
              {contentFormatTag && <Tag>{contentFormatTag}</Tag>}
              {getPublishedDate(metaData) && (
                <Published>
                  {articlePublishedDate(getPublishedDate(metaData))}
                </Published>
              )}
              {getReadTime(metaData) && (
                <ReadTime>{getReadTime(metaData)}</ReadTime>
              )}
            </Footer>
          )}
        </Content>
      </Wrapper>
    </Link>
  )
}

export default FeaturedArticleCard
