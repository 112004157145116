import styled from 'styled-components'

import { Column, Container } from '../../atoms'
import ParagraphStyles from '../../atoms/Paragraph/styles'
import HeadingStyles from '../../atoms/Title/styles'

import { AdviserHeroContainerProps, ImageColumnProps } from './types'

const {
  styles: { H2Styles },
} = HeadingStyles
const {
  styles: { largeStyles },
} = ParagraphStyles

export const ContactCardContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 0 4rem -1.6rem;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    max-width: 32rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    position: sticky;
    top: 20rem;
    margin: -13rem 3.2rem 0 -4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: -16.5rem 0 0 0;
  }
`

export const AdviserContainer = styled(Container)<AdviserHeroContainerProps>`
  padding-top: 0;
  padding-bottom: 0;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    background-color: ${(props) => props.theme.colors.background.grey5};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-bottom: 0;
  }
`

export const CopyColumn = styled(Column)`
  margin-bottom: 0;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    order: 1;
    margin-top: 4rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 11rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 14rem;
  }
`

export const ImageColumn = styled(Column)<ImageColumnProps>`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin: 0 -1.6rem;
    max-width: none;
    flex-basis: auto;
    width: calc(100% + 3.2rem);
    border-bottom: 1px solid ${(props) => props.theme.colors.background.grey2};
    background-color: ${(props) => props.theme.colors.background.grey1};
  }

  img {
    @media ${(props) => props.theme.breakpoints.tablet} {
      margin-right: -4rem;
      width: calc(100% + 4rem);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      margin-right: -1.6rem;
      width: calc(100% + 1.6rem);
    }
  }
`

export const StyledHeadline = styled.h2`
  ${H2Styles}

  font-weight: ${(props) => props.theme.fontWeights.bold};
`

export const StyledSubHeadline = styled.div`
  ${largeStyles}

  margin-top: 0.8rem;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

export const StyledDescription = styled.div`
  ${largeStyles}

  margin-top: 3rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 4rem;
  }
`

export const AdviserHeroWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background.beigeLight};
`

export const AdviserAboutProfile = styled.div`
  background-color: ${(props) => props.theme.colors.background.grey5};
`
