import React, { forwardRef, RefObject } from 'react'

import { Title } from '../../atoms'
import {
  ProfilingQuestionDragDrop,
  ProfilingQuestionList,
} from '../../molecules'
import { ProfilingToolAnswer } from '../ProfilingQuestionList/types'

import { SINGLE_CHOICE, SORTABLE_CHOICE } from './constants'
import { Fieldset, SubTitle } from './styles'
import { Props } from './types'

/**
 * A function which handles the `onChange` event from the answers in a
 * question.
 *
 * If the question type is `singleChoice`, the selected answer ID will be
 * provided to the `handleOnChange()` callback function, along with the
 * current questions ID.
 *
 * If the question type is `sortableChoice`, the sorted top 3 priority IDs
 * will be provided to the `handleOnChange()` callback function, along with
 * the current questions ID, unless the user has selected the `80+` age range.
 * In this case, only one priority ID will be provided.
 *
 * @param selectedAnswers - Required.
 */
export const onChange =
  (
    questionType: string,
    questionId: number,
    handleOnChange: (questionId: number, answer: number[]) => void
  ) =>
  (selectedAnswers: ProfilingToolAnswer[] | number[]) => {
    if (questionType === SORTABLE_CHOICE) {
      const topThreeAnswers: number[] = (
        selectedAnswers as ProfilingToolAnswer[]
      )
        .map(({ answerId }) => answerId)
        .slice(0, 3)

      handleOnChange(questionId, topThreeAnswers)
    } else {
      handleOnChange(questionId, selectedAnswers as number[])
    }
  }

const ProfilingQuestion = forwardRef(
  (
    {
      data: { questionId, questionType, title, subTitle, answers },
      currentlySelectedAnswerId,
      isActive,
      isDisabled,
      handleOnChange,
      handleOnClickNext,
    }: Props,
    ref: RefObject<HTMLInputElement>
  ) => {
    return (
      <Fieldset
        className={`${questionId}`}
        disabled={isDisabled}
        data-bdd-selector="profilingQuestion-fieldset"
      >
        <legend>
          <Title
            element={2}
            level={3}
            data-bdd-selector="profilingQuestion-title"
          >
            {title}
          </Title>
        </legend>
        <SubTitle>{subTitle}</SubTitle>
        {questionType === SINGLE_CHOICE && (
          <ProfilingQuestionList
            data={answers}
            ref={ref}
            currentlySelectedAnswerId={currentlySelectedAnswerId}
            questionId={questionId}
            isActive={isActive}
            onChange={onChange(questionType, questionId, handleOnChange)}
            data-bdd-selector="profilingQuestion-list-single"
          />
        )}
        {questionType === SORTABLE_CHOICE && answers && (
          <ProfilingQuestionDragDrop
            data={answers}
            onChange={onChange(questionType, questionId, handleOnChange)}
            onClickNext={handleOnClickNext}
            isActive={isActive}
            isDisabled={isDisabled}
            data-bdd-selector="profilingQuestion-list-sortable"
          />
        )}
      </Fieldset>
    )
  }
)

export default ProfilingQuestion
