import { RefObject } from 'react'

import { ColorThemeNames } from '../../../../types/colorThemes'

export interface Props {
  scrollType: ScrollType
  colorTheme?: ColorThemeNames
  buttonColor?: string
  scrollDuration?: ScrollDuration
  scrollElementRef?: RefObject<HTMLElement>
  targetElementName?: string
  invertTheme?: boolean
}

export interface WrapperProps {
  colorTheme?: ColorThemeNames
  buttonColor?: string
  isScrollToTop?: boolean
  invertTheme?: boolean
}

export enum ScrollDuration {
  verySlow = 1000,
  slow = 500,
  normal = 300,
  fast = 150,
}

export enum ScrollType {
  Top,
  ToElement,
}
