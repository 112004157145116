import styled from 'styled-components'

import { Container, RichText } from '../../atoms'

export const StyledModalContent = styled(Container)`
  text-align: center;
`

export const Title = styled(RichText)`
  padding-bottom: 1.5rem;

  h2 {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h4};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h4};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h4};
    }
  }
`

export const Body = styled(RichText)`
  color: ${(props) => props.theme.colors.text.grey4};
  padding-bottom: 3.5rem;
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`
