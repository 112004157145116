import { FunctionComponent } from 'react'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { TabData } from '../../atoms/Tabs/types'
import { Copy, StyledTabs, Tab, Title, Wrapper } from './styles'
import { Props } from './types'

const AnchorNav: FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: { anchorLinks },
    onTabClick,
    selectedTab,
    title = true,
  } = props

  const getCustomTab = (tab: TabData, index: number) => (
    <li key={tab._id}>
      <Tab
        selected={index === selectedTab}
        onClick={() => onTabClick(index)}
        colorTheme={colorTheme}
        data-bdd-selector="tabs-tab"
      >
        {title && tab.linkTitle && (
          <Title data-bdd-selector="tabs-tab-title">{tab.linkTitle}</Title>
        )}
        {tab.linkCopy && (
          <Copy data-bdd-selector="tabs-tab-copy">{tab.linkCopy}</Copy>
        )}
      </Tab>
    </li>
  )

  return (
    <Wrapper colorTheme={colorTheme}>
      <StyledTabs
        aria-label="Anchor navigation"
        tabs={anchorLinks}
        mobileIcon={false}
        customTab={getCustomTab}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
      />
    </Wrapper>
  )
}

export default AnchorNav
