import { LoadingSpinner, Paragraph, Title } from '../../atoms'

import { LoadingContainer, LoadingText, LoadingWrapper } from './styles'
import { Props } from './types'

const ProfilingLoading: React.FunctionComponent<Props> = (props) => {
  const {
    data: { title, subTitle },
  } = props

  return (
    <LoadingWrapper>
      <LoadingContainer center={true}>
        <LoadingText>
          <Title level={3} element={3}>
            {title}
          </Title>
          <Paragraph level="medium">{subTitle}</Paragraph>
        </LoadingText>
        <LoadingSpinner />
      </LoadingContainer>
    </LoadingWrapper>
  )
}

export default ProfilingLoading
