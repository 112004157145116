import { rgba } from 'polished'
import { ReactSVG } from 'react-svg'
import { css } from 'styled-components'

import { styled } from '../../../../styles/theme'
import { colorTheme } from '../../../../styles/utils'
import { ColorThemeNames } from '../../../../types/colorThemes'
import HeadingStyles from '../../atoms/Title/styles'

import { WrapperProps } from './types'

const {
  styles: { H5Styles },
} = HeadingStyles

export const Title = styled.h5`
  ${H5Styles};

  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
  margin-bottom: 3.2rem;
`

export const FeeInfo = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    grid-template-columns: 2fr 1fr;
  }
`

const FeeInfoPrimaryText = css`
  font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
  }
`

const FeeInfoSecondaryText = css`
  color: ${(props) => props.theme.colors.text.beige};
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`

export const FeeInfoTitle = styled.div`
  ${FeeInfoPrimaryText}

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    grid-row: 1 / span 2;
  }
`

export const FeeInfoPrice = styled.div`
  ${FeeInfoPrimaryText}
  text-align: right;
`

export const FeeInfoDescription = styled.div`
  ${FeeInfoSecondaryText}

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    grid-column: span 2;
    margin-top: 1.6rem;
  }
`

export const FeeInfoPriceDescription = styled.div`
  ${FeeInfoSecondaryText}
  text-align: right;

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    grid-row: 2;
  }
`

export const Wrapper = styled.div`
  padding: 3.2rem 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 3.4rem 4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 3.2rem 4rem;
  }
`

interface AverageFeeProps {
  colorTheme?: ColorThemeNames
}

export const AverageFeeExpandButton = styled.button<AverageFeeProps>`
  appearance: initial;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font-family: inherit;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  position: relative;
  text-decoration: underline;
  text-decoration-color: ${(props) =>
    colorTheme(props.colorTheme, 'pricingDetailBlock.borderThemeColor')};
  text-underline-position: under;
  line-height: ${(props) => props.theme.lineHeights.medium};
  display: inline-flex;
  align-items: center;
  text-align: left;
  margin-top: 1.6rem;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.mobile.body.tertiary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
  }
`

export const AverageFeeExpandButtonIcon = styled(ReactSVG)`
  fill: currentColor;
  transition: transform ${(props) => props.theme.transitions.normal} ease-in-out;
  line-height: ${(props) => props.theme.lineHeights.none};
`

interface ExpandableAreaProps {
  averageAreaExpanded: boolean
}

export const ExpandableArea = styled.div<ExpandableAreaProps>`
  max-height: ${(props) => (props.averageAreaExpanded ? '100rem' : '0')};
  overflow: hidden;
`

export const RootWrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.light
      ? css`
          ${FeeInfoTitle}, ${FeeInfoPrice} {
            color: ${props.theme.colors.text.black};
          }

          ${FeeInfoDescription}, ${FeeInfoPriceDescription} {
            color: ${props.theme.colors.text.grey4};
          }
        `
      : undefined}

  ${AverageFeeExpandButtonIcon} {
    ${(props) =>
      props.averageAreaExpanded &&
      css`
        transform: rotate(90deg);
      `};
  }

  ${ExpandableArea} {
    transition: max-height ${(props) => props.theme.transitions.slow}
      ${(props) =>
        props.averageAreaExpanded
          ? 'cubic-bezier(0.77, 0.06, 0.95, 0.65)'
          : 'cubic-bezier(0.18, 0.74, 0.36, 1)'};
  }
`

export const AverageFeeDetailsWrapper = styled.div`
  position: relative;
  box-shadow: inset 0px 0px 13px 0px
    ${(props) => rgba(props.theme.colors.background.black, 0.8)};
  padding: 0 0.8rem 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 1.6rem 3.2rem;
  }

  .average-fee-detail {
    background: ${(props) => props.theme.colors.background.white};
    padding: 2.4rem 1.2rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding: 3.2rem 2.4rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding: 2.4rem;
    }

    ${Wrapper} {
      padding: 0;
    }
  }

  .average-fee-detail + .average-fee-detail {
    border-top: 1px solid ${(props) => props.theme.colors.ui.grey8};
  }

  .average-fee-detail:first-child {
    border-radius: 8px 8px 0 0;
  }

  .average-fee-detail:last-child {
    border-radius: 0 0 8px 8px;
  }
`
