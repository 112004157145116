import styled from 'styled-components'

export const Section = styled.section`
  text-align: center;
  margin-top: 6rem;
`

export const Figure = styled.div`
  font-size: 5rem;
  line-height: 1;
  font-weight: ${(props) => props.theme.fontWeights.light};
  margin-bottom: 3.2rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 7.2rem;
  }
`
