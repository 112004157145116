import React from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { transformImageObject } from '../../../../utils/articles/imageTransform'
import { DividerLine } from '../../atoms'
import { ArticleCarousel } from '../../molecules'
import { SelectedArticlesData } from '../../templates/Layout/types'

import {
  ArticleCarouselWrapper,
  Headline,
  StyledContainer,
  Wrapper,
} from './styles'
import { Props } from './types'

export const isValidSelectedArticles = (
  selectedArticles: SelectedArticlesData
) => {
  return selectedArticles && selectedArticles.articleCollection.items
}

export const SelectedArticles: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: {
      headline,
      selectedArticles: { items },
    },
  } = props

  const cards = items.map((card) => {
    const imageTransform = transformImageObject(
      card.articleHeroBlockCollection.items[0].backgroundImageCollection
        .items[0]
    )
    return { ...card, backgroundImage: imageTransform.backgroundImage }
  })

  return (
    <Wrapper data-bdd-selector="selected-articles">
      <StyledContainer>
        {headline && (
          <Headline
            html={headline}
            colorTheme={colorTheme}
            data-bdd-selector="selected-articles-headline"
          />
        )}
        {cards && (
          <ArticleCarouselWrapper data-bdd-selector="selected-articles-carousel">
            <ArticleCarousel cards={cards} colorTheme={colorTheme} />
          </ArticleCarouselWrapper>
        )}
      </StyledContainer>
      <DividerLine />
    </Wrapper>
  )
}

export default SelectedArticles
