/**
 * Below are all routes defined within this Next.js application, utilising
 * `next-routes`.
 *
 * The format below follows:
 *
 * | Name   | Page      | Pattern
 * | ----   | ----      | -----
 * | about  | about     | /about
 * | blog   | blog      | /blog/:slug
 * | user   | profile   | /user/:id
 * | (none) | complex   | /:noname/:lang(en|es)/:wow+
 * | beta   | v3        | /v3
 *
 * Docs can be found here: https://github.com/fridays/next-routes
 */

var routes = require('next-routes')

// This class is trying to do what next does by default, which is provide URL parts as parameters.
// It is also handing off specific routes to specific pages.
// We don't need this, and it's likely going to clash with next's handling of routing. Not good.
module.exports = routes()
  .add('adviser', '/advisers/:slug', 'adviser-profile')
  .add('advisers', '/advisers')
  .add('articles', '/wealth-lens/:slug', 'article')
  .add('campaign', '/campaign/:slug')
  .add('referral', '/referral/:slug')
  .add('careers', '/careers', 'work-with-us')
  .add('common-reporting-standard')
  .add('contact-us')
  .add('cookie-policy')
  .add('dashboard')
  .add('faq')
  .add('fund-info')
  .add('how-it-works')
  .add('wealth-lens')
  .add('legal-information')
  .add('modern-slavery-act')
  .add('share-dealing')
  .add('media-centre')
  .add('preference-centre', '/preferences')
  .add('pricing')
  .add('privacy-policy')
  .add('profiling-tool-results', '/questionnaire/results')
  .add('profiling-tool', '/questionnaire')
  .add('robots.txt')
  .add('security')
  .add('terms-and-conditions')
  .add('what-we-do')
