import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'
import { Container } from '../../../../atoms'
import { Link } from '../../../../atoms/Button/styles'

import {
  StyledHeaderWrapper,
  StyledMenuBars,
  StyledPrimaryMenuContainer,
  StyledSecondaryMenuContainer,
} from './types'

export const HeaderWrapper = styled.header<StyledHeaderWrapper>`
  background-color: ${(props) => {
    if (props.fixedHeader) return props.theme.colors.background.black
    if (props.isMenuFloating) return props.theme.colors.background.white
    return 'transparent'
  }};
  background: ${(props) => {
    if (props.hasCustomHeaderBG)
      return props.theme.colors.background.rocketboyGradient
  }};

  border-bottom: ${(props) =>
    props.fixedHeader && `1px solid ${props.theme.colors.ui.white}`};

  ${(props) =>
    !props.fixedHeader &&
    css`
      top: 0;
      left: 0;
      width: 100%;
      z-index: ${(props) => props.theme.zIndex.header};
      position: absolute;
      transition: transform ${(props) => props.theme.transitions.normal} ease,
        background-color ${(props) => props.theme.transitions.normal} ease,
        color ${(props) => props.theme.transitions.normal} ease;
    `}

  /* Has scrolled */
  ${(props) =>
    props.hasScrolled &&
    css`
      top: -7rem;
      transform: translate3d(0, 100%, 0);

      @media ${props.theme.breakpoints.tablet} {
        top: -10rem;
      }

      @media ${props.theme.breakpoints.desktopMedium} {
        top: -13.3rem;
      }
    `}

  /* Is scrolling up */
  ${(props) =>
    !props.isScrollingDown &&
    props.isMenuFloating &&
    css`
      position: fixed;
      top: -7rem;
      transform: translate3d(0, 100%, 0);

      @media ${props.theme.breakpoints.tablet} {
        top: -10rem;
        transform: translate3d(0, 100%, 0);
      }

      @media ${props.theme.breakpoints.desktop} {
        top: -13.3rem;
        transform: translate3d(0, calc(100% - 3.3rem), 0);
      }
    `}

    /* Is scrolling down */
  ${(props) =>
    props.isScrollingDown &&
    props.isMenuFloating &&
    css`
      position: fixed;
      top: -7rem;
      transform: translate3d(0, 0, 0);

      @media ${props.theme.breakpoints.tablet} {
        top: -10rem;
      }

      @media ${props.theme.breakpoints.desktop} {
        top: -13.3rem;
      }
    `}

  /* Was at top of page and header is now fixed again */
  ${(props) =>
    props.isMenuFloating &&
    props.hasReachedTop &&
    css`
      opacity: 0;
      transition-delay: 0s;
    `}

  /* Is at top of page */
    ${(props) =>
    props.hasReachedTop &&
    css`
      transition: transform ${props.theme.transitions.normal}
          ${props.theme.transitions.moderate} ease,
        background-color ${props.theme.transitions.normal} ease;
    `}

  /* Add margin to the first child element inside <main/> so it sits underneath
  the header */
  ${(props) =>
    !props.fixedHeader &&
    css`
      & + main > *:first-child {
        padding-top: 9rem;

        @media ${(props) => props.theme.breakpoints.tablet} {
          padding-top: 12rem;
        }

        @media ${(props) => props.theme.breakpoints.desktop} {
          padding-top: 16.5rem;
        }
      }
    `}
`

export const SecondaryMenuWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0.8rem 1.6rem;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: ${(props) => props.theme.dimensions.containerMaxWidth};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    max-width: ${(props) => props.theme.dimensions.superContainerMaxWidth};
    padding-right: 4rem;
    padding-left: 4rem;
  }
`

export const SecondaryMenuContainer = styled.div<StyledSecondaryMenuContainer>`
  display: none;
  height: 3.3rem;
  text-align: right;
  font-size: ${(props) => props.theme.fontSizes.mobile.overline.primary};
  background-color: ${(props) => props.theme.colors.background.black};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.overline.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: flex-end;
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};

    svg {
      position: relative;
      height: 1.2rem;
      width: 1.2rem;
      top: -0.1rem;
      margin-right: 0.5rem;
      fill: ${(props) =>
        colorTheme(props.colorTheme, 'header.utilityIconColor')};
      transition: fill ${(props) => props.theme.transitions.normal} ease;
    }
  }

  ${Link} {
    height: auto;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    color: ${(props) => props.theme.colors.background.white};

    &::after {
      content: none;
    }

    &:focus,
    &:active,
    &:hover {
      color: ${(props) =>
        colorTheme(props.colorTheme, 'header.utilityLinkHoverColor')};

      svg {
        fill: ${(props) =>
          colorTheme(props.colorTheme, 'header.utilityLinkHoverColor')};
      }
    }
  }
`

export const PrimaryMenuContainer = styled(
  Container
)<StyledPrimaryMenuContainer>`
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.fixedHeader && 'center'};

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 8.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 10rem;
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    max-width: ${(props) => props.theme.dimensions.superContainerMaxWidth};
    padding-right: 4rem;
    padding-left: 4rem;
  }
`

export const PrimaryMenuLogo = styled.div`
  line-height: ${(props) => props.theme.lineHeights.base};
  width: 13rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 16rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 19rem;
  }
`

const PrimaryMenuColumn = styled.div`
  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    display: flex;
  }
`

export const PrimaryMenuColumnStart = styled(PrimaryMenuColumn)`
  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    order: 1;
  }
`

export const PrimaryMenuColumnCenter = styled(PrimaryMenuColumn)`
  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    justify-content: flex-end;
    display: flex;
    flex: 1;
    order: 2;
  }
`

export const PrimaryMenuColumnEnd = styled(PrimaryMenuColumn)`
  justify-content: flex-end;
  @media ${(props) => props.theme.breakpoints.desktopMediumMax} {
    order: 3;
  }
`

const MenuBar = css`
  content: '';
  display: block;
  height: 0.3rem;
  position: absolute;
  right: 0;
  width: 100%;
`

export const MenuBars = styled.span<StyledMenuBars>`
  display: block;
  height: 0.3rem;
  width: 2.2rem;
  position: relative;
  border-radius: 20%;
  top: 0.7rem;
  background-color: ${(props) => {
    if (props.invert) {
      return props.useColorTheme
        ? colorTheme(props.colorTheme, 'header.menuButtonBaseColor')
        : props.theme.colors.ui.white
    }
    return props.theme.colors.ui.blue2
  }};

  &::before,
  &::after {
    ${MenuBar}
    background-color: ${(props) => {
      if (props.invert) {
        return props.useColorTheme
          ? colorTheme(props.colorTheme, 'header.menuButtonBaseColor')
          : props.theme.colors.ui.white
      }
      return props.theme.colors.ui.blue2
    }};
  }

  &::before {
    top: 0.8rem;
  }

  &::after {
    top: 1.6rem;
  }
`

export const MenuButton = styled.button`
  position: relative;
  height: 4.4rem;
  width: 4.4rem;
  padding: 0.6rem;
  margin-right: -0.8rem;
  margin-left: 0.6rem;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    ${MenuBars} {
      &::before,
      &::after {
        width: 100%;
      }
    }
  }
`
