import styled from 'styled-components'

import { RichText } from '../../atoms'

export const RichTextStyled = styled(RichText)`
  font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
  }
`
