import styled from 'styled-components'

import { SKIP_TO_CONTENT_ZINDEX } from '../../../../constants/ui'

export const SkipToContentWrapper = styled.ul`
  a {
    color: ${(props) => props.theme.colors.text.beigeMedium};
    font-size: ${(props) => props.theme.fontSizes.desktop.overline.primary};
    position: absolute;
    height: 1px;
    width: 1px;
    left: 0;
    margin-top: 0;
    line-height: 2em;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    z-index: ${SKIP_TO_CONTENT_ZINDEX};
    display: block;
  }

  a:focus {
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    clip: auto;
    top: 0;
  }
`
