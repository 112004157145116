import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'

import { BaseProps, StyledIcon, StyledText, StyledWrapper } from './types'

const BaseStyles = css<BaseProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 10rem;
  min-height: 4.8rem;
  z-index: 0;
  cursor: pointer;
  justify-content: center;
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding: ${(props) => {
    if (props.hasIcon && !props.isLoading) {
      if (props.iconBeforeText) {
        return '1.3rem 2.4rem 1.3rem 1.6rem'
      }
      return '1.3rem 1.6rem 1.3rem 2.4rem'
    }
    return '1.3rem 2.4rem'
  }};
  border: 0;
  border-radius: 100px;
  overflow: hidden;
  flex-shrink: 0;
  ${(props) =>
    props.iconOnly &&
    css`
      min-width: auto;
      padding: 0;
      width: 3.6rem;
      border-radius: 50%;
    `};

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    ${(props) =>
      props.hideTextOnMobile &&
      css`
        min-width: 4.8rem;
        padding: 0;
      `}
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    ${(props) =>
      props.iconOnly &&
      css`
        min-width: auto;
        width: 4.8rem;
      `};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-height: 4.8rem;
    ${(props) =>
      props.iconOnly &&
      css`
        min-width: auto;
        width: 4.8rem;
      `};
  }
`

const HoverAnimationStyles = css`
  &::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    padding-top: 100%;
    border-radius: 50%;
    z-index: -1;
    transform: translateY(0%);
    transition: transform ${(props) => props.theme.transitions.slow} ease;
  }

  &:focus,
  &:active,
  &:hover {
    &::before {
      transform: translateY(-100%);
    }
  }
`

export const PrimaryButton = styled.button<StyledWrapper>`
  ${BaseStyles}
  color: ${(props) =>
    props.invertColorTheme
      ? colorTheme(
          props.colorTheme,
          'callToAction.primary.active.backgroundThemeColor'
        )
      : colorTheme(
          props.colorTheme,
          'callToAction.primary.active.textThemeColor'
        )};
  background-color: ${(props) =>
    props.invertColorTheme
      ? colorTheme(
          props.colorTheme,
          'callToAction.primary.active.textThemeColor'
        )
      : colorTheme(
          props.colorTheme,
          'callToAction.primary.active.backgroundHoverThemeColor'
        )};
  ${HoverAnimationStyles}

  &::before {
    background-color: ${(props) =>
      props.invertColorTheme
        ? colorTheme(
            props.colorTheme,
            'callToAction.primary.active.textThemeColor'
          )
        : colorTheme(
            props.colorTheme,
            'callToAction.primary.active.backgroundThemeColor'
          )};
  }
`

export const PrimaryButtonDisabled = styled.button.attrs({
  disabled: true,
})<StyledWrapper>`
  ${BaseStyles}
  cursor: default;
  color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.primary.disabled.textThemeColor'
    )};
  background-color: ${(props) =>
    rgba(
      colorTheme(
        props.colorTheme,
        'callToAction.primary.disabled.backgroundThemeColor'
      ),
      0.3
    )};
`

export const Icon = styled.span<StyledIcon>`
  line-height: ${(props) => props.theme.lineHeights.none};
  fill: currentColor;
  transition: fill ${(props) => props.theme.transitions.normal} ease;

  ${(props) =>
    props.iconBeforeText
      ? css`
          margin-right: 0.2rem;
        `
      : css`
          margin-left: 0.2rem;
        `}

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    ${(props) =>
      props.hideTextOnMobile &&
      css`
        margin: 0;
      `}
  }
`

export const Text = styled.span<StyledText>`
  text-align: center;
  line-height: ${(props) => props.theme.lineHeights.compact};

  ${(props) =>
    props.hideTextOnMobile &&
    css`
      @media ${props.theme.breakpoints.mobileMax} {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    `}
`

export const CloseButton = styled.button<StyledWrapper>`
  ${BaseStyles}
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) => props.theme.colors.background.blue};

  &:focus,
  &:active,
  &:hover {
    width: auto;

    & ${Icon} {
      margin-left: 0.5rem;
    }

    & ${Text} {
      max-width: 5rem;
    }
  }

  & ${Icon} {
    margin-left: 0;
    transition: margin-left ${(props) => props.theme.transitions.fast} ease;
  }

  & ${Text} {
    max-width: 0;
    overflow: hidden;
    transition: max-width ${(props) => props.theme.transitions.normal} ease,
      flex ${(props) => props.theme.transitions.normal} ease;
  }

  && {
    height: 4.8rem;
    min-width: 4.8rem;
    padding: 0 1rem;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${(props) => props.theme.colors.background.white};
  }
`

export const SecondaryButton = styled.button<StyledWrapper>`
  ${BaseStyles}
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.secondary.active.textThemeColor'
    )};
  background-color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.secondary.active.backgroundHoverThemeColor'
    )};
  border: solid 2px
    ${(props) =>
      colorTheme(
        props.colorTheme,
        'callToAction.secondary.active.borderThemeColor'
      )};
  ${HoverAnimationStyles}
  &::before {
    background-color: ${(props) =>
      colorTheme(
        props.colorTheme,
        'callToAction.secondary.active.backgroundThemeColor'
      )};
  }
`

export const SecondaryButtonDisabled = styled(
  PrimaryButtonDisabled
)<StyledWrapper>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.secondary.disabled.textThemeColor'
    )};
  background-color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.secondary.disabled.backgroundThemeColor'
    )};
  border: solid 1px
    ${(props) =>
      colorTheme(
        props.colorTheme,
        'callToAction.secondary.disabled.borderThemeColor'
      )};
  opacity: 0.3;
`

export const Link = styled.span<StyledWrapper>`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
  color: ${(props) =>
    colorTheme(props.colorTheme, 'link.textAccentThemeColor')};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  transition: color ${(props) => props.theme.transitions.normal} ease;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${(props) =>
      colorTheme(props.colorTheme, 'link.textUnderlineThemeColor')};
    transform: translateX(-100%);
    transform: transform ${(props) => props.theme.transitions.normal} ease;
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;

    &::after {
      transform: translateX(0);
    }
  }
`

export const TertiaryButton = styled.button<StyledWrapper>`
  ${BaseStyles}
  color: ${(props) =>
    props.invertColorTheme
      ? colorTheme(
          props.colorTheme,
          'callToAction.tertiary.active.backgroundThemeColor'
        )
      : colorTheme(
          props.colorTheme,
          'callToAction.tertiary.active.textThemeColor'
        )};
  background-color: ${(props) =>
    props.invertColorTheme
      ? colorTheme(
          props.colorTheme,
          'callToAction.tertiary.active.textThemeColor'
        )
      : colorTheme(
          props.colorTheme,
          'callToAction.tertiary.active.backgroundHoverThemeColor'
        )};
  ${HoverAnimationStyles}

  &::before {
    background-color: ${(props) =>
      props.invertColorTheme
        ? colorTheme(
            props.colorTheme,
            'callToAction.tertiary.active.textThemeColor'
          )
        : colorTheme(
            props.colorTheme,
            'callToAction.tertiary.active.backgroundThemeColor'
          )};
  }
`

export const TertiaryButtonDisabled = styled.button.attrs(() => ({
  disabled: true,
}))<StyledWrapper>`
  ${BaseStyles}
  cursor: default;
  color: ${(props) =>
    colorTheme(
      props.colorTheme,
      'callToAction.tertiary.disabled.textThemeColor'
    )};
  background-color: ${(props) =>
    rgba(
      colorTheme(
        props.colorTheme,
        'callToAction.tertiary.disabled.backgroundThemeColor'
      ),
      0.3
    )};
`
