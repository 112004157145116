import styled from 'styled-components'
import { Container } from '../../../common/atoms'

export const ResultModalContent = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.background.beige};
  color: ${(props) => props.theme.colors.text.black};
  padding: 1.5rem;
  margin: -1.5rem;
  position: relative;

  h3 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 35px;
  }

  p {
    margin-bottom: 40px;
    font-size: 20px;

    & + p {
      margin-bottom: 20px;
    }
  }
`

export const ResultModalHeader = styled.div`
  button {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }
`

export const ResultModalFooterContainer = styled(Container)`
  padding-top: 0;
`
export const ResultSuccessIcon = styled.img.attrs({
  src: '/static/icons/icon_Confirmation_blue.svg',
})`
  width: 60px;
  margin-bottom: 40px;
`

export const ResultFailureIcon = styled.img.attrs({
  src: '/static/icons/icon_Alert_blue.svg',
})`
  width: 60px;
  margin-bottom: 40px;
`
