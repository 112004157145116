import React, { useState } from 'react'
import { StyledTopLevelNavItem, StyledNavLink } from '../styles'
import { SPWMenuItemProps } from '../types'

const NavMenuItem: React.FunctionComponent<SPWMenuItemProps> = (props) => {
  const [active, setActive] = useState(false)

  const handleMouseOver = () => {
    setActive(true)
  }

  const handleMouseLeave = () => {
    setActive(false)
  }

  return (
    <StyledTopLevelNavItem
      className={active && 'active'}
      onMouseOver={props.isDesktop && handleMouseOver}
      onMouseLeave={props.isDesktop && handleMouseLeave}
      onFocus={props.isDesktop && handleMouseOver}
      onBlur={props.isDesktop && handleMouseLeave}
    >
      <StyledNavLink
        invert={props.invert}
        useColorTheme={props.useColorTheme}
        href={props.pageUrl}
        data-bdd-selector="nav-link-anchor"
        hasCustomHeaderBG={props.hasCustomHeaderBG}
      >
        {props.content}
      </StyledNavLink>
    </StyledTopLevelNavItem>
  )
}
export default NavMenuItem
