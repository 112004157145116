import styled from 'styled-components'

import { colorTheme } from '../../../../../../styles/utils'
import { BaseInput, Label } from '../../styles'

import { StyledSelect, StyledSelectLabel } from './types'

export const SelectLabel = styled(Label)<StyledSelectLabel>`
  pointer-events: none;
  color: ${(props) =>
    (props.focus &&
      props.hasValue &&
      colorTheme(props.colorTheme, 'input.labelThemeColor')) ||
    (props.focus &&
      props.hasValue &&
      !props.errorsInField &&
      colorTheme(props.colorTheme, 'input.labelThemeColor')) ||
    (props.hasValue && props.errorsInField && props.theme.colors.text.red2) ||
    props.theme.colors.text.black};
  font-weight: ${(props) => props.hasValue && props.theme.fontWeights.bold};
  font-size: ${(props) =>
    props.hasValue
      ? props.theme.fontSizes.desktop.input.labelOnFocus
      : props.theme.fontSizes.desktop.input.label};
  transform: ${(props) =>
    props.hasValue ? 'translateY(-1rem)' : 'translateY(0px)'};
`

export const Select = styled(BaseInput).attrs({
  as: 'select',
})<StyledSelect>`
  padding: 2.4rem 4rem 0.6rem 2.5rem;
  appearance: none;
  color: ${(props) =>
    props.hasValue
      ? props.theme.colors.text.grey
      : props.theme.colors.text.white};
  border: 0.1rem solid
    ${(props) =>
      (props.isTouched && props.errorsInField && props.theme.colors.ui.red2) ||
      props.theme.colors.ui.grey2};
  box-shadow: 0 0 0 0.1rem
    ${(props) =>
      (props.isTouched && props.errorsInField && props.theme.colors.ui.red2) ||
      'transparent'};
  transition: border-color ${(props) => props.theme.transitions.fast} ease,
    box-shadow ${(props) => props.theme.transitions.fast} ease;

  & > option {
    color: ${(props) => props.theme.colors.text.black};
  }

  &:focus,
  &:active {
    border: 0.1rem solid
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        colorTheme(props.colorTheme, 'input.borderThemeColor')};
    box-shadow: 0 0 0 0.1rem
      ${(props) =>
        (props.isTouched &&
          props.errorsInField &&
          props.theme.colors.ui.red2) ||
        colorTheme(props.colorTheme, 'input.boxShadowThemeColor')};
  }
`
