import { FunctionComponent } from 'react'

import ContentBlockWithCaption from '../ContentBlockWithCaption'

import { Wrapper } from './styles'
import { Props } from './types'

const ColumnsContentBlock: FunctionComponent<Props> = (props) => {
  const { data, className } = props
  return (
    <Wrapper className={className} data-bdd-selector="columns-content-block">
      {data.map((contentItem, index) => {
        return <ContentBlockWithCaption key={index} data={contentItem} />
      })}
    </Wrapper>
  )
}

export default ColumnsContentBlock
