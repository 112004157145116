import styled from 'styled-components'

import { Button } from '../../atoms'
import { Input, Message } from '../../atoms/Input/styles'
import ParagraphStyles from '../../atoms/Paragraph/styles'

const {
  styles: { mediumStyles },
} = ParagraphStyles

export const InputWrapper = styled.div`
  position: relative;
`

export const StyledInput = styled(Input)`
  border-radius: 32px;
  height: 6.4rem;
  padding: 0 12rem 0 2.8rem;

  &::placeholder {
    opacity: 1;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 20rem 0 2.8rem;
  }
`

export const StyledButton = styled(Button)`
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 0;
  height: 4.4rem;
  padding: 0 1.7rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 5rem;
    padding: 0 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 5rem;
  }
`

export const StyledMessage = styled(Message)`
  margin-top: 0.8rem;

  ${mediumStyles}
  font-weight: ${(props) => props.theme.fontWeights.bold};
`
