import { GLOBAL_REDUCER_NAMESPACE } from '../../../../constants'
import { AppState } from '../../../../types'

export const headerDataSelector = (state: AppState) => {
  return state[GLOBAL_REDUCER_NAMESPACE].header.data
}

export const headerSlimDataSelector = (state: AppState) => {
  return state[GLOBAL_REDUCER_NAMESPACE].headerSlim.data
}

export const footerDataSelector = (state: AppState) => {
  return state[GLOBAL_REDUCER_NAMESPACE].footer.data
}

export const viewportInfoSelector = (state: AppState) => {
  return state[GLOBAL_REDUCER_NAMESPACE].viewportInfo
}

export const headerStatusSelector = (state: AppState) => {
  return state[GLOBAL_REDUCER_NAMESPACE].header.status
}

export const selectedArticlesSelector = (state: AppState) => {
  if (state[GLOBAL_REDUCER_NAMESPACE].selectedArticles) {
    return state[GLOBAL_REDUCER_NAMESPACE].selectedArticles.data
  } else {
    return undefined
  }
}

export const browserNotSupportedMessageSelector = (state: AppState) => {
  if (state[GLOBAL_REDUCER_NAMESPACE].browserNotSupportedMessage) {
    return state[GLOBAL_REDUCER_NAMESPACE].browserNotSupportedMessage.data
  } else {
    return undefined
  }
}
