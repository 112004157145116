import styled from 'styled-components'

export const MapStyles = {
  width: '100%',
  height: '80vh',
}

export const StyledPlaceholder = styled.div`
  width: 100%;
  height: 80vh;
`

export const InfoWindowContent = styled.div`
  padding: 0.5rem;
  max-width: 25rem;
  h3,
  h4 {
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 0.5rem;
  }
  h3 b,
  h4 b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
  h3,
  h4,
  p {
    font-family: ${(props) => props.theme.fontFamily.primary};
  }
  p {
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.fontWeights.normal};
    line-height: 2.3rem;
  }
`
