import styled, { css } from 'styled-components'
import { ParallaxBanner } from 'react-scroll-parallax'
import { BackgroundImage, Column } from '../../atoms'
import HeadingStyles from '../../atoms/Title/styles'

const {
  styles: { H4Styles },
} = HeadingStyles

const ParallaxBackground = css`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  bottom: 2rem;
  pointer-events: none;
  max-width: 168rem;
  @media ${(props) => props.theme.breakpoints.tablet} {
    bottom: 3rem;
  }
`

export const StyledParallaxLayer = styled(BackgroundImage)`
  ${ParallaxBackground};
`

export const StyledBaselineImage = styled(BackgroundImage)`
  ${ParallaxBackground};
  bottom: -3rem;
  background-position: bottom;
  width: 110%;
  opacity: 1;
  @media ${(props) => props.theme.breakpoints.tablet} {
    bottom: -5rem;
  }
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  ${ParallaxBackground};
  background-position: center;
  z-index: 0;
  width: 100%;
  height: 110%;

  &:before {
    content: '';
    width: 100%;
    height: 110%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    overflow: hidden;
    &::after {
      width: 168rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      content: '';
      background-image: linear-gradient(
          to top,
          black 5%,
          rgba(0, 0, 0, 0.4) 10%,
          rgba(0, 0, 0, 0) 15%
        ),
        linear-gradient(
          to left,
          black 1%,
          rgba(0, 0, 0, 0.5) 3%,
          rgba(0, 0, 0, 0) 7%
        ),
        linear-gradient(
          to right,
          black 1%,
          rgba(0, 0, 0, 0.5) 3%,
          rgba(0, 0, 0, 0) 7%
        );
    }
  }
`

export const StyledParallaxBanner = styled(ParallaxBanner as any)`
  position: relative;
  background-color: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  min-height: 65rem;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    min-height: 84rem;
  }

  h1 {
    b {
      font-weight: ${(props) => props.theme.fontWeights.light};
      color: ${(props) => props.theme.colors.text.blue};
    }

    p {
      b {
        font-weight: ${(props) => props.theme.fontWeights.bold};
      }
    }
  }
`

export const TitleColumn = styled(Column)`
  margin-bottom: 2rem;
  max-width: 34rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 2.5rem;
    max-width: 38rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 3.4rem;
    max-width: 50rem;
  }
`

export const ParagraphColumn = styled(Column)`
  margin-bottom: 1rem;
  max-width: 36rem;

  p {
    margin-bottom: 1.5rem;
  }

  h2,
  h3 {
    ${H4Styles}
    margin: 2rem 0;
  }

  ul {
    margin: 0 0 2rem;

    li {
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  button {
    margin-top: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin-bottom: 15rem;

    ul li p {
      font-size: 1.5rem;
    }
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: inherit;
  }
`
