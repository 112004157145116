import styled from 'styled-components'
import { BackgroundImage } from '../../atoms'

export const Wrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 4rem 2rem 4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 5rem 3rem 6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 600px;
    padding: 10rem 1.6rem 8rem;
  }
`

export const LeftColumn = styled(Column as any)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-right: 11.6rem;
  }
`

export const RightColumn = styled(Column as any)`
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-left: 11.6rem;
  }
`

export const LeftColumnWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background.black};

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
`

export const RightColumnWrapper = styled.div`
  position: relative;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: auto;
    width: 50%;
  }
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  background-position: bottom;
  background-color: ${(props) => props.theme.colors.background.black};
`
