import { PaddingSizes } from '../../../types/paddingSizes'

/**
 * A function that returns the desired padding size based on the requested
 * amount, in REM value.
 *
 * @param normalPaddingSize - Required. The base padding size.
 * @param desiredPaddingSize - Required. The amount to multiply the base size
 * by.
 *
 * @returns - The padding amount, multiplied if necessary, in REM units.
 */
export const paddingSize = (
  normalPaddingSize: number,
  desiredPaddingSize: PaddingSizes
): string => {
  let padding

  if (desiredPaddingSize === undefined) {
    padding = normalPaddingSize
  }

  if (desiredPaddingSize === 'none') {
    return
  }

  if (desiredPaddingSize === 'quarter') {
    padding = normalPaddingSize / 4
  }

  if (desiredPaddingSize === 'half') {
    padding = normalPaddingSize / 2
  }

  if (desiredPaddingSize === 'default') {
    padding = normalPaddingSize
  }

  if (desiredPaddingSize === 'double') {
    padding = normalPaddingSize * 2
  }

  if (desiredPaddingSize === 'triple') {
    padding = normalPaddingSize * 3
  }

  return `${padding}rem`
}
