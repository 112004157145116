import 'babel-polyfill'
import isequal from 'lodash.isequal'
import throttle from 'lodash.throttle'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { TemplateA, TemplateB, TemplateC } from '../'
import { APP_DEFAULT_THEME_COLOUR } from '../../../../constants/ui'
import { ThemeContextProvider } from '../../../../context'
import GlobalStyle from '../../../../styles/global'
import { AppState } from '../../../../types'
import { getViewportInfo, isBrowserSupported } from '../../../../utils'
import { logPageView } from '../../../../utils/analytics'
import { CustomHead, Scroll } from '../../atoms'
import { ScrollType } from '../../atoms/Scroll/types'
import { BrowserNotSupported } from '../../molecules'
import CallbackFormModal from '../../organisms/CallbackFormModal'

import { actions } from './actions'
import {
  browserNotSupportedMessageSelector,
  footerDataSelector,
  headerDataSelector,
  headerSlimDataSelector,
  viewportInfoSelector,
} from './selectors'
import { ModalProps, Props } from './types'

const addEventListener = (onResize) => {
  window.addEventListener('resize', throttle(onResize, 500), false)
}

export const Layout = (props: Props) => {
  const {
    template,
    children,
    slug,
    noScrollToTop,
    headerData,
    headerType,
    headerIsDark,
    headerSlimData,
    isOnOverlayHero,
    isUsingTransparentHero,
    enableCustomHeaderBackground,
    fixedHeader,
    footerData,
    viewportInfo,
    viewportInfoSet,
    browserNotSupportedMessageData,
    pageTheme: { colour = APP_DEFAULT_THEME_COLOUR } = {},
    metaData: {
      seoMetaData: {
        seoFields = [],
        seoTitle = '',
        canonicalTag,
        seoFieldsCollection,
      } = {},
    } = {},
  } = props

  const templatesOptions = { a: TemplateA, b: TemplateB, c: TemplateC }
  const TemplateComponent = templatesOptions[template]
  const [modalState, setModalState] = useState<ModalProps>({ show: false })
  const browserSupported = isBrowserSupported()
  const seoFieldItems = seoFieldsCollection ? seoFieldsCollection.items : []

  useEffect(() => {
    logPageView(document.location.pathname, seoTitle)
    onResize()
    addEventListener(onResize)
  }, [])

  const onResize = () => {
    const newViewportInfo = getViewportInfo()

    if (!isequal(viewportInfo, newViewportInfo)) {
      viewportInfoSet(newViewportInfo)
    }
  }

  const showModal = () => {
    setModalState({ show: true })
  }

  const hideModal = () => {
    setModalState({ show: false })
  }

  useEffect(() => {
    if (!browserSupported) {
      showModal()
    }
    onResize()
    addEventListener(onResize)
  }, [])

  const seoSlug: string = canonicalTag ? canonicalTag : slug

  return (
    <ThemeContextProvider value={colour}>
      <CustomHead
        seoFields={seoFields.concat(seoFieldItems)}
        seoTitle={seoTitle}
        seoSlug={seoSlug}
      />
      <GlobalStyle />
      <TemplateComponent
        headerData={headerData}
        headerType={headerType}
        headerIsDark={headerIsDark}
        headerSlimData={headerSlimData}
        isOnOverlayHero={isOnOverlayHero}
        isUsingTransparentHero={isUsingTransparentHero}
        enableCustomHeaderBackground={enableCustomHeaderBackground}
        fixedHeader={fixedHeader}
        footerData={footerData}
        viewportInfo={viewportInfo}
      >
        {children}
        {browserNotSupportedMessageData && (
          <BrowserNotSupported
            visible={modalState.show}
            data={browserNotSupportedMessageData}
            clickHandler={hideModal}
          />
        )}
      </TemplateComponent>
      <CallbackFormModal />
      {!noScrollToTop && <Scroll scrollType={ScrollType.Top} />}
    </ThemeContextProvider>
  )
}

export const mapStateToProps = (state: AppState) => {
  return {
    viewportInfo: viewportInfoSelector(state),
    headerData: headerDataSelector(state),
    headerSlimData: headerSlimDataSelector(state),
    footerData: footerDataSelector(state),
    browserNotSupportedMessageData: browserNotSupportedMessageSelector(state),
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    viewportInfoSet: (viewportInfo) =>
      dispatch(actions.viewportInfoSet(viewportInfo)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
