import styled from 'styled-components'

import { Row } from '../../atoms'

export const Section = styled.section`
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 5%;
  }

  h4 p {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};
    line-height: ${(props) => props.theme.lineHeights.base};
    font-weight: ${(props) => props.theme.fontWeights.light};
    margin-top: 1rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h2};
      line-height: ${(props) => props.theme.lineHeights.close};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h2};
      line-height: ${(props) => props.theme.lineHeights.base};
      margin-top: 1.4rem;
    }
  }

  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  h5 {
    font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
    line-height: ${(props) => props.theme.lineHeights.compact};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin: 2.3rem 0 1.7rem;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${(props) => props.theme.breakpoints.tablet} {
      line-height: ${(props) => props.theme.lineHeights.compact};
      font-weight: ${(props) => props.theme.fontWeights.bold};
      margin: 3.3rem 0 1.6rem;
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
      line-height: ${(props) => props.theme.lineHeights.default};
      font-weight: ${(props) => props.theme.fontWeights.bold};
      margin: 0.5rem 0 1.6rem;
    }
  }
`

export const ItemsRow = styled(Row)`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex: 1 1 33.333%;
    padding: 0 2.5%;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 3%;
  }
`
