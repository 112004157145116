import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { Link } from '../../atoms/Button/styles'

import {
  StyledDragIconBar,
  StyledItemText,
  StyledList,
  StyledListItem,
  StyledSkipButton,
} from './types'

export const Wrapper = styled.div`
  display: flex;
  margin-top: 3.5rem;
`

export const NumberList = styled.ul`
  display: flex;
  flex-flow: column;
  color: ${(props) => props.theme.colors.text.black};
`

export const NumberListItem = styled.li`
  flex: 1 0 auto;
  align-items: center;
  display: flex;
  margin-right: 0.8rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-right: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 3.2rem;
  }
`

export const List = styled.ul<StyledList>`
  display: grid;
  flex: 1;
  grid-auto-rows: 1fr;
  color: ${(props) => props.theme.colors.text.black};
`

export const ItemNumber = styled.div`
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  flex: 0 0 auto;
  line-height: ${(props) => props.theme.lineHeights.base};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background.white};
  border-radius: 100%;
`

export const ItemText = styled.div<StyledItemText>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 1.6rem 3.2rem 1.6rem 1.6rem;
  transition: background-color ${(props) => props.theme.transitions.normal} ease,
    color ${(props) => props.theme.transitions.normal} ease;

  ${(props) =>
    props.isDragging
      ? props.isBeingDragged
        ? css`
            background-color: ${props.theme.colors.background.white};
          `
        : css`
            color: ${props.theme.colors.background.white};
            background-color: ${rgba(props.theme.colors.background.white, 0.2)};
          `
      : css`
          background-color: ${props.theme.colors.background.white};
        `};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 1.6rem 4.8rem 1.6rem 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 1.6rem 6.4rem 1.6rem 3.2rem;
  }
`

const Dot = css`
  display: inline-block;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 100%;
`

export const DragIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  line-height: 0;

  @media ${(props) => props.theme.breakpoints.tablet} {
    right: 1.5rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    right: 2.5rem;
  }
`

export const DragIconBar = styled.span<StyledDragIconBar>`
  position: relative;
  ${Dot}

  ${(props) =>
    props.isDragging
      ? props.isBeingDragged
        ? css`
            background-color: ${props.theme.colors.background.grey4};
          `
        : css`
            background-color: ${props.theme.colors.background.white};
          `
      : css`
          background-color: ${props.theme.colors.background.grey4};
        `};

  &::before,
  &::after {
    content: '';
    position: absolute;
    transition: transform ${(props) => props.theme.transitions.normal} ease,
      background-color ${(props) => props.theme.transitions.normal} ease;
    ${Dot}

    ${(props) =>
      props.isDragging
        ? props.isBeingDragged
          ? css`
              background-color: ${props.theme.colors.background.grey4};
            `
          : css`
              background-color: ${props.theme.colors.background.white};
            `
        : css`
            background-color: ${props.theme.colors.background.grey4};
          `};
  }

  &::before {
    top: -0.6rem;
  }

  &::after {
    bottom: -0.6rem;
  }

  & + & {
    margin-left: 0.4rem;
  }
`

const DotHover = css`
  ${DragIconBar} {
    &::before {
      transform: translateY(-0.3rem);
    }

    &::after {
      transform: translateY(0.3rem);
    }
  }
`

export const ListItem = styled.li<StyledListItem>`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  margin: 0.8rem 0;
  cursor: move;

  ${(props) => props.styles}

  ${(props) =>
    !props.isActive &&
    !props.hasDragged &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.isActive &&
    !props.hasDragged &&
    css`
      opacity: 0;
      animation: ${props.theme.animations.fadeIn}
        ${props.theme.transitions.verySlow} ${props.index * 0.2 + 1}s ease
        forwards;
      animation-iteration-count: 1;
    `}

  &:hover {
    ${(props) => !props.isDragging && DotHover}
  }
`

export const SkipButton = styled(Link)<StyledSkipButton>`
  height: auto;
  color: ${(props) => props.theme.colors.text.white};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  text-decoration: underline;
  margin: 1.2rem 0 0 4.4rem;
  opacity: 0;
  animation: ${(props) => props.theme.animations.fadeIn}
    ${(props) => props.theme.transitions.verySlow}
    ${(props) => props.animationDelay * 0.2 + 1}s ease forwards;
  animation-iteration-count: 1;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin: 1.8rem 0 0 5.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin: 2.5rem 0 0 10rem;
  }
`
