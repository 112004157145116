import { FunctionComponent } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { Column, Row } from '../../atoms'
import { CallToAction, FeeGrid } from '../../molecules'
import { FeeGridData } from '../FeeGrid/types'
import { Props } from './types'

import {
  PricingContentBlock,
  StyledColumn,
  Wrapper,
  ButtonWrapper,
  Container,
} from './styles'

const TieredPricing: FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { data } = props

  const pricingContentBlockData = {
    _id: data._id,
    _type: data._type,
    entryTitle: data.entryTitle,
    headline: data.headline,
    description: data.description,
  }

  const feeGridData: FeeGridData = {
    feeTableLeft: {
      headline: data.feeBlockLeftTitle,
      feeBlocks: data.feeBlockLeft,
    },
    feeTableRight: {
      headline: data.feeBlockRightTitle,
      feeBlocks: data.feeBlockRight,
    },
  }

  return (
    <Wrapper>
      <Container data-bdd-selector="tiered-pricing">
        {pricingContentBlockData && (
          <Row horizontalAlignment="center">
            <StyledColumn tablet={8} desktop={9}>
              <PricingContentBlock
                data={pricingContentBlockData}
                colorTheme={colorTheme}
                data-bdd-selector="tiered-pricing-heading"
              />
            </StyledColumn>
          </Row>
        )}
        {feeGridData && (
          <Row>
            <Column>
              <FeeGrid
                data={feeGridData}
                data-bdd-selector="tiered-pricing-fee-grid"
              />
            </Column>
          </Row>
        )}
        {data.callToAction && (
          <ButtonWrapper>
            <CallToAction {...data.callToAction} />
          </ButtonWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

export default TieredPricing
