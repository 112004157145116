import React from 'react'
import { ReactSVG } from 'react-svg'

import { GetThemeContext, ThemeContext } from '../../../../context'

import { StyledHeader, Wrapper } from './styles'
import { Props } from './types'

// Remove spaces from numbers to prevent any issues across devices when OS
// initiates dialler.
const phoneNumberWithoutSpaces = (phoneNumber: string) => {
  return phoneNumber.toString().replace(/\s/g, '')
}

export const AdviserContactCard = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    className,
    data: { name, city, regionC, email, phone, linkedinUrlC },
  } = props
  if (!name && !city && !regionC && !email && !phone && !linkedinUrlC) {
    return null
  }

  return (
    <Wrapper
      data-bdd-selector="adviser-contact-card"
      className={className}
      colorTheme={colorTheme}
    >
      {name && (
        <StyledHeader data-bdd-selector="adviser-contact-card-header">
          Contact {name}
        </StyledHeader>
      )}
      <ul>
        {(regionC || city) && (
          <li data-bdd-selector="adviser-contact-card-region">
            <ReactSVG src="/static/icons/fontawesome/map-marker-alt.svg" />
            {`${city ? city : ''}${city && regionC ? ', ' : ''}${
              regionC ? regionC : ''
            }`}
          </li>
        )}
        {email && name && (
          <li data-bdd-selector="adviser-contact-card-email">
            <a href={`mailto:${email}`}>
              <ReactSVG src="/static/icons/fontawesome/envelope.svg" />
              {`Email ${name}`}
            </a>
          </li>
        )}
        {phone && name && (
          <li data-bdd-selector="adviser-contact-card-phone">
            <a href={`tel:${phoneNumberWithoutSpaces(phone)}`}>
              <ReactSVG src="/static/icons/fontawesome/phone-alt.svg" />

              {`Call ${name}`}
            </a>
          </li>
        )}
        {linkedinUrlC && (
          <li data-bdd-selector="adviser-contact-card-likedin">
            <a href={linkedinUrlC} target="_blank">
              <ReactSVG src="/static/icons/fontawesome/linkedin.svg" />
              Connect on LinkedIn
            </a>
          </li>
        )}
      </ul>
    </Wrapper>
  )
}

export default AdviserContactCard
