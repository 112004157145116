// tslint:disable-next-line:no-implicit-dependencies
import { rgba } from 'polished'
import 'semantic-ui-css/components/dropdown.min.css'
import { Dropdown } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { colorTheme } from '../../../../styles/utils'
import ParagraphStyles from '../Paragraph/styles'

import { DropdownProps, StyledTab, StyledWrapper, TriggerProps } from './types'

const {
  styles: { overlineStyles },
} = ParagraphStyles

export const Wrapper = styled.div<StyledWrapper>`
  .ui.dropdown.active .menu .item {
    padding: 1.4rem ${(props) => (props.mobileIcon ? 4.6 : 2)}rem !important;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: center;
  }
`

export const StyledTrigger = styled.div<TriggerProps>`
  font-weight: ${(props) =>
    props.dropdownOpened
      ? props.theme.fontWeights.normal
      : props.theme.fontWeights.bold};

  display: flex;
  align-items: center;
  line-height: ${(props) => props.theme.lineHeights.tight};

  & div + span {
    margin-left: 1rem;
  }

  & + .icon {
    pointer-events: none;
  }
`

export const StyledDropdown = styled(Dropdown)<DropdownProps>`
  color: ${(props) => colorTheme(props.colorTheme, 'tabs.textThemeColor')};

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: none !important;
  }

  &.ui.dropdown {
    border-radius: 2.3rem;
    border: 0;
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    box-shadow: none;
    background: ${(props) =>
      colorTheme(props.colorTheme, 'tabs.backgroundThemeColor')};

    &.visible {
      border-radius: 2.3rem 2.3rem 0 0;
    }

    &:hover {
      box-shadow: none;
      border: 0;

      &.active .menu {
        box-shadow: 0 2px 26px 0
          ${(props) => rgba(props.theme.colors.background.black, 0.08)};
        border: 0;
      }
    }

    & .icon {
      margin-left: auto;
      line-height: ${(props) => props.theme.lineHeights.none};

      & svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    & > .text {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }

    & .item {
      color: ${(props) => colorTheme(props.colorTheme, 'tabs.textThemeColor')};

      @media ${(props) => props.theme.breakpoints.mobileMax} {
        white-space: normal;
        line-height: ${(props) => props.theme.lineHeights.tight};
      }
    }

    &.active .menu {
      background: ${(props) =>
        colorTheme(props.colorTheme, 'tabs.backgroundThemeColor')};
      width: 100%;
      min-width: 100%;
      border-radius: 0 0 2.3rem 2.3rem;
      max-height: unset;
      box-shadow: 0 2px 26px 0
        ${(props) => rgba(props.theme.colors.background.black, 0.08)};
      border: 0;
      overflow: hidden;

      & > .item {
        color: ${(props) =>
          colorTheme(props.colorTheme, 'tabs.textThemeColor')};
        font-size: 1.5rem;
        white-space: normal;
        padding: 1.4rem ${(props) => (props.mobileIcon ? 4.6 : 2)}rem !important;
        border-top: 2px solid
          ${(props) => colorTheme(props.colorTheme, 'tabs.borderThemeColor')};

        &:hover {
          background: inherit;
          color: ${(props) =>
            colorTheme(props.colorTheme, 'tabs.textThemeColor')};
        }
      }

      & > .selected.item {
        background: inherit;
        color: ${(props) =>
          colorTheme(props.colorTheme, 'tabs.textThemeColor')};
      }
    }

    &.upward {
      &.visible {
        border-radius: 0 0 2.3rem 2.3rem;
      }

      &.active .menu {
        border-radius: 2.3rem 2.3rem 0 0;
      }
    }
  }

  & .menu.visible {
    visibility: visible !important;
    display: block !important;
  }
`

export const TabsList = styled.ul`
  display: none;
  justify-content: center;

  position: relative;

  &::after {
    content: '';
    height: 2px;
    border-radius: 1.5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${(props) => props.theme.colors.ui.grey4};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    width: 100%;
    max-width: ${(props) => props.theme.dimensions.containerMaxWidth};

    li {
      flex: 1;
    }
  }
`

export const TabText = styled.span`
  white-space: normal;
`

export const Tab = styled.button<StyledTab>`
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 0;
  font-family: inherit;
  background-color: transparent;
  appearance: initial;
  ${overlineStyles}

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) =>
      props.isNested
        ? props.theme.colors.text.white
        : props.theme.colors.text.grey4};

    padding: 1.2rem 0.8rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 1.5rem 0.8rem 2.5rem;
  }

  ${(props) =>
    props.selected &&
    css`
      @media ${props.theme.breakpoints.tablet} {
        color: ${colorTheme(props.colorTheme, 'tabs.selectedTextThemeColor')};
        position: relative;

        &::after {
          content: '';
          position: absolute;
          height: 2px;
          background-color: ${colorTheme(
            props.colorTheme,
            'tabs.selectedBorderThemeColor'
          )};
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 1;
        }
      }
    `};
`

export const TabIcon = styled.span<StyledTab>`
  position: relative;
  width: 6.4rem;
  height: 6.4rem;
  margin-bottom: 1.6rem;
  border-radius: 50%;
  border: solid 0.2rem;

  border-color: ${(props) => {
    if (props.selected)
      return colorTheme(
        props.colorTheme,
        'tabs.selectedIconBackgroundThemeColor'
      )
    if (props.isNested) return props.theme.colors.ui.white
    return props.theme.colors.ui.grey4
  }};

  background-color: ${(props) =>
    props.selected &&
    colorTheme(props.colorTheme, 'tabs.selectedIconBackgroundThemeColor')};

  svg {
    position: absolute;
    top: 1.4rem;
    left: 1.4rem;
    width: 3.2rem;
    height: 3.2rem;

    fill: ${(props) => {
      if (props.selected)
        return colorTheme(props.colorTheme, 'tabs.selectedIconThemeColor')
      if (props.isNested) return props.theme.colors.ui.white
      return props.theme.colors.ui.grey4
    }};
  }
`
