import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Container, RichText } from '../../atoms'

export const Wrapper = styled.div`
  text-align: center;
  background: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  position: relative;
`

export const StyledContainer = styled(Container)``

export const ArticleCarouselWrapper = styled.div`
  margin: auto;
  margin-top: 3.2rem;
  margin-bottom: 8.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.7rem;
    margin-bottom: 11.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 4.5rem;
    margin-bottom: 11.4rem;
  }
`

export const Headline = styled(RichText)`
  b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'selectedArticles.textAccentThemeColor')};
  }
`
