import styled, { css } from 'styled-components'

import { Column, Row } from '../../../../atoms'

export const Wrapper = styled.div``

export const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.background.black};
  color: ${(props) => props.theme.colors.text.white};
  font-size: ${(props) => props.theme.fontSizes.mobile.footer.primary};
  position: relative;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.footer.primary};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.footer.primary};
  }
`

export const LogoColumn = styled(Column)`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin-bottom: 5.5rem;
  }
`

export const FooterLogo = styled.div`
  width: 13.6rem;
  height: 5rem;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 16rem;
    height: 4.4rem;
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 19rem;
    height: 6.2rem;
  }
`

const LinkStyles = css`
  a {
    transition: color ${(props) => props.theme.transitions.moderate} ease;

    &:hover,
    &:active,
    &:focus {
      color: ${(props) => props.theme.colors.text.blue};
    }
  }
`

export const LinkListItem = styled.li`
  & + & {
    margin-top: 1.3rem;
  }

  ${LinkStyles}
`

export const SocialLinks = styled.div`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    display: flex;
    align-items: center;
    margin-top: 4rem;
  }
`

export const SocialList = styled.ul`
  line-height: 1;
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    margin-left: 2rem;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.2rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 1.5rem;
  }
`

export const SocialLinksHeading = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  display: flex;
  align-items: center;

  ul {
    display: flex;
    margin-left: 1.2rem;
  }
`

export const SocialListItem = styled.li`
  display: inline-block;

  & + & {
    margin-left: 1.2rem;
  }
`

export const SocialListLink = styled.a`
  display: block;
  svg {
    circle {
      stroke: ${(props) => props.theme.colors.text.white};
    }
    path:last-of-type {
      fill: ${(props) => props.theme.colors.text.white};
    }
  }

  &:hover {
    svg {
      circle {
        stroke: ${(props) => props.theme.colors.text.white};
      }
    }
  }
`

export const Copyright = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

export const SecondaryLinksRow = styled(Row)`
  margin-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.1rem;
  }
`

export const SecondaryLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const SecondaryLinksItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      height: 0.2rem;
      width: 0.2rem;
      background-color: ${(props) => props.theme.colors.ui.beige};
      border-radius: 100%;
      margin: 0 1rem;
    }
  }

  ${LinkStyles}
`

export const Disclaimer = styled.div`
  margin-top: 5rem;

  p {
    font-size: ${(props) => props.theme.fontSizes.mobile.footer.primary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.footer.primary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.footer.primary};
    }
  }
`
