import { Fragment, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { Icons } from '../../../Icon/types'
import { Input, InputContainer, Message, HelpText } from '../../styles'
import { TextInputLabel } from '../Text/styles'

const PhoneNumberInput = ({
  field,
  form: { touched, errors, dirty, setFieldTouched },
  id,
  colorTheme,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [oldValue, setOldValue] = useState('')
  const [hadValue, setHadValue] = useState(undefined)

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleOnBlur = (e) => {
    field.onBlur(e)
    setIsFocused(false)
  }

  const preventNonNumericalInput = (e) => {
    // Check if value is digit
    const value = e.target.value
    if (/^\+?\d*$/.test(value)) {
      // Set the old value (used below) to the current new value.
      setOldValue(field.value || '')

      // Set the caret the caret positions.
      e.target.oldSelectionStart = e.target.selectionStart
      e.target.oldSelectionEnd = e.target.selectionEnd

      // If previously had value but not a digit value, so any time after any first key input.
      setHadValue(true)
      setFieldTouched(field.name, true)
    } else if (hadValue) {
      // Set the value to the old value before the non-alphanumeric value was entered.
      e.target.value = oldValue || ''
      // Set the selection range to where the user left off.
      e.target.setSelectionRange(
        e.target.oldSelectionStart,
        e.target.oldSelectionEnd
      )
    }
  }

  const { label, helpText } = props

  const isTouched = touched[field.name]
  const hasErrors = isTouched ? errors[field.name] : ''
  const hasValue = Boolean(field.value)

  const errorsInField = typeof hasErrors !== 'undefined'
  const icon =
    (isTouched && errorsInField && 'ExclamationMark') ||
    (isTouched && !hasErrors && 'Tick')

  return (
    <Fragment>
      <InputContainer>
        <TextInputLabel
          htmlFor={id}
          focus={isFocused}
          dirty={dirty}
          isTouched={isTouched}
          errorsInField={errorsInField}
          hasValue={hasValue}
          colorTheme={colorTheme}
        >
          {label}
        </TextInputLabel>
        <Input
          {...field}
          {...props}
          id={id}
          type="tel"
          isTouched={isTouched}
          errorsInField={errorsInField}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onInput={preventNonNumericalInput}
          onKeyDown={preventNonNumericalInput}
          onKeyUp={preventNonNumericalInput}
          onMouseDown={preventNonNumericalInput}
          onMouseUp={preventNonNumericalInput}
          onSelect={preventNonNumericalInput}
          onContextMenu={preventNonNumericalInput}
          onDrop={preventNonNumericalInput}
          data-bdd-selector={`input_${field.name}`}
          className={isFocused && 'focused'}
          colorTheme={colorTheme}
        />
        {icon && (
          <ReactSVG
            className="a-icon"
            src={`/static/icons/${icon as Icons}.svg`}
          />
        )}
      </InputContainer>
      <Message
        className="error"
        data-bdd-selector={`input_${field.name}_message`}
      >
        {hasErrors}
      </Message>
      {helpText && (
        <HelpText data-bdd-selector={`input_${field.name}_helpText`}>
          {helpText}
        </HelpText>
      )}
    </Fragment>
  )
}

export default PhoneNumberInput
