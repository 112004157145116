import React, { FunctionComponent } from 'react'

import { Image, RichText } from '../../atoms'
import TrustpilotCarousel from '../../atoms/TrustpilotCarousel'
import { Props } from './types'

import {
  StyledContainer as Container,
  Section,
  Wrapper,
  StyledRow,
  Description,
  ImageLink,
  ImageContainer,
} from './styles'

const TrustpilotCarouselBlock: FunctionComponent<Props> = (props) => {
  const {
    data: { title, description, image, url, target, linkTitle },
  } = props
  // Image needs an array
  const imageAsArray = image ? [image] : null
  return (
    <Wrapper>
      <Container paddingSize="double" center={true}>
        <Section>
          <RichText html={title} />
          {description && (
            <Description>
              <RichText html={description} />
            </Description>
          )}
          {imageAsArray ? (
            <StyledRow>
              <Container>
                <ImageContainer>
                  {url ? (
                    <ImageLink
                      title={linkTitle}
                      href={url}
                      {...(target === 'New Window' && { target: '_blank' })}
                    >
                      <Image
                        data={imageAsArray}
                        data-bdd-selector="what-customers-say-image"
                      />
                    </ImageLink>
                  ) : (
                    <Image
                      data={imageAsArray}
                      data-bdd-selector="what-customers-say-image"
                    />
                  )}
                </ImageContainer>
              </Container>
            </StyledRow>
          ) : (
            <StyledRow>
              <Container>
                <TrustpilotCarousel />
              </Container>
            </StyledRow>
          )}
        </Section>
      </Container>
    </Wrapper>
  )
}

export default TrustpilotCarouselBlock
