import { FunctionComponent } from 'react'

import { SkipToContentWrapper } from './styles'

const SkipToContent: FunctionComponent = () => {
  return (
    <SkipToContentWrapper>
      <li aria-label="skip to main content">
        <a href="#main">Skip to Content</a>
      </li>
      <li aria-label="skip to site footer">
        <a href="#footer">Skip to Footer</a>
      </li>
    </SkipToContentWrapper>
  )
}

export default SkipToContent
