import styled from 'styled-components'
import { Column, RichText } from '../../atoms'

export const StyledModalContent = styled.div`
  &&&&&&&& {
    background-color: ${(props) => props.theme.colors.background.black};
    padding-right: 4em;
    overflow: auto;
    color: ${(props) => props.theme.colors.text.white};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 0;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    form > div > div:nth-child(1) {
      flex-basis: 100%;
      max-width: 100%;
    }

    form > div > div:nth-child(2) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  [class*='__CheckboxInputLabel'] {
    color: ${(props) => props.theme.colors.text.white};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.body.secondary};
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.primary};
    }
  }
`

export const Headline = styled(RichText)`
  color: black;
  h3,
  h4 {
    font-size: 3.5rem;
    font-weight: ${(props) => props.theme.fontWeights.light};
    line-height: 3.9rem;
    padding: 0;
    margin-bottom: 2rem;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 5rem;
      line-height: 5.5rem;
      margin-bottom: 3rem;
    }
  }
  h3,
  h4 b {
    color: ${(props) => props.theme.colors.text.blue};
    font-weight: ${(props) => props.theme.fontWeights.light};
  }
`

export const Paragraph = styled(Column)`
  color: black;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-top: 1rem;
  padding: 0;
  white-space: pre-wrap;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 1.5rem;
  }
`
