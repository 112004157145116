import * as React from 'react'

import { DisclaimerRichText } from './styles'
import { Props } from './types'

const Disclaimer: React.FunctionComponent<Props> = (props) => {
  const {
    data: { disclaimerText },
  } = props

  return <DisclaimerRichText html={disclaimerText} />
}

export default Disclaimer
