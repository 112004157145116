import { FunctionComponent } from 'react'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { Row, Column } from '../../atoms'

import { Wrapper, ContentContainer, StyledContentBlock } from './styles'
import { Props } from './types'

const CallbackBlock: FunctionComponent<Props> = (props) => {
  const {
    data: { contentItem },
  } = props
  const colorTheme = GetThemeContext(ThemeContext)

  return (
    <Wrapper data-bdd-selector="callback-block">
      <ContentContainer colorTheme={colorTheme}>
        <Row>
          <Column>
            {contentItem && (
              <StyledContentBlock data={contentItem} isNested={true} />
            )}
          </Column>
        </Row>
      </ContentContainer>
    </Wrapper>
  )
}

export default CallbackBlock
