/* istanbul ignore file */
// Rendering <Head> returns 'null' and can't get the result of it for testing without navigating the react object.
// Refer: https://spectrum.chat/next-js/general/testing-head-in-jest-with-react-testing-library~7957fa45-be54-4673-9f8e-8caa70a48e15

import Head from 'next/head'
import * as React from 'react'

import { Props } from './types'

// the head tags need to be direct children of Head, else it doesn't work !
// https://github.com/zeit/next.js/issues/5628
const CustomHead: React.FunctionComponent<Props> = (props) => {
  const { seoFields = [], seoTitle = '', seoSlug = '' } = props
  const canonicalURL = `https://www.spw.com/${seoSlug}`
  return (
    <Head>
      <title>{seoTitle}</title>
      <link rel="canonical" href={canonicalURL} />
      {seoFields.map((item, index) =>
        item.isProperty ? (
          <meta
            property={item.keyName}
            content={item.keyValue}
            key={`${index}-${item._id}`}
          />
        ) : (
          <meta
            name={item.keyName}
            content={item.keyValue}
            key={`${index}-${item._id}`}
          />
        )
      )}
    </Head>
  )
}

export default CustomHead
