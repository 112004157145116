import styled, { css } from 'styled-components'

import theme from '../../../../styles/theme'
import { colorTheme } from '../../../../styles/utils'
import { Column, Title } from '../../atoms'
const { breakpoints } = theme

import { StyledTitleColumn } from './types'

interface WrapperProps {
  textColor: string
  backgroundColor: string
  desktopImage?: string
  tabletImage?: string
  mobileImage?: string
}

export const StyledWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  min-height: 50rem;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  ${(props) =>
    props.mobileImage &&
    css`
      background-image: url(${props.mobileImage + '?q=90'});
    `}
  background-position: center;
  background-size: contain;

  @media ${breakpoints.tablet} {
    padding: 0
      ${(props) =>
        props.tabletImage &&
        css`
          background-image: url(${props.tabletImage + '?q=90'});
        `};
  }

  @media ${breakpoints.desktop} {
    ${(props) =>
      props.desktopImage &&
      css`
        background-image: url(${props.desktopImage + '?q=90'});
      `}
  }

  @media ${breakpoints.tablet} {
    padding: 3rem 2rem 4rem;
  }

  @media ${breakpoints.desktop} {
    padding: 3.5rem 3rem 5rem;
  }
`

export const ParagraphColumn = styled(Column)`
  margin-top: 3.2rem;

  div {
    font-size: ${(props) => props.theme.fontSizes.mobile.body.primary};
    @media ${breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.body.tertiary};
    }

    @media ${breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.body.tertiary};
    }
    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }
    display: inline;
  }
`

export const FormWrapper = styled(Column)`
  margin-top: 3.2rem;

  form > div > div:nth-child(1) {
    flex-basis: 100%;
  }

  form > div > div:nth-child(2) {
    flex-basis: 100%;
  }

  form .error {
    margin-left: 0;
  }
  @media ${breakpoints.tablet} {
    form > div > div:nth-child(1) {
      flex-basis: 50%;
    }

    form > div > div:nth-child(2) {
      flex-basis: 50%;
    }
  }
`

export const TitleColumn = styled(Column)<StyledTitleColumn>`
  padding: 0 2rem;
  text-align: center;

  h3 {
    font-weight: ${(props) => props.theme.fontWeights.normal};
    b {
      font-weight: ${(props) => props.theme.fontWeights.bold};
      color: ${(props) =>
        colorTheme(
          props.colorTheme,
          'newsletterSignUpBanner.textAccentThemeColor'
        )};
    }
  }

  h2 {
    b {
      color: ${(props) =>
        colorTheme(
          props.colorTheme,
          'newsletterSignUpBanner.textAccentThemeColor'
        )};
    }
  }

  & p {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};
    line-height: 1.3;
    @media ${breakpoints.tablet} {
      font-size: ${(props) => props.theme.fontSizes.tablet.headings.h3};
    }

    @media ${breakpoints.desktop} {
      font-size: ${(props) => props.theme.fontSizes.desktop.headings.h3};
    }

    b {
      color: ${(props) =>
        colorTheme(
          props.colorTheme,
          'newsletterSignUpBanner.textAccentThemeColor'
        )};
    }
  }

  margin-top: 1.8rem;
  @media ${breakpoints.tablet} {
    font-size: 2.3rem;
  }
`

export const ButtonColumn = styled(Column)`
  margin-top: 3.2rem;
`

export const ResultWrapper = styled(Column)``

export const Headline = styled(Title)`
  margin-top: 3rem;

  & + p {
    margin-top: 1rem;
  }
`

export const SubHeadline = styled.p``

export const Message = styled.p`
  font-size: ${(props) => props.theme.fontSizes.desktop.body.secondary};
  @media ${breakpoints.tablet} {
    font-size: ${(props) => props.theme.fontSizes.tablet.body.secondary};
  }

  @media ${breakpoints.desktop} {
    font-size: ${(props) => props.theme.fontSizes.desktop.body.secondary};
  }
  line-height: 2.8rem;
  margin-top: 2.4rem;
`

export const Icon = styled.img`
  margin-top: 2rem;
  height: 12rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 3.2rem;
  font-size: 1.8rem;
`
