import styled from 'styled-components'

import { Description, Title } from '../../atoms/FeaturedArticleCard/styles'

export const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 22.4rem);
  grid-gap: 1.6rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 20.4rem);
    grid-gap: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 22.6rem);
    grid-gap: 3.2rem;
  }
`

export const FeaturedCardItem = styled.li`
  &:first-child {
    grid-row: span 2;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    &:first-child {
      grid-column: span 2;
    }
  }

  &:not(:first-child) {
    ${Title} {
      font-size: 2rem;
    }

    ${Description} {
      display: none;
    }
  }
`
