import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import { Button, Container, Tabs } from '../../atoms'
import { Tab, TabsList } from '../../atoms/Tabs/styles'

import { StyledHeadline } from './types'

export const ViewMoreButton = styled(Button)`
  margin-top: 3.2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.9rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 5rem;
  }
`

export const StyledContainer = styled(Container)`
  text-align: center;
`

export const Headline = styled.div<StyledHeadline>`
  b {
    color: ${(props) =>
      colorTheme(props.colorTheme, 'richText.textAccentThemeColor')};
  }
`

export const StyledTabs = styled(Tabs)`
  ${TabsList} {
    justify-content: space-between;
    padding: 0 7.5rem;
  }

  ${Tab} {
    max-width: 14.9rem;
  }

  margin-top: 2.4rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 6rem;
  }
`

export const CardsContainer = styled.div`
  margin-top: 2.4rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 3.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 6rem;
  }
`
