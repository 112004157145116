import { GetThemeContext, ThemeContext } from '../../../../context'
import { BackgroundImage, Column, Container, Row } from '../../atoms'

import {
  Headline,
  MetaDataIcon,
  MetaDataList,
  MetaDataListItem,
  MetaDataText,
  Overline,
  Wrapper,
} from './styles'
import { Props } from './types'

const ArticleHero = (props: Props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: { backgroundImage, overline, headline },
    metaData,
  } = props

  return (
    <BackgroundImage
      data={backgroundImage}
      colorTheme={colorTheme}
      overlayOpacity={0.7}
    >
      <Wrapper colorTheme={colorTheme} data-bdd-selector="article-hero">
        <Container paddingSize="none">
          <Row>
            <Column desktop={[10, 1]}>
              {overline && (
                <Overline data-bdd-selector="article-hero-overline">
                  {overline}
                </Overline>
              )}
              {headline && (
                <Headline
                  html={headline}
                  data-bdd-selector="article-hero-headline"
                />
              )}
              <MetaDataList data-bdd-selector="article-hero-meta-data">
                {metaData.author && (
                  <MetaDataListItem colorTheme={colorTheme}>
                    <MetaDataIcon
                      src={`/static/icons/author.svg`}
                      data-bdd-selector="article-hero-meta-data-author-icon"
                    />
                    <MetaDataText data-bdd-selector="article-hero-meta-data-author">
                      {metaData.author}
                    </MetaDataText>
                  </MetaDataListItem>
                )}
                {metaData.publishedDateAge && (
                  <MetaDataListItem colorTheme={colorTheme}>
                    <MetaDataIcon
                      src={`/static/icons/pencil.svg`}
                      data-bdd-selector="article-hero-meta-data-publish-date-icon"
                    />
                    <MetaDataText data-bdd-selector="article-hero-meta-data-publish-date">
                      {metaData.publishedDateAge}
                    </MetaDataText>
                  </MetaDataListItem>
                )}
                {metaData.timeOnPage && (
                  <MetaDataListItem colorTheme={colorTheme}>
                    <MetaDataIcon
                      src={`/static/icons/time.svg`}
                      data-bdd-selector="article-hero-meta-data-publish-time-icon"
                    />
                    <MetaDataText data-bdd-selector="article-hero-meta-data-publish-time">
                      {metaData.timeOnPage}
                    </MetaDataText>
                  </MetaDataListItem>
                )}
              </MetaDataList>
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </BackgroundImage>
  )
}

export default ArticleHero
