import styled from 'styled-components'

import { RichText } from '../../../common/atoms'

export const CaptionText = styled(RichText)`
  & + & > {
    p:first-of-type {
      margin-top: 0;
    }
  }
  h6 {
    display: inherit;
  }
  padding-right: 0.5rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: inline;
  }
`

export const Credit = styled(CaptionText)`
  color: ${(props) => props.theme.colors.ui.greyMedium};
`

export const Wrapper = styled.div`
  margin-top: 1rem;
  border-left: solid 2px ${(props) => props.theme.colors.ui.beigeMedium};
  padding-left: 1rem;
`
