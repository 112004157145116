import styled from 'styled-components'

import { FeeBlock } from '..'
import { Column } from '../../atoms'

import { FeeBlockStyle } from './types'

export const Wrapper = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledFeeBlock = styled(FeeBlock)<FeeBlockStyle>`
  @media ${(props) => props.theme.breakpoints.mobile} {
    flex-basis: ${(props) => props.colWidths.mobile}%;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-basis: ${(props) => props.colWidths.tablet}%;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    flex-basis: ${(props) => props.colWidths.desktop}%;
  }
`
