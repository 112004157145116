import { CALLBACK_FORM_REDUCER_NAMESPACE } from '../../../../constants'
import { namespacer } from '../../../../utils'

const namespacedAction = namespacer(CALLBACK_FORM_REDUCER_NAMESPACE)

export const actionTypes = {
  CLOSE: namespacedAction('CLOSE'),
}

export const actions = {
  close() {
    return {
      payload: {
        visible: false,
      },
      type: actionTypes.CLOSE,
    }
  },
}
