import * as React from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { RichText } from '../../atoms'

import { Credit, Quote, QuoteWrapper } from './styles'
import { Props } from './types'

const PullQuote: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { quote, credit } = props

  return (
    <QuoteWrapper colorTheme={colorTheme}>
      <Quote>
        <RichText html={quote} data-bdd-selector="pullquote-caption" />
      </Quote>
      <Credit>
        <RichText html={credit} data-bdd-selector="pullquote-credit" />
      </Credit>
    </QuoteWrapper>
  )
}

export default PullQuote
