import React, { Fragment } from 'react'
import { ReactSVG } from 'react-svg'

import { GetThemeContext, ThemeContext } from '../../../../context'
import { HiddenText, LoadingSpinner } from '../../atoms'
import { Icons, mapIconName } from '../Icon/types'

import {
  CloseButton,
  Icon,
  Link,
  PrimaryButton,
  PrimaryButtonDisabled,
  SecondaryButton,
  SecondaryButtonDisabled,
  TertiaryButton,
  TertiaryButtonDisabled,
  Text,
} from './styles'
import { ButtonType, Props } from './types'

const Button: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    buttonType,
    iconType,
    iconBeforeText,
    label,
    type,
    isButton,
    disabled,
    onClick = () => undefined,
    'data-bdd-selector': dataBddSelector,
    'aria-label': ariaLabel,
    className,
    hideTextOnMobile,
    id,
    loading,
    invertColorTheme,
    componentTheme,
  } = props

  let ButtonComponent: any

  switch (buttonType) {
    case ButtonType.Primary:
      ButtonComponent =
        disabled || loading ? PrimaryButtonDisabled : PrimaryButton
      break
    case ButtonType.Secondary:
      ButtonComponent =
        disabled || loading ? SecondaryButtonDisabled : SecondaryButton
      break
    case ButtonType.Tertiary:
      ButtonComponent =
        disabled || loading ? TertiaryButtonDisabled : TertiaryButton
      break
    case ButtonType.Link:
      ButtonComponent = Link
      break
    case ButtonType.Close:
      ButtonComponent = CloseButton
      break
  }

  const handleClick = (ev) => {
    return !disabled && !loading && onClick(ev)
  }

  const hasIcon = iconType && iconType !== 'none'
  const isIconOnly = !Boolean(label) && hasIcon
  const themeToUse = componentTheme || colorTheme

  return (
    <ButtonComponent
      id={id}
      colorTheme={themeToUse}
      type={type}
      iconOnly={isIconOnly}
      onClick={handleClick}
      data-bdd-selector={dataBddSelector}
      aria-label={ariaLabel}
      className={className}
      hasIcon={hasIcon}
      hideTextOnMobile={hideTextOnMobile}
      iconBeforeText={iconBeforeText}
      as={isButton ? 'button' : 'span'}
      isLoading={loading}
      invertColorTheme={invertColorTheme}
    >
      {loading ? (
        <Fragment>
          <HiddenText all={true}>Loading</HiddenText>
          <LoadingSpinner isSmall={true} />
        </Fragment>
      ) : (
        <Fragment>
          {hasIcon && iconBeforeText && (
            <Icon
              iconBeforeText={iconBeforeText}
              hideTextOnMobile={hideTextOnMobile}
            >
              <ReactSVG
                src={`/static/icons/${mapIconName(iconType) as Icons}.svg`}
                wrapper="span"
              />
            </Icon>
          )}
          <Text>
            <HiddenText mobile={hideTextOnMobile}>{label}</HiddenText>
          </Text>
          {hasIcon && !iconBeforeText && (
            <Icon
              iconBeforeText={iconBeforeText}
              hideTextOnMobile={hideTextOnMobile}
            >
              <ReactSVG
                src={`/static/icons/${mapIconName(iconType) as Icons}.svg`}
                wrapper="span"
              />
            </Icon>
          )}
        </Fragment>
      )}
    </ButtonComponent>
  )
}

export default Button
