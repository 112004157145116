import React, { Fragment, useRef } from 'react'

import { GetThemeContext, ThemeContext } from '../../../../context'
import Table from '../../../containers/Table'
import { Column, Container, Content, Row } from '../../atoms'
import { Hero } from '../../molecules'

import { RichTextStyled } from './styles'

const getComponentToRender = (content, colorTheme) => {
  const components = {
    richText: () => (
      <Container
        paddingSize="half"
        key={content._id}
        data-bdd-selector="dynamic-components-rich-text"
      >
        <Row>
          <Column tablet={8} desktop={[8, 2]}>
            {content.richText && (
              <RichTextStyled html={content.richText} colorTheme={colorTheme} />
            )}
          </Column>
        </Row>
      </Container>
    ),
    table: () => (
      <Container
        paddingSize="half"
        key={content._id}
        data-bdd-selector="dynamic-components-table"
      >
        <Row>
          <Column tablet={8} desktop={[8, 2]}>
            <Table data={content} />
          </Column>
        </Row>
      </Container>
    ),
  }

  return components[content._type] ? components[content._type]() : null
}

export const RegulatoryInfo = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: [{ hero, addContent }],
  } = props

  const scrollDownElementRef = useRef()

  return (
    <Fragment>
      <Hero data={hero} scrollDownElementRef={scrollDownElementRef} />
      {addContent && (
        <Content
          ref={scrollDownElementRef}
          addBottomPadding={true}
          data-bdd-selector="dynamic-components"
        >
          {addContent.map((content) =>
            getComponentToRender(content, colorTheme)
          )}
        </Content>
      )}
    </Fragment>
  )
}

export default RegulatoryInfo
