import React, { forwardRef, RefObject } from 'react'
import { Answer, Input, Label, ListItem } from './styles'
import { Props } from './types'

const ProfilingQuestionListItem = forwardRef(
  (
    {
      data: { answerId, answer },
      questionId,
      currentlySelectedAnswerId,
      isLongListItem,
      isActive,
      index,
      onChange,
    }: Props,
    ref: RefObject<HTMLInputElement>
  ) => {
    return (
      <ListItem
        isLongListItem={isLongListItem}
        isActive={isActive}
        index={index}
      >
        <Label>
          <Input
            id={`${questionId}_${answerId}`}
            name={questionId.toString()}
            onChange={() => onChange([answerId])}
            data-bdd-selector={`input_${questionId}_${answerId}`}
            ref={currentlySelectedAnswerId === answerId ? ref : null}
          />
          <Answer isLongListItem={isLongListItem}>{answer}</Answer>
        </Label>
      </ListItem>
    )
  }
)

export default ProfilingQuestionListItem
