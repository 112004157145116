import { addDays, addMinutes, endOfDay } from 'date-fns'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import api from '../../../../services/api'
import { getUTM } from '../../../../utils/helpers'
import { RichText } from '../../atoms'
import FormComponent from '../Form'
import Result from '../FormResult'
import OrderedStepsList from '../OrderedStepsList'
import { Wrapper, FormElement } from './styles'
import { Error, FormData as Props, FormStatus, ReferPageType } from './types'

// TODO: For Refer and Referral, use an identifier to build payload
// (i.e. referral has SPW_Employer_Account__c field)
const FormWrapper: FC<Props> = ({
  headline,
  description,
  formType,
  stepsToComplete,
  additionalFormValues,
  referPageType,
  dark = false,
  leadSource,
  campaignName,
  formFields,
  ...props
}) => {
  const router = useRouter()
  const [status, setStatus] = useState<FormStatus>('idle')
  const [showCaptchaV2, setShowCaptchaV2] = useState(false)
  const [candidates, setCandidates] = useState([])
  const closeModal = () => setStatus('idle')

  useEffect(() => {
    const getCandidates = async () => {
      const formFieldData = formFields.find(
        ({ name }) => name === 'Appointment_Date_Time__c'
      )

      if (formFieldData && formFieldData.name === 'Appointment_Date_Time__c') {
        const now = new Date()

        const getCandidatesData = await api.getAppointmentCandidates({
          startTime: addMinutes(now, 30).toISOString(),
          endTime: addDays(endOfDay(now), 14).toISOString(),
          territoryIds: formFieldData.territoryIds,
        })
        setCandidates(getCandidatesData.data.candidates)
      }
    }

    if (formType !== 'Book a Call') return
    getCandidates()
  }, [formType, formFields])

  const handleSave = async (formValues: any, resetForm?: Function) => {
    formValues = getUTM(formValues)
    formValues._formId = props.sys ? props.sys.id : props._id
    if (leadSource) formValues.LeadSource = leadSource.primaryLeadSourceKey
    if (leadSource) formValues.SPW_Secondary_Lead_Source__c = leadSource.key
    if (campaignName) {
      formValues.SPW_Campaign_Name__c = campaignName
    }

    /* istanbul ignore next */
    if (formValues && formValues.Profiling_Results_Opt_In_c) {
      const query = router && router.query
      formValues.profilingAnswers = query
    } else {
      formValues.profilingAnswers = undefined
    }

    const body =
      referPageType === ReferPageType.Referral ||
      referPageType === ReferPageType.Refer
        ? { ...formValues, ...additionalFormValues }
        : { ...formValues }

    setStatus('loading')

    switch (formType) {
      case 'Newsletter':
        try {
          await api.postNewsLetterSignup(body)
          setStatus('success')
          resetForm()
        } catch (error) {
          errorHandler(error)
        }
        break
      case 'Book a Call':
        try {
          await api.postCallbackForm(body)
          setStatus('success')
          resetForm()
        } catch (error) {
          errorHandler(error)
        }
        break
      case 'Gated Content':
        try {
          if (referPageType === ReferPageType.Referral) {
            await api.postEmployerForm(body)
          } else if (referPageType === ReferPageType.Refer) {
            await api.postReferForm(body)
          } else {
            await api.postGatedForm(body)
          }
          resetForm()
          setStatus('success')
        } catch (error) {
          errorHandler(error)
        }
        break
    }
    // Handle Links but not if modal
    const successLink = props.results.find(
      (r) =>
        r.outputType === 'Redirect' &&
        r.resultType === 'Success' &&
        r.displayType !== 'Modal'
    )
    /* istanbul ignore next */
    if (successLink) {
      // Disect URL and port parameters
      if (successLink.URL)
        return setTimeout(() => router.push(successLink.URL), 1500)
    }
  }
  /* istanbul ignore next */
  const errorHandler = (error: Error) => {
    setStatus('failure')
    if (
      error &&
      error.response &&
      error.response.status === 406 &&
      !showCaptchaV2
    ) {
      setShowCaptchaV2(true)
    }
  }

  const resultContent = props.results?.find(
    (r) => r.resultType && r.resultType.toUpperCase() === status.toUpperCase()
  )

  // inline so never a modal
  if (
    resultContent &&
    resultContent.displayType &&
    resultContent.displayType === 'Inline' &&
    (status === 'failure' || status === 'success')
  )
    return (
      <Result
        content={resultContent}
        formType={formType}
        dark={dark}
        status={status}
      />
    )
  return (
    <Wrapper data-bdd-selector="form-wrapper">
      {headline && (
        <div>
          <RichText
            data-bdd-selector="form-headline"
            html={typeof headline === 'string' ? headline : undefined}
            document={typeof headline === 'object' ? headline.json : undefined}
          />
        </div>
      )}
      {stepsToComplete && (
        <OrderedStepsList
          list={stepsToComplete}
          data-bdd-selector="callback-form-orderedlist"
          light={true}
        />
      )}
      {description && (
        <div>
          <RichText
            html={typeof description === 'string' ? description : undefined}
          />
        </div>
      )}
      <FormElement>
        <FormComponent
          saveCallback={handleSave}
          loading={status === 'loading'}
          showCaptchaV2={showCaptchaV2}
          formType={formType}
          formFields={formFields}
          candidates={candidates}
          {...props}
        />
      </FormElement>
      {status === 'failure' || status === 'success' ? (
        <Result
          content={resultContent}
          formType={formType}
          status={status}
          closeModal={closeModal}
        />
      ) : null}
    </Wrapper>
  )
}

export default FormWrapper
