import styled from 'styled-components'

import { palette } from '../../../../styles/theme/colors'
const { white, blue } = palette

export const Wrapper = styled.section`
  width: 100%;
  min-width: 400px;
  background-color: ${blue};
  color: ${white};
  border-left: none;
  margin-top: 50px;

  h5 {
    margin-bottom: 20px;
  }
`
