import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import styled, { css } from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import ParagraphStyles from '../../atoms/Paragraph/styles'

import {
  StyledAccordionContainer,
  StyledAccordionItemContent,
  StyledAccordionItemPanelProps,
  StyledContentWrapper,
  StyledExpandBtn,
  StyledHeadingWrapper,
} from './types'

export const AccordionContainer = styled.div<StyledAccordionContainer>`
  width: 100%;

  .accordion__Heading {
    ${ParagraphStyles.styles.smallStyles}
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) =>
      props.colorTheme
        ? colorTheme(props.colorTheme, 'accordion.textThemeColor')
        : props.theme.colors.text.black};
  }

  .accordion__Button {
    padding: 1.8rem 2.4rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`

export const HeadingWrapper = styled.div<StyledHeadingWrapper>`
  ${(props) =>
    props.headingLeft
      ? css`
          margin-bottom: 1.4rem;

          @media ${props.theme.breakpoints.mobileMax} {
            margin-left: 2.4rem;
          }

          @media ${props.theme.breakpoints.tablet} {
            margin-bottom: 1.7rem;
          }
        `
      : css`
          text-align: center;
          margin-bottom: 4.5rem;

          @media ${props.theme.breakpoints.tablet} {
            margin-bottom: 5.5rem;
          }
        `}
`

export const ExpandBtn = styled.div<StyledExpandBtn>`
  position: relative;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid;
  border-color: ${(props) =>
    colorTheme(props.colorTheme, 'accordion.borderThemeColor')};
  transform: rotate(${(props) => (props.expanded ? '180deg' : 0)});
  transition: transform ${(props) => props.theme.transitions.moderate} ease,
    border-color ${(props) => props.theme.transitions.moderate} ease;

  svg {
    position: absolute;
    top: -2px;
    left: -2px;
    path {
      fill: ${(props) =>
        props.colorTheme
          ? colorTheme(props.colorTheme, 'accordion.textThemeColor')
          : props.theme.colors.text.black};
    }
  }
`

export const StyledAccordionItem = styled(AccordionItem)`
  & + & {
    margin-top: 2.4rem;
  }
`

export const AccordionItemContent = styled.div<StyledAccordionItemContent>`
  -webkit-tap-highlight-color: transparent;
  border-radius: 16px;
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme.colors.ui.grey6
      : props.expanded
      ? props.theme.colors.ui.white
      : props.theme.colors.ui.beigeHighlight};
  transition: background-color ${(props) => props.theme.transitions.moderate}
    ease;

  .accordion__Panel {
    ${(props) =>
      props.expanded
        ? css`
            opacity: 1;
          `
        : css`
            max-height: 0 !important;
            transition-delay: 0s;
          `}
  }
`

export const StyledAccordionItemPanel = styled(
  AccordionItemPanel
)<StyledAccordionItemPanelProps>`
  ${ParagraphStyles.styles.smallStyles};
  display: block !important;
  opacity: 0;
  overflow: hidden;
  transition: max-height ${(props) => props.theme.transitions.moderate} ease,
    opacity ${(props) => props.theme.transitions.moderate}
      ${(props) => props.theme.transitions.normal} ease;

  & > div {
    padding: 0.6rem 1.8rem 2.4rem;
  }
`

export const ContentWrapper = styled.div<StyledContentWrapper>`
  color: ${(props) =>
    props.colorTheme
      ? colorTheme(props.colorTheme, 'accordion.textThemeColor')
      : props.theme.colors.text.black};
`

export const AccordionItemHeadingText = styled.p`
  padding-right: 2rem;
  align-self: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-right: 2.6rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-right: 3.6rem;
  }
`

export const ButtonWrapper = styled.div`
  margin: 4.7rem auto 0;
  text-align: center;
`
