import styled from 'styled-components'

import { StyledContent } from './types'

export const Content = styled.div<StyledContent>`
  padding-top: 2rem;
  padding-bottom: ${(props) => props.addBottomPadding && `2rem`};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 2.5rem;
    padding-bottom: ${(props) => props.addBottomPadding && `2.5rem`};
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 3rem;
    padding-bottom: ${(props) => props.addBottomPadding && `3rem`};
  }
`
