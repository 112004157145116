import { css } from 'styled-components'

import theme from '../theme'

const { colors } = theme

export default css`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${colors.ui.blue};
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 4px ${colors.ui.blue}, 0 0 4px ${colors.ui.blue};
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }
`
