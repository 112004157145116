import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUTM } from '../../../../utils/helpers'
import { AppState } from '../../../../types'
import { Button } from '../../atoms'
import { ButtonType } from '../../atoms/Button/types'
import { CallbackFormResult } from '../../organisms/CallbackFormResult'
import { FormInputValues } from '../CallbackForm/types'

import { actions } from './actions'
import { callbackFormSelector } from './selectors'
import {
  StyledCallbackform,
  StyledModal,
  StyledModalContent,
  StyledModalHeader,
} from './styles'
import { CallbackFormState, Props } from './types'

// TODO - Rodrigo - Move the logic to the CallbackForm and rename this component as a global modal component.

const CallbackFormModal = (props: Props) => {
  const [formInput, setFormInput] = useState({})
  const [showCaptchaV2, setShowCaptchaV2] = useState(false)
  const dispatch = useDispatch()
  const router = useRouter()
  const { formData, visible, success, error, loading, timeslots, candidates } =
    useSelector<AppState, CallbackFormState>((state) => {
      const a = callbackFormSelector(state)
      return a
    })

  const { colorTheme } = props
  const query = router && router.query

  /* istanbul ignore next */
  useEffect(() => {
    if (
      error &&
      error.response &&
      error.response.status === 406 &&
      !showCaptchaV2
    ) {
      setShowCaptchaV2(true)
    }
  })

  if (!visible) {
    return null
  }

  /* istanbul ignore next */
  if (!formData && visible && !loading) {
    dispatch(actions.load())
    return null
  }

  const isProfilingToolResultsPage =
    router && router.route.indexOf('profiling-tool-results') > -1

  // Do a shallow copy to do not change the formData, since it is cached between pages.
  const formLayoutData = { ...formData }

  if (!isProfilingToolResultsPage && formData) {
    formLayoutData.formFields = formData.formFields.filter(
      (formField) => formField.name !== 'Profiling_Results_Opt_In_c'
    )
  }

  /* istanbul ignore next */
  const resultsCTAHandler = (e: any) => {
    success ? closeHandler(e) : dispatch(actions.back())
  }

  /* istanbul ignore next */
  const closeHandler: React.FormEventHandler = () => {
    setFormInput({})
    dispatch(actions.close())
  }

  /* istanbul ignore next */
  const save = (data: any) => {
    if (data.hasOwnProperty('Appointment_Date_Time__c')) {
      data.Appointment_Date_Time__c =
        data.Appointment_Date_Time__c.toISOString()
    }

    if (formData.leadSource) {
      data.LeadSource = formData.leadSource.primaryLeadSourceKey
      data.SPW_Secondary_Lead_Source__c = formData.leadSource.key
    }

    if (isProfilingToolResultsPage && data) {
      data.profilingAnswers = data.Profiling_Results_Opt_In_c
        ? query
        : undefined
    }

    data = getUTM(data)

    setFormInput(data)
    dispatch(actions.save(data))
  }

  return (
    <StyledModal
      data-bdd-selector="callback-form-modal"
      open={visible && formData !== undefined}
      onClose={closeHandler}
    >
      <StyledModalContent>
        <StyledModalHeader colorTheme={colorTheme}>
          <Button
            buttonType={ButtonType.Close}
            onClick={closeHandler}
            data-bdd-selector="callback-form-modal-close-button"
            label="Close"
            iconType="Close"
            analyticsAction="close form"
            analyticsCategory="callback form"
          />
        </StyledModalHeader>
        {success || error ? (
          <CallbackFormResult
            data-bdd-selector="callback-form-result"
            formInput={formInput as FormInputValues}
            successPageData={formData.results[1]}
            errorPageData={formData.results[0]}
            success={success}
            clickHandler={resultsCTAHandler}
          />
        ) : (
          <StyledCallbackform
            data-bdd-selector="callback-form"
            data={formLayoutData}
            timeslots={timeslots}
            candidates={candidates}
            formInput={formInput}
            saveCallback={save}
            showCaptchaV2={showCaptchaV2}
            loading={loading}
          />
        )}
      </StyledModalContent>
    </StyledModal>
  )
}

export default CallbackFormModal
