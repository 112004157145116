import { FunctionComponent } from 'react'

import { Description, Statistic, StatisticWrapper, Wrapper } from './styles'
import { Props } from './types'

const FeeBlock: FunctionComponent<Props> = (props) => {
  const { data, className } = props
  const { description, statistic } = data

  return (
    <Wrapper className={className} data-bdd-selector="fee-block">
      <StatisticWrapper>
        {statistic && (
          <Statistic level="large" weight="bold">
            {statistic}
          </Statistic>
        )}
        {description && <Description level="small">{description}</Description>}
      </StatisticWrapper>
    </Wrapper>
  )
}

export default FeeBlock
