import React from 'react'

import { Button, Column, Modal, Row } from '../../../common/atoms'
import { ButtonType } from '../../../common/atoms/Button/types'
import IconLinkHolder from '../../atoms/IconLinkHolder/IconLinkHolder'
import CallToAction from '../CallToAction'

import {
  ResultFailureIcon,
  ResultModalContent,
  ResultModalFooterContainer,
  ResultModalHeader,
  ResultSuccessIcon,
} from './styles'

import { ModalProps as Props } from './types'

const ResultModal = (props: Props) => {
  const { showModal, closeModal, modalContent, status } = props
  return (
    <Modal show={showModal} size="tiny">
      <ResultModalContent>
        <ResultModalHeader>
          <Button
            buttonType={ButtonType.Close}
            label="Close"
            iconType="Close"
            onClick={closeModal}
            isButton={true}
          />
        </ResultModalHeader>
        <ResultModalFooterContainer>
          <Row>
            <Column>
              {status === 'success' ? (
                <ResultSuccessIcon />
              ) : (
                <ResultFailureIcon />
              )}
            </Column>
          </Row>
          <Row>
            <Column>
              {modalContent ? (
                <>
                  <h3>{modalContent?.headline}</h3>
                  <h4>{modalContent?.subHeadline}</h4>
                  <br />
                  <p>{modalContent?.description}</p>
                </>
              ) : (
                <>
                  <h3>Oops!</h3>
                  <p>
                    Something went wrong. Your details haven’t been processed,
                    try again.
                  </p>
                </>
              )}
            </Column>
          </Row>
          <Row>
            {/* Static list */}
            {modalContent?.resultLinks?.map((link, index) => (
              <IconLinkHolder
                key={index}
                linkTitle={link.linkTitle}
                linkIcon={link.linkIcon}
                linkUrl={link.linkUrl}
                target={link.target}
              />
            ))}
          </Row>
        </ResultModalFooterContainer>
        {/* Static list */}

        {modalContent ? (
          modalContent?.callToActions?.map((call, index) => (
            <CallToAction
              {...call}
              key={index}
              clickHandler={closeModal}
              componentTheme={'blue'}
              data-bdd-selector="modal-call-to-action-button"
            />
          ))
        ) : (
          <Button
            buttonType={ButtonType.Close}
            label="Close"
            iconType="Close"
            onClick={closeModal}
            isButton={true}
          />
        )}
      </ResultModalContent>
    </Modal>
  )
}

export default ResultModal
