import { Form, Formik, FormikActions, FormikValues } from 'formik'
import {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CallToAction } from '..'
import { EnvConfig } from '../../../../constants/environmentConfig'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { Input, RichText, Row } from '../../atoms'
import { InputTypes, InputVariantMapping } from '../../atoms/Input/types'
import { generateYupSchema } from './generateschema'
import { formatTelephoneSF } from '../../../../utils/helpers'
import {
  StyledRichTextBlock,
  CaptchaWrapper,
  CTAWrapper,
  FormColumn,
} from './styles'
import { Props } from './types'
import { ColumnsTablet } from '../../../../types/grid'
import { buildFields } from '../../../../utils/form'

const DEFAULT_COL_SIZE = 8 as ColumnsTablet
const FORM_RICH_TEXT_BLOCK_TYPE = 'richTextBlock'
const FORM_CHECKBOX_TYPE = 'checkbox'

const FormComponent: FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    captchaAction,
    formType,
    formFields = [],
    formInput,
    callToActions,
    saveCallback,
    showCaptchaV2,
    captchaLegalText,
    loading,
    isNewletterSignUp,
    gtmButtonID,
    timeslots,
    candidates,
    handleSkip,
  } = props

  const fullFormFields = buildFields(formType, formFields)

  const validationSchema = generateYupSchema(fullFormFields)
  const [preFormattedPhone, setPreFormattedPhone] = useState(null)

  const isInitialValid = (): boolean => {
    if (formInput && Object.keys(formInput).length > 0) {
      try {
        validationSchema.validateSync(formInput)
      } catch (e) {
        return false
      }
    }
    return true
  }

  const initialValues = () => {
    if (formInput && Object.keys(formInput).length > 0) {
      return formInput
    }
    const result = {}
    // Need to set the default values to have the field touched
    // when the form is submitted.
    for (const field of fullFormFields) {
      result[field.name] = field.type === FORM_CHECKBOX_TYPE ? false : ''

      // Remove RichtextBlock from submittable formFields
      if (field.type === FORM_RICH_TEXT_BLOCK_TYPE) {
        result[field.name] = undefined
      }
    }
    return result
  }

  // Updates the form state when there are values from the previous attempt.
  const form: MutableRefObject<Formik> = useRef()
  let recaptchaV2WidgetId

  useEffect(() => {
    if (form.current && formInput && Object.keys(formInput).length > 0) {
      const currentForm = form.current
      for (const fieldName of Object.keys(form.current.fields)) {
        currentForm.setFieldTouched(fieldName, true, true)
      }

      if (preFormattedPhone) {
        currentForm.setFieldValue('Phone', preFormattedPhone, true)
      }
    }

    /* istanbul ignore next */
    if (showCaptchaV2 && EnvConfig.SPW_UI_GOOGLE_CAPTCHA_V2_SITE_KEY) {
      const grecaptcha = (window as any).grecaptcha
      recaptchaV2WidgetId = grecaptcha.render('recaptchaWrapper', {
        sitekey: EnvConfig.SPW_UI_GOOGLE_CAPTCHA_V2_SITE_KEY,
      })
    }
  })

  /* istanbul ignore next */
  const preProcessFormData = async (
    data: FormikValues,
    { resetForm }: FormikActions<FormikValues>
  ) => {
    if (showCaptchaV2 && EnvConfig.SPW_UI_GOOGLE_CAPTCHA_V2_SITE_KEY) {
      const grecaptcha = (window as any).grecaptcha
      const token = grecaptcha.getResponse(recaptchaV2WidgetId)
      data.__token = token
      data.__reCaptchaVersion = 2
    } else if (EnvConfig.SPW_UI_GOOGLE_CAPTCHA_V3_SITE_KEY) {
      const token = await executeRecaptcha(captchaAction)
      data.__token = token
      data.__reCaptchaVersion = 3
    }
    let date = new Date()
    if (data.hasOwnProperty('Appointment_Date__c')) {
      date = new Date(`${data.Appointment_Date__c} ${data.Appointment_Time__c}`)
    }
    data.__timeZoneOffset = date.getTimezoneOffset()
    if (data.hasOwnProperty('Phone')) {
      setPreFormattedPhone(data.Phone)
      data.Phone = formatTelephoneSF(data.Phone)
    }

    saveCallback(data, resetForm)
  }

  return (
    <Formik
      ref={form}
      isInitialValid={isInitialValid()}
      initialValues={initialValues()}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={preProcessFormData}
    >
      {({ isValid, errors, touched }) => (
        <Form autoComplete="off" data-bdd-selector="form">
          <Row>
            {fullFormFields.map((formItem, index) => {
              const {
                sys,
                _id,
                label,
                text,
                name,
                type,
                helpText,
                excludedDates,
                data,
                columnSize,
              } = formItem
              let colSize = DEFAULT_COL_SIZE
              if (columnSize) {
                colSize = columnSize
              } else {
                colSize = index === 2 || index === 7 || index === 8 ? 8 : 4
              }

              // Get the type of form element so we can show different components
              const inputType = type as InputTypes
              const isInput = InputVariantMapping[inputType]
              const isRichTextBlock = type === FORM_RICH_TEXT_BLOCK_TYPE
              return (
                <FormColumn tablet={colSize} key={sys ? sys.id : _id}>
                  {isInput ? (
                    <Input
                      name={name}
                      label={label}
                      inputType={inputType}
                      errors={errors}
                      touched={touched}
                      id={sys ? sys.id : _id}
                      helpText={helpText}
                      excludedDates={excludedDates}
                      disabled={loading}
                      data={data}
                      timeslots={timeslots}
                      candidates={candidates}
                    />
                  ) : (
                    <>
                      {isRichTextBlock && (
                        <StyledRichTextBlock colorTheme={colorTheme}>
                          <RichText
                            html={sys ? undefined : text}
                            document={sys ? text.json : undefined}
                          />
                        </StyledRichTextBlock>
                      )}
                    </>
                  )}
                </FormColumn>
              )
            })}
          </Row>
          <CaptchaWrapper id="recaptchaWrapper">
            {captchaLegalText && (
              <StyledRichTextBlock colorTheme={colorTheme}>
                <RichText
                  html={captchaLegalText.json ? undefined : captchaLegalText}
                  document={
                    captchaLegalText.json ? captchaLegalText.json : undefined
                  }
                />
              </StyledRichTextBlock>
            )}
          </CaptchaWrapper>
          <CTAWrapper>
            {callToActions.map((callToAction) => {
              if (callToAction.buttonLabel.match(/skip/i))
                return (
                  <CallToAction
                    key={
                      callToAction.sys ? callToAction.sys.id : callToAction._id
                    }
                    id={gtmButtonID}
                    {...callToAction}
                    clickHandler={handleSkip}
                    componentTheme={isNewletterSignUp && 'blue'}
                    loading={loading}
                    disabled={loading}
                  />
                )
              return (
                <CallToAction
                  key={
                    callToAction.sys ? callToAction.sys.id : callToAction._id
                  }
                  id={gtmButtonID}
                  {...callToAction}
                  componentTheme={isNewletterSignUp && 'blue'}
                  loading={loading}
                  disabled={!isValid}
                />
              )
            })}
          </CTAWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default FormComponent
