import React from 'react'

const TrustPilotCarousel = () => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5e5670de7116b20001234cce"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-style-alignment="center"
      data-stars="4,5"
      data-review-languages="en"
      ref={ref}
    >
      <a
        href="https://uk.trustpilot.com/review/spw.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}
export default TrustPilotCarousel
