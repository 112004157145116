import { useCallback, useState } from 'react'

import { HookSetDraggableItems, HookSetHasDragged } from './types'

export function useHasDragged(itemWasDragged: boolean) {
  const [hasDragged, setHasDragged] = useState<HookSetHasDragged>(false)

  const dragged = useCallback(
    () => setHasDragged(itemWasDragged),
    [itemWasDragged]
  )

  return { hasDragged, setHasDragged, dragged }
}

export const useDraggableItems = (data) => {
  const [draggableItems, setDraggableItems] =
    useState<HookSetDraggableItems>(data)

  const items = useCallback(() => setDraggableItems(data), [data])

  return { draggableItems, setDraggableItems, items }
}
