import {
  Container,
  RichText,
  Row,
  TrustBar,
  TrustPilotTrustBoxMini,
} from '../../../../atoms'
import { CallToAction } from '../../../../molecules'

import {
  StyledBackgroundImage,
  ParagraphColumn,
  TitleColumn,
  Wrapper,
  WrapperWithBackgroundImage,
  TrustPilotRatingWrapper,
} from './styles'
import { Props } from './types'

const TextOnly = (props: Props) => {
  const {
    data: {
      headline,
      description,
      backgroundImage,
      useColorThemeFor,
      callToAction,
    },
    trustBar,
    className,
    colorTheme,
    setColorThemeForOverlay,
    short,
    isTrustPilotRatingBarVisible = false,
  } = props

  const hasBackgroundImage = Boolean(
    backgroundImage && (backgroundImage || backgroundImage[0])
  )

  const useColorThemeForOverlay =
    useColorThemeFor === 'overlay' || setColorThemeForOverlay

  const hasCta = Boolean(callToAction)

  const mainElement = () => (
    <Container paddingSize="none">
      {headline && (
        <Row>
          <TitleColumn desktop={10}>
            <RichText
              html={headline}
              data-bdd-selector="hero-with-text-headline"
            />
          </TitleColumn>
        </Row>
      )}
      <Row>
        <ParagraphColumn tablet={4} desktop={5}>
          {description && (
            <RichText
              html={description}
              data-bdd-selector="hero-with-text-description"
              useTicksForBullets
            />
          )}
          {hasCta && (
            <CallToAction
              {...callToAction}
              id="gtm-campaign-hero-callback-modal-open-button"
              data-bdd-selector="campaign-hero-callback-button"
            />
          )}
        </ParagraphColumn>
      </Row>
    </Container>
  )

  if (hasBackgroundImage) {
    return (
      <>
        <WrapperWithBackgroundImage short={short}>
          <StyledBackgroundImage
            className={className}
            data={backgroundImage}
            colorTheme={colorTheme}
            useColorThemeForOverlay={useColorThemeForOverlay}
            data-bdd-selector="hero-with-text-background-image"
          />
          {mainElement()}
          {isTrustPilotRatingBarVisible && (
            <TrustPilotRatingWrapper>
              <TrustPilotTrustBoxMini />
            </TrustPilotRatingWrapper>
          )}
        </WrapperWithBackgroundImage>
        {trustBar && <TrustBar {...trustBar} />}
      </>
    )
  }

  return (
    <Wrapper short={short}>
      {mainElement()}
      {trustBar && <TrustBar {...trustBar} />}
    </Wrapper>
  )
}

export default TextOnly
