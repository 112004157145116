import React, { createRef, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion'
import { ReactSVG } from 'react-svg'

import { RichText, Title } from '../../atoms'
import { CallToAction } from '../../molecules'

import { SCROLL_TIMEOUT } from './constants'
import {
  AccordionContainer,
  AccordionItemContent,
  AccordionItemHeadingText,
  ButtonWrapper,
  ContentWrapper,
  ExpandBtn,
  HeadingWrapper,
  StyledAccordionItem,
  StyledAccordionItemPanel,
} from './styles'
import { Props } from './types'

const AccordionComponent: React.FunctionComponent<Props> = (props: Props) => {
  const [active, setActive] = useState<string>('')
  const container = createRef<HTMLDivElement>()

  const {
    data: { headline, accordionItems, callToAction },
    headingLeft,
    colorTheme,
  } = props

  useEffect(() => {
    if (active && container.current) {
      scrollTo()
    }
  }, [active])

  /**
   * A function that returns the calculated scroll position for the page to
   * scroll to
   *
   * @returns Number - The value to be used as the Y coordinate for the scrollTo
   */
  const calculateScrollPosition = (activeAccordionItem) => {
    const elementRect = activeAccordionItem.getBoundingClientRect()
    const halfElementHeight = elementRect.height / 2
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const middlePoint =
      absoluteElementTop + halfElementHeight - window.innerHeight / 2

    return middlePoint
  }

  /**
   * A function that calls the `window.ScrollTo()` function to scroll the
   * viewport, after a delay to allow for the UI animation to have time to
   * complete
   */
  const scrollTo = () => {
    setTimeout(() => {
      const activeAccordionItem = container.current.querySelector(
        `.accordion__Item[data-uuid="${active}"]`
      )

      window.scrollTo({
        top: calculateScrollPosition(activeAccordionItem),
        behavior: 'smooth',
      })
    }, SCROLL_TIMEOUT)
  }

  const toggleAccordion = (ids) => {
    if (ids[0]) {
      container.current
        .querySelectorAll(`.accordion__Panel`)
        .forEach((item) => {
          ;(item as HTMLElement).style.maxHeight = `0px`
        })
      const panelShowElement = container.current.querySelector(
        `.accordion__Panel[data-uuid="${ids[0]}"]`
      )
      ;(
        panelShowElement as HTMLElement
      ).style.maxHeight = `${panelShowElement.scrollHeight}px`
    }
    setActive(ids[0] || '')
  }

  return (
    <AccordionContainer
      ref={container}
      colorTheme={colorTheme}
      data-bdd-selector="accordion"
    >
      {headline && (
        <HeadingWrapper headingLeft={headingLeft}>
          <Title
            element={3}
            level={headingLeft ? 5 : 2}
            weight={headingLeft ? 'bold' : 'normal'}
            data-bdd-selector="accordion-heading"
          >
            {headline}
          </Title>
        </HeadingWrapper>
      )}
      <Accordion
        allowZeroExpanded={true}
        onChange={toggleAccordion}
        data-bdd-selector="accordion-items"
      >
        {accordionItems.map((item) => {
          const isActive = active === item._id

          return (
            <StyledAccordionItem
              className="accordion__Item"
              uuid={item._id}
              data-uuid={item._id}
              key={item._id}
              data-bdd-selector="accordion-item"
            >
              <AccordionItemContent expanded={isActive} colorTheme={colorTheme}>
                <AccordionItemHeading className="accordion__Heading">
                  <AccordionItemButton className="accordion__Button">
                    <AccordionItemHeadingText data-bdd-selector="accordion-item-heading">
                      {item.label}
                    </AccordionItemHeadingText>
                    <ExpandBtn
                      expanded={isActive}
                      colorTheme={colorTheme}
                      data-bdd-selector="accordion-item-expand-button"
                    >
                      <ReactSVG
                        src="/static/icons/ChevronDown.svg"
                        wrapper="span"
                      />
                    </ExpandBtn>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <StyledAccordionItemPanel
                  className="accordion__Panel"
                  data-uuid={item._id}
                  data-bdd-selector="accordion-item-contents"
                >
                  <ContentWrapper colorTheme={colorTheme}>
                    <RichText
                      html={item.supportingCopy}
                      ref={(node) =>
                        node &&
                        (isActive
                          ? node.removeAttribute('inert')
                          : node.setAttribute('inert', ''))
                      }
                    />
                  </ContentWrapper>
                </StyledAccordionItemPanel>
              </AccordionItemContent>
            </StyledAccordionItem>
          )
        })}
      </Accordion>
      {callToAction && callToAction.length > 0 && (
        <ButtonWrapper>
          <CallToAction
            {...callToAction[0]}
            iconBeforeText={false}
            colorTheme="grey"
            data-bdd-selector="faq-accordion-callToAction"
          />
        </ButtonWrapper>
      )}
    </AccordionContainer>
  )
}

export default AccordionComponent
