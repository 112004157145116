import styled from 'styled-components'

import { Container, Row } from '../../../common/atoms'

export const ImageContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.mobileMax} {
    padding: 0 5px;
  }
`

export const ImageLink = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledRow = styled(Row)`
  justify-content: center;
`

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.background.grey1};
`

export const StyledContainer = styled(Container)`
  padding-top: 2.5rem;
  padding-bottom: 4rem;
`
export const Description = styled.div`
  padding-top: 0.5rem;
`
export const Section = styled.section`
  h2 {
    margin-bottom: 0rem;
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h1};
    color: ${(props) => props.theme.colors.background.black};
  }

  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }

  @media ${(props) => props.theme.breakpoints.tabletMax} {
    font-size: ${(props) => props.theme.fontSizes.mobile.headings.h2};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 0rem;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-bottom: 0rem;
  }
`
