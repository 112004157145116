import React, { useEffect, useState } from 'react'

import { NavItemData, Props } from '../types'
import MenuItem from '../NavMenuItem'
import Dropdown from '../NavDropdown'

const Desktop: React.FunctionComponent<Props> = (props) => {
  const { primaryMenuData, useColorTheme, invert, hasCustomHeaderBG } = props
  const [menuItems, setMenuItems] = useState([])

  const buildDropdownItem = (item: NavItemData, index) => {
    return (
      <Dropdown
        invert={invert}
        useColorTheme={useColorTheme}
        data={item}
        key={index}
        hasCustomHeaderBG={hasCustomHeaderBG}
      />
    )
  }

  const buildLinkItem = (item: NavItemData, index) => {
    return (
      <MenuItem
        invert={invert}
        useColorTheme={useColorTheme}
        key={index}
        content={item.pageTitle}
        pageUrl={item.pageUrl}
        isDesktop
        hasCustomHeaderBG={hasCustomHeaderBG}
      />
    )
  }

  const buildMenuItemList = (dataArray: NavItemData[]) => {
    return (
      dataArray &&
      dataArray.map((item, index) => {
        return item.footerLevelTwo && item.footerLevelTwo.length > 0
          ? buildDropdownItem(item, index)
          : buildLinkItem(item, index)
      })
    )
  }

  useEffect(() => {
    setMenuItems(buildMenuItemList(primaryMenuData))
  }, [invert, hasCustomHeaderBG])

  return <>{menuItems}</>
}

export default Desktop
