import throttle from 'lodash.throttle'
import React, { createRef, useEffect, useRef, useState } from 'react'

import { HEADER_HEIGHTS } from '../../../../../../constants/ui'
import { GetThemeContext, ThemeContext } from '../../../../../../context'
import { HiddenText, Link, Logo } from '../../../../atoms'
import { CallToAction, CallOrBeCalled } from '../../../../molecules'
import { Navigation } from '../../../../organisms'

import PRIMARY_MENU_BUTTON_LABEL from './constants'
import {
  HeaderWrapper,
  MenuBars,
  MenuButton,
  PrimaryMenuColumnEnd,
  PrimaryMenuColumnCenter,
  PrimaryMenuColumnStart,
  PrimaryMenuContainer,
  PrimaryMenuLogo,
  SecondaryMenuContainer,
  SecondaryMenuWrapper,
} from './styles'
import { Props } from './types'

const headerWrapperRef: React.RefObject<HTMLElement> = createRef()
const secondaryMenuRef: React.RefObject<HTMLElement> = createRef()
const primaryMenuRefRef: React.RefObject<HTMLElement> = createRef()

const HeaderDefault: React.FunctionComponent<Props> = (props) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const {
    data: {
      utilityMenu,
      primaryMenu,
      secondaryMenu,
      callToAction,
      phoneNumber,
    },
    imageUrl,
    isOnOverlayHero,
    isUsingTransparentHero,
    enableCustomHeaderBackground,
    fixedHeader,
    viewportInfo,
    viewportInfo: { equals, isSmallerThan },
  } = props

  const [eventListenerAdded, setEventListenerAdded] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [hasReachedTop, setHasReachedTop] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const [isMenuFloating, setIsMenuFloating] = useState(false)
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const currentViewportSize: { current: any } = useRef([])

  let lastScrollPosition = 0

  useEffect(() => {
    if (!eventListenerAdded) {
      addEventListeners()
    }

    currentViewportSize.current = equals

    return () => {
      removeEventListeners()
    }
  }, [eventListenerAdded, isSideMenuOpen, equals])

  const addEventListeners = () => {
    if (!fixedHeader) {
      window.addEventListener('scroll', throttle(onScroll, 250), true)
      setEventListenerAdded(true)
    }
  }

  const removeEventListeners = () => {
    if (!fixedHeader) {
      window.removeEventListener('scroll', throttle(onScroll, 250), true)
    }
  }

  const onScroll = () => {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop

    /**
     * When scrolling down
     */
    if (currentScrollPosition > lastScrollPosition) {
      setIsScrollingDown(true)

      if (currentScrollPosition > HEADER_HEIGHTS[currentViewportSize.current]) {
        setHasScrolled(true)
        setIsMenuFloating(true)

        // Removing for now as this is planned to be utilised in AnchorNav.
        // dispatch(globalActions.headerStatusSet('hidden'));
      }
    }

    /**
     * When scrolling up
     */
    if (currentScrollPosition < lastScrollPosition) {
      setIsScrollingDown(false)
      setHasReachedTop(false)

      // Removing for now as this is planned to be utilised in AnchorNav.
      // if (currentScrollPosition > HEADER_HEIGHTS[currentViewportSize.current]) {
      // dispatch(globalActions.headerStatusSet('sticky'));
      // }
    }

    /**
     * When at top of page
     */
    if (currentScrollPosition === 0) {
      setIsMenuFloating(false)
      setHasReachedTop(true)

      // Removing for now as this is planned to be utilised in AnchorNav.
      // dispatch(globalActions.headerStatusSet('visible'));
    }

    lastScrollPosition = currentScrollPosition <= 0 ? 0 : currentScrollPosition
  }

  const onOpenSideMenuClick = () => {
    setBodyStyles()
    setIsSideMenuOpen(!isSideMenuOpen)
  }

  const setBodyStyles = () => {
    if (isSmallerThan.desktopMedium) {
      const styles = {
        overflow: !isSideMenuOpen ? `hidden` : '',
      }

      Object.assign(document.body.style, styles)
      setIsSideMenuOpen(!isSideMenuOpen)
    }
  }

  const invert = !(isMenuFloating || isUsingTransparentHero)

  const useColorTheme = !isMenuFloating && isOnOverlayHero

  const hasCustomHeaderBG = !isMenuFloating && enableCustomHeaderBackground

  return (
    <HeaderWrapper
      hasScrolled={hasScrolled}
      hasReachedTop={hasReachedTop}
      isScrollingDown={isScrollingDown}
      isMenuFloating={isMenuFloating}
      fixedHeader={fixedHeader}
      hasCustomHeaderBG={hasCustomHeaderBG}
      ref={headerWrapperRef}
      data-bdd-selector="header-default"
    >
      {utilityMenu && !fixedHeader && !isSmallerThan.desktopMedium && (
        <SecondaryMenuContainer
          ref={secondaryMenuRef}
          colorTheme={colorTheme}
          data-bdd-selector="header-utility-menu"
        >
          <SecondaryMenuWrapper>
            <CallToAction
              {...utilityMenu}
              iconBeforeText={true}
              data-bdd-selector="header-utility-menu-external-link"
            />
          </SecondaryMenuWrapper>
        </SecondaryMenuContainer>
      )}
      <PrimaryMenuContainer
        paddingSize="none"
        ref={primaryMenuRefRef}
        fixedHeader={fixedHeader}
        data-bdd-selector="header-primary-menu"
      >
        <PrimaryMenuColumnStart>
          <PrimaryMenuLogo data-bdd-selector="header-primary-menu-logo">
            <Link route="/">
              <Logo
                invert={invert}
                useColorTheme={useColorTheme}
                imageData={imageUrl}
                hasCustomHeaderBG={hasCustomHeaderBG}
              />
              <HiddenText all={true}>Go to home page</HiddenText>
            </Link>
          </PrimaryMenuLogo>
        </PrimaryMenuColumnStart>
        {!fixedHeader && (
          <>
            {isSmallerThan.desktopMedium && (
              <PrimaryMenuColumnEnd>
                <MenuButton
                  onClick={onOpenSideMenuClick}
                  data-bdd-selector="header-primary-menu-button"
                >
                  <HiddenText mobile={true} tablet={true} desktop={true}>
                    {PRIMARY_MENU_BUTTON_LABEL}
                  </HiddenText>
                  <MenuBars
                    invert={invert}
                    useColorTheme={useColorTheme}
                    colorTheme={colorTheme}
                  />
                </MenuButton>
              </PrimaryMenuColumnEnd>
            )}
            <Navigation
              primaryMenuData={primaryMenu}
              secondaryMenuData={secondaryMenu}
              callToAction={callToAction}
              phoneNumber={phoneNumber}
              isOpen={isSideMenuOpen}
              onOpenSideMenuClick={onOpenSideMenuClick}
              onNavItemClick={setBodyStyles}
              viewportInfo={viewportInfo}
              invert={invert}
              useColorTheme={useColorTheme}
              imageUrl={imageUrl}
              hasCustomHeaderBG={hasCustomHeaderBG}
            />
            <PrimaryMenuColumnCenter>
              <CallOrBeCalled
                callToAction={callToAction}
                phoneNumber={phoneNumber}
                invert={invert}
                useColorTheme={useColorTheme}
                hasCustomHeaderBG={hasCustomHeaderBG}
              />
            </PrimaryMenuColumnCenter>
          </>
        )}
      </PrimaryMenuContainer>
    </HeaderWrapper>
  )
}

export default HeaderDefault
