import * as React from 'react'
import { ReactSVG } from 'react-svg'

import { getTargetWindow } from '../../../../../../utils'
import { isUrlExternalTarget } from '../../../../../../utils/helpers'
import {
  Column,
  Container,
  HiddenText,
  Link,
  Logo,
  RichText,
  Row,
  DividerLine,
} from '../../../../atoms'
import { Props } from '../../types'

import {
  Copyright,
  Disclaimer,
  FooterLogo,
  LinkListItem,
  LogoColumn,
  SecondaryLinks,
  SecondaryLinksItem,
  SecondaryLinksRow,
  SocialLinks,
  SocialLinksHeading,
  SocialList,
  SocialListItem,
  SocialListLink,
  StyledFooter,
} from './styles'

const FooterDefault: React.FunctionComponent<Props> = (props) => {
  try {
    const {
      data: {
        primaryLinks: [{ column1, column2, socialLinkTitle, socialLinks }],
        secondaryLinks,
        copyright,
        disclaimer,
      },
      imageUrl,
    } = props

    const copyrightText = () => {
      const year = new Date().getFullYear()

      return `\u00A9 ${year} ${copyright}`
    }

    return (
      <StyledFooter data-bdd-selector="footer-default" id="footer">
        <Container>
          <Row>
            <LogoColumn tablet={2} desktop={6}>
              <FooterLogo>
                <Link route="/" data-bdd-selector="footer-default-logo">
                  <Logo
                    invert={true}
                    useColorTheme={false}
                    imageData={imageUrl}
                  />
                  <HiddenText all={true}>Go to home page</HiddenText>
                </Link>
              </FooterLogo>
            </LogoColumn>
            <Column mobile={1} tablet={2} desktop={2}>
              {column1 && (
                <ul data-bdd-selector="footer-default-links-1">
                  {column1
                    .filter((item) => !!item.pageTitle)
                    .map((item) => {
                      const { _id, pageTitle, pageUrl, target } = item

                      return (
                        <LinkListItem key={_id}>
                          <Link
                            route={pageUrl}
                            target={getTargetWindow(target)}
                            data-bdd-selector="footer-default-link"
                          >
                            {pageTitle}
                          </Link>
                        </LinkListItem>
                      )
                    })}
                </ul>
              )}
            </Column>
            <Column mobile={1} tablet={2} desktop={2}>
              {column2 && (
                <ul data-bdd-selector="footer-default-links-2">
                  {column2
                    .filter((item) => !!item.pageTitle)
                    .map((item) => {
                      const { _id, pageTitle, pageUrl, target } = item

                      return (
                        <LinkListItem key={_id}>
                          <Link
                            route={pageUrl}
                            target={getTargetWindow(target)}
                            data-bdd-selector="footer-default-link"
                            externalTarget={isUrlExternalTarget(pageUrl)}
                          >
                            {pageTitle}
                          </Link>
                        </LinkListItem>
                      )
                    })}
                </ul>
              )}
            </Column>
            <Column tablet={2} desktop={2}>
              {socialLinks && (
                <SocialLinks data-bdd-selector="footer-default-social-links">
                  <SocialLinksHeading>{socialLinkTitle}</SocialLinksHeading>
                  <SocialList>
                    {socialLinks.map((item) => {
                      const {
                        _id,
                        socialIcon: {
                          title,
                          file: { url },
                        },
                        socialUrl,
                        target,
                      } = item

                      return (
                        <SocialListItem key={_id}>
                          <SocialListLink
                            href={socialUrl}
                            target={getTargetWindow(target)}
                            data-bdd-selector="footer-default-social-link"
                          >
                            <ReactSVG src={url} wrapper="span" />
                            <HiddenText all={true}>{`${title} url`}</HiddenText>
                          </SocialListLink>
                        </SocialListItem>
                      )
                    })}
                  </SocialList>
                </SocialLinks>
              )}
            </Column>
          </Row>
        </Container>
        <DividerLine />
        <Container>
          <Row>
            <Column desktop={5}>
              <Copyright data-bdd-selector="footer-default-copyright">
                {copyrightText()}
              </Copyright>
            </Column>
          </Row>
          {secondaryLinks && (
            <SecondaryLinksRow>
              <Column>
                <SecondaryLinks data-bdd-selector="footer-default-secondary-links">
                  {secondaryLinks.map(({ _id, pageTitle, pageUrl, target }) => (
                    <SecondaryLinksItem key={_id}>
                      <Link
                        route={pageUrl}
                        target={getTargetWindow(target)}
                        data-bdd-selector="footer-default-secondary-link"
                      >
                        {pageTitle}
                      </Link>
                    </SecondaryLinksItem>
                  ))}
                </SecondaryLinks>
              </Column>
            </SecondaryLinksRow>
          )}
          {disclaimer && (
            <Disclaimer data-bdd-selector="footer-default-disclaimer">
              <RichText html={disclaimer} />
            </Disclaimer>
          )}
        </Container>
      </StyledFooter>
    )
  } catch (e) {
    return null
  }
}

export default FooterDefault
