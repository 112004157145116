import React from 'react'

import { Props as ArticleCardProps } from '../../atoms/ArticleCard/types'
import { ButtonType } from '../../atoms/Button/types'
import Link from '../../atoms/Link'

import {
  StyledArticleCard,
  StyledCarousel,
  StyledButton as Button,
} from './styles'
import { Props } from './types'

const ArticleCarousel: React.FunctionComponent<Props> = (props) => {
  const { cards, className, textLimit = true, colorTheme } = props

  const carouselItems = cards.map((card, index) => {
    const _props: ArticleCardProps = {
      ...card,
      textLimit,
    }

    if (textLimit) {
      _props.descriptionLimit = 3
      _props.titleLimit = 3
    }

    return <StyledArticleCard key={index} {..._props} />
  })

  return (
    <>
      <StyledCarousel
        data-bdd-selector="article-carousel"
        isSelectedArticlesCarousel={true}
        className={className}
        colorTheme={colorTheme}
      >
        {carouselItems}
      </StyledCarousel>
      <Link route="/wealth-lens">
        <Button label="View More" buttonType={ButtonType.Primary} />
      </Link>
    </>
  )
}

export default ArticleCarousel
