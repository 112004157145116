import { css } from 'styled-components'

import SchrodersCircularBold from '../../public/static/fonts/SchrodersCircular-Bold.otf'
import SchrodersCircularLight from '../../public/static/fonts/SchrodersCircular-Light.otf'
import SchrodersCircularLightItalic from '../../public/static/fonts/SchrodersCircular-LightItalic.otf'
import SchrodersCircularNormal from '../../public/static/fonts/SchrodersCircular-Normal.otf'
import SchrodersCircularItalic from '../../public/static/fonts/SchrodersCircular-NormalItalic.otf'

// // Style Linking using @font-face
// // Design uses just these 3 fonts for now. Have added other fonts to the repo but not requiring it in code
export default css`
  @font-face {
    font-family: 'SchrodersCircular';
    src: url(${SchrodersCircularLight}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SchrodersCircular';
    src: url(${SchrodersCircularNormal}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SchrodersCircular';
    src: url(${SchrodersCircularBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SchrodersCircular';
    src: url(${SchrodersCircularItalic}) format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SchrodersCircular';
    src: url(${SchrodersCircularLightItalic}) format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
`
