import { Fragment, FunctionComponent } from 'react'
import { ReactSVG } from 'react-svg'

import { Column, Row } from '../../atoms'
import { CallToAction } from '../../molecules'

import {
  FactDescription,
  FactHeadline,
  Facts,
  FactTextWrapper,
  FactWrapper,
  KeyFactsContainer,
  MainHeadline,
  SvgIconContainer,
} from './styles'
import { Props } from './types'

const KeyFacts: FunctionComponent<Props> = (props) => {
  const {
    data: {
      componentTheme: { colour },
      headline,
      facts,
      callToAction,
    },
  } = props

  return (
    <KeyFactsContainer data-bdd-selector="key-facts" colorTheme={colour}>
      <Row>
        <Column tablet={[6, 1]} desktop={[8, 2]}>
          {headline && (
            <MainHeadline
              html={headline}
              colorTheme={colour}
              data-bdd-selector="key-facts-headline"
            />
          )}
        </Column>
      </Row>
      <Row>
        <Column tablet={8} desktop={[10, 1]}>
          <Facts data-bdd-selector="key-facts-list">
            {facts.map((fact, index) => {
              return (
                <Fragment key={index}>
                  <FactWrapper
                    key={`fact-${index}`}
                    data-bdd-selector={`key-facts-item-${index + 1}`}
                  >
                    <SvgIconContainer colorTheme={colour}>
                      {fact.svgIcon.file.url && (
                        <ReactSVG src={fact.svgIcon.file.url} />
                      )}
                    </SvgIconContainer>
                    <FactTextWrapper>
                      {fact.headline && (
                        <FactHeadline
                          element={5}
                          level={5}
                          data-bdd-selector={`key-facts-item-${
                            index + 1
                          }-headline`}
                        >
                          {fact.headline}
                        </FactHeadline>
                      )}
                      {fact.description && (
                        <FactDescription
                          html={fact.description}
                          data-bdd-selector={`key-facts-item-${
                            index + 1
                          }-description`}
                        />
                      )}
                    </FactTextWrapper>
                  </FactWrapper>
                </Fragment>
              )
            })}
          </Facts>
        </Column>
      </Row>
      {callToAction && (
        <CallToAction
          {...callToAction}
          iconBeforeText={false}
          data-bdd-selector="callout-banner-button"
        />
      )}
    </KeyFactsContainer>
  )
}

export default KeyFacts
