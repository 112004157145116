import React, { FunctionComponent } from 'react'

import {
  LoadingSpinnerAnimationSegment,
  LoadingSpinnerContainer,
} from './styles'
import { Props } from './types'

const LoadingSpinner: FunctionComponent<Props> = (props) => {
  const { isSmall, isDark } = props

  return (
    <LoadingSpinnerContainer isSmall={isSmall}>
      <LoadingSpinnerAnimationSegment isDark={isDark} />
      <LoadingSpinnerAnimationSegment isDark={isDark} />
      <LoadingSpinnerAnimationSegment isDark={isDark} />
    </LoadingSpinnerContainer>
  )
}

export default LoadingSpinner
