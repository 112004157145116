import { Form, Formik } from 'formik'
import React, { useState } from 'react'

import { POSTCODE_ERROR_MESSAGE } from '../../../../constants'
import { GetThemeContext, ThemeContext } from '../../../../context'
import { ButtonType } from '../../atoms/Button/types'

import {
  InputWrapper,
  StyledButton,
  StyledInput,
  StyledMessage,
} from './styles'
import { Props } from './types'

const AdviserLocatorPostcodeSearch: React.FunctionComponent<Props> = (
  props
) => {
  const colorTheme = GetThemeContext(ThemeContext)
  const { className, onSubmit, hasErrors } = props

  const [value, setValue] = useState('')
  const [isTouched, setIsTouched] = useState(false)
  const initialValues = {}

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setIsTouched(true)
  }

  const handleFocus = () => {
    setIsTouched(true)
  }

  const handleSubmit = () => {
    setIsTouched(true)
    onSubmit(value)
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <Form
        className={className}
        autoComplete="off"
        data-bdd-selector="adviser-locator-postcode-search"
      >
        <InputWrapper>
          <StyledInput
            name="search"
            aria-label="Postcode input"
            placeholder="E.g. EC2Y 5AU"
            value={value}
            isTouched={isTouched}
            onFocus={handleFocus}
            errorsInField={hasErrors}
            onChange={handleChange}
            colorTheme={colorTheme}
            data-bdd-selector="adviser-locator-postcode-search-input"
          />
          <StyledButton
            buttonType={ButtonType.Primary}
            isButton={true}
            label="Search"
            iconType="ChevronRight"
            type="submit"
            data-bdd-selector="adviser-locator-postcode-search-button"
          />
        </InputWrapper>

        {hasErrors && (
          <StyledMessage data-bdd-selector="adviser-locator-postcode-search-error-message">
            {POSTCODE_ERROR_MESSAGE}
          </StyledMessage>
        )}
      </Form>
    </Formik>
  )
}

export default AdviserLocatorPostcodeSearch
