import {
  BOOK_A_CALL_FIELDS,
  GATED_FIELDS,
  NEWSLETTER_FIELDS,
} from '../../components/common/molecules/Form/baseData'
import { FormElement } from '../../components/common/molecules/Form/types'
import { LeadSource } from '../../components/common/molecules/FormWrapper/types'

export type FormType = 'Newsletter' | 'Gated Content' | 'Book a Call'

export const buildFields = (
  formType: FormType,
  formFields: any
): FormElement[] => {
  switch (formType) {
    case 'Newsletter':
      return spliceOptional(NEWSLETTER_FIELDS, formFields)
    case 'Gated Content':
      return spliceOptional(GATED_FIELDS, formFields)
    case 'Book a Call':
      return spliceOptional(BOOK_A_CALL_FIELDS, formFields)
    default:
      return formFields
  }
}

export const spliceOptional = (
  baseFields: FormElement[],
  formFields: FormElement[]
) => {
  const response = [...baseFields]
  for (const field of formFields) {
    if (field.sequence) {
      response.splice(field.sequence - 1, 0, field)
    } else {
      response.push(field)
    }
  }
  return response
}

export const getLeadSource = (
  sourceOne: LeadSource,
  sourceTwo: LeadSource
): {
  primaryLeadSource: string | undefined
  secondaryLeadSource: string | undefined
} => {
  const employerPrimaryLeadSource = sourceOne
    ? sourceOne.primaryLeadSourceKey
    : undefined
  const formPrimaryLeadSource = sourceTwo
    ? sourceTwo.primaryLeadSourceKey
    : undefined
  const primaryLeadSource = employerPrimaryLeadSource
    ? employerPrimaryLeadSource
    : formPrimaryLeadSource

  const employerSecondaryLeadSource = sourceOne ? sourceOne.key : undefined
  const formSecondaryLeadSource = sourceTwo ? sourceTwo.key : undefined
  const secondaryLeadSource = employerSecondaryLeadSource
    ? employerSecondaryLeadSource
    : formSecondaryLeadSource
  return { primaryLeadSource, secondaryLeadSource }
}
