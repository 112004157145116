import React from 'react'
import TextTruncate from 'react-text-truncate'
import { ARTICLE_URL } from '../../../../constants/routes'
import { articlePublishedDate } from '../../../../utils/dates'

import {
  Content,
  Description,
  Footer,
  LinkWrapper,
  Published,
  ReadTime,
  StyledImage,
  Tag,
  Title,
  Wrapper,
} from './styles'
import { Props } from './types'

const ArticleCard: React.FunctionComponent<Props> = (props) => {
  const {
    contentFormatTag,
    cardTitle,
    cardDescription,
    publishedDate,
    sys,
    metaData,
    timeOnPage,
    className,
    backgroundImage,
    slug,
    titleLimit = 0,
    descriptionLimit = 0,
    textLimit,
    index,
  } = props

  return (
    <LinkWrapper route={`${ARTICLE_URL}/${slug}`} className={className}>
      <Wrapper
        className={className}
        hasTextLimit={textLimit}
        index={index}
        data-bdd-selector="article-card"
      >
        {backgroundImage && (
          <StyledImage data={backgroundImage} keepRatio={true} size="small" />
        )}
        <Content>
          {contentFormatTag && <Tag>{contentFormatTag}</Tag>}
          {cardTitle && (
            <Title>
              {titleLimit ? (
                <TextTruncate
                  line={titleLimit}
                  element="span"
                  truncateText="…"
                  text={cardTitle}
                />
              ) : (
                cardTitle
              )}
            </Title>
          )}
          {cardDescription && (
            <Description>
              {descriptionLimit ? (
                <TextTruncate
                  line={descriptionLimit}
                  element="span"
                  truncateText="…"
                  text={cardDescription}
                />
              ) : (
                cardDescription
              )}
            </Description>
          )}
          {((sys && sys.publishedAt) ||
            publishedDate ||
            timeOnPage ||
            (metaData && metaData.timeOnPage)) && (
            <Footer>
              {sys && sys.publishedAt && (
                <Published>{articlePublishedDate(sys.publishedAt)}</Published>
              )}
              {publishedDate && (
                <Published>{articlePublishedDate(publishedDate)}</Published>
              )}
              {timeOnPage && <ReadTime>{timeOnPage}</ReadTime>}
              {metaData && metaData.timeOnPage && (
                <ReadTime>{metaData.timeOnPage}</ReadTime>
              )}
            </Footer>
          )}
        </Content>
      </Wrapper>
    </LinkWrapper>
  )
}

export default ArticleCard
