import { rgba } from 'polished'
import styled from 'styled-components'

import { colorTheme } from '../../../../styles/utils'
import ParagraphStyles from '../../atoms/Paragraph/styles'
const {
  styles: { largeStyles },
} = ParagraphStyles

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 3.6rem;
  background-color: ${(props) => props.theme.colors.background.white};
  border-radius: 0 1.2rem 1.2rem 0;
  box-shadow: ${(props) =>
    `0 0.2rem 2.6rem 0 ${rgba(props.theme.colors.ui.black, 0.1)}`};

  ul {
    li,
    li a {
      line-height: ${(props) => props.theme.lineHeights.base};
      display: flex;
      align-items: center;
    }

    li svg {
      height: 1.15em;
      min-width: 1.25em;
      margin-right: 1em;
      fill: ${(props) => props.theme.colors.ui.beigeMedium};
    }

    li a {
      cursor: pointer;
      text-decoration: underline;

      &:focus,
      &:active,
      &:hover {
        color: ${(props) =>
          colorTheme(props.colorTheme, 'contactCard.textHoverThemeColor')};

        svg {
          fill: ${(props) =>
            colorTheme(props.colorTheme, 'contactCard.iconHoverThemeColor')};
        }
      }
    }

    li + li {
      padding-top: 3rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    border-radius: 1.2rem;
  }
`

export const StyledHeader = styled.h5`
  ${largeStyles}

  font-weight: ${(props) => props.theme.fontWeights.bold};

  & + ul {
    margin-top: 2.5rem;
  }
`
